/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * DropdownContext Component
 * Description: This component creates a React context for managing the state related to the dropdown. It provides a 'Context' with an initial value of 'null'. The 'DropdownContext' component takes 'children' and 'value' as props, where 'children' represents the content inside the context provider, and 'value' is the context value. The 'useDropdownContext' hook allows accessing the context within functional components.
 *
 * Props:
 * - children: React.ReactNode - The content to be rendered inside the context provider.
 * - value: any - The context value to be provided.
 *
 * Returns:
 * - Context.Provider: A provider component for the context with the specified value.
 *
 * Purpose:
 * - Creates a context to share dropdown-related state across components
 * - Uses React.createContext to define a context with an initial value of null
 * - Memoizes the context value to optimize performance using React.useMemo
 * - Exports a hook, useDropdownContext, for consuming the context in functional components
 * - Wraps its children inside a Context.Provider with the provided value
 *
 */
import React from 'react';

const Context = React.createContext<any>(null);

const DropdownContext = ({ children, value }: { children: React.ReactNode; value: any }) => {
    const context = React.useMemo(() => {
        return { value };
    }, [value]);

    return <Context.Provider value={context}>{children}</Context.Provider>;
};

export function useDropdownContext() {
    return React.useContext(Context);
}

export default DropdownContext;
