/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * OnlineBookingPage Component
 * Description: This component serves as a page for managing online booking settings.
 * It includes the OnlineBooking component to handle specific online booking configurations like stripe connectivity/disconnectivity color setting for booking customer widget.
 *
 */

import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Layout } from '@components/common';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import { OnlineBooking } from '@components/settings';
import { useTranslation } from 'react-i18next';

export default function OnlineBookingPage() {
    let _userData = JSON.parse(localStorage.getItem('user') || '{}')?.user?.business_id;
    const { t } = useTranslation();

    return (
        <Wrapper>
            <Row>
                <Column lg={12}>
                    {_userData?.leftMenu === true && (
                        <Link to="/settings">
                            <Icon className="fal fa-chevron-left"></Icon>
                        </Link>
                    )}
                    <Header>{t('Online Booking')}</Header>
                </Column>
            </Row>
            <OnlineBooking />
        </Wrapper>
    );
}

const Wrapper = styled(Container)``;

const Column = styled(Col)`
    display: flex;
    align-items: center;
    margin: 1rem 0;
`;

const Header = styled.h1`
    font-size: 1.875rem;
    font-weight: 600;
`;

const Icon = styled.i`
    font-size: 1.75rem !important;
    line-height: 2.25rem !important;
    margin-right: 1.25rem !important;
`;

OnlineBookingPage.Layout = Layout;
