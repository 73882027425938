/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Component: DropdownMenu
 * Description: Represents a menu within a dropdown component.
 * Purpose:
 * - Conditionally renders a menu based on the dropdown context
 * - Accepts optional className for additional styling
 * - Supports a scrollable option for longer menus
 * - Designed to be used in conjunction with the Dropdown component
 *
 * Props:
 * - className: string - Additional CSS class for styling.
 * - scrollable: boolean - Indicates whether the dropdown menu is scrollable.
 * - children: React.ReactNode - Content to be displayed within the dropdown menu.
 *
 */

import { useDropdownContext } from '../dropdown/DropdownContext';

const DropdownMenu = ({
    className,
    scrollable,
    children,
}: {
    className?: string;
    scrollable?: boolean;
    children?: React.ReactNode;
}) => {
    const context = useDropdownContext();

    return (
        context.value.isOpen && (
            <div className={`dropdown--menu ${className ?? ''}`} role="menu" tabIndex={-1}>
                {scrollable ? <div className="dropdown--scrollable">{children}</div> : children}
            </div>
        )
    );
};

export default DropdownMenu;
