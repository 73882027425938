/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * TeamOptions Component
 * Description: This component provides options for different types of team members such as service providers, team members, and others. It displays a list of options with titles and subtitles, and clicking on an option redirects the user to the corresponding team member list or card.
 *
 */

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import { Link, Routes, Route, useNavigate } from 'react-router-dom';
import { ServiceProviderCard, TeamCard, OthersCard } from '@components/team';
import { Header } from '@components/common';
import { HeaderContent } from '@components/common/layout/Layout';
import { useTranslation } from 'react-i18next';
import { palette } from 'styled/common';

const TeamOptions = () => {
    const { t }: any = useTranslation();
    const navigate = useNavigate();
    let _userData = JSON.parse(localStorage.getItem('user') || '{}');

    const [teamOptionList, setTeamOptionList] = useState([
        {
            link: '/team/service-provider-list',
            title: t('Service Providers'),
            subTitle: t('Team members with bookable schedules.'),
        },
        {
            link: '/team/team-list',
            title: t('Team Members'),
            subTitle: t('Team members with unbookable schedules.'),
        },
        {
            link: '/team/other-list',
            title: t('Others'),
            subTitle: t('Team members who simply need access.'),
        },
    ]);

    const callServiceProvider = () => {};
    const callTeam = () => {};
    const callOther = () => {};

    useEffect(() => {
        if (_userData.user_login == 'serviceProvider') {
            navigate(`/team/service-provider-list/${_userData.user._id}`);
        }
        if (_userData.user_login == 'teamMember') {
            navigate(`/team/team-list/${_userData.user._id}`);
        }
        if (_userData.user_login == 'others') {
            navigate(`/team/other-list/${_userData.user._id}`);
        }
    }, []);

    return (
        <>
            <Wrapper>
                <HeaderContent smallScreen={true} display={true}>
                    <Header activeRoute={{ title: 'Team', icon: 'far fa-users', path: '/team' }} />
                </HeaderContent>
                <Row>
                    {_userData.user_login == 'user' ? (
                        <>
                            {teamOptionList.map((list, index) => {
                                return (
                                    <Col
                                        key={index}
                                        md={6}
                                        lg={6}
                                        style={{ marginBottom: '1.5rem' }}>
                                        <CustomLink to={list.link}>
                                            <div>
                                                <Heading>{list.title}</Heading>
                                                <Text>{list.subTitle}</Text>
                                            </div>
                                            <div>
                                                <Arrow>
                                                    <i className="fal fa-chevron-right"></i>
                                                </Arrow>
                                            </div>
                                        </CustomLink>
                                    </Col>
                                );
                            })}
                        </>
                    ) : _userData.user_login == 'serviceProvider' ? (
                        <Routes>
                            <Route
                                path=":id"
                                element={
                                    <ServiceProviderCard
                                        callServiceProvider={callServiceProvider}
                                    />
                                }
                            />
                        </Routes>
                    ) : _userData.user_login == 'teamMember' ? (
                        <Routes>
                            <Route path=":id" element={<TeamCard callTeam={callTeam} />} />
                        </Routes>
                    ) : _userData.user_login == 'others' ? (
                        <Routes>
                            <Route path=":id" element={<OthersCard callOther={callOther} />} />
                        </Routes>
                    ) : (
                        ''
                    )}
                </Row>
            </Wrapper>
        </>
    );
};

const Wrapper = styled(Container)`
    max-width: 100%;
`;

const CustomLink = styled(Link)`
    display: flex;
    justify-content: space-between;
    background: ${palette.white};
    box-shadow: inset -1.80118px -2.70178px 9.00592px ${palette.box_hadow_color};
    border-radius: 4.50296px;
    padding: 2rem 2rem;
    align-items: center;
    text-decoration: none;
`;

const Heading = styled.h1`
    color: #263446;
    font-size: 1.13rem;
    font-weight: 500;
`;

const Text = styled.p`
    color: #a8aeb5;
    font-size: 0.9rem;
`;

const Arrow = styled.span`
    color: #a8aeb5;
    font-size: 1.2rem;
`;

export default TeamOptions;
