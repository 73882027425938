/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Component: Input
 * Description: Custom input component with dropdown functionality.
 * Purpose:
 * - Provides an input field with dropdown functionality for selecting options from a list.
 * - Supports features like autocomplete, custom height, and number input validation.
 * - Can be used for various scenarios like selecting dates, services, etc.
 *
 * Props:
 * - name: string - The name of the input field.
 * - type: string - The type of the input field (e.g., 'text', 'number').
 * - id: string - The ID of the input field.
 * - value: string | number - The current value of the input field.
 * - label: React.ReactNode - The label for the input field.
 * - className: string - Additional class names for styling.
 * - readOnly: boolean - Specifies whether the input field is read-only.
 * - disabled: boolean - Specifies whether the input field is disabled.
 * - options: Option[] - The list of options for the dropdown.
 * - slotLeft: React.ReactNode - Custom content to be displayed to the left of the input field.
 * - maxLength: any - The maximum length of the input value.
 * - allowNumber: boolean - Specifies whether to allow only numeric input.
 * - onBlur: () => void - Callback function triggered when the input field loses focus.
 * - onInput: (value: string) => void - Callback function triggered on input change.
 * - onChange: (value: any) => void - Callback function triggered when the selected option changes or new option selected.
 * - customHeight: any - Custom height for the dropdown content.
 * - error: { message: string } - Error message to display below the input field.
 * - openMenue: boolean - Specifies whether the dropdown menu is open.
 * - onClick: (value: any) => void - Callback function triggered on input click.
 */

import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { scrollIntoView } from '../utils';
import { useTranslation } from 'react-i18next';
import { palette } from '../../../styled/common';

interface Option {
    label: string;
    value?: string | number;
    render?: string;
}

export type FocusDirection = 'up' | 'down' | 'pageup' | 'pagedown' | 'first' | 'last';

export interface InputProps {
    name?: string;
    type?: string;
    id?: string;
    value?: string | number;
    label?: any;
    className?: string;
    readOnly?: boolean;
    disabled?: boolean;
    options: Option[];
    slotLeft?: React.ReactNode;
    maxLength?: any;
    allowNumber?: boolean;
    onBlur?(): void;
    onInput?(value: string): void;
    onChange?(value: any): void;
    openMenue?: boolean;
    customHeight?: any;
    onClick?(value: any): void;
    error?: {
        message: string;
    };
}

const Input = ({
    name,
    type,
    id,
    value,
    options,
    label,
    className,
    readOnly,
    disabled,
    slotLeft,
    maxLength,
    allowNumber,
    onBlur,
    onInput,
    onChange,
    customHeight,
    error,
    openMenue,
    onClick,
    ...props
}: InputProps) => {
    const [searchValue, setSearchValue] = useState('');
    const [focusedOption, setFocusedOption] = useState<Option | null>(null);
    const [scrollToFocusedOption, setScrollToFocusedOption] = useState(false);
    const [isActive, setActive] = useState(false);
    const [isFocused, setFocused] = useState(false);
    const [inputValue, setInputValue] = useState<string | number>('');
    const inputRef = useRef<HTMLInputElement>(null);
    const focusedOptionRef = useRef<HTMLDivElement>(null);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const { t }: any = useTranslation();

    if (readOnly === undefined) {
        readOnly = false;
    }

    useEffect(() => {
        setInputValue(value ?? '');
    }, [value]);

    useEffect(() => {
        if (inputRef.current && inputRef.current.value?.length > 0) setActive(true);
        // Check if input value was a value rather than a label
        const selectedOption = options.find(option => option.value === inputValue);
        if (selectedOption) setInputValue(selectedOption.label);
    }, []);

    useEffect(() => {
        if (dropdownRef.current && focusedOptionRef.current && scrollToFocusedOption) {
            scrollIntoView(dropdownRef.current, focusedOptionRef.current);
            setScrollToFocusedOption(false);
        }
    }, [scrollToFocusedOption, focusedOptionRef]);

    const focusInput = () => {
        if (!isFocused) {
            setActive(true);
            setFocused(true);
            if (inputRef.current) {
                inputRef.current.focus();
            }
            setTimeout(() => {
                if (focusedOptionRef.current && dropdownRef.current) {
                    scrollIntoView(dropdownRef.current, focusedOptionRef.current);
                }
            });
        } else {
            setFocused(false);
        }
    };

    const unfocusInput = (event: React.FocusEvent<HTMLInputElement>) => {
        if (!disabled) {
            if (!event.relatedTarget) {
                setFocused(false);
                if (inputRef.current && inputRef.current.value?.length === 0) setActive(false);
            } else {
                focusInput();
            }
            if (onBlur) onBlur();
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        if (!disabled) {
            if (allowNumber) {
                const re = /^[0-9\b]+$/;
                let val: any = event.target.value.replaceAll(' ', '');
                if (val == '') {
                    val = 0;
                }
                if (re.test(val) && parseInt(val) == 12 && maxLength == 2) {
                    setInputValue(event.target.value);
                    setSearchValue(event.target.value);
                    if (onInput) onInput(event.target.value);
                } else if (re.test(val) && parseInt(val) == 2022 && maxLength == 4) {
                    setInputValue(event.target.value);
                    setSearchValue(event.target.value);
                    if (onInput) onInput(event.target.value);
                }
            } else {
                setInputValue(event.target.value);
                setSearchValue(event.target.value);
                if (onInput) onInput(event.target.value);
            }
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (!readOnly) {
            const validKeys = [' ', 'ArrowUp', 'ArrowDown', 'Enter'];
            if (validKeys.indexOf(event.key) !== -1) {
                event.preventDefault();
                if (event.key === 'ArrowDown') {
                    focusOption('down');
                }
                if (event.key === 'ArrowUp') {
                    focusOption('up');
                }
                if (event.key === 'Enter') {
                    if (!focusedOption) return;
                    selectOption(focusedOption);
                }
            }
        }
    };

    const focusOption = (direction: FocusDirection = 'first') => {
        if (!options.length) return;
        let nextFocus = 0; // handles 'first'
        let focusedIndex = options.indexOf(focusedOption!);
        if (!focusedOption) {
            focusedIndex = -1;
        }
        switch (direction) {
            case 'up':
                nextFocus = focusedIndex > 0 ? focusedIndex - 1 : options?.length - 1;
                break;
            case 'down':
                nextFocus = (focusedIndex + 1) % options?.length;
                break;
            default:
                break;
        }
        setScrollToFocusedOption(true);
        setFocusedOption(options[nextFocus]);
    };

    //label: string, value: string, render?: string
    const selectOption = (option: Option) => {
        if (option.render) setInputValue(option.render);
        else setInputValue(option.label);
        // inputRef.current?.blur(); // Doesn't work for now cause of asynchronous state change, which doesn't update the value of input in time
        setFocused(false);
        // Reset search value
        setSearchValue('');
        if (onChange) onChange((option.value as string) || option.label || '');
    };

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target as Node) &&
                !inputRef.current?.contains(event.target as Node)
            ) {
                setTimeout(() => {
                    setFocused(false);
                    setActive(false);
                }, 100);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [isFocused]);

    return (
        <div className={`dropdown ${className ?? ''}`} tabIndex={-1} onKeyDown={handleKeyDown}>
            <div
                className={`select--container select--active ${
                    isFocused ? 'select--focused select--opened' : ''
                }`}
                style={{ borderColor: error && '#eb5353' }}
                onClick={() => focusInput()}>
                <div className="select--wrapper" style={{ borderColor: error && '#eb5353' }}>
                    <div className="select--label-wrapper">
                        <label style={{ color: error && '#eb5353' }}>{label}</label>
                    </div>
                    <div className="select--input--wrapper">
                        {slotLeft && <div className="select--leftSlot">{slotLeft}</div>}
                        <input
                            style={{ caretColor: disabled ? 'transparent' : 'auto' }}
                            ref={inputRef}
                            placeholder={!isActive ? label : ''}
                            id={name}
                            name={name}
                            type={type ? type : 'text'}
                            onChange={event => handleInputChange(event)}
                            value={inputValue}
                            className={className}
                            // disabled={disabled}
                            readOnly={readOnly}
                            autoComplete="none"
                            onBlur={e => unfocusInput(e)}
                            onClick={onClick}
                            maxLength={maxLength}
                        />
                    </div>
                    <div className="select--icon">
                        <i className="fa fal fa-chevron-down" style={{ fontWeight: 300 }}></i>
                    </div>
                </div>
            </div>
            {error && <Message>{error.message}</Message>}
            {isFocused && (
                <div
                    className={`${readOnly === false ? 'dropdown--content' : 'dropdown-content'}`}
                    style={{ maxHeight: customHeight }}
                    ref={dropdownRef}>
                    <div className="dropdown--wrapper">
                        <ul>
                            {options
                                ?.filter(option => {
                                    if (
                                        option.label
                                            .toLowerCase()
                                            .indexOf(searchValue.toLowerCase()) === -1
                                    ) {
                                        return false;
                                    }
                                    return true;
                                })
                                .map((option, i: number) => {
                                    const isOptionFocused = focusedOption === option;
                                    return (
                                        <li key={i}>
                                            <div
                                                style={{
                                                    backgroundColor:
                                                        inputValue === option.label
                                                            ? palette.secondary
                                                            : '',
                                                    color:
                                                        inputValue === option.label
                                                            ? palette.white
                                                            : '',
                                                }}
                                                className={`dropdown--item ${
                                                    inputValue === option.label
                                                        ? 'item--selected'
                                                        : ''
                                                } ${isOptionFocused ? 'item--focused' : ''}`}
                                                onClick={() => selectOption(option)}
                                                ref={
                                                    isOptionFocused ? focusedOptionRef : undefined
                                                }>
                                                <div className="item--text">{option.label}</div>
                                            </div>
                                        </li>
                                    );
                                })}
                            {options?.length == 0 ? (
                                <div style={{ padding: '0 0.6rem' }}>
                                    {t('No Option Available')}
                                </div>
                            ) : (
                                ''
                            )}
                        </ul>
                    </div>
                </div>
            )}
        </div>
    );
};
const Message = styled.div`
    color: #eb5353;
    background-color: white;
    border: 1px solid #eb5353;
    border-radius: 0px 0px 0.325rem 0.325rem;
    padding: 2px 10px;
    margin-top: -3px;
    font-size: 0.75rem;
    font-weight: 300;
`;
export default Input;
