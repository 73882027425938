import { api } from 'helpers/auth-axios';
import useRouter from 'hooks/router';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, media } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { palette } from 'styled/common';
import { useSnackbar } from '.';
import LoaderOverlay from './loaderOverlay';
import * as XLSX from 'xlsx';

const ServiceProviderStats = ({ serviceProviderId }: any) => {
    const { t }: any = useTranslation();
    const [openSnackbar] = useSnackbar();
    const [loading, setLoading] = useState(false);
    const [servoceProvider, setServiceProvider] = useState([]);
    const [start, setStart] = useState<any>(moment().format());
    const [endDate, setEndDate] = useState<any>(moment().format());
    const [currentWeek, setCurrentWeek] = useState(true);
    const [serviceProvidersStats, setServiceProvidersStats] = useState<any[]>([]);
    let end = moment(start).clone().startOf('week');
    const currentDate = moment(start).format();
    let endWeek = moment(end).format() === currentDate;
    const router = useRouter();
    let _userData = JSON.parse(localStorage.getItem('user') || '{}');

    useEffect(() => {
        serviceProviderData();
    }, [router.query.id]);

    const weekStart = moment(
        endWeek === true
            ? moment(end.subtract(1, 'day')).clone().startOf('week').add(1, 'day')
            : moment(start).clone().startOf('week').add(1, 'day')
    );

    const weekEnd =
        endWeek === true
            ? moment(end.subtract(1, 'day')).clone().endOf('week').add(1, 'day')
            : moment(start).clone().endOf('week').add(1, 'day');

    const serviceProviderData = async () => {
        setLoading(true);
        await api
            .get(
                `/appointments/serviceProvider_stats?id=${serviceProviderId}&from=${moment(
                    currentWeek === true ? weekStart : start
                ).format('YYYY-MM-DD')}&to=${moment(
                    currentWeek === true ? weekEnd : endDate
                ).format('YYYY-MM-DD')}`
            )
            .then((res: any) => {
                setServiceProvider(res.data?.serviceProvider);
                setServiceProvidersStats(res?.data?.serviceProviderAppointments);
                setLoading(false);
            })
            .catch((e: any) => {
                setLoading(false);
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    };

    const exportServiceProviderStatsToExcel = (singleProviderData?: any[]) => {
        let providerData = singleProviderData ? singleProviderData : serviceProvidersStats;
        const groupedStats = providerData.reduce((groups, stat) => {
            const serviceProviderId = stat.serviceProvider._id;
            const serviceProviderName = stat.serviceProvider.name;
            const combinedName = `${serviceProviderId} - ${serviceProviderName}`;
            if (!groups[combinedName]) {
                groups[combinedName] = [];
            }
            groups[combinedName].push([
                stat.customer.fullname,
                stat.booked_from_iso,
                stat.serviceProvider.name,
                stat.service.name,
                stat.status,
                stat.revenue,
            ]);
            return groups;
        }, {});

        const data = [];
        for (const combinedName in groupedStats) {
            const statsForProvider = groupedStats[combinedName];
            data.push([combinedName]);
            data.push([]);
            data.push(...statsForProvider);
            data.push([]);
        }

        const headers = [
            t('Customer Name'),
            t('Booked Time'),
            t('Service Provider'),
            t('Service'),
            t('Status'),
            t('Revenue'),
        ];
        data.unshift(headers);

        const ws = XLSX.utils.aoa_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, `ServiceProviderStats.xlsx`);
    };

    return (
        <div>
            {loading && <LoaderOverlay />}
            <CustomCol lg={12}>
                <ServiceProviders>
                    <LeaderBoardHeading>
                        <ProviderHeading>
                            <ThisWeek
                                onClick={() => {
                                    setStart(moment().format());
                                    setCurrentWeek(true);
                                }}>
                                {t('This Week')}
                            </ThisWeek>
                            <DateDiv>
                                <CalenderDate left={'3.1rem'}>
                                    <WeekDate>
                                        {moment(currentWeek === true ? weekStart : start).format(
                                            'MMM DD'
                                        )}
                                    </WeekDate>
                                    <input
                                        type="date"
                                        value={moment(start).format('YYYY-MM-DD')}
                                        onChange={(e: any) => {
                                            setStart(e.target.value);
                                            setCurrentWeek(false);
                                        }}
                                    />
                                </CalenderDate>
                                -
                                <CalenderDate left={'9rem'}>
                                    <WeekDate>
                                        {moment(currentWeek === true ? weekEnd : endDate).format(
                                            'MMM DD'
                                        )}
                                    </WeekDate>
                                    <input
                                        type="date"
                                        value={moment(endDate).format('YYYY-MM-DD')}
                                        onChange={(e: any) => {
                                            setEndDate(e.target.value);
                                            setCurrentWeek(false);
                                        }}
                                    />
                                </CalenderDate>
                                <CustomDivHeader
                                    style={{ color: 'blue' }}
                                    cursor={'pointer'}
                                    border={'none'}
                                    fontSize={'1rem'}
                                    onClick={() => exportServiceProviderStatsToExcel()}>
                                    {t('Generate Report')}
                                </CustomDivHeader>
                            </DateDiv>
                        </ProviderHeading>
                    </LeaderBoardHeading>

                    <Card>
                        {servoceProvider?.length
                            ? servoceProvider?.map((data: any, i: any) => {
                                  return (
                                      <CardContent key={i}>
                                          <ExcelIcon
                                              className="fal fa-file-excel"
                                              onClick={() =>
                                                  exportServiceProviderStatsToExcel(
                                                      data?.singleServiceProviderAppointments
                                                  )
                                              }
                                          />
                                          <Image
                                              src={`${process.env.REACT_APP_PROFILE_URL}${data?.photo}`}></Image>

                                          <Name>{data?.name}</Name>
                                          <div
                                              style={{
                                                  display: 'flex',
                                                  justifyContent: 'center',
                                                  alignItems: 'center',
                                                  gap: '0.2rem',
                                              }}>
                                              <CardHeading>{t('Work Type')}:</CardHeading>
                                              <Description>{data?.rate_type}</Description>
                                          </div>
                                          <div
                                              style={{
                                                  display: 'flex',
                                                  justifyContent: 'center',
                                                  alignItems: 'center',
                                                  gap: '0.2rem',
                                              }}>
                                              <CardHeading>
                                                  {data?.rate_type == 'daily'
                                                      ? t('Daily Rate')
                                                      : t('Percentage on appointments')}
                                                  :
                                              </CardHeading>
                                              <Description>
                                                  {data?.appointment_percenatege}{' '}
                                                  {data?.rate_type == 'daily' ? '' : '%'}
                                              </Description>
                                          </div>
                                          <BookedDiv>
                                              <Booked>
                                                  {data?.totalAppiontments} {t('Booked')}
                                              </Booked>

                                              <Revenue>
                                                  {' '}
                                                  {_userData?.user?.business_id?.currency}
                                                  {data?.totalRevenue
                                                      ? parseFloat(data?.totalRevenue).toFixed(2)
                                                      : '0.00'}
                                              </Revenue>
                                          </BookedDiv>
                                      </CardContent>
                                  );
                              })
                            : ''}
                    </Card>
                </ServiceProviders>
            </CustomCol>
        </div>
    );
};

const CustomCol = styled(Col)`
    padding: 0;
    margin-top: 1rem;
`;

const ServiceProviders = styled(Col)`
    background: ${palette.white};
    /* box-shadow: inset -1.80118px -2.70178px 9.00592px ${palette.dashboard_card_shadow}; */
    border-radius: 4.50296px;
    margin-top: 0;
    width: 100%;
    height: auto;
    min-height: 35rem;
    margin-top: 0.9rem;
    padding: 15px;
`;

const Card = styled.div`
    white-space: nowrap;
    position: absolute;
    width: 95%;
    overflow-x: auto;
    overflow-y: hidden;
    gap: 1rem;
    padding-top: 0.75rem;
    display: flex;
    justify-content: center;
`;

const CardContent = styled.div`
    height: auto;
    width: 18rem;
    background: ${palette.white};
    border: 1px solid ${palette.secondary};
    border-radius: 8px;
    display: inline-block;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-right: 1rem;
    ${media.xs`
    width: 12.5rem;
    font-size: 0.9rem;
  `}
    ${media.sm`
     font-size: 0.9rem;
  `}
`;

const Image = styled.img`
    height: 6rem;
    width: 6rem;
    border-radius: 100%;
    object-fit: cover;
    /* margin-bottom: 1rem; */
    margin-top: 0.7rem;
`;

const Name = styled.div`
    text-align: center;
    font-weight: 700;
    font-size: 17px;
    margin-bottom: 4px;
    color: ${palette.grey.dark};
`;

const Description = styled.div`
    text-align: center;
    font-weight: 500;
    font-size: 1rem;
    margin-bottom: 4px;
    color: ${palette.grey.dark};
`;
const CardHeading = styled.div`
    text-align: center;
    font-weight: 700;
    font-size: 1rem;
    margin-bottom: 4px;
    color: ${palette.grey.dark};
`;

const Number = styled.div`
    height: 1.8rem;
    width: 1.8rem;
    display: flex;
    align-items: center;
    border-radius: 100%;
    justify-content: center;
    font-weight: 500;
    font-size: 1.5rem;
    color: #f2994a;
    position: relative;
    background: ${palette.secondary};
    top: -2.5rem;
    right: 0rem;
    left: 0.5rem;
    ${media.xs`
    top: -2.5rem;
    right: 0rem;
  `}
    ${media.sm`
    top: -7.5rem;
    right: 0rem;
  `}
	${media.md`
    top: -7.5rem;
    right: 0rem;
    left: 0.5rem;
  `}
	${media.lg`
    top:-7.5rem;
    right: 0rem;
    left: 0.5rem;
  `}
`;

const BookedDiv = styled(Col)`
    display: flex;
    height: 4rem;
    justify-content: center;
    align-items: center;
`;

const Booked = styled.div`
    font-weight: 500;
    background: ${palette.secondary};
    border: 1px solid ${palette.white};
    border-radius: 8px;
    padding: 0.5rem;
    width: 10rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    color: white;
`;

const Revenue = styled.div`
    font-weight: 500;
    width: 6rem;
    background: ${palette.secondary};
    border: 1px solid ${palette.white};
    border-radius: 8px;
    padding: 0.5rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    color: white;
`;

const CalenderDate = styled.div<any>`
    display: block;
    input {
        border: none;
        box-sizing: border-box;
        outline: 0;
        position: absolute;
        left: ${({ left }) => (left ? left : '2.6rem')};
        width: 5rem;
        opacity: 0;
        cursor: pointer;
    }
    input[type='date']::-webkit-calendar-picker-indicator {
        background: transparent;
        bottom: 0;
        color: transparent;
        cursor: pointer;
        height: auto;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
    }
`;

const CustomDivHeader = styled.div<any>`
    display: block;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-style: normal;
    font-weight: 300;
    font-size: ${({ fontSize }) => (fontSize ? fontSize : '18px')};
    line-height: 18px;
    letter-spacing: 0.05em;
    color: #828282;
    cursor: ${({ cursor }) => cursor};
    ${media.xs`
   margin-top: 0.9rem;
   border-top: ${({ border }: any) => (border ? '' : '0.3px solid #a1a1a1')} ;
  `}
    ${media.sm`
    margin-top: 0.9rem;
    border-top: ${({ border }: any) => (border ? '' : '0.3px solid #a1a1a1')} ;
  `}
	${media.md`
    border-top: none;
    margin-top: 0.9rem;
  `}
	${media.lg`
    border-top: none;
    margin-top: 0rem;
  `}
`;

const DateDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.4rem;
`;

const ExcelIcon = styled.i`
    font-size: 1.3rem;
    cursor: pointer;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin: 0.5rem 0.5rem 0;
`;

const LeaderBoardHeading = styled.div`
    display: grid;
    align-items: center;
    padding-left: 1.5rem;
    padding: 1.5rem;
    padding-bottom: 0;
    border-radius: 4.50296px;
    flex-wrap: wrap;
`;

const ProviderHeading = styled.span`
    padding-bottom: 0.5rem;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 0.05em;
    display: grid;
    justify-content: start;
`;

const ThisWeek = styled.span`
    color: ${palette.blue.defaultBlue};
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 22px;
    letter-spacing: 0.05em;
`;

const WeekDate = styled.span`
    font-size: 1.15rem;
    opacity: 0.75;
    cursor: pointer;
`;

export default ServiceProviderStats;
