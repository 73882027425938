/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Timer Component
 * Description: This component represents a simple timer that increments seconds and minutes, displaying the elapsed time in the format MM:SS.
 * This timer does not pause or reset and will continue counting seconds indefinitely.
 *
 * Purpose:
 * This component is used in video call appointments to display and track the duration of the call.
 *
 */

import { useState, useEffect } from 'react';

const Timer = () => {
    const [seconds, setSeconds] = useState(0);
    const [minutes, setMinutes] = useState(0);

    useEffect(() => {
        setTimeout(() => setSeconds(seconds + 1), 1000);
        if (seconds === 60) {
            setMinutes(minutes + 1);
            setSeconds(0);
        }
    });

    return (
        <p>
            {(minutes < 10 ? '0' + minutes : minutes) +
                ':' +
                (seconds < 10 ? '0' + seconds : seconds)}
        </p>
    );
};
export default Timer;
