/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Team User Permissions Component
 *
 * Description:
 * - Displays and manages team user permissions for a specific business.
 * - Allows team users to toggle individual permissions such as dashboard, calendar, sales, etc.
 * - Utilizes Switch and Button components from common components.
 * - Communicates with the server using the api helper.
 * - Displays loading overlay during API requests.
 *
 * Props:
 * - businessId: string - The ID of the business for which permissions are being managed.
 * - defaultPermissions: IPermissions - Default permissions values to be set initially.
 */

import Button from '@components/Button';
import { Switch, useSnackbar } from '@components/common';
import { api } from 'helpers/auth-axios';
import useRouter from 'hooks/router';
import { IPermissions } from 'interfaces/permissions.interface';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { palette } from 'styled/common';
import LoaderOverlay from '../loaderOverlay';

const Permissions = ({ businessId, defaultPermissions }: any) => {
    const { t }: any = useTranslation();
    const [loading, setLoading] = useState(false);
    const [showActions, setShowActions] = useState(false);
    const [permission, setPermissions] = useState<IPermissions>();
    const [openSnackbar] = useSnackbar();
    const router = useRouter();
    let _userData = JSON.parse(localStorage.getItem('user') || '{}');

    useEffect(() => {
        if (defaultPermissions) {
            setPermissions(defaultPermissions);
        }
    }, [defaultPermissions]);

    const onSubmit = () => {
        let id: any = permission?._id;
        let data: any = {
            business_id: businessId,
            dashboard: permission?.dashboard,
            calendar: permission?.calendar,
            sales: permission?.sales,
            team: permission?.team,
            customers: permission?.customers,
            business: permission?.business,
        };
        if (id) {
            setLoading(true);
            api.put(`/permissions/${id}`, data)
                .then(res => {
                    setLoading(false);
                    setShowActions(false);
                    openSnackbar(t('Update Successfully!'));
                    getPermissionsData();
                    setPermissions(res.data);
                })
                .catch((e: any) => {
                    setLoading(false);
                    if (e?.response) {
                        openSnackbar(e?.response?.data?.message);
                    }
                });
        }
    };

    const getPermissionsData = () => {
        setLoading(true);
        api.get(`/permissions/${router.query.id as string}`)
            .then((permission: any) => {
                setLoading(false);
                setPermissions(permission.data);
            })
            .catch((e: any) => {
                setLoading(false);
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    };

    return (
        <>
            {loading && <LoaderOverlay />}
            <Container>
                <Row>
                    {permission && (
                        <CustomCol lg={12}>
                            <Options>
                                <div>
                                    <Icon className="far fa-tachometer-alt"></Icon>
                                    {t('Dashboard')}
                                </div>
                                <Switch
                                    disabled={_userData.user_login != 'user' ? true : false}
                                    onChange={(val: boolean) => {
                                        setShowActions(true);
                                        setPermissions((prevState: any) => ({
                                            ...prevState,
                                            dashboard: val,
                                        }));
                                    }}
                                    value={permission?.dashboard}
                                />
                            </Options>
                            <Hr />
                            <Options>
                                <div>
                                    <Icon className="far fa-calendar-week"></Icon>
                                    {t('Calender')}
                                </div>
                                <Switch
                                    disabled={_userData.user_login != 'user' ? true : false}
                                    onChange={(val: boolean) => {
                                        setShowActions(true);
                                        setPermissions((prevState: any) => ({
                                            ...prevState,
                                            calendar: val,
                                        }));
                                    }}
                                    value={permission?.calendar}
                                />
                            </Options>
                            <Hr />
                            <Options>
                                <div>
                                    <Icon className="far fa-receipt"></Icon>
                                    {t('Sales')}
                                </div>
                                <Switch
                                    disabled={_userData.user_login != 'user' ? true : false}
                                    onChange={(val: boolean) => {
                                        setShowActions(true);
                                        setPermissions((prevState: any) => ({
                                            ...prevState,
                                            sales: val,
                                        }));
                                    }}
                                    value={permission?.sales}
                                />
                            </Options>
                            <Hr />
                            <Options>
                                <div>
                                    <Icon className="far fa-cut"></Icon>
                                    {t('Teams')}
                                </div>
                                <Switch
                                    disabled={_userData.user_login != 'user' ? true : false}
                                    onChange={(val: boolean) => {
                                        setShowActions(true);
                                        setPermissions((prevState: any) => ({
                                            ...prevState,
                                            team: val,
                                        }));
                                    }}
                                    value={permission?.team}
                                />
                            </Options>
                            <Hr />
                            <Options>
                                <div>
                                    <Icon className="far fa-user"></Icon>
                                    {t('Customers')}
                                </div>
                                <Switch
                                    disabled={_userData.user_login != 'user' ? true : false}
                                    onChange={(val: boolean) => {
                                        setShowActions(true);
                                        setPermissions((prevState: any) => ({
                                            ...prevState,
                                            customers: val,
                                        }));
                                    }}
                                    value={permission?.customers}
                                />
                            </Options>
                            <Hr />
                            <Options>
                                <div>
                                    <Icon className="far fa-cogs"></Icon>
                                    {t('Business Settings')}
                                </div>
                                <Switch
                                    disabled={_userData.user_login != 'user' ? true : false}
                                    onChange={(val: boolean) => {
                                        setShowActions(true);
                                        setPermissions((prevState: any) => ({
                                            ...prevState,
                                            business: val,
                                        }));
                                    }}
                                    value={permission?.business}
                                />
                            </Options>
                            {showActions && (
                                <ButtonModel>
                                    <Button
                                        ifClicked={onSubmit}
                                        bgtype="secondary"
                                        label={t('Update')}></Button>
                                </ButtonModel>
                            )}
                        </CustomCol>
                    )}
                </Row>
            </Container>
        </>
    );
};

const CustomCol = styled(Col)`
    padding: 0rem;
    @media screen and (min-width: 1px) and (max-width: 900px) {
        padding: 1rem;
    }
`;

const Options = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
`;

const Icon = styled.i`
    color: ${palette.silver};
    font-size: 1.25rem;
    line-height: 1.75rem;
    margin-right: 0.5rem;
`;

const Hr = styled.div`
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
    border-bottom: 1px solid #e5e7eb;
`;

const ButtonModel = styled.div`
    padding-top: 1.5rem;
    margin-top: 1.25rem;
    display: flex;
    justify-content: flex-end;
    gap: 0.75rem;
`;

export default Permissions;
