/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Admin Component for managing business-related operations in the application.
 *
 * Admin Component Description:
 * - Fetches and displays a list of businesses.
 * - Allows viewing business details, blocking/unblocking businesses, and navigating to the business view.
 * - Renders a loader overlay while fetching data.
 *
 */

import React, { useEffect, useState } from 'react';
import { Layout } from '@components/common';
import styled from 'styled-components';
import { Col, Container, media, Row } from 'styled-bootstrap-grid';
import { api } from 'helpers/auth-axios';
import { LoaderOverlay, useSnackbar } from '@components/common';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '@components/Button';
import { palette } from 'styled/common';
import DeleteModel from '@components/common/deleteModel/DeleteModel';

const Admin = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [openSnackbar] = useSnackbar();
    const [bussines, setBusniess] = useState<any>([]);
    const navigate = useNavigate();
    const location = useLocation();
    const path = location.pathname;
    let _userData = JSON.parse(localStorage.getItem('user') || '{}');
    const { t } = useTranslation();
    const [openModel, setOpenModel] = useState(false);
    const [businessId, setBusinessId] = useState({ id: '', title: '' });

    useEffect(() => {
        getUsers();
    }, []);

    const getUsers = () => {
        setLoading(true);
        api.get('/users/all')
            .then(res => {
                setLoading(false);
                setBusniess(res.data);
            })
            .catch(err => {
                if (err?.response) {
                    setLoading(false);
                    openSnackbar(err?.response?.data?.message);
                }
            });
    };

    const ViewBusiness = (email: any) => {
        const data = {
            email: email,
            role: 'user',
            business: true,
        };
        _userData.user.role == 'ADMIN' &&
            api.post('auth/login', data).then((res: any) => {
                if (res.data) {
                    localStorage.removeItem('user');
                    localStorage.setItem('user', JSON.stringify(res.data));
                    localStorage.setItem('url', JSON.stringify(path));
                    navigate('/');
                }
            });
    };

    const BlockUser = async (id?: any, active?: any) => {
        setLoading(true);
        await api
            .put(`businesses/${id}`, { active: active })
            .then((res: any) => {
                getUsers();
                setLoading(false);
                setBusinessId({
                    id: '',
                    title: '',
                });
                setOpenModel(false);
                if (res.data.active == true) {
                    openSnackbar(t('User UnBlocked successfully!'));
                } else {
                    openSnackbar(t('User Blocked successfully!'));
                }
            })
            .catch((e: any) => {
                setLoading(false);
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    };

    const deleteBusiness = async (id?: any, active?: any) => {
        setLoading(true);
        await api
            .delete(`appointments/business/${id}`)
            .then((res: any) => {
                setLoading(false);
                getUsers();
                setBusinessId({
                    id: '',
                    title: '',
                });
                setOpenModel(false);
                openSnackbar(t('Business deleted successfully!'));
            })
            .catch((e: any) => {
                setLoading(false);
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    };

    return (
        <Wrapper id="container">
            {loading && <LoaderOverlay />}
            <Content>
                <AppointmentsDiv>
                    <Head>
                        <Heading>{t('Business Name')}</Heading>
                        <Heading>{t('Owner Full Name')}</Heading>
                        <Heading>{t('Owner Email')}</Heading>
                        <Heading>{t('Status')}</Heading>
                        <Heading>{t('View Business')}</Heading>
                        <Heading>{t('Action')}</Heading>
                    </Head>
                    <>
                        {bussines?.length >= 0 ? (
                            bussines.map((user: any, index: any) => {
                                return user.role === 'ADMIN' ? (
                                    ''
                                ) : (
                                    <Body key={index}>
                                        <List>{user?.business_id?.name}</List>
                                        <List>{user?.name}</List>
                                        <List>{user?.email}</List>
                                        <List>
                                            {user?.business_id?.active === true
                                                ? 'Active'
                                                : 'Blocked'}
                                        </List>
                                        <UnBlock onClick={() => ViewBusiness(user?.email)}>
                                            {t('View')}
                                        </UnBlock>

                                        <List style={{ display: 'flex', gap: '1rem' }}>
                                            <Block
                                                onClick={() => {
                                                    setBusinessId({
                                                        id: user?.business_id?._id,
                                                        title: 'delete',
                                                    });
                                                    setOpenModel(true);
                                                }}>
                                                {t('Delete')}
                                            </Block>
                                            {user?.business_id?.active == false ? (
                                                <UnBlock
                                                    onClick={() => {
                                                        setBusinessId({
                                                            id: user?.business_id?._id,
                                                            title: 'unblock',
                                                        });
                                                        setOpenModel(true);
                                                    }}>
                                                    {t('Unblock')}
                                                </UnBlock>
                                            ) : (
                                                <Block
                                                    onClick={() => {
                                                        setBusinessId({
                                                            id: user?.business_id?._id,
                                                            title: 'block',
                                                        });
                                                        setOpenModel(true);
                                                    }}>
                                                    {t('Block')}
                                                </Block>
                                            )}
                                        </List>
                                    </Body>
                                );
                            })
                        ) : (
                            <div> {t('No User Found!')}</div>
                        )}
                    </>
                </AppointmentsDiv>
            </Content>
            {openModel && (
                <DeleteModel
                    isAdmin={true}
                    onDelete={() => {
                        setOpenModel(false);
                        setBusinessId({
                            id: '',
                            title: '',
                        });
                    }}
                    onClose={() => {
                        setOpenModel(false);
                        setBusinessId({
                            id: '',
                            title: '',
                        });
                    }}
                    name={t(
                        businessId?.id && businessId.title == 'unblock'
                            ? 'Unblock Business'
                            : businessId?.id && businessId.title == 'block'
                            ? 'Block Business'
                            : businessId?.id && businessId.title == 'delete'
                            ? 'Delete Business'
                            : ''
                    )}
                    buttonTitle={
                        businessId?.id && businessId.title == 'unblock'
                            ? 'Unblock'
                            : businessId?.id && businessId.title == 'block'
                            ? 'Block'
                            : businessId?.id && businessId.title == 'delete'
                            ? 'Delete'
                            : ''
                    }
                    content={t(
                        businessId?.id && businessId.title == 'unblock'
                            ? 'Are you sure you want to unblock this business?'
                            : businessId?.id && businessId.title == 'block'
                            ? 'Are you sure you want to block this business?'
                            : businessId?.id && businessId.title == 'delete'
                            ? 'Are you sure you want to delete this business?'
                            : ''
                    )}
                    loading={loading}
                    onClick={() =>
                        businessId?.id && businessId.title == 'unblock'
                            ? BlockUser(businessId?.id, true)
                            : businessId?.id && businessId.title == 'block'
                            ? BlockUser(businessId?.id, false)
                            : businessId?.id &&
                              businessId.title == 'delete' &&
                              deleteBusiness(businessId?.id)
                    }
                />
            )}
        </Wrapper>
    );
};

const Wrapper = styled(Container)`
    width: 100%;
    position: relative;
    height: 100%;
    ${media.xs`
  display: block !important;
  overflow: hidden;
  max-width:100%;
`};
    ${media.sm`
display: block !important;
max-width:100%;
`};
    ${media.md`
display: flex !important;
margin:0;
min-width:100%;
`};
    ${media.lg`
display: flex !important;
`};
`;

const Content = styled(Row)`
    width: 100%;
    flex-direction: column;
`;

const AppointmentsDiv = styled(Col)`
    position: absolute;
    background-color: white;
    width: 100%;
    padding: 2.5rem;
    border-radius: 8px;
    @media screen and (min-width: 0px) and (max-width: 700px) {
        overflow-x: scroll;
        border: 0.5rem solid white;
        padding: 0rem;
    }
    @media screen and (min-width: 700px) and (max-width: 991px) {
        padding: 0.5rem;
    }
`;

const Head = styled.div`
    display: grid !important;
    grid-template-columns: repeat(6, minmax(0, 1fr)) !important;
    gap: 0.75rem !important;
    background: #f0f0f0;
    padding: 1rem;
    border-radius: 5px;
    @media screen and (min-width: 0px) and (max-width: 700px) {
        width: 60rem;
    }
`;

const Body = styled(Head)`
    background: white;
`;

const Heading = styled.div`
    width: 100%;
    height: 100%;
    overflow-wrap: break-word;
    font-weight: bold;
    font-size: 1rem;
`;

const List = styled(Heading)`
    font-weight: normal;
`;

const Block = styled.div`
    background: ${palette.primary};
    color: white;
    padding: 0.5rem;
    width: 5rem;
    font-size: 0.8rem;
    text-align: center;
    cursor: pointer;
    border-radius: 5px;
`;
const UnBlock = styled(Block)`
    background: ${palette.simpleGreen};
    border-radius: 5px;
`;

Admin.Layout = Layout;
export default Admin;
