/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Business Reducer
 * Description: This reducer handles the state related to the business, including fetching and updating business information.
 *
 * Constants:
 * - businessConstants: Constants for business actions.
 *
 * Initial State:
 * - INITIAL_STATE: Initial state for the business reducer.
 *
 * Reducer:
 * - business: Reducer function for handling business-related actions and updating the state.
 *
 */

import { businessConstants } from 'actions/business';
import { IBusiness } from 'interfaces/business.interface';

const INITIAL_STATE: IBusiness = {};
export function business(state = INITIAL_STATE, action: any) {
    switch (action.type) {
        case businessConstants.GET_BUSINESS:
            return {
                business: action.business,
            };
        case businessConstants.UPDATE_SUCCESS:
            return {
                business: action.business,
            };
        default:
            return state;
    }
}
