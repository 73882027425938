/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * CreateServiceProvider Component for creating service providers.
 *
 * Component Description:
 * - Imports necessary components and dependencies from react.
 * - Renders the CreateServiceProvider component within the specified Layout.
 * - Exports the CreateServiceProvider component to use for routing.
 */

import { Layout, CreateServiceProvider as Create } from '@components/team';

const CreateServiceProvider = () => {
    return <Create />;
};

CreateServiceProvider.Layout = Layout;
export default CreateServiceProvider;
