/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Details Component for managing business working hours details.
 *
 * Component Description:
 * - Allows users to navigate back to the settings page.
 * - Utilizes the Layout component from common components.
 *
 */

import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import { Link } from 'react-router-dom';
import { Layout } from '@components/common';
import { BusinessHours } from '@components/settings';
import { useTranslation } from 'react-i18next';

export default function Details() {
    const onChange = (day: any) => {};
    let _userData = JSON.parse(localStorage.getItem('user') || '{}')?.user?.business_id;
    const { t } = useTranslation();

    return (
        <Wrapper>
            <Row>
                <Column lg={_userData?.leftMenu === true ? 6.5 : 6}>
                    {_userData?.leftMenu === true && (
                        <Link to="/settings">
                            <Icon className="fal fa-chevron-left"></Icon>
                        </Link>
                    )}
                    <Header>{t('Business Hours')}</Header>
                </Column>
            </Row>
            <BusinessHours active={true} onChange={onChange} />
        </Wrapper>
    );
}

const Wrapper = styled(Container)``;

const Column = styled(Col)`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem 0;
`;

const Header = styled.h1`
    font-size: 1.875rem;
    font-weight: 600;
    margin: 0rem;
`;

const Icon = styled.i`
    font-size: 1.75rem !important;
    line-height: 2.25rem !important;
    margin-right: 1.25rem !important;
`;

Details.Layout = Layout;
