/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * App Entry Point
 *
 * Description: This file serves as the entry point for the React application.
 * It uses ReactDOM to render the main App component, wrapped in Redux's Provider,
 * with routing provided by BrowserRouter, and includes a SnackbarProvider for displaying notifications.
 *
 * Components:
 * - Provider: React Redux component providing the Redux store to the app.
 * - SnackbarProvider: Custom component for displaying snackbar notifications.
 * - BrowserRouter: React Router component providing navigation capabilities.
 * - App: The root component of the React application.
 *
 */

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './pages/App';

import { SnackbarProvider } from './components/common';

import { Provider } from 'react-redux';
import { store } from './helpers/store';
import './i18n/i18n';
ReactDOM.render(
    <Provider store={store}>
        <SnackbarProvider>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </SnackbarProvider>
    </Provider>,
    document.getElementById('root')
);
