/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * NotificationOptions Component
 * Description: This component provides options for managing notifications settings for different user roles.
 * It displays links to navigate to specific notification settings pages for User, ServiceProvider, and Customer.
 *
 */

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const NotificationOptions = () => {
    const navigate = useNavigate();
    let _userData = JSON.parse(localStorage.getItem('user') || '{}');
    const { t } = useTranslation();
    const [optionsList, setOptionList] = useState([
        {
            link: '/notifications/customer',
            title: t('User Notifications setting'),
            subTitle: t('Manage Email, SMS and push notifications for user.'),
        },
        {
            link: '/team/team-list',
            title: t('ServiceProvider Notifications setting'),
            subTitle: t('Manage Email, SMS and push notifications for user.'),
        },
        {
            link: '/team/other-list',
            title: t('Customer Notifications setting'),
            subTitle: t('Manage Email, SMS and push notifications for user.'),
        },
    ]);

    useEffect(() => {
        if (_userData.user_login == 'serviceProvider') {
            navigate(`/team/service-provider-list/${_userData.user._id}`);
        }
        if (_userData.user_login == 'teamMember') {
            navigate(`/team/team-list/${_userData.user._id}`);
        }
        if (_userData.user_login == 'others') {
            navigate(`/team/other-list/${_userData.user._id}`);
        }
    }, []);

    return (
        <>
            <Container>
                <Row>
                    <>
                        {optionsList.map((list, index) => {
                            return (
                                <Col key={index} md={6} lg={6} style={{ marginBottom: '1.5rem' }}>
                                    <CustomLink to={list.link}>
                                        <div>
                                            <Heading>{list.title}</Heading>
                                            <Text>{list.subTitle}</Text>
                                        </div>
                                        <div>
                                            <Arrow>
                                                <i className="fal fa-chevron-right"></i>
                                            </Arrow>
                                        </div>
                                    </CustomLink>
                                </Col>
                            );
                        })}
                    </>
                </Row>
            </Container>
        </>
    );
};

const CustomLink = styled(Link)`
    display: flex;
    justify-content: space-between;
    background: white;
    padding: 2rem 2rem;
    border-radius: 0.5rem;
    align-items: center;
`;

const Heading = styled.h1`
    color: #263446;
    font-size: 1.13rem;
    font-weight: 500;
`;

const Text = styled.p`
    color: #a8aeb5;
    font-size: 0.9rem;
`;

const Arrow = styled.span`
    color: #a8aeb5;
    font-size: 1.2rem;
`;
export default NotificationOptions;
