/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Component: DropdownToggle
 * Description: Represents a toggle button for a dropdown component.
 * Purpose:
 * - Provides a clickable area to toggle the visibility of the dropdown menu
 * - Accepts an optional onClick function for custom behavior
 * - Typically includes visual elements like an arrow icon or label
 * - Designed to be used in conjunction with the Dropdown component
 *
 * Props:
 * - onClick?: () => void - Callback function triggered on click.
 * - children?: React.ReactNode - Content to be displayed within the dropdown toggle.
 *
 */

const DropdownToggle = ({
    onClick,
    children,
}: {
    onClick?(): void;
    children?: React.ReactNode;
}) => {
    return (
        <div className="dropdown--toggle cursor-pointer" onClick={onClick}>
            {children}
        </div>
    );
};

export default DropdownToggle;
