/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * ColorPickerModel Component
 * Description: Modal component for selecting colors. Allows users to choose from a set of predefined colors and provides visual feedback for the selected color.
 * Utilizes styled-components for styling and responsiveness.
 *
 * Props:
 * - closeModal: () => void - Callback function to close the modal.
 * - selectedColor: number - The currently selected color index.
 * - onSelect: (colorIndex: number) => void - Callback function triggered when a color is selected.
 *
 * Purpose:
 * - Provides a modal for selecting colors.
 * - Users can choose from a set of predefined colors.
 * - Visual feedback is provided for the selected color.
 *
 */

import { HeaderCustom } from '@components/settings/businessServices/EditService';
import { useTranslation } from 'react-i18next';
import { Container, media, Row } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { palette } from 'styled/common';
import s from '../settings/businessServices/EditService.module.scss';
const ColorPickerModel = ({ closeModal, selectedColor, onSelect }: any) => {
    const { t } = useTranslation();

    return (
        <>
            <Model tabIndex={-1} onClick={closeModal}>
                <ModelBody>
                    <ModelHeader>
                        <HeaderCustom>
                            <Heading>{t('Choose Color')}</Heading>
                            <Cross className="fal fa-times" onClick={closeModal}></Cross>
                        </HeaderCustom>
                    </ModelHeader>
                    <ModelContent>
                        <ModelWrapper>
                            <Row style={{ justifyContent: 'center' }}>
                                {[...Array(32)].map((x, i) => (
                                    <Color key={i}>
                                        <div
                                            className={` ${s.colorPicker_serviceColor} service-color-${i}--bd-light`}
                                            onClick={() => onSelect(i)}>
                                            {selectedColor == i && (
                                                <IconCover>
                                                    <i className="fal fa-check"></i>
                                                </IconCover>
                                            )}
                                        </div>
                                    </Color>
                                ))}
                            </Row>
                        </ModelWrapper>
                    </ModelContent>
                </ModelBody>
            </Model>
        </>
    );
};

const Heading = styled.h1`
    font-weight: 600 !important;
    font-size: 1.875rem !important;
    line-height: 2.25rem !important;
    margin: 0;
`;

const Cross = styled.i`
    font-size: 1.75rem !important;
    line-height: 2.5rem !important;
    cursor: pointer !important;
`;

const Model = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background-color: ${palette.overlay};
    padding: 1.71rem;
    overflow: auto;
    overscroll-behavior-y: contain;
    z-index: 102;
`;

const ModelBody = styled.div`
    min-width: 45.71rem;
    display: flex;
    flex-direction: column;
    background: ${palette.white};
    box-shadow: inset -1.80118px -2.70178px 9.00592px ${palette.box_hadow_color};
    border-radius: 4.50296px;
    padding: 1.71rem;
    width: 50rem;
    ${media.xs`
	min-width: 25rem;
  `}
    ${media.sm`
	min-width: 35rem;
  `}
	${media.md`
	min-width: 40rem;
  `}
	${media.lg`
	min-width: 45.71rem;
  `}
`;

const ModelHeader = styled.div`
    flex: 1 1;
    display: flex;
`;

const ModelContent = styled.div`
    padding-top: 2.5rem !important;
`;

const ModelWrapper = styled(Container)`
    height: 30rem;
    overflow-y: auto;
    overflow-x: hidden;
    ${media.xs`
	height: 22rem;
  `}
    ${media.sm`
	height: 24rem;
  `}
	${media.md`
	height: 24rem;
  `}
	${media.lg`
	height: 28rem;
  `}
`;

const Color = styled.div`
    margin-bottom: 1rem;
    padding: 0.3rem;
`;

const IconCover = styled.div`
    color: ${palette.white};
    font-size: 2rem;
`;

export default ColorPickerModel;
