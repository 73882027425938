/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * useRouter Hook
 * Description: Custom React Hook for simplifying navigation and accessing route information using React Router.
 *
 * Hooks:
 * - useRouter: Custom hook providing navigation and route information using React Router, navigate, replace url, set query with url, match route, find location and get pathname of route will be return.
 */

import { useMemo } from 'react';
import { useParams, useSearchParams, useLocation, useNavigate, useMatch } from 'react-router-dom';
import queryString from 'query-string';

const useRouter = () => {
    const [searchParam, setSearchParams] = useSearchParams();
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const match = useMatch('');

    return useMemo(() => {
        return {
            navigate: navigate,
            replace: navigate,
            pathname: location.pathname,
            // Merge params and parsed query string into single "query" object
            // so that they can be used interchangeably.
            query: {
                ...queryString.parse(location.search), // Convert string to object
                ...params,
            },
            match,
            location,
            setParams: setSearchParams,
        };
    }, [params, match, location, navigate, setSearchParams]);
};

export default useRouter;
