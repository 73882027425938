/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Component: Header
 * Description: Represents the header section of the web app.
 * Purpose:
 * - Displays the application title and active route information
 * - Allows navigation through the application using a sidebar menu (for non-admin users)
 * - Integrates mobile responsiveness with an aside mobile menu
 * - Provides user navigation options such as notifications and authentication
 * - Utilizes styled-components for styling and theming
 * - Responsive design for various screen sizes
 * - Uses react-router for navigation
 *
 * Props:
 * - activeRoute: RouteLink - The currently active route, containing information about the path, title, and icon.
 *
 */

import React, { FC, useState } from 'react';
import { UserNav } from '@components/common';
import AsideMobile from '../asideMobile';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import useRouter from 'hooks/router';

interface RouteLink {
    path: string;
    title: string;
    icon: string;
    smallScreen?: any;
}

const Header: FC<{ activeRoute: RouteLink }> = ({ activeRoute }) => {
    const [open, setOpen] = useState(false);
    const [openNotificationPopup, setOpenNotificationPopup] = useState(false);
    const [openAuthPopup, setOpenAuthPopup] = useState(false);
    let _userData = JSON.parse(localStorage.getItem('user') || '{}');
    const router = useRouter();

    const openAsidNew = () => {
        if (open) {
            setOpen(false);
        } else if (open === false) {
            setOpen(true);
        }
    };

    const openNotification = () => {
        if (openNotificationPopup) {
            setOpenNotificationPopup(false);
        } else {
            setOpenAuthPopup(false);
            setOpenNotificationPopup(true);
        }
    };

    const openAuth = () => {
        if (openAuthPopup) {
            setOpenAuthPopup(false);
        } else {
            setOpenAuthPopup(true);
            setOpenNotificationPopup(false);
        }
    };

    return (
        <>
            <Container padding={activeRoute?.title === 'Customer Reported Appointments' && '0'}>
                <Flex>
                    {_userData?.user?.role === 'ADMIN' ? (
                        ''
                    ) : (
                        <Bars onClick={openAsidNew} smallScreen={activeRoute?.smallScreen}>
                            <BarIcon className="far fa-bars"></BarIcon>
                        </Bars>
                    )}
                    <Title>
                        <Icon
                            className={`${activeRoute?.icon}`}
                            onClick={() =>
                                activeRoute?.path ? router.navigate(activeRoute?.path) : ''
                            }
                            style={{ cursor: activeRoute?.path ? 'pointer' : 'auto' }}></Icon>
                        {activeRoute?.title}
                    </Title>
                </Flex>
                {_userData?.user_login === 'customer' && (
                    <UserNav
                        openNotification={openNotification}
                        openNotificationPopup={openNotificationPopup}
                        setOpenAuthPopup={setOpenAuthPopup}
                        openAuth={openAuth}
                        openAuthPopup={openAuthPopup}
                        userData={_userData}
                    />
                )}
            </Container>
            {open &&
                (_userData?.user?.role === 'ADMIN' ? (
                    ''
                ) : (
                    <AsideMobile
                        open={open}
                        openAsidNew={openAsidNew}
                        user={
                            <UserNav
                                openNotification={openNotification}
                                openNotificationPopup={openNotificationPopup}
                                setOpenAuthPopup={setOpenAuthPopup}
                                openAuth={openAuth}
                                openAuthPopup={openAuthPopup}
                                userData={_userData}
                            />
                        }
                    />
                ))}
        </>
    );
};

const Container = styled.div<any>`
    height: 64px;
    width: 100%;
    padding: ${({ padding }) => (padding ? padding : '1rem 1.5rem')};
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Flex = styled.div`
    display: flex;
    align-items: center;
`;

const Title = styled.h1`
    font-size: 1.75rem;
    line-height: 1.75rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    margin-left: 1rem;
    @media screen and (min-width: 200px) and (max-width: 363px) {
        display: none;
    }
    @media screen and (min-width: 200px) and (max-width: 370px) {
        margin-left: 0rem;
    }
    @media screen and (min-width: 371px) and (max-width: 398px) {
        margin-left: 0.5rem;
    }
    @media screen and (min-width: 1px) and (max-width: 434px) {
        font-size: 1.1rem;
    }
`;

const Bars = styled.h1<any>`
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 500;
    display: none;
    align-items: center;
    align-items: center;
    ${media.xs`
    display: ${({ smallScreen }: any) => (smallScreen ? 'none' : 'flex')};
  `}
    ${media.sm`
    display: ${({ smallScreen }: any) => (smallScreen ? 'none' : 'flex')};
  `}
    ${media.md`
	display: ${({ smallScreen }: any) => (smallScreen ? 'none' : 'flex')};
  `}
    ${media.lg`
    display: ${({ display }: any) => (display ? 'flex' : 'none')};
  `}
`;

const BarIcon = styled.i`
    font-size: 1.5rem !important;
    line-height: 2rem !important;
    margin-right: 1rem !important;
    cursor: pointer;
`;

const Icon = styled.i`
    display: block;
    font-size: 1.5rem !important;
    line-height: 2rem !important;
    margin-right: 1rem !important;
    @media screen and (min-width: 1px) and (max-width: 473px) {
        display: none !important;
    }
    ${media.xs`
    display:block;
  `}
    ${media.sm`
    display:block;
  `}
        ${media.md`
	display:block;
  `}
        ${media.lg`
    display:block;
  `}
`;

export default Header;
