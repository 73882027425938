/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Private Route Component
 * Description: This component handles private routes, ensuring that only authenticated users can access certain routes.
 */

import { Navigate } from 'react-router-dom';

interface Props {
    Component: React.FunctionComponent;
    redirectTo?: string;
}

export const PrivateRoute: React.FC<Props> = ({ Component, redirectTo, ...props }) => {
    const isAuthenticated = localStorage.getItem('user') ? true : false;

    if (!isAuthenticated) return <Navigate to={redirectTo ?? '/login'} />;

    const component = <Component {...props} />;
    const Layout = component.type?.Layout ?? <> </>;
    return <Layout>{component}</Layout>;
};
