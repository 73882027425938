/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * CreateCustomer Component for managing customer creation.
 *
 * Component Description:
 * - Imports the Create and Layout components from customers components.
 * - Renders the Create component within the specified Layout.
 * - Exports the CreateCustomer component to use for routing.
 */

import { CreateCustomer as Create, Layout } from '@components/customers';

const CreateCustomer = () => {
    return <Create />;
};

CreateCustomer.Layout = Layout;
export default CreateCustomer;
