/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Appointment Actions
 * Description: This file contains action creators related to appointments, including creating, fetching, updating, and canceling appointments.
 *
 * Constants:
 * - appointmentConstants: Constants for appointment actions.
 *
 * Actions:
 * - createAppointment: Action creator for creating a new appointment.
 * - getAppointments: Action creator for fetching appointments.
 * - getAppointmentById: Action creator for fetching a specific appointment by ID.
 * - updateAppointmentById: Action creator for updating an appointment by ID.
 * - cancelAppointmentById: Action creator for canceling an appointment by ID.
 */

import { IAppointment, IGetAppointments } from 'interfaces/appointment.interface';
import { Dispatch } from 'redux';
import { appointmentService } from 'services';

export const appointmentConstants = {
    CREATE_REQUEST: 'APPOINTMENT_CREATE_REQUEST',
    CREATE_SUCCESS: 'APPOINTMENT_CREATE_SUCCESS',
    CREATE_FAILURE: 'APPOINTMENT_CREATE_FAILURE',

    GET_REQUEST: 'APPOINTMENT_GET_REQUEST',
    GET_SUCCESS: 'APPOINTMENT_GET_SUCCESS',
    GET_FAILURE: 'APPOINTMENT_GET_FAILURE',
};

const createAppointment =
    (appointmentData: any) =>
    (dispatch: Dispatch): Promise<IAppointment> => {
        dispatch({
            type: appointmentConstants.CREATE_REQUEST,
        });

        return appointmentService.create(appointmentData).then(appointment => {
            dispatch({
                type: appointmentConstants.CREATE_SUCCESS,
                appointment,
            });

            return appointment;
        });
    };

const getAppointments =
    (getAppointmentsData: IGetAppointments) =>
    (dispatch: Dispatch): Promise<IAppointment[]> => {
        dispatch({
            type: appointmentConstants.CREATE_REQUEST,
        });

        return appointmentService.getAppointments(getAppointmentsData).then(appointments => {
            dispatch({
                type: appointmentConstants.GET_SUCCESS,
                appointments,
            });

            return appointments;
        });
    };

const updateAppointmentById =
    (appointmentId: string, appointmentData: any) =>
    (dispatch: Dispatch): Promise<IAppointment> => {
        return appointmentService
            .updateAppointmentById(appointmentId, appointmentData)
            .then(appointment => {
                return appointment;
            });
    };
const cancelAppointmentById =
    (appointmentId: string) =>
    (dispatch: Dispatch): Promise<IAppointment> => {
        return appointmentService.cancelAppointmentById(appointmentId).then(appointment => {
            return appointment;
        });
    };
const getAppoinmentById =
    (appointmentId: string) =>
    (dispatch: Dispatch): Promise<IAppointment> => {
        return appointmentService.getAppointmentById(appointmentId).then(appointment => {
            return appointment;
        });
    };

export const appointmentActions = {
    createAppointment,
    getAppointments,
    getAppoinmentById,
    updateAppointmentById,
    cancelAppointmentById,
};
