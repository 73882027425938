/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * CustomerPackages Component
 * Description: This component displays a list of packages for a customer.
 *
 * Props:
 * - customerId: string - The ID of the customer for whom packages are displayed.
 */

import { useState, useEffect } from 'react';
import { Spinner } from '@components/common';
import { IPayment } from 'interfaces/payment.interface';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { api } from 'helpers/auth-axios';
import { useTranslation } from 'react-i18next';
import { NotFound } from '@components/team/list/serviceProviderList/ServiceProviderList';
import moment from 'moment';

const CustomerPackages = ({ customerId }: { customerId?: any }) => {
    const { t } = useTranslation();
    const pageSize = 20;
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [payments, setPayments] = useState<IPayment[]>([]);
    let _userData = JSON.parse(localStorage.getItem('user') || '{}');

    useEffect(() => {
        setLoading(true);
        setPage(0);

        customerPackageDetails();
    }, [customerId]);

    const customerPackageDetails = async () => {
        setLoading(true);
        await api
            .post(`/appointments/customer/package`, { ...customerId, is_web: true })
            .then((res: any) => {
                setLoading(false);
                console.log('success', res.data);
                setPayments(res.data?.packages);
            })
            .catch((e: any) => {
                setLoading(false);
                if (e?.response) {
                    const response = e?.response?.data?.message;
                    console.log('error', response?.message);
                }
            });
    };

    const loadMore = () => {
        setPage(prevPage => prevPage + 1);
    };

    const formatTime = (hours: any) => {
        const duration = moment.duration(hours, 'minutes');
        const formattedTime = [];

        if (duration.hours() > 0) {
            formattedTime.push(`${duration.hours()} ${duration.hours() === 1 ? 'hour' : 'hours'}`);
        }

        if (duration.minutes() > 0) {
            formattedTime.push(`${duration.minutes()} ${duration.minutes() === 1 ? 'min' : 'min'}`);
        }

        return formattedTime.join(' ');
    };
    return (
        <>
            <Wrapper>
                <Row>
                    {payments?.length
                        ? payments.map((payment: any, i: number) => {
                              return (
                                  <CustomCol lg={12} key={i}>
                                      <Body>
                                          <DateWrapper>
                                              <Price>{payment?.name}</Price>

                                              {payment?.occurrences > 0 ? (
                                                  <div className="service-title">
                                                      {t('No. of Purchases')}: &nbsp;
                                                      {payment?.occurrences}
                                                  </div>
                                              ) : (
                                                  ''
                                              )}

                                              {payment?.services?.map(
                                                  (e: any, index: number, array: any[]) => (
                                                      <div
                                                          key={e.id}
                                                          style={{
                                                              alignItems: 'center',
                                                              display: 'flex',
                                                              gap: '0.1rem',
                                                          }}>
                                                          <Price style={{ marginRight: '0.4rem' }}>
                                                              {e.name}
                                                          </Price>

                                                          <span
                                                              style={{
                                                                  opacity: '0.8',
                                                                  fontSize: '0.8rem',
                                                              }}>{`(${t('Price')}: ${
                                                              _userData.user.business_id?.currency +
                                                              e?.price
                                                          }, ${t('Duration')}: ${formatTime(
                                                              e?.duration
                                                          )}, ${t('Total Appointments')}: ${
                                                              payment?.services_details?.find(
                                                                  (re: any) =>
                                                                      re?.service?._id == e?._id
                                                              )?.quantity > 0
                                                                  ? payment?.services_details?.find(
                                                                        (re: any) =>
                                                                            re?.service?._id ==
                                                                            e?._id
                                                                    )?.quantity
                                                                  : 0
                                                          }, ${t('Remaining')}: ${
                                                              payment?.services_details?.find(
                                                                  (re: any) =>
                                                                      re?.service?._id == e?._id
                                                              )?.remainingAppointments > 0
                                                                  ? payment?.services_details?.find(
                                                                        (re: any) =>
                                                                            re?.service?._id ==
                                                                            e?._id
                                                                    )?.remainingAppointments
                                                                  : 0
                                                          })`}</span>
                                                          {e?.is_video && (
                                                              <i className="fal fa-video" />
                                                          )}
                                                          {index < array.length - 1 && <br />}
                                                      </div>
                                                  )
                                              )}

                                              <div
                                                  style={{ display: 'flex', alignItems: 'center' }}>
                                                  {t('Price')}:&nbsp;
                                                  <Price>
                                                      {_userData.user.business_id?.currency}
                                                      {payment?.price}
                                                  </Price>
                                              </div>

                                              <div
                                                  style={{ display: 'flex', alignItems: 'center' }}>
                                                  {t('Discount')}:&nbsp;
                                                  <Price>{payment?.discount}%</Price>
                                              </div>
                                          </DateWrapper>
                                      </Body>

                                      {payment?.services_details?.some(
                                          (re: any) => re?.remainingAppointments > 0
                                      ) ? (
                                          <PaymentStatus className="label label-success text-sm px-3 font-medium">
                                              {t('Active')}
                                          </PaymentStatus>
                                      ) : (
                                          <PaymentStatusFinished className="label label-gray text-sm px-3 font-medium">
                                              {t('Finished')}
                                          </PaymentStatusFinished>
                                      )}
                                  </CustomCol>
                              );
                          })
                        : loading == false && <NotFound>{t('No package found')}</NotFound>}
                    {(total > (page + 1) * pageSize || loading) && (
                        <PaymentPages>
                            <Button disabled={loading} onClick={loadMore}>
                                {loading ? (
                                    <Spinner color="secondary" />
                                ) : (
                                    `${t('Load more payments')} (${total - (page + 1) * pageSize})`
                                )}
                            </Button>
                        </PaymentPages>
                    )}
                </Row>
            </Wrapper>
        </>
    );
};
const Wrapper = styled(Container)`
    @media screen and (min-width: 1px) and (max-width: 900px) {
        padding: 1rem;
    }
`;
const CustomCol = styled(Col)`
    display: flex !important;
    padding: 0px;
    align-items: center !important;
    justify-content: space-between !important;
    border-bottom-width: 1px !important;
    padding-bottom: 1rem !important;
    margin-bottom: 1rem !important;
    border-bottom: solid gray;
`;
const Body = styled.div`
    display: flex;
    align-items: center;
`;

const DateWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;
const Price = styled.div`
    margin-right: 1rem;
    font-weight: 500;
`;

const PaymentPages = styled.div`
    display: flex;
    justify-content: center;
`;
const Button = styled.button`
    padding-top: 0.5rem /* 8px */;
    padding-bottom: 0.5rem /* 8px */;
    padding-left: 2rem /* 32px */;
    padding-right: 2rem /* 32px */;
    font-weight: 500;
    font-size: 0.9rem /* 14.4px */;
    border: none;
    cursor: pointer;
`;

const PaymentStatus = styled.div`
    font-size: 0.875rem /* 14px */;
    line-height: 1.25rem /* 20px */;
    padding-left: 1.75rem !important /* 12px */;
    padding-right: 1.75rem !important /* 12px */;
    border-radius: 50px;
    font-weight: 400;
    color: #fff;
    display: flex;
    align-items: center !important;
    text-transform: capitalize;
    color: #00be70;
    background-color: #e2fff3;
`;

const PaymentStatusFinished = styled.div`
    font-size: 0.875rem /* 14px */;
    line-height: 1.25rem /* 20px */;
    padding-left: 0.75rem /* 12px */;
    padding-right: 0.75rem /* 12px */;
    border-radius: 50px;
    font-weight: 400;
    color: #fff;
    display: flex;
    align-items: center !important;
    text-transform: capitalize;
    background-color: #2b2929;
`;
export default CustomerPackages;
