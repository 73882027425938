/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * MultiSelect Component
 *
 * Description:
 * - Renders a multi-select dropdown input component using react-select library.
 * - Custom styles for react-select to match the application theme.
 * - Supports multiple options, and displays error message if any.
 * - User can select multiple options using this.
 *
 * Props:
 * - name?: string - The name attribute for the multi-select input.
 * - label?: string - The label for the multi-select input.
 * - options: Option[] - An array of Option objects representing the selectable options.
 * - onChange?: (selectedOptions: Option[]) => void - Callback function triggered when options are selected/changed.
 * - value?: any - The current selected value(s) of the multi-select input.
 * - isMulti?: any - Boolean indicating whether multiple options can be selected.
 * - noOptionsMessage?: any - Custom message to display when no options are available.
 * - defalutValue?: any - Default value for the multi-select input.
 * - required?: any - Boolean indicating whether the multi-select input is required.
 * - disabled?: any - Boolean indicating whether the multi-select input is disabled.
 * - error?: { message: string } - An object with a message property representing an error message.
 *
 * Usage:
 * - Import this component and use it where multiple options selection is needed.
 *
 */

import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { palette } from '../../../styled/common';

interface Option {
    label: any;
    value?: string | number;
    render?: string;
}

export interface InputProps {
    name?: string;
    label?: string | undefined;
    options?: Option[];
    onChange?(selectedOptions: Option[]): void;
    value?: any;
    isMulti?: any;
    noOptionsMessage?: any;
    defalutValue?: any;
    required?: any;
    disabled?: any;
    error?: {
        message: string;
    };
    hideSelectedOptions?: any;
}

const MultiSelect = ({
    name,
    label,
    options,
    onChange,
    error,
    value,
    isMulti,
    noOptionsMessage,
    defalutValue,
    required,
    disabled,
    hideSelectedOptions,
    ...props
}: InputProps) => {
    const [isFocused, setFocused] = useState(false);
    const colourStyles = {
        control: (provided: any, state: any) => {
            setFocused(state.isFocused);
            return {
                ...provided,
                color: palette.primary,
                border: 'none',
                borderColor: 'none',
                width: '100% !important',
                backgroundColor: 'transparent',
                boxShadow: 'none !important',
                '&:hover': {
                    border: 'none',
                    boxShadow: 'none !important',
                },
            };
        },

        option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
            return {
                ...styles,
                width: '100% !important',
                zIndex: 6,
                cursor: isDisabled ? 'not-allowed' : 'defult',
                // backgroundColor: '#f3f6f9',
                ':hover': {
                    backgroundColor: '#f3f6f9',
                    color: isDisabled ? null : palette.black,
                },
                ':focus': {
                    border: 'none',
                    backgroundColor: isSelected ? palette.secondary : null,
                    color: isDisabled ? null : palette.white,
                },
                ':active': {
                    backgroundColor: isSelected ? palette.secondary : null,
                    color: isDisabled ? null : palette.white,
                },
            };
        },
    };

    return (
        <>
            <div
                className={`select--container select--active  ${
                    isFocused ? 'select--focused select--opened' : ''
                }`}
                style={{ borderColor: error && '#eb5353', padding: '0.5rem 1rem' }}>
                <div
                    className="select--wrapper"
                    style={{ borderColor: error && '#eb5353', display: 'contents' }}>
                    <div className="select--label-wrapper">
                        <label style={{ color: error && '#eb5353' }}>{label}</label>
                    </div>
                    <Select
                        placeholder={label}
                        styles={colourStyles}
                        name={name}
                        value={value}
                        onChange={e => onChange && onChange(e)}
                        options={
                            value && value?.length
                                ? options?.filter(
                                      (e: any) => !value?.some((re: any) => re.label == e.label)
                                  )
                                : options
                        }
                        isMulti={isMulti}
                        isDisabled={disabled}
                        required={required}
                        loadingMessage={noOptionsMessage}
                        hideSelectedOptions={hideSelectedOptions ?? true}
                    />
                </div>
            </div>
            {error && !disabled && <Message>{error?.message}</Message>}
        </>
    );
};

const Message = styled.div`
    color: #eb5353;
    background-color: white;
    border: 1px solid #eb5353;
    border-radius: 0px 0px 0.325rem 0.325rem;
    padding: 2px 10px;
    margin-top: -3px;
    font-size: 0.75rem;
    font-weight: 300;
`;
export default MultiSelect;
