/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * BookingSettings Component
 * Description: React functional component representing the booking settings page.
 *
 * Purpose: This component allows the user to configure various booking rules and settings for their business, including cancellation policy, rescheduling policy, booking leeway, calendar size, currency, time format, book by first available, and show invice after payment of appointmennt.
 */

import { useEffect, useState } from 'react';
import Button from '@components/Button';
import styled from 'styled-components';
import { Container, Row, Col, media } from 'styled-bootstrap-grid';
import { Input, LoaderOverlay, Select, useSnackbar } from '@components/common';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { bookingWindow } from 'constants/data';
import { palette } from '../../../styled/common';
import Switch from '@components/common/switch';
import { api } from 'helpers/auth-axios';
import { CustomButtom } from '../businessDetails/BusinessDetails';
import { CustomRow } from '@components/team/create/createTeamMember/CreateTeamMember';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';

const BookingSettings = () => {
    const { t }: any = useTranslation();
    const [business, setBusiness] = useState<any>();
    const [openSnackbar] = useSnackbar();
    const [invoice, setInvoice] = useState(false);
    const [useCustomCalendar, setUseCustomCalendar] = useState<any>(false);
    const [ignoreWorkingHours, setIgnoreWorkingHours] = useState(false);
    const validationSchema = Yup.object().shape({});
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { handleSubmit, setValue, formState, trigger } = useForm(formOptions);
    const { errors, isSubmitting } = formState;
    const [loading, setLoading] = useState(false);
    let _userData = JSON.parse(localStorage.getItem('user') || '{}');

    async function onChange({ name, value }: { name: string; value: string }) {
        setValue(name as never, value as never);
        await trigger(name as never);
    }

    // Set initial values
    useEffect(() => {
        setTimeout(() => {
            setValue('cancellation_policy' as never, business?.cancellation_policy as never);
            setValue('rescheduling_policy' as never, business?.rescheduling_policy as never);
            setValue(
                'booking_reschedule_time' as never,
                business?.booking_reschedule_time as never
            );
            setValue('calendar_size' as never, business?.calendar_size as never);
        });
    }, []);

    useEffect(() => {
        getBusiness();
    }, []);

    const getBusiness = () => {
        setLoading(true);
        api.get(`/businesses/${_userData?.user?.business_id?._id}`)
            .then((res: any) => {
                if (res) {
                    setLoading(false);
                    setBusiness(res.data);
                    setInvoice(res.data.invoice);
                    setIgnoreWorkingHours(res.data.ignore_workingHours);
                    setUseCustomCalendar(res.data.customCalendar);
                }
            })
            .catch((e: any) => {
                setLoading(false);
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    };

    const onSubmit = (data: any) => {
        setLoading(true);
        api.put(`businesses/${business._id!}`, {
            ...data,
            invoice: invoice,
            ignore_workingHours: ignoreWorkingHours,
            customCalendar: useCustomCalendar,
        })
            .then((res: any) => {
                setLoading(false);
                var _userData = JSON.parse(localStorage.getItem('user') || '{}');
                if (res !== undefined) {
                    _userData.user.business_id = res.data;
                    localStorage.setItem('user', JSON.stringify(_userData));
                }
                i18n.changeLanguage(res.data.language);
                getBusiness();
                openSnackbar(t('Updated successfully!'));
            })
            .catch((e: any) => {
                setLoading(false);
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    };

    const minutesArr: any[] = [];
    for (let i = 1; i < 12; i++) {
        minutesArr.push({
            label: `${i * 5}`,
            value: i * 5,
        });
    }

    return (
        <>
            <Wrapper>
                {loading && <LoaderOverlay />}
                <CustomRow>
                    <Col lg={12}>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                margin: '1rem 0rem',
                            }}>
                            {_userData?.user?.business_id?.leftMenu === true && (
                                <Link to="/settings">
                                    <Icon className="fal fa-chevron-left"></Icon>
                                </Link>
                            )}
                            <Header>{t('Calendar Settings')}</Header>
                        </div>
                        <Card>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Wrapper>
                                    <Row>
                                        <CustomCol lg={6}>
                                            <CustomCard>
                                                <CardHead>
                                                    <Heading>{t('Booking Rules')}</Heading>
                                                    <Para>
                                                        {t(
                                                            'Write your own rules regarding booking, cancellation and rescheduling.'
                                                        )}
                                                    </Para>
                                                </CardHead>
                                                <InputGroup>
                                                    <Input
                                                        label={t('Cancellation Policy')}
                                                        name="cancellation_policy"
                                                        value={business?.cancellation_policy}
                                                        onChange={onChange}
                                                        error={errors?.cancellation_policy as any}
                                                    />
                                                </InputGroup>
                                                <InputGroup>
                                                    <Input
                                                        label={t('Rescheduling Policy')}
                                                        name="rescheduling_policy"
                                                        value={business?.rescheduling_policy}
                                                        onChange={onChange}
                                                        error={errors.rescheduling_policy as any}
                                                    />
                                                </InputGroup>
                                                <InputGroup>
                                                    <Select
                                                        label={t('Booking Leeway')}
                                                        disabled={true}
                                                        value={business?.booking_reschedule_time}
                                                        options={bookingWindow.map((val: any) => {
                                                            return {
                                                                value: val,
                                                                label: `${t(
                                                                    'No less than'
                                                                )} ${val} ${t('in advance')}`,
                                                            };
                                                        })}
                                                        onChange={(val: string) => {
                                                            onChange({
                                                                name: 'booking_reschedule_time',
                                                                value: val,
                                                            });
                                                        }}
                                                    />
                                                </InputGroup>
                                                <InputGroup>
                                                    <Select
                                                        label={t('Calendar size')}
                                                        disabled={true}
                                                        value={business?.calendar_size}
                                                        options={minutesArr}
                                                        onChange={(val: string) => {
                                                            onChange({
                                                                name: 'calendar_size',
                                                                value: val,
                                                            });
                                                        }}
                                                    />
                                                </InputGroup>
                                                <InputGroup>
                                                    <Select
                                                        label={t('Currency')}
                                                        disabled={true}
                                                        value={
                                                            business?.currency === '$'
                                                                ? 'Dollar (USD)'
                                                                : business?.currency === '£'
                                                                ? 'Pound (Pound)'
                                                                : 'Euro (Euro)'
                                                        }
                                                        options={[
                                                            {
                                                                value: '$',
                                                                label: 'Dollar (USD)',
                                                            },
                                                            {
                                                                value: '£',
                                                                label: 'Pound (Pound)',
                                                            },
                                                            {
                                                                value: '€',
                                                                label: 'Euro (Euro)',
                                                            },
                                                        ]}
                                                        onChange={(val: string) => {
                                                            onChange({
                                                                name: 'currency',
                                                                value: val,
                                                            });
                                                        }}
                                                    />
                                                </InputGroup>
                                                <InputGroup>
                                                    <Select
                                                        label={t('Military time vs Standard Time')}
                                                        value={business?.time_select}
                                                        disabled={true}
                                                        options={[
                                                            {
                                                                value: '12',
                                                                label: '12',
                                                            },
                                                            {
                                                                value: '24',
                                                                label: '24',
                                                            },
                                                        ]}
                                                        onChange={(val: string) => {
                                                            onChange({
                                                                name: 'time_select',
                                                                value: val,
                                                            });
                                                        }}
                                                    />
                                                </InputGroup>

                                                <CustomButtom>
                                                    <Button
                                                        type="submit"
                                                        bgtype="secondary"
                                                        label={t('Update')}></Button>
                                                </CustomButtom>
                                            </CustomCard>
                                        </CustomCol>
                                        <CustomCol lg={6} margin={true}>
                                            <SettingCard
                                                style={{ paddingTop: '0.17rem !important' }}>
                                                <CardList>
                                                    <div>{t('Book by First Available')}</div>
                                                    <Switch
                                                        value={
                                                            business?.booking_mode == true
                                                                ? true
                                                                : false
                                                        }
                                                        onChange={(val: any) => {
                                                            onChange({
                                                                name: 'booking_mode',
                                                                value: val,
                                                            });
                                                        }}
                                                    />
                                                </CardList>
                                                <Divider />
                                                <CardList>
                                                    <div>{t('Show Invoice')}</div>
                                                    <Switch
                                                        onChange={(val: boolean) => setInvoice(val)}
                                                        value={invoice}
                                                    />
                                                </CardList>
                                            </SettingCard>
                                        </CustomCol>
                                    </Row>
                                </Wrapper>
                            </form>
                        </Card>
                    </Col>
                </CustomRow>
            </Wrapper>
        </>
    );
};

export const Wrapper = styled(Container)`
    padding: 0rem;
`;

export const CustomCol = styled(Col)<any>`
    ${media.xs`
    border-right: none;
    margin-top: ${({ margin }: any) => (margin ? '1rem' : '')};
  `};
    ${media.sm`
    border-right: none;
    margin-top: ${({ margin }: any) => (margin ? '1rem' : '')};
  `};
    ${media.md`
    border-right: none;
    margin-top: ${({ margin }: any) => (margin ? '1rem' : '')};
  `};
    ${media.lg`
    margin-top: 0px;
  `};
`;

export const Card = styled.div`
    background: ${palette.white};
    border-radius: 4.50296px;
`;

export const CustomCard = styled.div`
    height: 100%;
    background: ${palette.white};
    box-shadow: inset -1.80118px -2.70178px 9.00592px rgba(0, 0, 0, 0.25);
    border-radius: 4.50296px;
    padding: 3rem 2rem 1.75rem 2rem !important;
`;

export const CardHead = styled.div`
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    margin-bottom: 2.5rem !important;
`;

export const Heading = styled.h1`
    font-weight: 600 !important;
    font-size: 1.25rem !important;
    line-height: 1.75rem !important;
    margin-bottom: 0.5rem !important;
`;

export const Para = styled.p`
    color: rgba(157, 160, 164) !important;
    font-weight: 300 !important;
    font-size: 0.9rem !important;
`;

export const InputGroup = styled.div`
    margin-bottom: 1.71rem;
`;

export const Header = styled.h1`
    font-size: 1.875rem;
    font-weight: 600;
    margin-bottom: 0px;
`;

export const Icon = styled.i`
    font-size: 1.75rem !important;
    line-height: 2.25rem !important;
    margin-right: 1.25rem !important;
`;

export const SettingCard = styled.div`
    height: 100%;
    background: ${palette.white};
    box-shadow: inset -1.80118px -2.70178px 9.00592px rgba(0, 0, 0, 0.25);
    border-radius: 4.50296px;
    padding: 1.26rem 2rem !important;
`;

export const CardList = styled.div`
    align-items: center !important;
    cursor: pointer !important;
    display: flex !important;
    justify-content: space-between;
`;

export const Divider = styled.hr`
    margin-top: 0.8rem !important;
    margin-bottom: 0.8rem !important;
    border: 0.1rem none none none solid ${palette.grey.lightest};
    border-top: none;
    border-right: none;
    border-left: none;
`;

export default BookingSettings;
