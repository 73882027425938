/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * TimeOff Component
 * Description: Component for managing time-off entries, allowing users to add, view, and delete time-off records.
 * Purpose:
 * - Allows users to add new time-off entries by specifying the 'from' and 'to' dates.
 * - Displays a list of existing time-off entries with 'from' and 'to' dates, and provides an option to delete service provider timeoff.
 * - Uses react-hook-form for form handling and validation.
 *
 * Props:
 * - timeOffData: any - Data representing existing time-off entries.
 * - parent: string - Parent component identifier (e.g., 'teamMember', 'service provider').
 */
import { Input, useSnackbar } from '@components/common';
import { useForm } from 'react-hook-form';
import { Col, Container, Row, media } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { ITimeOff } from 'interfaces/timeoff.interface';
import useRouter from 'hooks/router';
import moment from 'moment';
import { palette } from 'styled/common';
import DeleteModel from '../deleteModel/DeleteModel';
import { api } from 'helpers/auth-axios';
import Button from '@components/Button';
import { CustomSpan } from '../breaks/Breaks';
import { useTranslation } from 'react-i18next';
import LoaderOverlay from '../loaderOverlay';
import DatePicker, { Calendar, DateObject } from 'react-multi-date-picker';
import weekends from 'react-multi-date-picker/plugins/highlight_weekends';
import './timeOff.scss';

const TimeOff = ({ timeOffData, parent }: any) => {
    const { t }: any = useTranslation();
    const [openSnackbar] = useSnackbar();
    const [deleteModel, setDeleteModel] = useState(false);
    const [loading, setLoading] = useState(false);
    const [deleteId, setDeleteId] = useState<any>();
    const [deletedTimeOffIds, setDeletedTimeOffIds] = useState<any>([]);
    const router = useRouter();
    const [timeoff, setTimeOff] = useState<any>([]);
    const [existingDates, setExistingDates] = useState<any>([]);
    const [selectedDates, setSelectedDates] = useState<any>([]);
    const [newDates, setNewDates] = useState<any>([]);

    const formattedDates = newDates.map((date: moment.Moment) => ({
        from_iso: date.format('YYYY-MM-DD'),
        to_iso: date.format('YYYY-MM-DD'),
    }));

    const validationSchema = Yup.object().shape({
        dates: Yup.array().min(1, t('This field is required')),
    });

    const { handleSubmit, setValue, getValues, trigger } = useForm<ITimeOff>({
        resolver: yupResolver(validationSchema),
    });

    useEffect(() => {
        if (parent === 'teamMember') {
            getTimeOff();
        }
        if (timeOffData) {
            setTimeOff(timeOffData);
            setExistingDates(timeOffData.map((item: any) => item.from_iso));
        }
    }, [timeOffData, router.query.id]);

    const getTimeOff = async () => {
        setLoading(true);
        await api
            .get(`/timeoff/all/${router.query.id as string}`)
            .then((timeoff: any) => {
                setLoading(false);
                setTimeOff(timeoff.data);
                setExistingDates(timeoff.data.map((item: any) => item.from_iso));
            })
            .catch((e: any) => {
                setLoading(false);
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    };

    interface DateObject {
        dateClicked?: moment.Moment;
        dateFocused?: moment.Moment;
    }

    const focusClick = (selectedDates: DateObject[]) =>
        selectedDates.map((date: DateObject) => ({
            focusDate: date.dateFocused
                ? moment(date.dateFocused.format()).format('YYYY-MM-DD')
                : undefined,
            clickDate: date.dateClicked
                ? moment(date.dateClicked.format()).format('YYYY-MM-DD')
                : undefined,
            clickDateMinus: date.dateClicked
                ? moment(date.dateClicked.format()).subtract(1, 'day').format('YYYY-MM-DD')
                : undefined,
        }));

    const handleDelete = (focusClickArray: any[]) => {
        focusClickArray.forEach(focusClick => {
            if (
                focusClick.focusDate === undefined ||
                focusClick.focusDate !== focusClick.clickDateMinus
            ) {
                timeoff.forEach((data: any) => {
                    const formattedFromIso = moment(data.from_iso).format('YYYY-MM-DD');
                    if (formattedFromIso === focusClick.clickDate) {
                        deleteTeamTimeOff(data._id);
                    }
                });
            }
        });
    };

    useEffect(() => {
        if (selectedDates) {
            const focusClickArray = focusClick(selectedDates);
            handleDelete(focusClickArray);
        }
    }, [selectedDates]);

    const onSubmit = async () => {
        setLoading(true);

        for (const date of formattedDates) {
            const payload = {
                from_iso: moment(date.from_iso).format(),
                to_iso: moment(date.to_iso).format(),
            };

            try {
                const res = await api.post(`/timeoff/${router.query.id as string}`, payload);
                setTimeOff((prevTimeOff: any) => [...prevTimeOff, res.data]);
                openSnackbar(t('Time off saved successfully!'));
            } catch (e: any) {
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            }
        }

        for (const id of deletedTimeOffIds) {
            try {
                await api.delete(`/timeoff/${id}`);
                setTimeOff((prevTimeOff: any) =>
                    prevTimeOff.filter((item: any) => item._id !== id)
                );
                openSnackbar(t('Time Off deleted successfully!'));
            } catch (e: any) {
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            }
        }

        setLoading(false);
    };

    const openDeleteModel = (id: any) => {
        setDeleteModel(true);
        setDeleteId(id);
    };

    const deleteTeamTimeOff = async (id: any) => {
        setDeletedTimeOffIds((prevIds: any) => [...prevIds, id]);
        const deleteTimeoff: any = timeoff.filter((item: any) => {
            return item._id !== id;
        });
        setTimeOff(deleteTimeoff);
    };

    const isMobile = window.innerWidth <= 768;
    const isTabletOrDesktop = window.innerWidth > 767;

    return (
        <Container style={{ padding: '0px' }}>
            {loading && <LoaderOverlay />}
            <Row>
                <Col lg={12}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Container>
                            <Row>
                                <Col
                                    lg={12}
                                    style={{ marginBottom: '0.5rem', textAlign: 'center' }}>
                                    <h3>{t('Add Time Off')}</h3>
                                </Col>
                                <CalendarContainer xs={12}>
                                    <div
                                        style={{
                                            width: isMobile ? '100%' : '45rem',
                                            height: isMobile ? '45vh' : '27rem',
                                        }}>
                                        <Calendar
                                            className="custom-calendar"
                                            multiple
                                            value={existingDates}
                                            onChange={setNewDates}
                                            plugins={[weekends()]}
                                            weekStartDayIndex={1}
                                            numberOfMonths={isMobile ? 1 : 2}
                                            onFocusedDateChange={(dateFocused, dateClicked) => {
                                                setSelectedDates([{ dateFocused, dateClicked }]);
                                            }}
                                            format="YYYY-MM-DD"
                                        />
                                    </div>
                                </CalendarContainer>
                                <ButtonContainer xs={12}>
                                    <Button
                                        type={'submit'}
                                        bgtype={'secondary'}
                                        label={t('Update')}></Button>
                                </ButtonContainer>
                            </Row>
                        </Container>
                    </form>
                </Col>
            </Row>
        </Container>
    );
};

const CalendarContainer = styled(Col)`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ButtonContainer = styled(Col)`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const CustomDiv = styled.div`
    margin-left: 1rem;
`;

const DeletIcon = styled.button`
    padding: 0;
    width: 1.85rem;
    height: 1.85rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 0rem;
    background-color: ${palette.primary};
    color: ${palette.light};
    font-weight: 600;
    text-transform: capitalize;
    border-radius: 0.51rem;
    font-size: 0.87rem;
    cursor: pointer;
`;

const CustomCol = styled(Col)`
    padding: 0;
`;

export default TimeOff;
