/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * User Actions
 * Description: This file contains action creators related to user authentication, registration, and fetching current user information.
 *
 * Constants:
 * - userConstants: Constants for user actions.
 *
 * Actions:
 * - login: Action creator for user login.
 * - register: Action creator for user registration.
 * - getCurrentUser: Action creator for fetching the current user.
 * - saveUser: Action creator for saving user information.
 */

import { ILogin, IUser } from 'interfaces/user.interface';

import { Dispatch } from 'redux';

import { userService } from 'services';

export const userConstants = {
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    GET_REQUEST: 'USERS_GET_REQUEST',

    SAVE_USER: 'SAVE_USER',

    LOGOUT: 'USERS_LOGOUT',
};

const login = (data: ILogin) => (dispatch: Dispatch) => {
    dispatch({
        type: userConstants.LOGIN_REQUEST,
    });

    return userService.login(data).then((response: any) => {
        if (response.error) {
            dispatch({
                type: userConstants.LOGIN_FAILURE,
            });
        } else if (response.success) {
            dispatch({
                type: userConstants.LOGIN_SUCCESS,
                response,
            });
        }

        return response;
    });
};

const register = (register: IUser) => (dispatch: Dispatch) => {
    dispatch({
        type: userConstants.REGISTER_REQUEST,
    });

    return userService
        .register(register)
        .then(user => {
            dispatch({
                type: userConstants.REGISTER_SUCCESS,
                user,
            });
            return user;
        })
        .catch(err => {
            dispatch({
                type: userConstants.REGISTER_FAILURE,
            });
            return err?.response?.data;
        });
};

const getCurrentUser = (accessToken: string) => (dispatch: Dispatch) => {
    dispatch({
        type: userConstants.GET_REQUEST,
    });
    return userService
        .getUser(accessToken)
        .then(user => {
            dispatch({
                type: userConstants.LOGIN_SUCCESS,
                user,
            });
            return user;
        })
        .catch(() => {
            dispatch({
                type: userConstants.LOGIN_FAILURE,
            });
        });
};

export const getSaveUser = (payload: any) => (dispatch: Dispatch) => {
    dispatch({
        type: userConstants.SAVE_USER,
    });
    return dispatch({
        type: userConstants.SAVE_USER,
        value: payload,
    });
};
export const userActions = {
    login,
    register,
    getCurrentUser,
};
