/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Input Component
 *
 * Description:
 * - Renders a customizable dropdown input component with options.
 * - Handles selection of options, filtering, and focus behavior.
 * - Utilizes styled-components for styling.
 * - User can select any service provider to see specific service provider appointments.
 *
 * Usage:
 * - Import this component and use it where select dropdown is needed with user profile and with user data.
 *
 */

import React, { useRef, useState, useEffect } from 'react';
import { scrollIntoView } from '../utils';
import { palette } from 'styled/common';

interface Option {
    label: string;
    value?: string | number;
    render?: string;
    id: string;
    icon?: any;
}

export type FocusDirection = 'up' | 'down' | 'pageup' | 'pagedown' | 'first' | 'last';

export interface InputProps {
    name?: string;
    type?: string;
    id?: string;
    value?: string | number;
    label?: string;
    className?: string;
    readOnly?: boolean;
    disabled?: boolean;
    options: Option[];
    slotLeft?: React.ReactNode;
    onBlur?(): void;
    onInput?(value: string): void;
    onChange?(value: any): void;
    isDashboard?: boolean;
}

const Input = ({
    name,
    type,
    id,
    value,
    options,
    label,
    className,
    readOnly,
    disabled,
    slotLeft,
    onBlur,
    onInput,
    onChange,
    isDashboard,
    ...props
}: InputProps) => {
    const [searchValue, setSearchValue] = useState('');
    const [focusedOption, setFocusedOption] = useState<Option | null>(null);
    const [scrollToFocusedOption, setScrollToFocusedOption] = useState(false);
    const [isActive, setActive] = useState(false);
    const [isFocused, setFocused] = useState(false);
    const [inputValue, setInputValue] = useState<string | number>('');
    const [inputIcon, setInputIcon] = useState<any>();
    const [inputId, setInputId] = useState<any>();
    const inputRef = useRef<HTMLInputElement>(null);
    const focusedOptionRef = useRef<HTMLDivElement>(null);
    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setInputValue(value ?? '');
        if (!value) {
            setInputIcon('');
        }

        setInputId(id);
    }, [value]);

    useEffect(() => {
        if (inputRef.current && inputRef.current.value?.length > 0) setActive(true);

        // Check if input value was a value rather than a label
        const selectedOption = options.find(option => option.value === inputValue);
        if (selectedOption) setInputId(selectedOption.id);
    }, []);

    useEffect(() => {
        if (dropdownRef.current && focusedOptionRef.current && scrollToFocusedOption) {
            scrollIntoView(dropdownRef.current, focusedOptionRef.current);
            setScrollToFocusedOption(false);
        }
    }, [scrollToFocusedOption, focusedOptionRef]);

    useEffect(() => {
        if (!isDashboard) {
            setInputIcon(options[0].icon);
        }
    }, [isDashboard]);

    const focusInput = () => {
        if (!isFocused) {
            setActive(true);
            setFocused(true);
            if (inputRef.current) {
                inputRef.current.focus();
            }

            setTimeout(() => {
                if (focusedOptionRef.current && dropdownRef.current) {
                    scrollIntoView(dropdownRef.current, focusedOptionRef.current);
                }
            });
        } else {
            setFocused(false);
        }
    };

    const unfocusInput = (event: React.FocusEvent<HTMLInputElement>) => {
        if (!disabled) {
            if (!event.relatedTarget) {
                setFocused(false);
                if (inputRef.current && inputRef.current.value?.length === 0) setActive(false);
            } else {
                focusInput();
            }
            if (onBlur) onBlur();
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!disabled) {
            setInputValue(event.target.value);
            setSearchValue(event.target.value);

            if (onInput) onInput(event.target.value);
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (!readOnly) {
            const validKeys = [' ', 'ArrowUp', 'ArrowDown', 'Enter'];
            if (validKeys.indexOf(event.key) !== -1) {
                event.preventDefault();
                if (event.key === 'ArrowDown') {
                    focusOption('down');
                }
                if (event.key === 'ArrowUp') {
                    focusOption('up');
                }
                if (event.key === 'Enter') {
                    if (!focusedOption) return;
                    selectOption(focusedOption);
                }
            }
        }
    };

    const focusOption = (direction: FocusDirection = 'first') => {
        if (!options.length) return;
        let nextFocus = 0; // handles 'first'
        let focusedIndex = options.indexOf(focusedOption!);
        if (!focusedOption) {
            focusedIndex = -1;
        }
        switch (direction) {
            case 'up':
                nextFocus = focusedIndex > 0 ? focusedIndex - 1 : options?.length - 1;
                break;
            case 'down':
                nextFocus = (focusedIndex + 1) % options?.length;
                break;
            default:
                break;
        }
        setScrollToFocusedOption(true);
        setFocusedOption(options[nextFocus]);
    };

    const selectOption = (option: Option) => {
        if (option.render) {
            setInputValue(option.render);
        } else {
            setInputValue(option.label);

            setInputIcon(option.icon);
        }

        setFocused(false);

        // Reset search value
        setSearchValue('');
        if (option.id) {
            setInputId(option.id);
            if (onChange) onChange((option.value as string) || option || '');
        } else if (onChange) onChange((option.value as string) || option.label || '');
    };

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target as Node) &&
                !inputRef.current?.contains(event.target as Node)
            ) {
                setTimeout(() => {
                    setFocused(false);
                    setActive(false);
                }, 100);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [isFocused]);

    return (
        <div className={`dropdown ${className ?? ''}`} tabIndex={-1} onKeyDown={handleKeyDown}>
            <div
                className={`select--container select--active ${
                    isFocused ? 'select--focused select--opened' : ''
                } p-075`}
                onClick={() => focusInput()}>
                <div className="select--wrapper">
                    <div className="select--label-wrapper">
                        <label>{label}</label>
                    </div>
                    {inputIcon && (
                        <div className="avatar">
                            <img
                                src={`${process.env.REACT_APP_PROFILE_URL}${inputIcon}`}
                                style={{ width: '2.3rem', height: '2.3rem', borderRadius: '100%' }}
                            />
                        </div>
                    )}
                    <div className="select--input--wrapper">
                        {slotLeft && <div className="select--leftSlot">{slotLeft}</div>}
                        <input
                            ref={inputRef}
                            placeholder={!isActive ? label : ''}
                            id={id}
                            name={name}
                            type={type ? type : 'text'}
                            onChange={event => handleInputChange(event)}
                            value={inputValue}
                            className={className}
                            readOnly={readOnly}
                            autoComplete="none"
                            onBlur={e => unfocusInput(e)}
                        />
                    </div>
                    <div className="select--icon">
                        <i className="fal fa-chevron-down"></i>
                    </div>
                </div>
            </div>

            {isFocused && (
                <div className="dropdown--content" ref={dropdownRef}>
                    <div className="dropdown--wrapper">
                        <ul>
                            {options
                                ?.filter(option => {
                                    if (
                                        option.label
                                            .toLowerCase()
                                            .indexOf(searchValue.toLowerCase()) === -1
                                    ) {
                                        return false;
                                    }

                                    return true;
                                })
                                .map((option: any, i: number) => {
                                    const isOptionFocused = focusedOption === option;
                                    return (
                                        <li key={i}>
                                            <div
                                                style={{
                                                    backgroundColor:
                                                        inputId === option.id
                                                            ? palette.secondary
                                                            : '',
                                                }}
                                                className={`dropdown-item-list ${
                                                    inputId === option.id ? 'item--selected' : ''
                                                } ${isOptionFocused ? 'item--focused' : ''}`}
                                                onClick={() => selectOption(option)}
                                                ref={
                                                    isOptionFocused ? focusedOptionRef : undefined
                                                }>
                                                <div className="avatar">
                                                    <img
                                                        src={`${process.env.REACT_APP_PROFILE_URL}${option.icon}`}
                                                        style={{
                                                            width: '2.3rem',
                                                            height: '2.3rem',
                                                            borderRadius: '100%',
                                                        }}
                                                    />
                                                </div>
                                                <div className="item--text" id={option.id}>
                                                    {option.label}
                                                </div>
                                            </div>
                                        </li>
                                    );
                                })}
                        </ul>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Input;
