/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Dashboard Component
 * Description: This component serves as the dashboard page for the web application. It provides an overview of key metrics and performance indicators related to appointments and service providers.
 * It includes functionalities such as fetching and displaying dashboard data, generating reports, exporting data to PDF and Excel, and presenting a leaderboard of service providers.
 *
 */

import React, { useEffect, useRef, useState } from 'react';
import { Header, Layout, Select } from '@components/common';
import styled from 'styled-components';
import { Col, Container, media, Row } from 'styled-bootstrap-grid';
import moment from 'moment';
import { api } from 'helpers/auth-axios';
import { LoaderOverlay, useSnackbar } from '@components/common';
import { HeaderContent } from '@components/common/layout/Layout';
import { Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import DashboardTable from '@components/common/dashboardData/DashboardDataTable';
import { useReactToPrint } from 'react-to-print';
import Button from '@components/Button';
import * as XLSX from 'xlsx';
import { CalenderSelect } from '@components/common/select';
import { palette } from 'styled/common';

const Dashboard = () => {
    const { t } = useTranslation();
    const [dashboardData, setDashboardData] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);
    const [serviceProvider, setServiceProvider] = useState([]);
    const [start, setStart] = useState<any>(moment().format());
    const [endDate, setEndDate] = useState<any>(moment().format());
    const [currentWeek, setCurrentWeek] = useState(true);
    const [reportModel, setReportModel] = useState(false);
    const [tableData, setTableData] = useState<any>();
    const [serviceProviders, setServiceProviders] = useState<any[]>([]);
    const [serviceProvidersStats, setServiceProvidersStats] = useState<any[]>([]);
    const [services, setServices] = useState<any>();
    const [total, setTotal] = useState(0);
    let end = moment(start).clone().startOf('week');
    const currentDate = moment(start).format();
    let endWeek = moment(end).format() === currentDate;
    const [openSnackbar] = useSnackbar();
    let _userData = JSON.parse(localStorage.getItem('user') || '{}');
    let componentRef = useRef(null);
    const [selectId, setSelectId] = useState('');
    let checIsServiceProvider = _userData?.user_login == 'serviceProvider';

    useEffect(() => {
        loadData();
    }, [start, endDate]);

    const loadData = async () => {
        await getDashboardData();
        await serviceProviderData();
        await getAllServiceProvider();
        await getAllCustomers();
    };

    const weekStart = moment(
        endWeek === true
            ? moment(end.subtract(1, 'day')).clone().startOf('week').add(1, 'day')
            : moment(start).clone().startOf('week').add(1, 'day')
    );

    const weekEnd =
        endWeek === true
            ? moment(end.subtract(1, 'day')).clone().endOf('week').add(1, 'day')
            : moment(start).clone().endOf('week').add(1, 'day');

    const getDashboardData = async (service = null) => {
        setLoading(true);
        await api
            .get(
                `/appointments/dashboard/data?from=${moment(
                    currentWeek === true ? weekStart : start
                ).format('YYYY-MM-DD')}&to=${moment(
                    currentWeek === true ? weekEnd : endDate
                ).format('YYYY-MM-DD')}&serviceProvider=${selectId ?? null}&service=${
                    service ?? null
                }`
            )
            .then((res: any) => {
                setDashboardData(res?.data);
                for (let i = 0; i < res?.data?.appointmentsData?.length; i++) {
                    if (res.data.appointmentsData[i]?.service === null) {
                        res.data.appointmentsData[i].service = { name: '---' };
                    }
                    if (res.data.appointmentsData[i]?.payment === null) {
                        res.data.appointmentsData[i].payment = t('unpaid');
                    } else if (res.data.appointmentsData[i]?.payment) {
                        res.data.appointmentsData[i].payment = t('paid');
                    }
                }
                setTableData(res?.data?.appointmentsData);
                setLoading(false);
            })
            .catch((e: any) => {
                setLoading(false);
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    };

    const serviceProviderData = async () => {
        setLoading(true);
        await api
            .get(
                `/appointments/dashboard/serviceProvider_data?from=${moment(
                    currentWeek === true ? weekStart : start
                ).format('YYYY-MM-DD')}&to=${moment(
                    currentWeek === true ? weekEnd : endDate
                ).format('YYYY-MM-DD')}`
            )
            .then((res: any) => {
                setServiceProvider(res.data?.serviceProvider);
                setServiceProvidersStats(res?.data?.serviceProviderAppointments);
                setLoading(false);
            })
            .catch((e: any) => {
                setLoading(false);
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    };

    const getAllCustomers = () => {
        setLoading(true);
        api.get(`/customers?page=${0}&pageSize=${555}`).then((customers: any) => {
            setTotal(customers.data.total);
            setLoading(false);
        });
    };

    const data = {
        labels: [
            moment(start).clone().startOf('week').add(1, 'day').format('MMM DD'),
            moment(start).clone().startOf('week').add(2, 'day').format('MMM DD'),
            moment(start).clone().startOf('week').add(3, 'day').format('MMM DD'),
            moment(start).clone().startOf('week').add(4, 'day').format('MMM DD'),
            moment(start).clone().startOf('week').add(5, 'day').format('MMM DD'),
            moment(start).clone().startOf('week').add(6, 'day').format('MMM DD'),
            moment(start).clone().startOf('week').add(7, 'day').format('MMM DD'),
        ],
        datasets: [
            {
                label: t('Appointments'),
                data: dashboardData?.dailyAppointments?.map((e: any) => e?.appointments),
                fill: true,
                backgroundColor: 'rgba(75,192,192,0.2)',
                borderColor: 'rgba(75,192,192,1)',
            },
        ],
    };

    const options = {
        plugins: {},
        responsive: true,
        legend: {
            display: false,
        },
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
            },
        },
        tooltips: {
            callbacks: {
                label: function (tooltipItem: any, data: any) {},
                title: function (tooltipItem: any, data: any) {
                    return (
                        t('Total Appointments: ') +
                        tooltipItem[0].value +
                        '\n' +
                        'Cancelled' +
                        ': ' +
                        dashboardData?.dailyAppointments[tooltipItem[0].index].cancelled +
                        '\n' +
                        t('Confirmed') +
                        ': ' +
                        dashboardData?.dailyAppointments[tooltipItem[0].index].confirmed
                    );
                },
            },
        },
    };

    const exportPDF = () => {
        const unit = 'pt';
        const size = 'A4'; // Use A1, A2, A3 or A4
        const orientation = 'portrait'; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = t('Appointment Report');
        const headers = [
            [
                t('Customer Name'),
                t('Booked Time'),
                t('Service Provider'),
                t('Service'),
                t('Status'),
                t('Payment'),
                t('Amount'),
            ],
        ];

        const data = tableData?.map((e: any) => [
            e.customer.fullname,
            e.booked_from_iso,
            e.serviceProvider.name,
            e.service.name,
            e.status,
            e.payment,
            e.total_value,
        ]);

        let content = {
            startY: 50,
            head: headers,
            body: data,
        };

        doc.text(title, marginLeft, 40);
        (doc as any)?.autoTable(content);
        doc.save('BookingReport.pdf');
    };

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const exportToExcel = () => {
        let data = tableData?.map((e: any) => [
            e.customer.fullname,
            e.booked_from_iso,
            e.serviceProvider.name,
            e.service.name,
            e.status,
            e.payment,
            e.total_value,
        ]);

        const headers = [
            t('Customer Name'),
            t('Booked Time'),
            t('Service Provider'),
            t('Service'),
            t('Status'),
            t('Payment'),
            t('Amount'),
        ];
        data.unshift(headers);

        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, `BookingReport.xlsx`);
    };

    const getAllServiceProvider = async () => {
        await api
            .post(`/serviceProvider/calendar-data`)
            .then((serviceProvider: any) => {
                setLoading(false);
                setServiceProviders(serviceProvider.data);
            })
            .catch(e => {
                setLoading(false);
            });
    };

    const calendar: { label: string; id: any; icon: any; calendar_size: any; vip: any }[] = [];
    let _services: { label: string; value: any }[] = [];
    serviceProviders.map((data: any) => {
        calendar.push({
            label: `${data.name}`,
            id: `${data._id}`,
            icon: `${data.photo}`,
            calendar_size: `${data.calendar_size}`,
            vip: data?.vip,
        });
        for (let i = 0; i < data?.services?.length; i++) {
            _services.push({
                label: `${data?.services[i]?.name}`,
                value: `${data?.services[i]?.service_id}`,
            });
        }
    });

    const uniqueServices: any = Object.values(
        _services.reduce((acc: any, item: any) => {
            acc[item.value] = item;
            return acc;
        }, {})
    );

    const exportServiceProviderStatsToExcel = (singleProviderData?: any[]) => {
        let providerData = singleProviderData ? singleProviderData : serviceProvidersStats;
        const groupedStats = providerData.reduce((groups, stat) => {
            const serviceProviderId = stat.serviceProvider._id;
            const serviceProviderName = stat.serviceProvider.name;
            const combinedName = `${serviceProviderId} - ${serviceProviderName}`;
            if (!groups[combinedName]) {
                groups[combinedName] = [];
            }
            groups[combinedName].push([
                stat.customer.fullname,
                stat.booked_from_iso,
                stat.serviceProvider.name,
                stat.service.name,
                stat.status,
                stat.revenue,
            ]);
            return groups;
        }, {});

        const data = [];
        for (const combinedName in groupedStats) {
            const statsForProvider = groupedStats[combinedName];
            data.push([combinedName]);
            data.push([]);
            data.push(...statsForProvider);
            data.push([]);
        }

        const headers = [
            t('Customer Name'),
            t('Booked Time'),
            t('Service Provider'),
            t('Service'),
            t('Status'),
            t('Revenue'),
        ];
        data.unshift(headers);

        const ws = XLSX.utils.aoa_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, `ServiceProviderStats.xlsx`);
    };

    return (
        <>
            <Wrapper id="container">
                {loading && <LoaderOverlay />}
                {reportModel == false ? (
                    <Content>
                        <HeaderContent smallScreen={true} display={true}>
                            <Header
                                activeRoute={{
                                    title: t('Dashboard'),
                                    icon: 'far fa-chart-line',
                                    path: '/',
                                }}
                            />
                        </HeaderContent>
                        <AppointmentsDiv>
                            <WeekHeading>
                                <div>
                                    <div style={{ gap: '0.5rem', display: 'flex' }}>
                                        <CalenderSelect
                                            label={
                                                t('Generate by service provider') ||
                                                'Generate by service provider'
                                            }
                                            id={selectId ? selectId : calendar[0]?.id}
                                            value={
                                                calendar?.find((e: any) => e.id == selectId)?.label
                                            }
                                            isDashboard={true}
                                            disabled={true}
                                            options={calendar}
                                            onChange={(e: any) => {
                                                getDashboardData(e?.id);
                                                setSelectId(e?.id);
                                                setServices('');
                                            }}
                                        />
                                        <Select
                                            label={t('Generate by service')}
                                            options={uniqueServices}
                                            value={
                                                uniqueServices?.find(
                                                    (e: any) => e.value == services
                                                )?.label
                                            }
                                            disabled={true}
                                            onChange={(e: any) => {
                                                getDashboardData(e);
                                                setServices(e);
                                                setSelectId('');
                                            }}
                                        />
                                    </div>
                                    &nbsp;
                                    <Heading>
                                        <ThisWeek
                                            onClick={() => {
                                                setStart(moment().format());
                                                setCurrentWeek(true);
                                            }}>
                                            {t('This Week')}
                                        </ThisWeek>
                                        <DateDiv>
                                            <CalenderDate left={'8.1rem'}>
                                                <WeekDate>
                                                    {moment(
                                                        currentWeek === true ? weekStart : start
                                                    ).format('MMM DD')}
                                                </WeekDate>
                                                <input
                                                    type="date"
                                                    value={moment(start).format('YYYY-MM-DD')}
                                                    onChange={(e: any) => {
                                                        setStart(e.target.value);
                                                        setCurrentWeek(false);
                                                    }}
                                                />
                                            </CalenderDate>
                                            -
                                            <CalenderDate left={'14rem'}>
                                                <WeekDate>
                                                    {moment(
                                                        currentWeek === true ? weekEnd : endDate
                                                    ).format('MMM DD')}
                                                </WeekDate>
                                                <input
                                                    type="date"
                                                    value={moment(endDate).format('YYYY-MM-DD')}
                                                    onChange={(e: any) => {
                                                        setEndDate(e.target.value);
                                                        setCurrentWeek(false);
                                                    }}
                                                />
                                            </CalenderDate>
                                            <CustomDivHeader
                                                style={{ color: 'blue' }}
                                                cursor={'pointer'}
                                                border={'none'}
                                                fontSize={'1rem'}
                                                onClick={() => setReportModel(true)}>
                                                {t('Generate Report')}
                                            </CustomDivHeader>
                                        </DateDiv>
                                    </Heading>
                                </div>

                                <CustomDivHeader>
                                    <HeaderTitle>
                                        {dashboardData ? dashboardData?.upcommingAppointments : '0'}
                                    </HeaderTitle>
                                    <CustomDiv>{t('Appointments')}</CustomDiv>
                                </CustomDivHeader>
                                <CustomDivHeader>
                                    <HeaderTitle>
                                        <>
                                            {parseFloat(dashboardData?.appointmentDifference) >
                                            0 ? (
                                                <RevenueDifference>
                                                    <TopArrow className="fa fa-solid fa-arrow-up" />
                                                    {dashboardData?.appointmentDifference}
                                                </RevenueDifference>
                                            ) : (
                                                <AppointmentDifference>
                                                    <TopArrow className="fa fa-solid fa-arrow-down" />
                                                    {dashboardData?.appointmentDifference}
                                                </AppointmentDifference>
                                            )}
                                            <LastWeek> {t('vs Prior Week')}</LastWeek>
                                        </>
                                    </HeaderTitle>
                                    <CustomDiv>{t('Upcoming Appointments')}</CustomDiv>
                                </CustomDivHeader>
                                <CustomDivHeader>
                                    <HeaderTitle>
                                        {_userData?.user?.business_id?.currency}
                                        {dashboardData?.currentWeekRevenue
                                            ? parseFloat(dashboardData?.currentWeekRevenue).toFixed(
                                                  2
                                              )
                                            : '0.00'}
                                    </HeaderTitle>
                                    <CustomDiv>{t('Total Revenue')}</CustomDiv>
                                </CustomDivHeader>
                            </WeekHeading>
                            <WeekHeading margin={'1rem'}>
                                {/* <CustomDivHeader border={'none'}>
                                    <CustomDiv>{t('Breakdown')} </CustomDiv>
                                </CustomDivHeader> */}
                                <CustomDivHeader>
                                    <HeaderTitle>
                                        {dashboardData ? dashboardData?.doneAppointments : '0'}
                                    </HeaderTitle>
                                    <CustomDiv>{t('Confirmed')} </CustomDiv>
                                </CustomDivHeader>
                                <CustomDivHeader>
                                    <HeaderTitle>{total ? total : '0'}</HeaderTitle>
                                    <CustomDiv>{t('Number of clients')} </CustomDiv>
                                </CustomDivHeader>
                                <CustomDivHeader>
                                    <HeaderTitle>
                                        {dashboardData
                                            ? dashboardData?.waitinglistAppointments
                                            : '0'}
                                    </HeaderTitle>
                                    <CustomDiv>{t('Waiting List')}</CustomDiv>
                                </CustomDivHeader>
                                <CustomDivHeader>
                                    <HeaderTitle>
                                        {dashboardData ? dashboardData?.cancelledAppointments : '0'}
                                    </HeaderTitle>
                                    <CustomDiv color={'red'}>{t('Cancelled')} </CustomDiv>
                                </CustomDivHeader>
                            </WeekHeading>
                            <TotalAppointments lg={12}>
                                <Line data={data} width={200} height={50} options={options} />
                            </TotalAppointments>
                        </AppointmentsDiv>
                        {checIsServiceProvider ? (
                            ''
                        ) : (
                            <ServiceProviders>
                                <LeaderBoardHeading>
                                    <LeaderBoard>{t('Service Provider Stats')}</LeaderBoard>

                                    {/* <CustomSpan>{t('Weekly team performance.')}</CustomSpan> */}
                                    <ProviderHeading>
                                        <ThisWeek
                                            onClick={() => {
                                                setStart(moment().format());
                                                setCurrentWeek(true);
                                            }}>
                                            {t('This Week')}
                                        </ThisWeek>
                                        <DateDiv>
                                            <CalenderDate left={'3.1rem'}>
                                                <WeekDate>
                                                    {moment(
                                                        currentWeek === true ? weekStart : start
                                                    ).format('MMM DD')}
                                                </WeekDate>
                                                <input
                                                    type="date"
                                                    value={moment(start).format('YYYY-MM-DD')}
                                                    onChange={(e: any) => {
                                                        setStart(e.target.value);
                                                        setCurrentWeek(false);
                                                    }}
                                                />
                                            </CalenderDate>
                                            -
                                            <CalenderDate left={'9rem'}>
                                                <WeekDate>
                                                    {moment(
                                                        currentWeek === true ? weekEnd : endDate
                                                    ).format('MMM DD')}
                                                </WeekDate>
                                                <input
                                                    type="date"
                                                    value={moment(endDate).format('YYYY-MM-DD')}
                                                    onChange={(e: any) => {
                                                        setEndDate(e.target.value);
                                                        setCurrentWeek(false);
                                                    }}
                                                />
                                            </CalenderDate>
                                            <CustomDivHeader
                                                style={{ color: 'blue' }}
                                                cursor={'pointer'}
                                                border={'none'}
                                                fontSize={'1rem'}
                                                onClick={() => exportServiceProviderStatsToExcel()}>
                                                {t('Generate Report')}
                                            </CustomDivHeader>
                                        </DateDiv>
                                    </ProviderHeading>
                                </LeaderBoardHeading>

                                <Card>
                                    {serviceProvider?.length
                                        ? serviceProvider?.map((data: any, i: any) => {
                                              return (
                                                  <CardContent key={i}>
                                                      <ExcelIcon
                                                          className="fal fa-file-excel"
                                                          onClick={() =>
                                                              exportServiceProviderStatsToExcel(
                                                                  data?.singleServiceProviderAppointments
                                                              )
                                                          }
                                                      />
                                                      <Image
                                                          src={`${process.env.REACT_APP_PROFILE_URL}${data?.photo}`}></Image>
                                                      {/* <Number>{i + 1}</Number> */}

                                                      <Name>{data?.name}</Name>
                                                      <div
                                                          style={{
                                                              display: 'flex',
                                                              justifyContent: 'center',
                                                              alignItems: 'center',
                                                              gap: '0.2rem',
                                                          }}>
                                                          <CardHeading>
                                                              {t('Work Type')}:
                                                          </CardHeading>
                                                          <Description>
                                                              {data?.rate_type}
                                                          </Description>
                                                      </div>
                                                      <div
                                                          style={{
                                                              display: 'flex',
                                                              justifyContent: 'center',
                                                              alignItems: 'center',
                                                              gap: '0.2rem',
                                                          }}>
                                                          <CardHeading>
                                                              {data?.rate_type == 'daily'
                                                                  ? t('Daily Rate')
                                                                  : t('Percentage on appointments')}
                                                              :
                                                          </CardHeading>
                                                          <Description>
                                                              {data?.appointment_percenatege}{' '}
                                                              {data?.rate_type == 'daily'
                                                                  ? ''
                                                                  : '%'}
                                                          </Description>
                                                      </div>
                                                      <BookedDiv>
                                                          <Booked>
                                                              {data?.totalAppiontments}{' '}
                                                              {t('Booked')}
                                                          </Booked>

                                                          <Revenue>
                                                              {' '}
                                                              {
                                                                  _userData?.user?.business_id
                                                                      ?.currency
                                                              }
                                                              {data?.totalRevenue
                                                                  ? parseFloat(
                                                                        data?.totalRevenue
                                                                    ).toFixed(2)
                                                                  : '0.00'}
                                                          </Revenue>
                                                      </BookedDiv>
                                                  </CardContent>
                                              );
                                          })
                                        : ''}
                                </Card>
                            </ServiceProviders>
                        )}
                    </Content>
                ) : (
                    <TableForm>
                        <TableHeader style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <BackBtn onClick={() => setReportModel(false)}>
                                <i className="fal fa-chevron-left"></i>&nbsp; {t('back')}
                            </BackBtn>{' '}
                            {tableData?.length ? (
                                <CustomDivForButtons style={{ display: 'flex', gap: '1rem' }}>
                                    <Button
                                        bgtype={'secondary'}
                                        ifClicked={() => exportToExcel()}
                                        label={t('Download Report in Excel')}></Button>
                                    <Button
                                        bgtype={'secondary'}
                                        ifClicked={() => exportPDF()}
                                        label={t('Download Report in PDF')}></Button>
                                    <Button
                                        bgtype={'secondary'}
                                        ifClicked={() => handlePrint()}
                                        label={t('Print')}></Button>
                                </CustomDivForButtons>
                            ) : (
                                ''
                            )}
                        </TableHeader>
                        <DashboardTable data={tableData} ref={componentRef} />
                    </TableForm>
                )}
            </Wrapper>
        </>
    );
};

const CustomDivForButtons = styled.div`
    justify-content: flex-end;
    ${media.xs`
 flex-wrap: wrap;
`}
    ${media.sm`
    flex-wrap: wrap;
`}
 ${media.md`
 flex-wrap: wrap;
`}
 ${media.lg`
 flex-wrap: wrap;
`};
`;

const Wrapper = styled(Container)`
    width: 100%;
    position: relative;
    height: 100%;
    ${media.xs`
  display: block !important;
  overflow: hidden;
`}
    ${media.sm`
display: block !important;
`}
 ${media.md`
display: flex !important;
margin:0;
min-width:100%;
`}
 ${media.lg`
display: flex !important;
`};
`;

const AppointmentsDiv = styled(Col)`
    width: 100%;
    height: auto;
    min-height: 28rem;
`;

const WeekHeading = styled.div<any>`
    display: flex;
    align-items: center;
    justify-content: ${({ justifyContent }) => (justifyContent ? '' : 'space-between')};
    gap: ${({ justifyContent }) => (justifyContent ? '1rem' : '')};
    padding-left: 1.5rem;
    padding: 2rem;
    background: ${palette.white};
    box-shadow: inset -1.80118px -2.70178px 9.00592px ${palette.dashboard_card_shadow};
    border-radius: 4.50296px;
    flex-wrap: wrap;
    margin-top: ${({ margin }) => margin};
    ${media.xs`
    flex-wrap: nowrap ;
    display: block;
  `}
    ${media.sm`
    flex-wrap: nowrap;
    display: block;
  `}
   ${media.md`
    flex-wrap: wrap;
    display: flex;
  `}
   ${media.lg`
    flex-wrap: wrap;
    display: flex;
  `};
`;

const LeaderBoardHeading = styled.div`
    display: grid;
    align-items: center;
    padding-left: 1.5rem;
    padding: 1.5rem;
    padding-bottom: 0;
    border-radius: 4.50296px;
    flex-wrap: wrap;
`;

const Heading = styled.span`
    padding-bottom: 0.5rem;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 0.05em;
    display: grid;
    justify-content: center;
`;

const ProviderHeading = styled.span`
    padding-bottom: 0.5rem;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 0.05em;
    display: grid;
    justify-content: start;
`;

const ThisWeek = styled.span`
    color: ${palette.blue.defaultBlue};
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 22px;
    letter-spacing: 0.05em;
`;

const WeekDate = styled.span`
    font-size: 1.15rem;
    opacity: 0.75;
    cursor: pointer;
`;

const HeaderTitle = styled.span<any>`
    font-size: 1.15rem;
    opacity: 0.75;
    color: ${({ color }) => color};
`;

const TotalAppointments = styled(Col)`
    display: flex;
    padding: 1rem 0;
    gap: 1rem;
    ${media.xs`
    display: block;
  `}
    ${media.sm`
    display: block;
  `}
	${media.md`
    display: block;
  `}
	${media.lg`
    display: flex;
  `}
`;

const RevenueDifference = styled.span`
    color: ${palette.blue.defaultBlue};
    font-size: 1rem;
`;

const AppointmentDifference = styled.span`
    color: ${palette.primary};
    font-size: 1rem;
`;

const TopArrow = styled.i`
    font-size: 1rem !important;
    margin-right: 0.2rem;
    font-weight: 450;
`;

const CustomDiv = styled.div<any>`
    display: flex;
    justify-content: center;
    color: ${({ color }) => color};
`;

const LastWeek = styled.span`
    font-size: 1rem;
`;

const Content = styled(Row)`
    width: 100%;
    flex-direction: column;
`;

const ServiceProviders = styled(Col)`
    background: ${palette.white};
    box-shadow: inset -1.80118px -2.70178px 9.00592px ${palette.dashboard_card_shadow};
    border-radius: 4.50296px;
    margin-top: 0;
    width: 100%;
    height: auto;
    min-height: 35rem;
    margin-top: 0.9rem;
    padding: 15px;
    margin-bottom: 1.75rem;
`;

const LeaderBoard = styled.span`
    text-transform: capitalize;
    font-size: 2rem;
    font-weight: 550;
`;

const CustomSpan = styled.span`
    opacity: 0.75;
`;

const Card = styled.div`
    white-space: nowrap;
    position: absolute;
    display: flex;
    width: 95%;
    overflow-x: auto;
    overflow-y: hidden;
    gap: 1rem;
    padding-top: 0.75rem;
`;

const CardContent = styled.div`
    width: 18rem;
    background: ${palette.white};
    border: 1px solid ${palette.secondary};
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    text-align: center;
    margin-right: 1rem;
    ${media.xs`
    width: 15rem;
    font-size: 0.9rem;
  `}
    ${media.sm`
     font-size: 0.9rem;
  `}
`;

const Image = styled.img`
    height: 6rem;
    width: 6rem;
    border-radius: 100%;
    object-fit: cover;
    /* margin-bottom: 1rem; */
    margin-top: 0.7rem;
    margin: auto;
`;

const Name = styled.div`
    text-align: center;
    white-space: pre-wrap;
    font-weight: 700;
    font-size: 17px;
    margin-bottom: 4px;
    color: ${palette.grey.dark};
`;

const Description = styled.div`
    text-align: center;
    font-weight: 500;
    font-size: 1rem;
    margin-bottom: 4px;
    color: ${palette.grey.dark};
`;
const CardHeading = styled.div`
    text-align: center;
    font-weight: 700;
    font-size: 1rem;
    margin-bottom: 4px;
    color: ${palette.grey.dark};
`;

const Number = styled.div`
    height: 1.8rem;
    width: 1.8rem;
    display: flex;
    align-items: center;
    border-radius: 100%;
    justify-content: center;
    font-weight: 500;
    font-size: 1.5rem;
    color: #f2994a;
    position: relative;
    background: ${palette.secondary};
    top: -2.5rem;
    right: 0rem;
    left: 0.5rem;
    ${media.xs`
    top: -2.5rem;
    right: 0rem;
  `}
    ${media.sm`
    top: -7.5rem;
    right: 0rem;
  `}
	${media.md`
    top: -7.5rem;
    right: 0rem;
    left: 0.5rem;
  `}
	${media.lg`
    top:-7.5rem;
    right: 0rem;
    left: 0.5rem;
  `}
`;

const BookedDiv = styled(Col)`
    display: flex;
    height: 4rem;
    justify-content: center;
    align-items: center;
`;

const Booked = styled.div`
    font-weight: 500;
    background: ${palette.secondary};
    border: 1px solid ${palette.white};
    border-radius: 8px;
    padding: 0.5rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    color: white;
`;

const Revenue = styled.div`
    font-weight: 500;
    width: 6rem;
    background: ${palette.secondary};
    border: 1px solid ${palette.white};
    border-radius: 8px;
    padding: 0.5rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    color: white;
`;

const CalenderDate = styled.div<any>`
    display: block;
    input {
        border: none;
        box-sizing: border-box;
        outline: 0;
        position: absolute;
        left: ${({ left }) => (left ? left : '2.6rem')};
        width: 5rem;
        opacity: 0;
        cursor: pointer;
    }
    input[type='date']::-webkit-calendar-picker-indicator {
        background: transparent;
        bottom: 0;
        color: transparent;
        cursor: pointer;
        height: auto;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
    }
`;

const CustomDivHeader = styled.div<any>`
    display: block;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-style: normal;
    font-weight: 300;
    font-size: ${({ fontSize }) => (fontSize ? fontSize : '18px')};
    line-height: 18px;
    letter-spacing: 0.05em;
    color: #828282;
    cursor: ${({ cursor }) => cursor};
    ${media.xs`
   margin-top: 0.9rem;
   border-top: ${({ border }: any) => (border ? '' : '0.3px solid #a1a1a1')} ;
  `}
    ${media.sm`
    margin-top: 0.9rem;
    border-top: ${({ border }: any) => (border ? '' : '0.3px solid #a1a1a1')} ;
  `}
	${media.md`
    border-top: none;
    margin-top: 0.9rem;
  `}
	${media.lg`
    border-top: none;
    margin-top: 0rem;
  `}
`;

const DateDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.4rem;
`;

const TableForm = styled(Col)`
    padding: 0;
    margin-top: 1rem;
`;

const TableHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0;
`;

const BackBtn = styled.span`
    font-size: 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;
`;

const ExcelIcon = styled.i`
    font-size: 1.3rem;
    cursor: pointer;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin: 0.5rem 0.5rem 0;
`;
Dashboard.Layout = Layout;
export default Dashboard;
