/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * ServiceProviderCard Component
 * Description: This component renders a card for managing service providers, providing features such as viewing and modifying details, managing services, working hours, breaks, and permissions.
 * It includes functionalities such as deleting service providers and sending messages. The component utilizes Tabs and Accordion for organizing different sections.
 *
 * Props:
 * - callServiceProvider: (params: any) => void - Callback function triggered when a service provider is called to load service providers again.
 */

import useRouter from 'hooks/router';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { IServiceProvider } from 'interfaces/serviceProvider.interface';
import { serviceActions } from 'actions/service';
import TabContext from '@components/common/tabContext';
import { LoaderOverlay, Tab, TabPanel, Tabs, useSnackbar } from '@components/common';
import ServiceProviderServices from './ServiceProviderServices';
import styled from 'styled-components';
import { Container, Row, Col, media } from 'styled-bootstrap-grid';
import TimeOff from '@components/common/timeOff/TimeOff';
import Permissions from '@components/common/permissions/Permissions';
import WorkingHours from '@components/common/workingHours/WorkingHours';
import Breaks from '@components/common/breaks/Breaks';
import DeleteModel from '@components/common/deleteModel/DeleteModel';
import Accordion from '@components/Accordion';
import { api } from 'helpers/auth-axios';
import CalendarBlock from '@components/common/calendarBlock/CalendarBlock';
import EditServiceProvider from '@components/team/edit/editServiceProvider';
import CardHeader from '@components/common/cardHeader/CardHeader';
import SendMessage from '@components/common/SendMessage';
import { TabContent, TabsCard } from '@components/customers/customerCard/CustomerCard';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import AgoraDetails from '@components/common/AgoraDetails/AgoraDetails';
import ServiceProviderStats from '@components/common/ServiceProviderStats';

const ServiceProviderCard = ({ callServiceProvider }: any) => {
    const { t }: any = useTranslation();
    const [serviceProvider, setServiceProvider] = useState<IServiceProvider>();
    const [tabValue, setTabValue] = useState(0);
    const [openDeleteModel, setOpenDeleteModel] = useState(false);
    const [loading, setLoading] = useState(false);
    const [deleteId, setDeleteId] = useState<any>();
    const [businessId, setBusinessId] = useState<any>();
    const router = useRouter();
    const dispatch = useAppDispatch();
    const [openSnackbar] = useSnackbar();
    const [isSendMessage, setIsSendMessage] = useState(false);
    const { services } = useAppSelector(state => state.service);
    const [availabilityStatus, setAvailabilityStatus] = useState<any[]>([]);
    const [is_available, setIs_available] = useState(false);
    let _userData = JSON.parse(localStorage.getItem('user') || '{}');
    const onChange = (day: any) => {};

    useEffect(() => {
        data();
        setIs_available(false);
        getAvailabilityOfServiceProvider();
    }, [router.query.id]);

    useEffect(() => {
        if (services?.length) return;
        dispatch(serviceActions.getServices());
    }, [dispatch, services?.length]);

    useEffect(() => {
        if (availabilityStatus?.length) {
            let format = 'hh:mm';
            for (let i = 0; i <= availabilityStatus?.length; i++) {
                let time = moment(todayTime, format),
                    beforeTime = moment(availabilityStatus[i], format),
                    afterTime = moment(availabilityStatus[i + 1], format);

                if (time.isBetween(beforeTime, afterTime)) {
                    setIs_available(true);
                } else {
                }
            }
        }
    }, [availabilityStatus]);

    const data = () => {
        setLoading(true);
        api.get(`/serviceProvider/${router.query.id as string}`)
            .then((res: any) => {
                setLoading(false);
                if (res.data !== 0) {
                    setServiceProvider(res.data);
                    setBusinessId(res.data.business_id);
                }
            })
            .catch((e: any) => {
                setLoading(false);
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    };

    const deleteModel = (id: any) => {
        setOpenDeleteModel(true);
        setDeleteId(id);
    };

    const deleteServiceProvider = (id: any) => {
        setLoading(true);
        api.delete(`/appointments/service_provider/${id}`)
            .then((res: any) => {
                if (res) {
                    router.navigate('/team/service-provider-list');
                    openSnackbar(t('Service Provider deleted successfully!'));
                    setOpenDeleteModel(false);
                    callServiceProvider();
                } else {
                    openSnackbar(t('Failed to delete Service Provider!'));
                    setOpenDeleteModel(false);
                }
            })
            .catch((e: any) => {
                setLoading(false);
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
        setLoading(false);
        setOpenDeleteModel(false);
    };

    const todayTime = moment().format('HH:mm');
    const getAvailabilityOfServiceProvider = () => {
        if ((router.query.id as string) !== undefined) {
            let date: any = moment().format();
            let duration = '15';
            api.post(`appointments/availability_status/${router.query.id as string}`, {
                date: date,
                duration: duration,
            }).then((res: any) => {
                setAvailabilityStatus(res.data?.slots);
            });
        }
    };

    const tabsNameList = [
        {
            name: t('About'),
            content: (
                <>
                    <MobileViewDiv>
                        <CardHeader
                            parent={'team'}
                            userData={serviceProvider}
                            deleteModel={() => deleteModel(router.query.id)}
                            messageModel={() => setIsSendMessage(true)}
                            available={is_available}
                        />
                    </MobileViewDiv>
                    <EditServiceProvider
                        available={is_available}
                        defaultData={serviceProvider}
                        clickCallBack={() => {
                            data();
                            callServiceProvider();
                        }}
                    />
                </>
            ),
        },
        {
            name: t('Services'),
            content: (
                <ServiceProviderServices
                    defaultServices={serviceProvider?.services}
                    serviceProviderData={serviceProvider}
                    callBack={() => data()}
                />
            ),
        },
        {
            name: t('Working Hours'),
            content: (
                <WorkingHours
                    active={true}
                    onChange={onChange}
                    business_id={businessId}
                    defaultWorkingHours={serviceProvider?.workingHours}
                    updateCallBack={() => {
                        setIs_available(false);
                        setAvailabilityStatus([]);
                        callServiceProvider();
                        getAvailabilityOfServiceProvider();
                    }}
                />
            ),
        },
        {
            name: t('Breaks'),
            content: (
                <Breaks
                    day={{ day: 1, from: '10:00', to: '19:00' }}
                    onChang={onChange}
                    defaultBreak={serviceProvider?.breaks}
                />
            ),
        },
        {
            name: t('Time Off'),
            content: <TimeOff timeOffData={serviceProvider?.time_off} />,
        },
        {
            name: t('Permissions'),
            content: (
                <Permissions
                    businessId={businessId}
                    defaultPermissions={serviceProvider?.permissions}
                />
            ),
        },
        {
            name: t('Block'),
            content: <CalendarBlock serviceProviderId={router.query.id as string} />,
        },
        {
            name: t('Stats'),
            content: <ServiceProviderStats serviceProviderId={router.query.id as string} />,
        },
        // {
        //     name: t('Agora'),
        //     content: <AgoraDetails defaultDetails={serviceProvider} />,
        // },
    ];

    return (
        <>
            {loading && <LoaderOverlay />}
            <MainContainer>
                <Row>
                    {serviceProvider && (
                        <>
                            <WebView lg={12}>
                                <CardHeader
                                    parent={'team'}
                                    userData={serviceProvider}
                                    deleteModel={() => deleteModel(router.query.id)}
                                    messageModel={() => setIsSendMessage(true)}
                                    available={is_available}
                                />
                            </WebView>
                            <WebView lg={12}>
                                <TabsCard>
                                    <TabContext value={tabValue}>
                                        <Tabs
                                            onChange={(event, newValue) => {
                                                setTabValue(newValue);
                                            }}>
                                            <Tab label={tabsNameList[0].name} />
                                            <Tab label={tabsNameList[1].name} />
                                            <Tab label={tabsNameList[2].name} />
                                            <Tab label={tabsNameList[3].name} />
                                            <Tab label={tabsNameList[4].name} />
                                            <Tab label={tabsNameList[5].name} />
                                            <Tab label={tabsNameList[6].name} />
                                            {_userData?.user_login !== 'user' ? (
                                                <Tab label={tabsNameList[7].name} />
                                            ) : (
                                                ''
                                            )}
                                        </Tabs>
                                        <TabPanel value={0} preload={true}>
                                            <TabContent padding={'0'}>
                                                {tabsNameList[0].content}
                                            </TabContent>
                                        </TabPanel>
                                        <TabPanel value={1}>
                                            <TabContent>{tabsNameList[1].content}</TabContent>
                                        </TabPanel>
                                        <TabPanel value={2} preload={true}>
                                            <TabContent>{tabsNameList[2].content}</TabContent>
                                        </TabPanel>
                                        <TabPanel value={3} preload={true}>
                                            <TabContent>{tabsNameList[3].content}</TabContent>
                                        </TabPanel>
                                        <TabPanel value={4} preload={true}>
                                            <TabContent>{tabsNameList[4].content}</TabContent>
                                        </TabPanel>
                                        <TabPanel value={5} preload={true}>
                                            <TabContent>{tabsNameList[5].content}</TabContent>
                                        </TabPanel>
                                        <TabPanel value={6} preload={true}>
                                            <TabContent>{tabsNameList[6].content}</TabContent>
                                        </TabPanel>
                                        {_userData?.user_login !== 'user' ? (
                                            <TabPanel value={7} preload={true}>
                                                <TabContent>{tabsNameList[7].content}</TabContent>
                                            </TabPanel>
                                        ) : (
                                            ''
                                        )}
                                    </TabContext>
                                </TabsCard>
                            </WebView>
                            <MobileView lg={12}>
                                <Accordion tabsNameList={tabsNameList} />
                            </MobileView>

                            {openDeleteModel && serviceProvider && (
                                <DeleteModel
                                    id={serviceProvider._id}
                                    onDelete={() => {
                                        setOpenDeleteModel(false);
                                    }}
                                    onClose={() => {
                                        setOpenDeleteModel(false);
                                    }}
                                    name={t('Service Provider')}
                                    content={t(
                                        'If you delete this service provider you will lose all information connected to their profile including past services, woking hours, break time, off time and permissions. This action cannot be undone.'
                                    )}
                                    loading={loading}
                                    onClick={() => deleteServiceProvider(deleteId)}
                                />
                            )}
                        </>
                    )}
                    {isSendMessage && (
                        <SendMessage
                            name={serviceProvider?.name}
                            phone={serviceProvider?.phone}
                            senderEmail={_userData.user.email}
                            reciverEmail={serviceProvider?.email}
                            onClose={() => {
                                setIsSendMessage(false);
                            }}
                        />
                    )}
                </Row>
            </MainContainer>
        </>
    );
};

const MainContainer = styled(Container)`
    margin-top: 1rem;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    ${media.xs`
    overflow-x: hidden;
    overflow-y: auto;
	`}
    ${media.sm`
    overflow-x: hidden;
    overflow-y: auto;
	`}
`;

const WebView = styled(Col)`
    display: block;
    @media screen and (min-width: 1px) and (max-width: 900px) {
        display: none;
    }
`;

const MobileView = styled(Col)`
    display: none;
    @media screen and (min-width: 1px) and (max-width: 900px) {
        display: block;
    }
`;

export const MobileViewDiv = styled.div`
    display: none;
    @media screen and (min-width: 1px) and (max-width: 900px) {
        display: block;
    }
`;

export default ServiceProviderCard;
