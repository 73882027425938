/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Calendar Actions
 * Description: This file contains action creators related to the calendar, including saving the current view.
 *
 * Actions:
 * - saveCalendarCurrentView: Action creator for saving the current view of the calendar.
 */

import { Dispatch } from 'redux';

const saveCalenderCurrentView = (name: string) => (dispatch: Dispatch) => {
    dispatch({
        type: 'current_view',
        current_view: name,
    });
};

export const calenderActions = {
    saveCalenderCurrentView,
};
