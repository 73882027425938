/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Appointment Service
 * Description: This service handles API requests related to appointments and to create/update/cancel appointments.
 */

import { IAppointment, IGetAppointments } from 'interfaces/appointment.interface';
import { api } from '../helpers/auth-axios';

export const appointmentService = {
    create,
    getAppointments,
    getAppointmentById,
    updateAppointmentById,
    cancelAppointmentById,
};

function create(appointmentData: IAppointment): Promise<IAppointment> {
    return api.post('/appointments', appointmentData).then(res => {
        return res.data;
    });
}

function getAppointments(getAppointmentsData: IGetAppointments): Promise<IAppointment[]> {
    return api.get('/appointments', { params: getAppointmentsData }).then(res => {
        return res.data;
    });
}

function getAppointmentById(appointmentId: string): Promise<IAppointment> {
    return api.get(`/appointments/get/${appointmentId}`).then(res => {
        return res.data;
    });
}

function updateAppointmentById(appointmentId: string, appointmentData: any): Promise<IAppointment> {
    return api.put(`/appointments/reschedule/${appointmentId}`, appointmentData).then(res => {
        return res.data;
    });
}

function cancelAppointmentById(appointmentId: string): Promise<IAppointment> {
    return api.put(`/appointments/status/${appointmentId}`).then(res => {
        return res.data;
    });
}
