/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Component: CustomiseCalendarHeader
 * Description: This component represents the customized header for the custom calendar view. It includes controls for navigating between dates, changing views, and selecting service providers. The header is responsive and adjusts its layout based on the screen size. It also provides options for modifying business hours and enabling VIP appointments.
 *
 * Props:
 * - goNext: () => void - Callback function to navigate to the next time period in the calendar.
 * - goPrev: () => void - Callback function to navigate to the previous time period in the calendar.
 * - goToday: () => void - Callback function to navigate to today's date in the calendar.
 * - currentView: string - The current view of the calendar (e.g., 'timeGridDay', 'timeGridWeek').
 * - changeView: (view: string) => void - Callback function triggered when the calendar view is changed.
 * - dateRange: any - The date range for the calendar.
 * - setServiceProviderId: (id: any) => void - Callback function to set the selected service provider's ID.
 * - showTeamCalendar: boolean - Boolean indicating whether to show the team calendar view.
 * - setPickDate: any - Callback function triggered when a date is picked on the calendar.
 * - setCalendar_Size: any - Callback function to set the size of the calendar.
 * - setTeamCalendarDate: any - Callback function to set the date for the team calendar.
 * - teamCalendarDate: any - The date for the team calendar.
 * - setDateRange: any - Callback function triggered when the date range is changed.
 * - setCustomBusinessHours: any - Callback function to set custom business hours.
 * - customBusinessHours: any - Information about custom business hours.
 * - setVipAppointment: (enabled: boolean) => void - Callback function to enable or disable the VIP appointment creation flow, allowing 24 business hours of the day, and ignoring already scheduled appointment times.
 * - vipAppointment: VipAppointmentInfo - Value of the VIP appointment creation flow to enable working hours.
 *
 */

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Select from '../../common/select/CalenderSelect';
import {
    Dropdown,
    DropdownMenu,
    DropdownToggle,
    LoaderOverlay,
    Switch,
    useSnackbar,
} from '@components/common';
import s from '../../common/userNav/UserNav.module.scss';
import { api } from 'helpers/auth-axios';
import { palette } from 'styled/common';
import { media } from 'styled-bootstrap-grid';
import AsideMobile from '../../common/asideMobile/AsideMobile';
import { useAppDispatch } from 'hooks/redux';
import { calenderActions } from '../../../actions/calender';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Button from '@components/Button';
import { CustomDiv } from '../../team/list/serviceProviderList/ServiceProviderList';

interface IProps {
    goNext: () => void;
    goPrev: () => void;
    goToday: () => void;
    currentView: string;
    changeView: (view: string) => void;
    dateRange: any;
    setServiceProviderId: any;
    showTeamCalendar: boolean;
    setPickDate: any;
    setCalendar_Size: any;
    setTeamCalendarDate: any;
    teamCalendarDate: any;
    setDateRange: any;
    setCustomBusinessHours: any;
    customBusinessHours: any;
    setVipAppointment: any;
    vipAppointment: any;
}

const calendarViews = [
    {
        name: 'timeGridDay',
        icon: 'fal fa-calendar-day',
    },
    {
        name: 'timeGridWeek',
        icon: 'fal fa-calendar-week',
    },
    {
        name: 'dayGridMonth',
        icon: 'fal fa-calendar-alt',
    },
    {
        name: 'teamGridWeek',
        icon: 'fal fa-calendar-day',
    },
];

const CustomiseCalendarHeader = ({
    goNext,
    goPrev,
    goToday,
    currentView,
    changeView,
    dateRange,
    showTeamCalendar,
    setServiceProviderId,
    setCalendar_Size,
    setPickDate,
    setTeamCalendarDate,
    teamCalendarDate,
    setDateRange,
    setCustomBusinessHours,
    customBusinessHours,
    setVipAppointment,
    vipAppointment,
}: IProps) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [currentCalendarView, setCurrentCalendarView] = useState(currentView);
    const [serviceProvider, setServiceProvider] = useState<any>([]);
    const [selectedServiceProvider, setSelectedServiceProvider] = useState(null);
    const [openCalenderPopup, setOpenCalenderPopup] = useState(false);
    const [loading, setLoading] = useState(false);
    var _userData = JSON.parse(localStorage.getItem('user') || '{}');
    const [open, setOpen] = useState(false);
    const [selectId, setSelectId] = useState<any>();
    const [is_vip_check, setIs_vip_check] = useState<any>();
    const [openSnackbar] = useSnackbar();

    useEffect(() => {
        getAllServiceProvider();
    }, []);

    const providerValue = (e: any) => {
        setIs_vip_check(e?.vip);
        setServiceProviderId(e.id);
        setSelectedServiceProvider(e.label);
        setCalendar_Size(e.calendar_size);
    };

    const calenderView = (name: string) => {
        if (name === 'teamGridWeek') {
            setCurrentCalendarView('teamGridWeek');
        } else {
            setCurrentCalendarView(name);
        }
        changeView(name);
        dispatch(calenderActions.saveCalenderCurrentView(name));
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getAllServiceProvider = () => {
        setLoading(true);
        api.post(`/serviceProvider/calendar-data`)
            .then((serviceProvider: any) => {
                setLoading(false);
                setServiceProvider(serviceProvider.data);
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                if (serviceProvider.data?.length) {
                    setServiceProviderId(serviceProvider.data[0]._id);
                    setIs_vip_check(serviceProvider.data[0]?.vip);
                    setCalendar_Size(serviceProvider.data[0].calendar_size);
                }
            })
            .catch(e => {
                setLoading(false);
            });
    };

    const calendar: { label: string; id: any; icon: any; calendar_size: any; vip: any }[] = [];
    serviceProvider.map((data: any) => {
        calendar.push({
            label: `${data.name}`,
            id: `${data._id}`,
            icon: `${data.photo}`,
            calendar_size: `${data.calendar_size}`,
            vip: data?.vip,
        });
    });

    const openAsidNew = () => {
        if (open) {
            setOpen(false);
        } else if (open === false) {
            setOpen(true);
        }
    };

    const openCalenderDrop = () => {
        if (openCalenderPopup) {
            setOpenCalenderPopup(false);
        } else {
            setOpenCalenderPopup(true);
        }
    };

    const CalendarDate = (e: any) => {
        if (e.target.value) {
            setTeamCalendarDate(e.target.value);
            setDateRange(e.target.value);
        }
    };

    const dateList = () => {
        var format = /[–]+/;
        var doFormat: any = '';

        if (format.test(dateRange)) {
            doFormat = dateRange.split(' ');
            if (doFormat?.length !== 0 && doFormat?.length === 7) {
                // eslint-disable-next-line no-useless-concat
                dateRange = doFormat[0] + ' ' + doFormat[1] + ',' + ' ' + doFormat[6];
            } else if (doFormat?.length !== 0 && doFormat?.length === 6) {
                // eslint-disable-next-line no-useless-concat
                dateRange = doFormat[0] + ' ' + doFormat[1] + ',' + ' ' + doFormat[5];
            } else if (doFormat?.length !== 0 && doFormat?.length === 5) {
                // eslint-disable-next-line no-useless-concat
                dateRange = doFormat[0] + ' ' + doFormat[1] + ',' + ' ' + doFormat[4];
            }
        }
    };

    if (currentCalendarView === 'teamGridWeek') {
        dateList();
    }

    return (
        <>
            {loading && <LoaderOverlay />}
            <CalendarHead>
                <Flex>
                    <Bars onClick={openAsidNew}>
                        <BarIcon className="far fa-bars"></BarIcon>
                    </Bars>
                </Flex>
                <TodayFlex>
                    <TodayButton onClick={goToday}>
                        {currentCalendarView == 'timeGridWeek'
                            ? t('Current Week')
                            : currentCalendarView == 'dayGridMonth'
                            ? 'Current Month'
                            : t('Today')}
                    </TodayButton>
                    <Section>
                        <LeftArrow onClick={goPrev}>
                            <i className="fas fa-chevron-left"></i>
                        </LeftArrow>
                        <RightArrow onClick={goNext}>
                            <i className="fas fa-chevron-right"></i>
                        </RightArrow>
                    </Section>
                    <CalenderDate top={_userData?.user?.business_id?.leftMenu === false && true}>
                        <Date> {dateRange}</Date>
                        <input
                            type="date"
                            min={moment().format('YYYY-MM-DD')}
                            value={moment(teamCalendarDate).format('YYYY-MM-DD')}
                            onChange={(e: any) => CalendarDate(e)}
                        />
                    </CalenderDate>
                </TodayFlex>
                {(!showTeamCalendar && _userData?.user_login === 'user') ||
                _userData?.user_login === 'teamMember' ||
                _userData?.user_login === 'others' ? (
                    <SelectFlex>
                        {calendar?.length > 0 ? (
                            <Select
                                disabled={true}
                                id={selectId ? selectId : calendar[0].id}
                                value={
                                    selectedServiceProvider != null
                                        ? selectedServiceProvider
                                        : calendar[0].label
                                }
                                options={calendar}
                                onChange={(e: any) => {
                                    providerValue(e);
                                    setSelectId(e?.id);
                                }}
                            />
                        ) : (
                            ''
                        )}
                    </SelectFlex>
                ) : (
                    ''
                )}
                <MobileCalenderOption>
                    <Dropdown>
                        <DropdownToggle onClick={openCalenderDrop}>
                            <CalendarIcon className={`${s.actionBtn}`}>
                                <i className="far fa-calendar-check"></i>
                            </CalendarIcon>
                        </DropdownToggle>
                        {openCalenderPopup && (
                            <DropdownMenu className={s.calender_menu} scrollable={false}>
                                <Cover>
                                    <CoverHeading>{dateRange}</CoverHeading>
                                    <input
                                        type="date"
                                        min={moment().format('YYYY-MM-DD')}
                                        value={moment(teamCalendarDate).format('YYYY-MM-DD')}
                                        onChange={(e: any) => CalendarDate(e)}
                                    />
                                </Cover>
                                <SettingListBody>
                                    <TodayList>
                                        <>
                                            {' '}
                                            <TodayButton onClick={goToday}>
                                                {t('Today')}
                                            </TodayButton>
                                            <Section>
                                                <LeftArrow onClick={goPrev}>
                                                    <i className="fas fa-chevron-left"></i>
                                                </LeftArrow>
                                                <RightArrow onClick={goNext}>
                                                    <i className="fas fa-chevron-right"></i>
                                                </RightArrow>
                                            </Section>
                                        </>
                                    </TodayList>
                                    {(calendar?.length > 0 && _userData.user_login === 'user') ||
                                    _userData.user_login === 'teamMember' ||
                                    _userData?.user_login === 'others' ? (
                                        <SelectList>
                                            <Select
                                                disabled={true}
                                                id={selectId ? selectId : calendar[0].id}
                                                value={
                                                    selectedServiceProvider != null
                                                        ? selectedServiceProvider
                                                        : calendar[0].label
                                                }
                                                options={calendar}
                                                onChange={(e: any) => {
                                                    providerValue(e);
                                                    setSelectId(e?.id);
                                                }}
                                            />
                                        </SelectList>
                                    ) : (
                                        ''
                                    )}
                                    <List>
                                        <CalendarsIcons>
                                            {calendarViews.map((view, i) => (
                                                <div key={i}>
                                                    {currentCalendarView === view.name ? (
                                                        <>
                                                            {_userData.user_login !== 'user' &&
                                                            view.name !== 'teamGridWeek' ? (
                                                                <CalandersPrimary
                                                                    key={i}
                                                                    onClick={() =>
                                                                        calenderView(view.name)
                                                                    }>
                                                                    <Icon
                                                                        className={`${view.icon}`}></Icon>
                                                                </CalandersPrimary>
                                                            ) : _userData.user_login === 'user' ||
                                                              _userData.user_login ===
                                                                  'teamMember' ||
                                                              _userData.user_login === 'others' ? (
                                                                <CalandersPrimary
                                                                    key={i}
                                                                    onClick={() =>
                                                                        calenderView(view.name)
                                                                    }>
                                                                    <Icon
                                                                        className={`${view.icon}`}></Icon>
                                                                </CalandersPrimary>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {' '}
                                                            {_userData.user_login !== 'user' &&
                                                            view.name !== 'teamGridWeek' ? (
                                                                <CalandersSecondary
                                                                    key={i}
                                                                    onClick={() =>
                                                                        calenderView(view.name)
                                                                    }>
                                                                    <Icon
                                                                        className={`${view.icon}`}></Icon>
                                                                </CalandersSecondary>
                                                            ) : _userData.user_login === 'user' ||
                                                              _userData.user_login ===
                                                                  'teamMember' ||
                                                              _userData.user_login === 'others' ? (
                                                                <CalandersSecondary
                                                                    key={i}
                                                                    onClick={() =>
                                                                        calenderView(view.name)
                                                                    }>
                                                                    <Icon
                                                                        className={`${view.icon}`}></Icon>
                                                                </CalandersSecondary>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            ))}
                                        </CalendarsIcons>
                                    </List>
                                </SettingListBody>
                            </DropdownMenu>
                        )}
                    </Dropdown>
                </MobileCalenderOption>
                <CustomDiv style={{ gap: '0.5rem' }}>
                    <Button
                        bgtype={customBusinessHours ? 'success' : 'secondary'}
                        ifClicked={() => {
                            setCustomBusinessHours(!customBusinessHours);
                            setVipAppointment(false);
                            customBusinessHours === false &&
                                openSnackbar(
                                    t('Select calendar area to create customize Working Hours')
                                );
                        }}
                        label={t('Modify Business Hours')}></Button>
                    {is_vip_check && (
                        <>
                            <span style={{ fontSize: '0.9rem' }}>{t('Vip Appointment?')}</span>
                            <Switch
                                onChange={(val: boolean) => {
                                    setCustomBusinessHours(false);
                                    setVipAppointment(!vipAppointment);
                                }}
                                value={vipAppointment}
                                isSmall={true}
                            />
                        </>
                    )}
                </CustomDiv>
                <Flex>
                    <CalendarsRow>
                        {calendarViews.map((view, i) => (
                            <div key={i}>
                                {currentCalendarView === view.name ? (
                                    <>
                                        {_userData.user_login !== 'user' &&
                                        view.name !== 'teamGridWeek' ? (
                                            <CalandersPrimary
                                                key={i}
                                                onClick={() => calenderView(view.name)}>
                                                <Icon className={`${view.icon}`}></Icon>
                                            </CalandersPrimary>
                                        ) : _userData.user_login === 'user' ||
                                          _userData.user_login === 'teamMember' ||
                                          _userData.user_login === 'others' ? (
                                            <CalandersPrimary
                                                key={i}
                                                onClick={() => calenderView(view.name)}>
                                                <Icon className={`${view.icon}`}></Icon>
                                            </CalandersPrimary>
                                        ) : (
                                            ''
                                        )}
                                    </>
                                ) : (
                                    <>
                                        {' '}
                                        {_userData.user_login !== 'user' &&
                                        view.name !== 'teamGridWeek' ? (
                                            <CalandersSecondary
                                                key={i}
                                                onClick={() => calenderView(view.name)}>
                                                <Icon className={`${view.icon}`}></Icon>
                                            </CalandersSecondary>
                                        ) : _userData.user_login === 'user' ||
                                          _userData.user_login === 'teamMember' ||
                                          _userData.user_login === 'others' ? (
                                            <CalandersSecondary
                                                key={i}
                                                onClick={() => calenderView(view.name)}>
                                                <Icon className={`${view.icon}`}></Icon>
                                            </CalandersSecondary>
                                        ) : (
                                            ''
                                        )}
                                    </>
                                )}
                            </div>
                        ))}
                    </CalendarsRow>
                </Flex>
            </CalendarHead>
            {open && <AsideMobile open={open} openAsidNew={openAsidNew} />}
        </>
    );
};

const Bars = styled.h1`
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 500;
    display: none;
    align-items: center;
    color: black;
    align-items: center;
    ${media.xs`
    display:flex;
  `}
    ${media.sm`
    display:flex;
  `}
    ${media.md`
	display:flex;
  `}
    ${media.lg`
    display:none;
  `}
`;

const BarIcon = styled.i`
    font-size: 1.5rem !important;
    line-height: 2rem !important;
    margin-right: 1rem !important;
    cursor: pointer;
`;

const CalendarHead = styled.div`
    height: 64px;
    width: 100%;
    padding: 1rem 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: ${palette.white};
    border-style: solid !important;
    border-left-width: 0px !important;
    border-right-width: 0px !important;
    border-top-width: 0px !important;
    border-bottom-width: 0.5px !important;
    border-color: rgb(229 231 235);
`;

const Flex = styled.div`
    display: flex;
    align-items: center !important;
`;

const MobileCalenderOption = styled.div`
    display: none;
    align-items: center !important;
    @media screen and (min-width: 200px) and (max-width: 1200px) {
        display: flex;
    }
`;

const TodayButton = styled.div`
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow,
        transform, filter, backdrop-filter, -webkit-backdrop-filter !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
    border: 1px solid ${palette.grey.lightest} !important;
    border-radius: 0.25rem !important;
    cursor: pointer;
    &:hover {
        background-color: ${palette.grey.lightest} !important;
    }
`;

const Section = styled.div`
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
    display: flex;
`;

const LeftArrow = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow,
        transform, filter, backdrop-filter, -webkit-backdrop-filter !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
    color: rgba(157, 160, 164) !important;
    border-radius: 9999px !important;
    width: 2.25rem !important;
    height: 2.25rem !important;
    cursor: pointer;
    &:hover {
        background-color: ${palette.grey.lightest} !important;
    }
`;

const RightArrow = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow,
        transform, filter, backdrop-filter, -webkit-backdrop-filter !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
    color: rgba(157, 160, 164) !important;
    border-radius: 9999px !important;
    width: 2.25rem !important;
    height: 2.25rem !important;
    cursor: pointer;
    &:hover {
        background-color: ${palette.grey.lightest} !important;
    }
`;

const Date = styled.div`
    color: rgba(38, 52, 70) !important;
    font-size: 1.25rem !important;
    line-height: 1.75rem !important;
    cursor: pointer;
    @media screen and (min-width: 200px) and (max-width: 700px) {
        display: none;
    }
`;

const CalendarsRow = styled.div`
    display: flex !important;
    margin-right: 2rem !important;
    @media screen and (min-width: 200px) and (max-width: 1200px) {
        display: none !important;
    }
`;

const CalendarsIcons = styled.div`
    display: flex !important;
    margin-right: 2rem !important;
    @media screen and (min-width: 200px) and (max-width: 1200px) {
        justify-content: center;
        margin-right: 0rem !important;
        margin-left: 2rem;
    }
`;

const CalandersPrimary = styled.div`
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
    color: black !important;
    border-radius: 9999px !important;
    width: 3rem !important;
    height: 3rem !important;
    cursor: pointer;
    align-items: center;
    display: flex;
`;

const CalandersSecondary = styled.div`
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 150ms !important;
    color: rgba(157, 160, 164) !important;
    border-radius: 9999px !important;
    width: 3rem !important;
    height: 3rem !important;
    cursor: pointer;
    align-items: center;
    display: flex;
`;

const Icon = styled.i`
    font-size: 1.5rem !important;
    line-height: 2rem !important;
`;

const CalendarIcon = styled.div`
    color: rgba(38, 52, 70) !important;
    border-radius: 9999px !important;
    cursor: pointer;
`;

const Cover = styled.div`
    background: ${palette.header};
    background-position: center center;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    background-size: cover;
    padding: 24px;
    input {
        border: none;
        box-sizing: border-box;
        outline: 0;
        position: absolute;
        top: 1.9rem;
        width: 10rem;
        opacity: 0;
        cursor: pointer;
    }
    input[type='date']::-webkit-calendar-picker-indicator {
        background: transparent;
        bottom: 0;
        color: transparent;
        cursor: pointer;
        height: auto;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
    }
`;

const CoverHeading = styled.div`
    color: rgba(255, 255, 255) !important;
    font-weight: 500 !important;
    font-size: 1.25rem !important;
    line-height: 1.75rem !important;
`;

const SettingListBody = styled.div``;

const List = styled.div`
    display: flex;
    padding: 0.75rem 0.75rem !important;
    border: 0.1rem solid ${palette.grey.lightest};
    align-items: center;
    cursor: pointer;
    font-size: 1.15rem !important;
    color: ${palette.dark};
`;

const TodayFlex = styled.div`
    display: flex;
    align-items: center !important;
    @media screen and (min-width: 200px) and (max-width: 531px) {
        display: none;
    }
`;

const TodayList = styled.div`
    display: none;
    padding: 0.75rem 0.75rem !important;
    border: 0.1rem solid ${palette.grey.lightest};
    align-items: center;
    cursor: pointer;
    font-size: 1.15rem !important;
    color: ${palette.dark};
    @media screen and (min-width: 200px) and (max-width: 531px) {
        display: flex;
    }
`;

const SelectFlex = styled.span`
    display: flex;
    align-items: center !important;
    width: 15%;
    @media screen and (min-width: 200px) and (max-width: 1050px) {
        display: none;
    }
`;

const SelectList = styled.div`
    display: none;
    padding: 0.75rem 0.75rem !important;
    border: 0.1rem solid ${palette.grey.lightest};
    align-items: center;
    cursor: pointer;
    font-size: 1.15rem !important;
    color: ${palette.dark};
    @media screen and (min-width: 200px) and (max-width: 1050px) {
        display: block;
    }
`;

const CalenderDate = styled.div<any>`
    display: block;
    input {
        border: none;
        box-sizing: border-box;
        outline: 0;
        position: absolute;
        top: ${({ top }) => (top ? '7rem' : '1.5rem')};
        width: 10rem;
        opacity: 0;
        cursor: pointer;
    }
    input[type='date']::-webkit-calendar-picker-indicator {
        background: transparent;
        bottom: 0;
        color: transparent;
        cursor: pointer;
        height: auto;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
    }
    @media screen and (min-width: 200px) and (max-width: 700px) {
        display: none;
    }
`;

export default CustomiseCalendarHeader;
