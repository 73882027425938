/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * PreviewScreen Component
 * Description: This component represents a modal for previewing and sharing documents. It allows users to view different types of documents and take actions such as canceling or validating.
 *
 * Props:
 * - onCloseCB: () => void - Callback function triggered when the modal is closed.
 * - onSubmitCB: () => void - Callback function triggered when the user submits the document.
 * - data: any - Additional data for the preview screen.
 *
 */

import { useTranslation } from 'react-i18next';

const PreviewScreen = ({ onCloseCB, onSubmitCB, data }: any) => {
    const { t } = useTranslation('common');

    return (
        <div className="col-8">
            <div className="row">
                <div className="">
                    <h5 className="">{t('Document preview/sharing')}</h5>
                </div>
            </div>
            <div className="row ">
                <div className="col-12">
                    <div className="row">
                        <div className="fw-bold">{t('Type of document')}</div>
                    </div>
                    <div className="row d-flex document-content">
                        <div className="col-3 bgGray56 m-0 p-0">
                            <div className="row gap-3 text-white m-0 p-3">
                                <div className="col-12 border rounded">
                                    {t('prescription drugs')}
                                </div>
                                <div className="col-12 border rounded">
                                    {t('biology prescription')}
                                </div>
                                <div className="col-12 border rounded">
                                    {t('imaging prescription')}
                                </div>
                                <div className="col-12 border rounded">{t('medical imaging')}</div>
                                <div className="col-12 border rounded">{t('report')}</div>
                                <div className="col-12 border rounded">{t('care sheet')}</div>
                                <div className="col-12 border rounded">{t('other')}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal-footer border-0">
                <button
                    type="button"
                    className="btn text-uppercase"
                    data-dismiss="modal"
                    onClick={onCloseCB}
                    style={{ fontSize: '12px' }}>
                    {t('Cancel')}
                </button>

                <button
                    type="button"
                    className="btn btn-default text-uppercase"
                    onClick={onSubmitCB}
                    style={{ fontSize: '12px' }}>
                    {t('Validate')}
                </button>
            </div>
        </div>
    );
};

export default PreviewScreen;
