/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Main App Component for handling global app logic.
 *
 * App Component Description:
 * - Integrates with Redux to manage user state.
 * - Initializes the app and fetches user data if available.
 * - Renders loading state while fetching data.
 * - Renders the main AppRoutes component once data is loaded.
 *
 */

import '../styles/globals.css';
import '../components/common/index.scss';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { IUserState } from 'interfaces/user.interface';
import AppRoutes from 'router/routes';
import { api } from 'helpers/auth-axios';
import AdBanner from '@components/common/adBanner';
import { LogoLoader } from '@components/common/loaderOverlay';
import useRouter from 'hooks/router';

const App = () => {
    const dispatch = useAppDispatch();
    const { user }: { user: IUserState } = useAppSelector(state => state.auth);
    const [loading, setLoading] = useState(true);
    const router = useRouter();

    useEffect(() => {
        var _userData = JSON.parse(localStorage.getItem('user') || '{}');
        if (user) {
            if (_userData?.user?.business_id?._id) {
                api.get(`/businesses/${_userData?.user?.business_id?._id}`).then((res: any) => {
                    setLoading(false);
                });
            } else {
                setLoading(false);
            }
        } else {
            setLoading(false);
            // Do something
        }
    }, [user, dispatch]);

    useEffect(() => {
        var _userData = JSON.parse(localStorage.getItem('user') || '{}');
        if (user) {
            if (_userData?.refreshToken) {
                api.post(`/auth/refresh`, { refreshToken: _userData?.refreshToken })
                    .then((res: any) => {
                        localStorage.setItem('user', JSON.stringify(res.data));
                        setLoading(false);
                    })
                    .catch((err: any) => {
                        if (err?.response?.data?.message == 'Refresh token has expired.') {
                            router.navigate('/login');
                            setLoading(false);
                        }
                    });
            } else {
                router.navigate('/login');
                setLoading(false);
            }
        } else {
            setLoading(false);
            // Do something
        }
    }, [user]);

    return loading ? (
        <>
            <LogoLoader />
        </>
    ) : (
        <>
            {<AppRoutes />}
            <div>
                <AdBanner />
            </div>
        </>
    );
};

export default App;
