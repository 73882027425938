/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Services Component
 * Description: This component provides a view for managing business services. It includes a list of services and an option to edit a selected service.
 *
 * State:
 * - showEdit: boolean - A flag to determine whether the edit service modal is visible.
 * - selectedService: IService | undefined - The selected service for editing.
 */

import React, { useState } from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import { Layout } from '@components/common';
import { BusinessServices } from '@components/settings';
import EditService from '@components/settings/businessServices/EditService';
import { IService } from 'interfaces/service.interface';

export default function Services() {
    const [showEdit, setShowEdit] = useState(false);
    const [selectedService, setSelectedService] = useState<IService>();

    const onSelectService = (service: IService) => {
        setSelectedService(service);
        setShowEdit(true);
    };

    return (
        <>
            <Wrapper>
                {showEdit === false ? (
                    <>
                        <Row>
                            <Column lg={12} style={{ marginTop: '2rem' }}>
                                <BusinessServices onSelectService={onSelectService} />
                            </Column>
                        </Row>
                    </>
                ) : (
                    <EditService service={selectedService} onClose={() => setShowEdit(false)} />
                )}
            </Wrapper>
        </>
    );
}

const Wrapper = styled(Container)``;

const Column = styled(Col)`
    display: flex;
    align-items: center;
`;

Services.Layout = Layout;
