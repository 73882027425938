/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * DashboardTable Component
 * Description: This component displays a table with booking/appointments details such as customer name, booked time, service provider, service, duration, status, payment, tip, price, voucher, and remaining.
 * It uses MDBDataTableV5 for the table functionality.
 *
 * Props:
 * - data: Array - Array of booking/appointments data.
 *
 */

import React, { useEffect, useState } from 'react';
import { NotFound } from '../../team/list/serviceProviderList/ServiceProviderList';
import styled from 'styled-components';
import 'bootstrap-css-only/css/bootstrap.min.css';
import { MDBDataTableV5 } from 'mdbreact';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const DashboardTable = React.forwardRef(({ data }: any, ref: any) => {
    const { t }: any = useTranslation();
    const [tableData, setTableData] = useState({
        columns: [
            {
                label: t('Customer Name'),
                field: 'customer',
                width: 470,
                attributes: { 'aria-controls': 'DataTable', 'aria-label': 'Name' },
            },
            {
                label: t('Booked Time'),
                field: 'booked_from_iso',
                width: 470,
                sort: String('disabled'),
            },
            {
                label: t('Service Provider'),
                field: 'serviceProvider',
                width: 470,
                sort: String('disabled'),
            },
            { label: t('Service'), field: 'service', width: 470, sort: String('disabled') },
            { label: t('Duration'), field: 'duration', width: 470, sort: String('disabled') },
            { label: t('Status'), field: 'status', width: 470, sort: String('disabled') },
            { label: t('Payment'), field: 'payment', width: 470, sort: String('disabled') },
            { label: t('Tip'), field: 'tip', width: 470, sort: String('disabled') },
            { label: t('Price'), field: 'total', width: 470, sort: String('disabled') },
            { label: t('Voucher'), field: 'discount', width: 470, sort: String('disabled') },
            { label: t('Remaining'), field: 'remaining', width: 470, sort: String('disabled') },
        ],
        rows: [],
    });

    useEffect(() => {
        if (data) {
            data.map((val: any) => createTable(val));
        }
    }, [data]);

    const createTable = (row: any) => {
        let _obj = {
            customer: <Text>{row?.customer?.fullname}</Text>,
            booked_from_iso: (
                <Text>
                    {moment(row?.booked_from_iso).format('YYYY-MM-DD')}{' '}
                    <Text>{moment(row?.booked_from_iso).format('HH:mm')}</Text>
                </Text>
            ),
            serviceProvider: <Text>{row?.serviceProvider?.name}</Text>,
            service: <Text>{row?.service?.name}</Text>,
            duration: (
                <Text>
                    {row?.duration}
                    &nbsp;
                    {t('min')}
                </Text>
            ),
            status: <Text>{row?.status}</Text>,
            payment: <Text>{row?.payment}</Text>,
            tip: (
                <Text>
                    {row?.tip ? row?.total?.charAt(0) + row?.tip : row?.total?.charAt(0) + 0}
                </Text>
            ),
            total: <Text>{row?.total?.charAt(0) + row?.total_value}</Text>,
            discount: (
                <Text>
                    {row?.coupen_used
                        ? row?.total?.charAt(0) + row?.discount
                        : row?.total?.charAt(0) + 0}
                </Text>
            ),
            remaining: <Text>{row?.total}</Text>,
        };
        setTableData((preValue): any => {
            return {
                columns: [...preValue.columns],
                rows: [...preValue.rows, _obj],
            };
        });
    };

    return (
        <>
            {data?.length ? (
                <MDBDataTableV5
                    data={tableData}
                    ref={ref}
                    entries={20}
                    pagesAmount={40}
                    noBottomColumns={true}
                    searchTop={false}
                    searchBottom={false}
                    responsive={true}
                    striped={true}
                />
            ) : (
                <CustomDiv>
                    <NotFound>{t('No Record Found!')}</NotFound>
                </CustomDiv>
            )}
        </>
    );
});

const CustomDiv = styled.div`
    margin-top: 2rem;
`;

const Text = styled.div``;

export default DashboardTable;
