/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Business Actions
 * Description: This file contains action creators related to business, including fetching and updating business information.
 *
 * Constants:
 * - businessConstants: Constants for business actions.
 *
 * Actions:
 * - getBusiness: Action creator for fetching business information.
 * - updateBusiness: Action creator for updating general business information.
 * - updateOpenHours: Action creator for updating business open hours.
 * - updateLocation: Action creator for updating business location.
 * - updateBookingSettings: Action creator for updating business booking settings.
 */

import { IBusiness } from 'interfaces/business.interface';
import { Dispatch } from 'redux';
import { businessService } from 'services';

export const businessConstants = {
    GET_BUSINESS: 'GET_BUSINESS_SUCCESS',

    UPDATE_REQUEST: 'BUSINESS_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'BUSINESS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'BUSINESS_UPDATE_FAILURE',

    UPDATE_OPEN_HOURS_REQUEST: 'BUSINESS_OPEN_HOURS_REQUEST',
    UPDATE_LOCATION_REQUEST: 'BUSINESS_LOCATION_REQUEST',
    UPDATE_BOOKING_SETTINGS_REQUEST: 'BUSINESS_BOOKING_SETTINGS_REQUEST',
};

const getBusiness = (businessId: string) => (dispatch: Dispatch) => {
    return businessService.getBusiness(businessId).then(business => {
        dispatch({
            type: businessConstants.GET_BUSINESS,
            business,
        });

        return business;
    });
};

const updateBusines = (id: string, business: IBusiness) => (dispatch: Dispatch) => {
    dispatch({
        type: businessConstants.UPDATE_REQUEST,
    });

    return businessService
        .updateBusiness(id, business)
        .then(business => {
            dispatch({
                type: businessConstants.UPDATE_SUCCESS,
                business,
            });

            return business;
        })
        .catch(() => {
            dispatch({
                type: businessConstants.UPDATE_FAILURE,
            });
        });
};

const updateOpenHours = (id: string, openHours: any) => (dispatch: Dispatch) => {
    dispatch({
        type: businessConstants.UPDATE_OPEN_HOURS_REQUEST,
    });

    businessService
        .updateOpenHours(id, openHours)
        .then(business => {
            dispatch({
                type: businessConstants.UPDATE_SUCCESS,
                business,
            });
            return business;
        })
        .catch(() => {
            dispatch({
                type: businessConstants.UPDATE_FAILURE,
            });
        });
};

const updateLocation = (id: string, location: any) => (dispatch: Dispatch) => {
    dispatch({
        type: businessConstants.UPDATE_LOCATION_REQUEST,
    });

    return businessService
        .updateLocation(id, location)
        .then(business => {
            dispatch({
                type: businessConstants.UPDATE_SUCCESS,
                business,
            });
        })
        .catch(() => {
            dispatch({
                type: businessConstants.UPDATE_FAILURE,
            });
        });
};

const updateBookingSettings = (id: string, settings: any) => (dispatch: Dispatch) => {
    dispatch({
        type: businessConstants.UPDATE_BOOKING_SETTINGS_REQUEST,
    });
    return businessService
        .updateBookingSettings(id, settings)
        .then(business => {
            dispatch({
                type: businessConstants.UPDATE_SUCCESS,
                business,
            });

            return business;
        })
        .catch(() => {
            dispatch({
                type: businessConstants.UPDATE_FAILURE,
            });
        });
};

export const businessActions = {
    getBusiness,
    updateBusines,
    updateOpenHours,
    updateLocation,
    updateBookingSettings,
};
