/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * EditProfileModel Component
 * Purpose: This component provides a modal for editing user profile details, including name, email, password, and profile picture. It supports features such as updating profile information, changing passwords, and uploading a new profile picture. It is designed to enhance the user experience and provide a seamless interface for managing user profile settings.
 *
 * Props:
 * - onClose: () => void - Callback function triggered when the modal is closed.
 * - actions?: any - Actions for interacting with the Redux store.
 */

import { Input } from '@components/common';
import styled from 'styled-components';
import { useState, useEffect } from 'react';
import Button from '@components/Button';
import { Container, Row, Col, media } from 'styled-bootstrap-grid';
import { palette } from 'styled/common';
import { useSnackbar } from './snackbar';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { api } from 'helpers/auth-axios';
import { connect } from 'react-redux';
import { getSaveUser } from 'actions/user';
import { bindActionCreators } from 'redux';
import LoaderOverlay from './loaderOverlay';
import Compressor from 'compressorjs';
import { useTranslation } from 'react-i18next';

interface Props {
    onClose(): void;
    actions?: any;
}

const EditProfileModel = ({ onClose, actions }: Props) => {
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const { t } = useTranslation();
    const validationSchema = Yup.object().shape(
        open
            ? {
                  newPassword: Yup.string()
                      .required(t('New Password is required') || 'New Password is required')
                      .min(
                          8,
                          t('Password length should be at least 8 characters') ||
                              'Password length should be at least 8 characters'
                      ),
                  confirmPassword: Yup.string()
                      .required(
                          t('Confirm Password field is required') ||
                              'Confirm Password field is required'
                      )
                      .min(
                          8,
                          t('Password length should be at least 8 characters') ||
                              'Password length should be at least 8 characters'
                      )
                      .oneOf(
                          [Yup.ref('newPassword'), null],
                          t('Passwords must and should match') || 'Passwords must and should match'
                      ),
              }
            : {
                  name: Yup.string().required(t('name is required') || 'name is required'),
                  email: Yup.string().required(t('email is required') || 'email is required'),
              }
    );

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { handleSubmit, setValue, formState, trigger, getValues } = useForm(formOptions);
    const { errors } = formState;
    const [profileImage, setProfileImage] = useState(null);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [uploadImage, setUploadImage] = useState<boolean>(false);
    const [openSnackbar] = useSnackbar();

    useEffect(() => {
        getUser();
    }, []);

    // Set initial values
    useEffect(() => {
        setTimeout(() => {
            setOldPassword('' as never);
            setNewPassword('' as never);
            setConfirmPassword('' as never);
        });
    }, [setValue]);

    const closeModal = (event: any) => {
        if (event.target === event.currentTarget) {
            onClose();
        }
    };

    const handleCapture = ({ target }: any) => {
        new Compressor(target.files[0], {
            quality: 0.6,
            success: res => {
                setUploadImage(true);
                const reader: any = new FileReader();
                reader.readAsDataURL(res);
                reader.onload = () => {
                    if (reader.readyState === 2) {
                        setProfileImage(reader.result);
                    }
                };
            },
        });
    };

    const onSubmit = () => {
        setLoading(true);
        const userData = {
            name: getValues('name'),
            email: getValues('email').toLowerCase(),
            oldPassword: oldPassword,
            newPassword: newPassword,
        };

        api.put('/users/update_profile', {
            ...userData,
            newPhoto: uploadImage === true ? profileImage : null,
        })
            .then((res: any) => {
                let _userData = JSON.parse(localStorage.getItem('user') || '{}');

                if (res !== undefined) {
                    if (res?.data?.accessToken) {
                        _userData.user.name = res.data.user.name;
                        _userData.user.photo = res.data.user.photo;
                        _userData.user.email = res.data.user.email;
                        _userData.accessToken = res.data.accessToken;
                        _userData.refreshToken = res.data.refreshToken;
                        localStorage.setItem('user', JSON.stringify(_userData));
                        actions.getSaveUser(res.data.user);
                    } else {
                        _userData.user.name = res.data.name;
                        _userData.user.photo = res.data.photo;
                        localStorage.setItem('user', JSON.stringify(_userData));
                        actions.getSaveUser(res.data);
                    }
                }
                openSnackbar(t('Updated successfully!'));
                setLoading(false);
                onClose();
            })
            .catch((e: any) => {
                setLoading(false);
                openSnackbar(e?.response?.data?.message);
            });
    };

    async function onChange({ name, value }: { name: string; value: string }) {
        setValue(name as never, value as never);
        await trigger(name as never);
    }

    const getUser = () => {
        var _user: any = JSON.parse(localStorage.getItem('user') || '{}');
        if (_user !== undefined && _user.accessToken) {
            setLoading(true);
            api.post('/auth/success', { accessToken: _user.accessToken })
                .then((res: any) => {
                    setValue('name' as never, res?.data?.user?.name as never);
                    setValue('email' as never, res?.data?.user?.email as never);
                    setLoading(false);
                    setProfileImage(res?.data?.user?.photo);
                })
                .catch((e: any) => {
                    setLoading(false);
                    if (e?.response?.data?.message) {
                        openSnackbar(e?.response?.data?.message);
                    }
                });
        }
    };

    const showPasswordInputs = () => {
        setOpen(prevValue => !prevValue);
    };

    return (
        <Model tabIndex={-1} onClick={closeModal}>
            {loading && <LoaderOverlay />}
            <form onSubmit={handleSubmit(onSubmit)}>
                <ModelBody style={{ width: '650px' }}>
                    <ModelHead>
                        <Heading>{t('Edit Profile')}</Heading>
                        <Icon className="fal fa-times" onClick={closeModal}></Icon>
                    </ModelHead>
                    <ModelContent>
                        <Wrapper>
                            <Row>
                                <Col md={6} lg={6}>
                                    <Wrapper>
                                        <Row>
                                            <Col lg={12}>
                                                <Cover>
                                                    {profileImage !== null && (
                                                        <ProfileImage
                                                            src={
                                                                uploadImage == true
                                                                    ? profileImage
                                                                    : `${`${process.env.REACT_APP_PROFILE_URL}${profileImage}`}`
                                                            }
                                                        />
                                                    )}
                                                </Cover>
                                            </Col>
                                            <Col lg={12}>
                                                <FileButton
                                                    htmlFor="faceImage"
                                                    aria-label="upload picture">
                                                    {t('Upload')}
                                                </FileButton>
                                                <FileInput
                                                    accept="image/jpeg/png"
                                                    id="faceImage"
                                                    type="file"
                                                    onChange={handleCapture}
                                                />
                                            </Col>
                                        </Row>
                                    </Wrapper>
                                </Col>
                                <Col md={6} lg={6}>
                                    <Wrapper>
                                        <Row>
                                            <Col sm={6} md={12} lg={12}>
                                                <InputGroup>
                                                    <Input
                                                        label={t('Name') || 'Name'}
                                                        name="name"
                                                        value={getValues('name')}
                                                        onChange={onChange}
                                                        error={errors.name as any}
                                                    />
                                                </InputGroup>
                                            </Col>
                                            <Col sm={6} md={12} lg={12}>
                                                <InputGroup>
                                                    <Input
                                                        label="Email"
                                                        name="email"
                                                        value={getValues('email')}
                                                        onChange={onChange}
                                                        error={errors.email as any}
                                                    />
                                                </InputGroup>
                                            </Col>
                                            <Col sm={12} md={12} lg={12}>
                                                <Divider>
                                                    <Span onClick={showPasswordInputs}>
                                                        {t('Change Password')}
                                                    </Span>
                                                </Divider>
                                            </Col>
                                            {open && (
                                                <>
                                                    <Col sm={6} md={12} lg={12}>
                                                        <InputGroup>
                                                            <Input
                                                                label={
                                                                    t('Old Password') ||
                                                                    'Old Password'
                                                                }
                                                                name="oldPassword"
                                                                value={oldPassword}
                                                                onChange={(e: any) => {
                                                                    setOldPassword(e.value);
                                                                    onChange({
                                                                        name: 'oldPassword',
                                                                        value: e.value,
                                                                    });
                                                                }}
                                                                error={errors.oldPassword as any}
                                                            />
                                                        </InputGroup>
                                                    </Col>
                                                    <Col sm={6} md={12} lg={12}>
                                                        <InputGroup>
                                                            <Input
                                                                label={
                                                                    t('New Password') ||
                                                                    'New Password'
                                                                }
                                                                name="newPassword"
                                                                value={getValues('newPassword')}
                                                                onChange={(e: any) => {
                                                                    setNewPassword(e.value);
                                                                    onChange({
                                                                        name: 'newPassword',
                                                                        value: e.value,
                                                                    });
                                                                }}
                                                                error={errors.newPassword as any}
                                                            />
                                                        </InputGroup>
                                                    </Col>
                                                    <Col sm={6} md={12} lg={12}>
                                                        <Input
                                                            label={
                                                                t('Confirm Password') ||
                                                                'Confirm Password'
                                                            }
                                                            name="confirmPassword"
                                                            value={getValues('confirmPassword')}
                                                            onChange={onChange}
                                                            error={errors.confirmPassword as any}
                                                        />
                                                    </Col>
                                                </>
                                            )}
                                        </Row>
                                    </Wrapper>
                                </Col>
                            </Row>
                        </Wrapper>
                    </ModelContent>
                    <Footer>
                        <Button type="submit" bgtype={'secondary'} label={t('Update')}></Button>
                        <Button ifClicked={onClose} bgtype={'discard'} label={t('Cancel')}></Button>
                    </Footer>
                </ModelBody>
            </form>
        </Model>
    );
};

const Wrapper = styled(Container)`
    padding: 0rem;
`;

const Model = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background-color: ${palette.modal_background};
    padding: 24px;
    overflow: auto;
    overscroll-behavior-y: contain;
    z-index: 102;
`;

const ModelBody = styled.div`
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 15px 25px -6px rgb(0 0 0 / 10%);
    padding: 24px;
    max-width: 82vw;
`;

const ModelHead = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.75rem;
`;

const ModelContent = styled.div`
    padding: 2rem 0 1rem 0 !important;
    color: rgba(157, 160, 164) !important;
`;

const InputGroup = styled.div`
    margin-bottom: 1.71rem;
`;

const Footer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const Heading = styled.div`
    font-size: 25px;
    font-weight: 600;
    color: ${palette.dark};
`;

const Icon = styled.i`
    font-size: 1.7rem;
    line-height: 2.5rem;
    color: ${palette.dark};
    cursor: pointer;
`;

const Cover = styled.div`
    margin-bottom: 1.9rem;
    display: flex;
    justify-content: center;
`;

const ProfileImage = styled.img`
    object-fit: cover;
    width: 19rem;
    height: 19rem;
    border-radius: 12rem;
    ${media.xs`
    width: 10rem;
    height: 10rem;
  `}
    ${media.sm`
    width: 15rem;
    height: 15rem;
  `}
    ${media.md`
	width: 19rem;
    height: 19rem;
  `}
    ${media.lg`
    width: 19rem;
    height: 19rem;
  `}
`;

const FileButton = styled.label`
    width: 100%;
    height: 3.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${palette.secondary};
    border-radius: 0.5rem;
    color: white;
    text-transform: capitalize;
    cursor: pointer;
    margin-top: 1.71rem;
    margin-bottom: 0rem;

    ${media.xs`
    margin-top: 0rem;
    margin-bottom: 1.71rem;
  `}
    ${media.sm`
    margin-top: 0rem;
    margin-bottom: 1.71rem;
  `}
    ${media.md`
	margin-top: 1.71rem;
    margin-bottom: 0rem;
  `}
    ${media.lg`
    margin-top: 1.71rem;
  `}
`;

const FileInput = styled.input`
    display: none;
`;

const Span = styled.span``;

const Divider = styled.div`
    border: 0.01rem solid #f3f4f6;
    width: 100%;
    height: 0.1rem;
    position: relative;
    margin-top: 0rem !important;
    margin-bottom: 1.5rem !important;
    cursor: pointer;
    & ${Span} {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        background-color: #fff;
        width: 10.14rem;
        font-weight: 500;
        text-align: center;
        color: rgba(12, 83, 252);
    }
`;

const mapStateToProps = (state: any) => {
    return {
        user: state.auth.user,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        actions: bindActionCreators({ getSaveUser }, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfileModel);
// export default EditProfileModel;
