/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Description: Default variables used commonly in web app like application name and logo of the application.
 */

import moment from 'moment';

export const CommonEnum = Object.freeze({
    AppName: 'Vanille.Me',
    logo: '/logo_vanille_white.png',
});

export const Languages = [
    {
        value: 'en',
        language: 'us',
        label: (
            <span style={{ display: 'flex', alignItems: 'center' }}>
                <img
                    src={`https://flagcdn.com/w320/us.png`}
                    alt={'English'}
                    style={{
                        marginRight: '5px',
                        borderRadius: '50%',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                        width: '2rem',
                    }}></img>{' '}
                {'English'}
            </span>
        ),
    },
    {
        value: 'uk',
        language: 'ua',
        label: (
            <span style={{ display: 'flex', alignItems: 'center' }}>
                <img
                    src={`https://flagcdn.com/w320/ua.png`}
                    alt={'Ukrainian'}
                    style={{
                        marginRight: '5px',
                        borderRadius: '50%',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                        width: '2rem',
                    }}></img>{' '}
                {'Ukrainian'}
            </span>
        ),
    },
    {
        value: 'de',
        language: 'de',
        label: (
            <span style={{ display: 'flex', alignItems: 'center' }}>
                <img
                    src={`https://flagcdn.com/w320/de.png`}
                    alt={'German'}
                    style={{
                        marginRight: '5px',
                        borderRadius: '50%',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                        width: '2rem',
                    }}></img>{' '}
                {'German'}
            </span>
        ),
    },
    {
        value: 'ru',
        language: 'ru',
        label: (
            <span style={{ display: 'flex', alignItems: 'center' }}>
                <img
                    src={`https://flagcdn.com/w320/ru.png`}
                    alt={'Russian'}
                    style={{
                        marginRight: '5px',
                        borderRadius: '50%',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                        width: '2rem',
                    }}></img>{' '}
                {'Russian'}
            </span>
        ),
    },
];

// for widget
export const languages = [
    {
        value: 'en',
        language: 'us',
        label: (
            <span style={{ display: 'flex', alignItems: 'center' }}>
                <img
                    src={`https://flagcdn.com/w320/us.png`}
                    alt={'English'}
                    style={{
                        marginRight: '5px',
                        borderRadius: '50%',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                        width: '2rem',
                    }}></img>{' '}
                {'Eng'}
            </span>
        ),
    },
    {
        value: 'uk',
        language: 'ua',
        label: (
            <span style={{ display: 'flex', alignItems: 'center' }}>
                <img
                    src={`https://flagcdn.com/w320/ua.png`}
                    alt={'Ukrainian'}
                    style={{
                        marginRight: '5px',
                        borderRadius: '50%',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                        width: '2rem',
                    }}></img>{' '}
                {'Uk'}
            </span>
        ),
    },
    {
        value: 'ru',
        language: 'ru',
        label: (
            <span style={{ display: 'flex', alignItems: 'center' }}>
                <img
                    src={`https://flagcdn.com/w320/ru.png`}
                    alt={'Russian'}
                    style={{
                        marginRight: '5px',
                        borderRadius: '50%',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                        width: '2rem',
                    }}></img>{' '}
                {'Rus'}
            </span>
        ),
    },
    {
        value: 'de',
        language: 'de',
        label: (
            <span style={{ display: 'flex', alignItems: 'center' }}>
                <img
                    src={`https://flagcdn.com/w320/de.png`}
                    alt={'German'}
                    style={{
                        marginRight: '5px',
                        borderRadius: '50%',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                        width: '2rem',
                    }}></img>{' '}
                {'Ger'}
            </span>
        ),
    },
];

export const formatTime = hours => {
    const duration = moment.duration(hours, 'minutes');
    const formattedTime = [];

    if (duration.hours() > 0) {
        formattedTime.push(`${duration.hours()} ${duration.hours() === 1 ? 'hour' : 'hours'}`);
    }

    if (duration.minutes() > 0) {
        formattedTime.push(
            `${duration.minutes()} ${duration.minutes() === 1 ? 'minute' : 'minutes'}`
        );
    }

    return formattedTime.join(' ');
};
