/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * SettingsOptions Component
 * Description: This component displays a list of settings options, allowing users to navigate to different settings-related features such as business details, business hours, online booking, calendar settings, and promotions.
 * It includes a list of cards, each representing a specific settings feature, with a link, heading, and description.
 *
 */

import React, { useState } from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import { Link } from 'react-router-dom';
import { palette } from 'styled/common';
import { Header } from '@components/common';
import { HeaderContent } from '@components/common/layout/Layout';
import { useTranslation } from 'react-i18next';

const SettingsOptions = () => {
    const { t }: any = useTranslation();
    const [Cards, setCards] = useState([
        {
            link: '/settings/details',
            heading: 'Business Details',
            text: 'Configure how your business appears to customers.',
        },
        {
            link: '/settings/business-hours',
            heading: 'Business Hours',
            text: "Let your customers know when you're available for services.",
        },
        {
            link: '/settings/online-booking',
            heading: 'Online Booking',
            text: 'Set up your booking widget, configure payments and more.',
        },
        {
            link: '/settings/booking-settings',
            heading: 'Calendar Settings',
            text: 'Set up calendar setting, cancellation rules and more.',
        },
        {
            link: '/settings/promotions',
            heading: 'Promotion, Discounts and packages',
            text: 'Set up gift card coupen, promotion, discounts and packages for customers.',
        },
    ]);

    return (
        <Wrapper>
            <HeaderContent smallScreen={true} display={true}>
                <Header
                    activeRoute={{
                        title: t('Business Settings'),
                        icon: 'far fa-cogs',
                        path: '/settings',
                    }}
                />
            </HeaderContent>
            <Row>
                {Cards.map((value, index) => {
                    return (
                        <Flex key={index} xs={12} sm={12} md={6} lg={6}>
                            <CustomLink to={value.link}>
                                <div>
                                    <Heading>{t(value.heading)}</Heading>
                                    <Text>{t(value.text)}</Text>
                                </div>
                                <div>
                                    <Arrow>
                                        <i className="fal fa-chevron-right"></i>
                                    </Arrow>
                                </div>
                            </CustomLink>
                        </Flex>
                    );
                })}
            </Row>
        </Wrapper>
    );
};

const Wrapper = styled(Container)`
    padding-bottom: 1.5rem !important;
    max-width: 100%;
`;

const Flex = styled(Col)`
    margin-bottom: 1.5rem;
`;

const CustomLink = styled(Link)`
    display: flex;
    justify-content: space-between;
    background: ${palette.white};
    box-shadow: inset -1.80118px -2.70178px 9.00592px ${palette.box_hadow_color};
    border-radius: 4.50296px;
    padding: 2rem 2rem;
    align-items: center;
`;

const Heading = styled.h1`
    color: ${palette.dark};
    font-size: 1.13rem;
    font-weight: 500;
`;

const Text = styled.p`
    color: ${palette.silver};
    font-size: 0.9rem;
`;

const Arrow = styled.span`
    color: ${palette.silver};
    font-size: 1.2rem;
`;

export default SettingsOptions;
