/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * LoaderOverlay Component
 *
 * Description:
 * - Displays an overlay with a spinning loader (animated circle).
 * - Typically used to indicate that a background operation is in progress.
 *
 * Usage:
 * - Import this component and use it where loading feedback is needed.
 *
 */

const LoaderOverlay = () => {
    return (
        <div className="overlay--loader">
            <svg className="spinner-loader" viewBox="0 0 50 50">
                <circle
                    className="path"
                    cx="25"
                    cy="25"
                    r="20"
                    fill="none"
                    strokeWidth="5"></circle>
            </svg>
        </div>
    );
};

export default LoaderOverlay;
