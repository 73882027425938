/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * CustomiseWidget Component
 * Description: This component provides customization options for the booking widget button design.
 * It allows users to choose and update background color and text color for the button.
 *
 * Props:
 * - btnBgColor: string - Background color of the button.
 * - btnTextColor: string - Text color of the button.
 * - setBtnTextColor: (color: string) => void - Callback function to set text color.
 * - setBtnBgColor: (color: string) => void - Callback function to set background color.
 * - callBack?: any - Callback function for color picker changes.
 */

import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import { LoaderOverlay, useSnackbar } from '@components/common';
import { businessActions } from 'actions/business';
import { useAppDispatch } from 'hooks/redux';
import ColorPicker from '@components/common/colorPicker/ColorPicker';
import { api } from 'helpers/auth-axios';
import Button from '@components/Button';
import { useTranslation } from 'react-i18next';
import { palette } from 'styled/common';

interface IProps {
    btnBgColor: any;
    btnTextColor: any;
    setBtnTextColor: any;
    setBtnBgColor: any;
    callBack?: any;
}

const CustomiseWidget = ({
    btnBgColor,
    btnTextColor,
    setBtnTextColor,
    setBtnBgColor,
    callBack,
}: IProps) => {
    const { t }: any = useTranslation();
    let _userData = JSON.parse(localStorage.getItem('user') || '{}');
    const dispatch = useAppDispatch();
    const [openSnackbar] = useSnackbar();
    const [business, setBusiness] = useState<any>();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        api.get(`/businesses/${_userData?.user?.business_id?._id}`)
            .then((res: any) => {
                setLoading(false);
                setBusiness(res?.data);
                setBtnBgColor(
                    res?.data?.booking_widget?.button_bg_color
                        ? res?.data?.booking_widget?.button_bg_color
                        : palette.secondary
                );
                setBtnTextColor(
                    res?.data?.booking_widget?.button_text_color
                        ? res?.data?.booking_widget?.button_text_color
                        : '#ffffff'
                );
            })
            .catch(e => {
                setLoading(false);
            });
    }, []);

    const updateSettings = () => {
        setLoading(true);

        dispatch(
            businessActions.updateBookingSettings(_userData?.user?.business_id?._id, {
                booking_widget: { button_text_color: btnTextColor, button_bg_color: btnBgColor },
            })
        ).then((res: any) => {
            setLoading(false);
            if (res) {
                openSnackbar(t('Update successfully!'));
            }
        });
    };

    return (
        <>
            {loading && <LoaderOverlay />}
            {business && (
                <TopPadding>
                    <BottomPadding>
                        <Heading>{t('Button Design')}</Heading>

                        <Wrapper>
                            <Row>
                                <Col md={6} lg={6}>
                                    <Text>{t('Background Color')}</Text>
                                    <ColorPicker
                                        callBack={callBack}
                                        color={
                                            btnBgColor
                                                ? btnBgColor
                                                : business?.booking_widget?.button_bg_color
                                                ? business?.booking_widget?.button_bg_color
                                                : palette.secondary
                                        }
                                        onChange={(color: any) => setBtnBgColor(color)}
                                    />
                                </Col>
                                <Col md={6} lg={6}>
                                    <Text>{t('Text Color')}</Text>
                                    <ColorPicker
                                        callBack={callBack}
                                        color={
                                            btnTextColor
                                                ? btnTextColor
                                                : business?.booking_widget?.button_text_color
                                                ? business?.booking_widget?.button_text_color
                                                : '#ffffff'
                                        }
                                        onChange={(color: any) => setBtnTextColor(color)}
                                    />
                                </Col>
                            </Row>
                        </Wrapper>
                    </BottomPadding>
                    <Wrapper>
                        <Row>
                            <Col>
                                <Flex>
                                    <Button
                                        bgtype={'secondary'}
                                        ifClicked={updateSettings}
                                        label={t('Update')}></Button>
                                </Flex>
                            </Col>
                        </Row>
                    </Wrapper>
                </TopPadding>
            )}
        </>
    );
};

const Wrapper = styled(Container)`
    padding: 0rem;
`;

const TopPadding = styled.div`
    padding-top: 1.5rem !important;
`;

const BottomPadding = styled.div`
    padding-bottom: 1rem !important;
`;

const Heading = styled.div`
    font-weight: 600 !important;
    padding-bottom: 0.25rem !important;
`;

const Text = styled.p`
    margin: 0rem;
    font-size: 0.9rem !important;
    color: rgba(103, 113, 126) !important;
    padding-bottom: 0.3rem !important;
`;

const Flex = styled.div`
    justify-content: flex-end;
    display: flex;
`;

export default CustomiseWidget;
