/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Component: Profile
 * Description: This component represents the user profile information, including the user's name, type (customer or user), and profile picture.
 *
 * Dependencies:
 * - React
 * - styled-components
 * - styled-bootstrap-grid (Col)
 *
 * Usage:
 * - Import this component to display the user profile information.
 */

import { Col } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { palette } from '../../../styled/common';
const Profile = ({ onClick }: any) => {
    let _user: any = JSON.parse(localStorage.getItem('user') || '{}');

    return (
        <>
            <ProfileContent lg={12} onClick={onClick}>
                <Info>
                    <Image
                        src={`${process.env.REACT_APP_PROFILE_URL}${_user?.user?.photo}`}
                        alt=""
                    />

                    <div>
                        <Name>
                            {_user?.user_login == 'customer'
                                ? _user?.user?.firstname + ' ' + _user?.user?.lastname
                                : _user?.user?.name}
                        </Name>
                        <Type>{_user?.user_login}</Type>
                    </div>
                </Info>
            </ProfileContent>
        </>
    );
};

const ProfileContent = styled(Col)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 100%;
    bottom: 0;
    margin-bottom: 1rem;
`;

const Info = styled.div`
    display: flex;
    align-items: center;
`;

const Image = styled.img`
    border-radius: 2rem;
    margin-right: 0.75rem;
    width: 3.14rem;
    height: 3.14rem;
`;

const Name = styled.div`
    font-weight: 500;
    padding-left: 0.45rem;
    color: ${palette.white};
`;

const Type = styled.div`
    font-size: 0.875rem;
    line-height: 1.25rem;
    padding-left: 0.45rem;
    color: ${palette.white};
    font-weight: 300;
    text-transform: capitalize;
`;

export default Profile;
