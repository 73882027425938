/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * CreateOther Component for creating other team members.
 *
 * Component Description:
 * - Imports necessary components and dependencies from react.
 * - Renders the CreateOther (Team) component within the specified Layout.
 * - Exports the CreateOther component to use for routing.
 */

import { Layout, CreateOther as Team } from '@components/team';

const CreateOther = () => {
    return <Team />;
};

CreateOther.Layout = Layout;
export default CreateOther;
