/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * TabPanel Component
 * Description: A component for rendering the content of a specific tab panel within a tabbed interface.
 * Purpose:
 * - Consumes the tab context using the useTabContext hook from the 'tabContext' module.
 * - Renders the content of the tab panel based on the provided 'value'.
 * - Supports preloading content for improved performance.
 *
 * Props:
 * - value: any - The value associated with the tab panel, indicating its identity.
 * - preload?: boolean - If true, the content is preloaded even if the tab panel is not active.
 * - children?: React.ReactNode - The content to be rendered within the tab panel.
 */

import { useTabContext } from '../tabContext';

export interface TabPanelProps {
    value: any;
    preload?: boolean;
    children?: React.ReactNode;
}

const TabPanel = ({ value, preload, children }: TabPanelProps) => {
    const context = useTabContext();
    if (context === null) {
        throw new TypeError('No TabContext provided');
    }

    return (
        <div role="tabpanel" hidden={value !== context.value} className="tabPanel">
            {preload ? children : value === context.value && children}
        </div>
    );
};

export default TabPanel;
