/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * PromotionsTabs Component
 * Description: This component displays a list of promotion-related tabs, allowing users to navigate to different promotion-related features such as gift cards, loyalty discounts, and service packages.
 * It includes a list of cards, each representing a specific promotion feature, with a link, heading, and description.
 *
 */

import React, { useState } from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import { Link } from 'react-router-dom';
import { palette } from 'styled/common';
import { Header } from '@components/common';
import { HeaderContent } from '@components/common/layout/Layout';
import { useTranslation } from 'react-i18next';

const PromotionsTabs = () => {
    const { t }: any = useTranslation();
    let _userData = JSON.parse(localStorage.getItem('user') || '{}')?.user?.business_id;
    const [Cards, setCards] = useState([
        {
            link: '/promotion/gift-card',
            heading: 'Gift Cards',
            text: 'Generate gift vouchers for customers.',
        },
        {
            link: '/promotion/loyality-discount',
            heading: 'Manage Loyality Discounts',
            text: 'Manage loyalty discounts for permanent customers.',
        },
        {
            link: '/promotion/package',
            heading: 'Manage Package for your services',
            text: 'Create and manage packages for customers when they book sessions with multiple services.',
        },
    ]);

    return (
        <Wrapper>
            <HeaderContent smallScreen={true} display={true}>
                <Header
                    activeRoute={{
                        title: t('Promotion, Discounts and packages'),
                        icon: _userData?.leftMenu === true ? 'fal fa-chevron-left' : 'far fa-gift',
                        path: _userData?.leftMenu === true ? '/settings' : '',
                    }}
                />
            </HeaderContent>
            <Row>
                {Cards.map((value, index) => {
                    return (
                        <Flex key={index} xs={12} sm={12} md={6} lg={6}>
                            <CustomLink to={value.link}>
                                <div>
                                    <Heading>{t(value.heading)}</Heading>
                                    <Text>{t(value.text)}</Text>
                                </div>
                                <div>
                                    <Arrow>
                                        <i className="fal fa-chevron-right"></i>
                                    </Arrow>
                                </div>
                            </CustomLink>
                        </Flex>
                    );
                })}
            </Row>
        </Wrapper>
    );
};

const Wrapper = styled(Container)`
    padding-bottom: 1.5rem !important;
    max-width: 100%;
`;

const Flex = styled(Col)`
    margin-bottom: 1.5rem;
`;

const CustomLink = styled(Link)`
    display: flex;
    justify-content: space-between;
    background: ${palette.white};
    box-shadow: inset -1.80118px -2.70178px 9.00592px ${palette.box_hadow_color};
    border-radius: 4.50296px;
    padding: 2rem 2rem;
    align-items: center;
`;

const Heading = styled.h1`
    color: ${palette.dark};
    font-size: 1.13rem;
    font-weight: 500;
`;

const Text = styled.p`
    color: ${palette.silver};
    font-size: 0.9rem;
`;

const Arrow = styled.span`
    color: ${palette.silver};
    font-size: 1.2rem;
`;

export default PromotionsTabs;
