/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Checkout Component for managing sales checkout.
 *
 * Component Description:
 * - Imports the Layout and Checkout components from sales components.
 * - Renders the SaleCheckout component within the specified Layout.
 * - Exports the Checkout component to use for routing.
 */

import { Layout } from '@components/sales';
import { Checkout as SaleCheckout } from '@components/sales';

const Checkout = () => {
    return <SaleCheckout />;
};

Checkout.Layout = Layout;
export default Checkout;
