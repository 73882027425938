/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Component: ConflictModal
 * Description: This component serves as a modal for displaying conflict information related to appointments or blocks.
 * It shows the conflicting dates and allows users to resolve the conflict by continuing or canceling the action.
 * The component integrates with various UI elements such as buttons and icons to provide a user-friendly interface.
 * Developed with React and styled-components for styling.
 *
 * Props:
 * - dates: string[] - An array of dates with conflicts.
 * - onContinue: () => void - Callback function triggered when the user decides to continue despite conflicts.
 * - onClose: () => void - Callback function triggered when the conflict modal is closed.
 * - recurringConflict?: any - Information about recurring appointment conflicts.
 * - title?: any - Title for the conflict modal.
 *
 */

import styled from 'styled-components';
import moment from 'moment';
import Button from '@components/Button';
import { useTranslation } from 'react-i18next';
import { palette } from 'styled/common';
const ConflictModal = ({
    dates,
    onContinue,
    onClose,
    recurringConflict,
    title,
}: {
    dates: string[];
    onContinue(): void;
    onClose(): void;
    recurringConflict?: any;
    title?: any;
}) => {
    const { t }: any = useTranslation();
    const closeModal = (event: any) => {
        event.preventDefault();
        if (event.target === event.currentTarget) {
            onClose();
        }
    };

    return (
        <Model tabIndex={-1} onClick={closeModal}>
            <ModelBody style={{ width: '480px' }}>
                <ModelHead>
                    <Heading>
                        {recurringConflict == true ? t('Conflict') : t('Resolve Conflict')}{' '}
                    </Heading>
                    <Cover onClick={onClose}>
                        <i className="fal fa-times"></i>
                    </Cover>
                </ModelHead>
                <ModelContent>
                    {title ? title + 'from:' : 'Appointments in these dates not created:'}
                    {recurringConflict == true ? (
                        <div>
                            {dates?.map((date, index) => {
                                return (
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        {' '}
                                        {date + (index !== dates?.length - 1 ? ', ' : '')}
                                    </div>
                                );
                            })}
                        </div>
                    ) : (
                        dates?.map((date, index) => {
                            return (
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    {moment(date).format('DD MMM') +
                                        (index !== dates?.length - 1 ? ', ' : '')}
                                </div>
                            );
                        })
                    )}
                    &nbsp;
                    {recurringConflict == true
                        ? ''
                        : t(
                              `overlaps with other ${
                                  title ? title : 'blocks or appointments'
                              } . Please modify your day, time or duration to proceed.`
                          )}
                </ModelContent>
                <Footer>
                    <Button bgtype={'secondary'} ifClicked={onClose} label={t('OK')}></Button>
                </Footer>
            </ModelBody>
        </Model>
    );
};
const Model = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background-color: ${palette.box_hadow_color};
    padding: 24px;
    overflow: auto;
    overscroll-behavior-y: contain;
    z-index: 102;
`;
const ModelBody = styled.div`
    display: flex;
    flex-direction: column;
    background: ${palette.white};
    border-radius: 8px;
    box-shadow: 0 15px 25px -6px ${palette.box_hadow_color};
    padding: 24px;
    max-width: 82vw;
`;
const ModelHead = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.75rem;
`;
const Cover = styled.div`
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${palette.grey.grey_20};
    border-radius: 8px;
    font-size: 1.7rem;
    cursor: pointer;
`;
const Heading = styled.h1`
    font-weight: 600 !important;
    font-size: 1.875rem !important;
    line-height: 2.25rem !important;
    margin-bottom: 0px !important;
`;
const ModelContent = styled.div`
    padding: 2rem 0 1rem 0 !important;
    color: ${palette.grey.grey_20} !important;
`;
const Footer = styled.div`
    display: flex;
    justify-content: center;
`;

export default ConflictModal;
