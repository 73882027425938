/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Details Component
 * Description: This component displays business details and allows users to switch between businesses, add new businesses, and create new business admins.
 * It also includes functionalities for updating user business, language switching, and handling loading states.
 *
 */

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import { Link } from 'react-router-dom';
import { Layout, LoaderOverlay, Select, useSnackbar } from '@components/common';
import { BusinessDetails } from '@components/settings';
import { useTranslation } from 'react-i18next';
import { api } from 'helpers/auth-axios';
import i18n from 'i18n/i18n';
import Button from '@components/Button';
import CreateBusinessAdmin from '@components/common/models/CreateBusinessAdmin';

export default function Details() {
    let _userData = JSON.parse(localStorage.getItem('user') || '{}')?.user?.business_id;
    const { t } = useTranslation();
    const [userBusiness, setUserBusiness] = useState([]);
    const [loading, setLoading] = useState(false);
    const [callBusiness, setCallBusiness] = useState(false);
    const [openSnackbar] = useSnackbar();
    const [blurBackground, setBlurBackground] = useState(true);
    const [createNewAdmin, setCreateNewAdmin] = useState(false);

    useEffect(() => {
        getUserBusiness();
        window.addEventListener('load', () => {
            setBlurBackground(false);
        });
    }, []);

    const getUserBusiness = async () => {
        await api.post('/businesses/all').then((res: any) => {
            if (res?.data?.length) {
                let business: any = [];
                res?.data?.map(
                    (e: any) =>
                        e?._id != _userData?._id &&
                        business.push({
                            label: e?.name,
                            value: e?._id,
                        })
                );
                setUserBusiness(business);
            }
        });
    };

    const updateUserBusiness = async (id: any) => {
        try {
            setLoading(true);
            await api.post('/users/update_business', { business_id: id }).then((res: any) => {
                setLoading(false);
                if (res?.data) {
                    let __userData = JSON.parse(localStorage.getItem('user') || '{}');
                    setLoading(false);

                    if (res.data?.business_id !== undefined) {
                        __userData.user.business_id = res.data?.business_id;
                        localStorage.setItem('user', JSON.stringify(__userData));
                    }
                    setCallBusiness(true);
                    i18n.changeLanguage(res.data?.business_id.language);
                    openSnackbar(t('Updated successfully!'));
                    getUserBusiness();
                    if (res.data?.business_id.leftMenu != _userData?.leftMenu) {
                        window.location.reload();
                    }
                }
            });
        } catch (e: any) {
            setLoading(false);
            if (e?.response) {
                openSnackbar(e?.response?.data?.message);
            }
        }
    };

    return (
        <Wrapper blurBackground={blurBackground}>
            {loading && <LoaderOverlay />}
            <Row alignItems="center">
                <Column lg={_userData?.leftMenu === true ? 3 : 2.5}>
                    {_userData?.leftMenu === true && (
                        <Link to="/settings">
                            <Icon className="fal fa-chevron-left"></Icon>
                        </Link>
                    )}
                    <Header>{t('Business Details')}</Header>
                </Column>
                <Column lg={_userData?.leftMenu === true ? 3 : 2.5}>
                    <Link to="/settings/business/new">
                        <AddNewBusiness
                            label={t('Add New Business')}
                            bgtype={'secondary'}></AddNewBusiness>
                    </Link>
                </Column>
                <Column lg={_userData?.leftMenu === true ? 3 : 2.5}>
                    <AddNewBusiness
                        label={t('Create New Admin')}
                        bgtype={'secondary'}
                        ifClicked={() => setCreateNewAdmin(true)}></AddNewBusiness>
                </Column>
                <Column lg={_userData?.leftMenu === true ? 3 : 3.5}>
                    {userBusiness?.length ? (
                        <Select
                            disabled={true}
                            value={''}
                            label={t('Switch Business')}
                            onChange={(val: string) => updateUserBusiness(val)}
                            options={userBusiness}
                        />
                    ) : (
                        <></>
                    )}
                </Column>
            </Row>
            <BusinessDetails callBusiness={callBusiness} />
            {createNewAdmin && (
                <CreateBusinessAdmin
                    title={t('Create New Business Admin')}
                    onClose={() => setCreateNewAdmin(false)}
                    onSave={() => ''}
                />
            )}
        </Wrapper>
    );
}

const Wrapper = styled(Container)<any>`
    /* padding-bottom: 1.5rem !important; */
`;

const BlurOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(8px); /* Adjust the blur value as needed */
    pointer-events: none; /* Allow click-through to underlying elements */
    z-index: 1; /* Place the overlay above other content */
`;

const Column = styled(Col)`
    display: flex;
    align-items: center;
    margin: 1rem 0;
    /* margin-bottom: 2rem !important; */
`;

const Header = styled.h1`
    font-size: 1.875rem;
    font-weight: 600;
    margin: 0;
`;

const AddNewBusiness = styled(Button)`
    font-size: 1.2rem;
    font-weight: 600;
    margin: 0;
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
`;

const Icon = styled.i`
    font-size: 1.75rem !important;
    line-height: 2.25rem !important;
    margin-right: 1.25rem !important;
`;

Details.Layout = Layout;
