/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Notifications Component
 * Description: This component serves as a container for managing notification settings of customer.
 * It includes the NotificationOptions component to handle specific notification settings of customer.
 *
 */

import { Layout } from '@components/common';
import NotificationOptions from '@components/settings/notificationSetting';

const Notifications = () => {
    return (
        <>
            <NotificationOptions />
        </>
    );
};

Notifications.Layout = Layout;
export default Notifications;
