/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Appointment Reducer
 * Description: This reducer handles the state related to appointments, including fetching and creating appointments.
 *
 * Constants:
 * - appointmentConstants: Constants for appointment actions.
 *
 * Initial State:
 * - INITIAL_STATE: Initial state for the appointment reducer.
 *
 * Reducer:
 * - appointment: Reducer function for handling appointment-related actions and updating the state.
 */

import { appointmentConstants } from 'actions/appointment';
import { IGetAppointmentsResponse } from 'interfaces/appointment.interface';
import moment from 'moment';

const INITIAL_STATE: any = {};
const getAppointments = (prevState: any, payload: IGetAppointmentsResponse) => {
    let state = prevState;

    let now = moment(payload.start_date).clone();

    while (now.diff(moment(payload.end_date)) < 0) {
        const date = now.format();
        if (!state[date]) {
            state[date] = [];
        }

        now.add(1, 'days');
    }

    if (payload.appointments?.length) {
        payload.appointments.forEach(appointment => {
            const startTime = moment(appointment.booked_from).format();
            state = {
                ...state,
                [startTime]: state[startTime]
                    ? state[startTime].concat(appointment)
                    : [appointment],
            };
        });
    }

    return state;
};

export function appointment(state = INITIAL_STATE, action: any) {
    switch (action.type) {
        case appointmentConstants.GET_SUCCESS:
            return getAppointments(state, action.appointments);
        case appointmentConstants.CREATE_SUCCESS:
            const startTime = moment(action.appointment.booked_from).format();
            return {
                [startTime]: state[startTime]
                    ? state[startTime].concat(action.appointment)
                    : [action.appointment],
            };
        default:
            return state;
    }
}
