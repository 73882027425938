/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Component: ReportModel
 * Description: This component represents a modal for reporting appointments. Users can submit a report and, if applicable, reply to the report. It provides a text area for entering the report details and reply. Users can submit the report or reply by clicking the "Submit Report" or "Submit your reply" button, respectively.
 * Developed with React and styled-components for styling.
 *
 * Props:
 * - onClose: () => void - Callback function triggered when the report appointment model is closed.
 * - onSave: (data: any) => void - Callback function triggered when saving the appointment report.
 * - appointmentData?: any - Data related to the appointment to be reported.
 * - dashboard?: boolean - Indicates whether the appointment has been reported or replied to by the admin.
 *
 */

import { LoaderOverlay, useSnackbar } from '@components/common';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import Button from '@components/Button';
import { api } from 'helpers/auth-axios';
import { useTranslation } from 'react-i18next';
import { palette } from 'styled/common';

const ReportModel = ({
    onClose,
    onSave,
    appointmentData,
    dashboard,
}: {
    onClose(): void;
    onSave(data: any): void;
    appointmentData?: any;
    dashboard?: any;
}) => {
    const { t }: any = useTranslation();
    const [openSnackbar] = useSnackbar();
    const [loading, setLoading] = useState<any>(false);
    const [report, setReport] = useState('');
    const [reply, setReply] = useState('');

    useEffect(() => {
        if (appointmentData?.reportId) {
            setReport(appointmentData?.reportId?.description);
            setReply(appointmentData?.reportId?.reply);
        }
    }, [appointmentData]);

    // submit report
    const submitReport = () => {
        setLoading(true);
        appointmentData?.reportId?._id
            ? api
                  .put(`/report/${appointmentData?.reportId?._id}`, {
                      customerId: appointmentData?.customer?._id,
                      appointmentId: appointmentData?._id,
                      description: report,
                  })
                  .then((res: any) => {
                      setLoading(false);
                      openSnackbar(t(`Report modified successfully`));
                      onSave(res.data);
                  })
                  .catch((e: any) => {
                      setLoading(false);
                      if (e?.response) {
                          const response = e?.response?.data?.response;
                          openSnackbar(response?.message);
                      }
                  })
            : api
                  .post(`/report/create`, {
                      customerId: appointmentData?.customer?._id,
                      appointmentId: appointmentData?._id,
                      description: report,
                      serviceProvider: appointmentData?.serviceProvider?._id,
                      service: appointmentData?.service?._id,
                  })
                  .then((res: any) => {
                      setLoading(false);
                      openSnackbar(t(`Appointment reported successfully`));
                      onSave(res.data);
                  })
                  .catch((e: any) => {
                      setLoading(false);
                      if (e?.response) {
                          const response = e?.response?.data?.response;
                          openSnackbar(response?.message);
                      }
                  });
    };

    //reply report
    const replyReport = () => {
        setLoading(true);
        appointmentData?.reportId?._id &&
            api
                .put(`/report/reply/${appointmentData?.reportId?._id}`, {
                    customerId: appointmentData?.customer?._id,
                    appointmentId: appointmentData?._id,
                    reply: reply,
                    serviceProvider: appointmentData?.serviceProvider?._id,
                    service: appointmentData?.service?._id,
                })
                .then((res: any) => {
                    setLoading(false);
                    openSnackbar(t(`Replied successfully`));
                    onSave(res.data);
                })
                .catch((e: any) => {
                    setLoading(false);
                    if (e?.response) {
                        const response = e?.response?.data?.response;
                        openSnackbar(response?.message);
                    }
                });
    };
    return (
        <ModelBody>
            {loading && <LoaderOverlay />}
            <ModelHeading>
                <Heading>{t('Report Appointment')}</Heading>
                <Cover onClick={() => !loading && onClose()}>
                    <i className="fal fa-times"></i>
                </Cover>
            </ModelHeading>
            <ModelHead></ModelHead>
            <div>
                <CustomersBody>
                    <SearchItemWrapper>
                        <Title>{t(dashboard === true ? 'Report' : 'Type Report')}</Title>
                        <InputGroup>
                            <Textarea
                                placeholder={t('Type your report here')}
                                rows={4}
                                value={report}
                                readOnly={dashboard === true && true}
                                onChange={(e: any) => {
                                    setReport(e.target.value);
                                }}></Textarea>
                        </InputGroup>
                    </SearchItemWrapper>
                    {dashboard === true ? (
                        <SearchItemWrapper>
                            <Title>{t('Reply')}</Title>
                            <InputGroup>
                                <Textarea
                                    placeholder={t('Type your reply here')}
                                    rows={4}
                                    value={reply}
                                    onChange={(e: any) => {
                                        setReply(e.target.value);
                                    }}></Textarea>
                            </InputGroup>
                        </SearchItemWrapper>
                    ) : (
                        dashboard === false &&
                        reply && (
                            <SearchItemWrapper>
                                <Title>{t('Reply')}</Title>
                                <InputGroup>
                                    <Textarea
                                        placeholder={t('Type your reply here')}
                                        rows={4}
                                        value={reply}
                                        readOnly={true}
                                        onChange={(e: any) => {
                                            setReply(e.target.value);
                                        }}></Textarea>
                                </InputGroup>
                            </SearchItemWrapper>
                        )
                    )}
                </CustomersBody>
            </div>
            <Footer>
                <div></div>
                <Button
                    bgtype={'secondary'}
                    ifClicked={() => {
                        if (dashboard === true) {
                            reply ? replyReport() : openSnackbar(t('Enter something to reply!'));
                        } else {
                            submitReport();
                        }
                    }}
                    label={t(dashboard === true ? 'Submit your reply' : 'Submit Report')}></Button>
            </Footer>
        </ModelBody>
    );
};
const ModelBody = styled.div`
    display: flex;
    flex-direction: column;
    background: ${palette.white};
    border-radius: 4.50296px;
    padding: 24px;
    padding-top: 0;
    max-width: 82vw;
`;
const ModelHead = styled.div`
    display: flex;
    justify-content: end;
    margin-bottom: 0.75rem;
`;
const Cover = styled.div`
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${palette.grey.grey_20};
    border-radius: 8px;
    font-size: 1.7rem;
    cursor: pointer;
`;
const ModelHeading = styled.div`
    align-items: center !important;
    display: flex !important;
    justify-content: space-between;
    margin: 1rem 0;
`;
const Heading = styled.h1`
    font-weight: 600 !important;
    font-size: 1.875rem !important;
    line-height: 2.25rem !important;
`;
const InputGroup = styled.div`
    margin-bottom: 1.71rem;
    width: 100%;
`;
const Footer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const CustomersBody = styled.div<any>`
    flex: 0 0 25rem;
    background-color: ${palette.white};
    margin-left: 0rem;
    overflow-y: auto;
    min-height: ${({ height }) => (height ? `58vh` : '71vh')};
`;

const SearchItemWrapper = styled.div`
    display: block;
    align-items: center;
    padding: 12px 0;
    justify-content: space-between;
`;
const Title = styled.div<any>`
    display: ${({ flexDirection }) => (flexDirection ? flexDirection : 'flex')};
    justify-content: start;
    font-weight: ${({ fw }) => (fw ? fw : '400')};
`;
const Textarea = styled.textarea<any>`
    width: 100%;
    padding: 0.75rem 1.15rem;
    outline: none;
    resize: none;
    border: 1px solid ${({ error }) => (error ? `red` : palette.grey.lightest)};
    border-radius: 0.325rem;
`;
export default ReportModel;
