/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * CustomerOptions Component
 * Description: React component for displaying customer-related options, including links to view all customers and customer reports.
 * This component is designed for users with the role 'user'.
 *
 */

import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import { Link } from 'react-router-dom';
import { Header } from '@components/common';
import { HeaderContent } from '@components/common/layout/Layout';
import { useTranslation } from 'react-i18next';
import { palette } from 'styled/common';

const CustomerOptions = () => {
    const { t }: any = useTranslation();
    let _userData = JSON.parse(localStorage.getItem('user') || '{}');

    const teamOptionList = [
        {
            link: '/customers',
            title: t('Customers'),
            subTitle: t('See all customers.'),
        },
        {
            link: '/customer-reports',
            title: t('Reports'),
            subTitle: t('See all customer reported appointments.'),
        },
    ];

    return (
        <>
            <Wrapper>
                <HeaderContent smallScreen={true} display={true}>
                    <Header
                        activeRoute={{
                            title: 'Customers',
                            icon: 'far fa-user',
                            path: '/customers',
                        }}
                    />
                </HeaderContent>
                <Row>
                    {_userData.user_login === 'user' ? (
                        <>
                            {teamOptionList.map((list, index) => {
                                return (
                                    <Col
                                        key={index}
                                        md={6}
                                        lg={6}
                                        style={{ marginBottom: '1.5rem' }}>
                                        <CustomLink to={list.link}>
                                            <div>
                                                <Heading>{list.title}</Heading>
                                                <Text>{list.subTitle}</Text>
                                            </div>
                                            <div>
                                                <Arrow>
                                                    <i className="fal fa-chevron-right"></i>
                                                </Arrow>
                                            </div>
                                        </CustomLink>
                                    </Col>
                                );
                            })}
                        </>
                    ) : (
                        ''
                    )}
                </Row>
            </Wrapper>
        </>
    );
};

const Wrapper = styled(Container)`
    max-width: 100%;
`;

const CustomLink = styled(Link)`
    display: flex;
    justify-content: space-between;
    background: ${palette.white};
    box-shadow: inset -1.80118px -2.70178px 9.00592px ${palette.box_hadow_color};
    border-radius: 4.50296px;
    padding: 2rem 2rem;
    align-items: center;
    text-decoration: none;
`;

const Heading = styled.h1`
    color: #263446;
    font-size: 1.13rem;
    font-weight: 500;
`;

const Text = styled.p`
    color: #a8aeb5;
    font-size: 0.9rem;
`;

const Arrow = styled.span`
    color: #a8aeb5;
    font-size: 1.2rem;
`;

export default CustomerOptions;
