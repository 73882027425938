/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Switch Businesses Model at Login Component
 *
 * Description:
 * - Renders a modal for selecting business at login time.
 * - Utilizes styled-components for styling and theming.
 * - Communicates with the server using the api helper.
 * - Displays loading overlay during API requests.
 * - Select Business using Select component.
 *
 * Props:
 * - onClose: () => void - A function to be called when the modal is closed at parent component.
 * - businesses: any[] - Get business from parent component.
 * - selected?: any - Default or user selected business.
 * - onContinue(selectedBusiness: any): void -  A function to be called when the business selected and user continue with this selected business.
 */

import styled from 'styled-components';
import Button from '@components/Button';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { palette } from 'styled/common';
import { media } from 'styled-bootstrap-grid';

const BusinessSelectionModal = ({
    businesses,
    onContinue,
    onClose,
    loading,
    selected,
}: {
    businesses: any[];
    onContinue(selectedBusiness: any): void;
    onClose(): void;
    loading?: any;
    selected?: any;
}) => {
    const { t }: any = useTranslation();
    const [selectedBusiness, setSelectedBusiness] = useState(selected);
    const [isError, setIsError] = useState(false);

    const closeModal = (event: any) => {
        event.preventDefault();
        if (event.target === event.currentTarget) {
            onClose();
        }
    };

    const handleBusinessSelect = (business: any) => {
        setSelectedBusiness(business);
        setIsError(false);
    };

    const handleContinue = () => {
        if (selectedBusiness) {
            setIsError(false);
            onContinue(selectedBusiness);
        } else {
            setIsError(true);
        }
    };

    return (
        <Modal tabIndex={-1} onClick={closeModal}>
            <ModalBody>
                <ModalHead>
                    <Heading>{t('Choose Business to Continue')}</Heading>
                    <CloseButton onClick={onClose}>
                        <i className="fal fa-times"></i>
                    </CloseButton>
                </ModalHead>
                <BusinessList heigth={businesses?.length >= 6}>
                    {businesses.map((business, index) => (
                        <BusinessItem
                            key={index}
                            onClick={() => handleBusinessSelect(business?._id)}
                            selected={business?._id === selectedBusiness}>
                            <BusinessLogo
                                src={process.env.REACT_APP_PROFILE_URL + business?.photo}
                                alt={`Logo for ${business?.name}`}
                            />
                            <BusinessName>{business?.name}</BusinessName>
                        </BusinessItem>
                    ))}
                </BusinessList>
                {isError ? (
                    <div style={{ color: 'red' }}>{t('Please choose business to continue')}</div>
                ) : (
                    <></>
                )}
                <Footer>
                    <CancelButton
                        bgtype={'discard'}
                        ifClicked={onClose}
                        label={t('Cancel')}></CancelButton>
                    <Button
                        bgtype={'secondary'}
                        ifClicked={handleContinue}
                        label={t('Continue')}
                        disabled={loading}
                    />
                </Footer>
            </ModalBody>
        </Modal>
    );
};

const Modal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background-color: rgba(0, 0, 0, 0.8);
    padding: 24px;
    overflow: auto;
    overscroll-behavior-y: contain;
    z-index: 102;
    backdrop-filter: blur(8px);
`;
const ModalBody = styled.div`
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 15px 25px -6px rgb(0 0 0 / 10%);
    padding: 24px;
    width: 33vw;
    ${media.xs`
    width: 100%;
	`}
    ${media.sm`
    width: 100%;
	`}
    ${media.md`
    width: 33vw;
	`}
    ${media.lg`
    width: 33vw;
	`}
    ${media.xl`
    width: 33vw;
	`}
`;
const ModalHead = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.75rem;
`;

const Heading = styled.h1`
    font-weight: 600 !important;
    font-size: 1.875rem !important;
    line-height: 2.25rem !important;
    margin-bottom: 0px !important;
`;

const CloseButton = styled.div`
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${palette.grey.grey_20};
    border-radius: 8px;
    font-size: 1.7rem;
    cursor: pointer;
`;

const BusinessList = styled.div<any>`
    height: ${({ heigth }) => (heigth ? ' 33rem' : 'auto')};
    overflow-y: scroll;
`;

const BusinessItem = styled.div<{ selected: boolean }>`
    cursor: pointer;
    border: 2px solid ${({ selected }) => (selected ? palette.secondary : '#ccc')};
    border-radius: 8px;
    padding: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    &:hover {
        border-color: ${palette.secondary};
    }
`;

const BusinessLogo = styled.img`
    width: 5rem;
    height: 3.5rem;
    margin-right: 10px;
    border-radius: 0.5rem;
`;

const BusinessName = styled.div`
    font-weight: bold;
`;

const Footer = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
`;

const CancelButton = styled(Button)<any>``;

export default BusinessSelectionModal;
