/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * CreateBusiness Component for creating a new business.
 *
 * Component Description:
 * - Imports necessary components and dependencies from react and styled-components.
 * - Renders the CreateNewBusiness component within the specified Layout.
 * - Exports the CreateBusiness component to use for routing.
 */

import styled from 'styled-components';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import { Link } from 'react-router-dom';
import { Layout } from '@components/common';
import { BusinessDetails } from '@components/settings';
import { useTranslation } from 'react-i18next';
import CreateNewBusiness from '@components/settings/businessDetails/CreateNewBusiness';

export default function CreateBusiness() {
    var _userData = JSON.parse(localStorage.getItem('user') || '{}')?.user?.business_id;
    const { t } = useTranslation();

    return (
        <Wrapper>
            <Row alignItems="center">
                <Column lg={12}>
                    <Link to="/settings/details">
                        <Icon className="fal fa-chevron-left"></Icon>
                    </Link>
                    <Header>{t('Add New Business')}</Header>
                </Column>
            </Row>
            <CreateNewBusiness />
        </Wrapper>
    );
}

const Wrapper = styled(Container)`
    /* padding-bottom: 1.5rem !important; */
`;

const Column = styled(Col)`
    display: flex;
    align-items: center;
    margin: 1rem 0;
    /* margin-bottom: 2rem !important; */
`;

const Header = styled.h1`
    font-size: 1.875rem;
    font-weight: 600;
    margin: 0;
`;

const AddNewBusiness = styled.h3`
    font-size: 1.2rem;
    font-weight: 600;
    margin: 0;
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
`;

const Icon = styled.i`
    font-size: 1.75rem !important;
    line-height: 2.25rem !important;
    margin-right: 1.25rem !important;
`;

CreateBusiness.Layout = Layout;
