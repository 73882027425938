/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Layout Component
 *
 * Description:
 * - Main layout component that structures the overall UI.
 * - Uses styled-components for styling.
 * - Renders an Aside (sidebar), Header, and Content.
 * - Checks the active route to highlight it in the Header.
 * - Manages the display of the sidebar based on user preferences.
 *
 * Props:
 * - children: React.ReactNode - Content to be rendered within the layout.
 *
 */

import React, { FC } from 'react';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import { Aside, Header } from '@components/common';
import { routes } from 'constants/routes';
import useRouter from 'hooks/router';
let _user: any = null;

const Layout: FC<any> = ({ children, ...props }) => {
    const router = useRouter();
    _user = router.pathname;
    let _userData = JSON.parse(localStorage.getItem('user') || '{}')?.user?.business_id;

    const activeRoute = () => {
        for (let i = 0; i < routes?.length; i++) {
            if (routes[i].patterns) {
                for (let j = 0; j < routes[i].patterns!.length; j++) {
                    if (router.pathname === routes[i].patterns![j].path) {
                        return routes[i].patterns![j];
                    }
                }
            }
            if (router.pathname === routes[i].path) {
                return routes[i];
            }
        }
    };

    return (
        <>
            <Wrapper flexDirection={_userData?.leftMenu === true && true}>
                {_user === '/admin' ? '' : <Aside />}
                <PageContent margin={_userData?.leftMenu === true && true}>
                    <HeaderContent>
                        <Header activeRoute={activeRoute()!} />
                    </HeaderContent>
                    <Content>{children}</Content>
                </PageContent>
            </Wrapper>
        </>
    );
};

const Wrapper = styled.div<any>`
    width: 100%;
    height: 100%;
    overflow: auto;
    flex-direction: ${({ flexDirection }) => (flexDirection ? 'row !important' : 'column')};
    display: flex !important;
`;

const PageContent = styled.div<any>`
    margin-left: ${({ margin }) => (margin ? ' 18.6rem ' : '')};
    flex-direction: column !important;
    flex-grow: 1 !important;
    display: flex !important;
    position: relative;
    ${media.xs`
    margin-left: 0rem;
  `}
    ${media.sm`
    margin-left: 0rem;
  `}
    ${media.md`
	margin-left: 0rem;
  `}

    ${media.lg`
    margin-left :${({ margin }: any) => (margin ? '18.6rem' : '0rem')};
  `}
`;

const Content = styled.div<any>`
    padding-left: 1.5rem;
    flex: 1;
    display: flex;
    flex-direction: ${({ flexDirection }) => (flexDirection ? flexDirection : 'column')};
`;

export const HeaderContent = styled.div<any>`
    z-index: 1;

    ${media.xs`
    display: ${({ smallScreen }: any) => (smallScreen ? 'none' : 'flex')};
  `}
    ${media.sm`
    display: ${({ smallScreen }: any) => (smallScreen ? 'none' : 'flex')};
  `}
    ${media.md`
	display: ${({ smallScreen }: any) => (smallScreen ? 'none' : 'flex')};
  `}
    ${media.lg`
    display: ${({ display }: any) => (display ? 'flex' : 'none')};
  `}
`;

export default Layout;
