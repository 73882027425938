/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * CustomerPayments Component
 * Description: This component displays a list of payments for a customer.
 *
 * Props:
 * - customerId: string - The ID of the customer for whom payments are displayed.
 */

import { useState, useEffect } from 'react';
import moment from 'moment';
import { Spinner } from '@components/common';
import { IPayment } from 'interfaces/payment.interface';
import { paymentOptions } from 'constants/paymentOptions';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { api } from 'helpers/auth-axios';
import { useTranslation } from 'react-i18next';
import { NotFound } from '@components/team/list/serviceProviderList/ServiceProviderList';

const CustomerPayments = ({ customerId }: { customerId: string }) => {
    const { t } = useTranslation();
    const pageSize = 20;
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [payments, setPayments] = useState<IPayment[]>([]);
    let _userData = JSON.parse(localStorage.getItem('user') || '{}');

    useEffect(() => {
        setLoading(true);
        setPage(0);
        api.get(`/payments?page=${0}&pageSize=${pageSize}&customer_id=${customerId}`).then(
            (res: any) => {
                setLoading(false);
                setPayments(res.data.payments);
            }
        );
    }, [customerId]);

    const loadMore = () => {
        setPage(prevPage => prevPage + 1);
    };

    return (
        <>
            <Wrapper>
                <Row>
                    {payments?.length
                        ? payments.map((payment: any, i: number) => {
                              const paymentOption = paymentOptions.find(
                                  option => payment.payment_method.code === option.code
                              );
                              return (
                                  <CustomCol lg={12} key={i}>
                                      <Body>
                                          <IconBody>
                                              <Icon className={`${paymentOption?.icon}`}></Icon>
                                          </IconBody>

                                          <DateWrapper>
                                              <Price>{payment.amount}</Price>
                                              <div style={{ display: 'flex', flexGrow: 'wrap' }}>
                                                  {payment?.transaction_id[0]?.items?.map(
                                                      (e: any, index: any) => (
                                                          <Price style={{ marginRight: '0.4rem' }}>
                                                              {e?.asset?.name}
                                                              {index <
                                                                  payment?.transaction_id[0]?.items
                                                                      .length -
                                                                      1 && ', '}
                                                          </Price>
                                                      )
                                                  )}
                                              </div>
                                              <Dates>
                                                  {moment(payment.created_at).format('DD/MM/YYYY')}{' '}
                                                  •{' '}
                                                  {moment(payment.created_at).format(
                                                      _userData.user.business_id.time_select ===
                                                          '12'
                                                          ? 'hh:mm a'
                                                          : 'HH:mm'
                                                  )}
                                              </Dates>
                                          </DateWrapper>
                                      </Body>
                                      {/* <PaymentStatus className="label label-success text-sm px-3 font-medium">
                                        {payment.status}
                                    </PaymentStatus> */}
                                  </CustomCol>
                              );
                          })
                        : loading == false && <NotFound>{t('No assets found')}</NotFound>}
                    {(total > (page + 1) * pageSize || loading) && (
                        <PaymentPages>
                            <Button disabled={loading} onClick={loadMore}>
                                {loading ? (
                                    <Spinner color="secondary" />
                                ) : (
                                    `${t('Load more payments')} (${total - (page + 1) * pageSize})`
                                )}
                            </Button>
                        </PaymentPages>
                    )}
                </Row>
            </Wrapper>
        </>
    );
};
const Wrapper = styled(Container)`
    @media screen and (min-width: 1px) and (max-width: 900px) {
        padding: 1rem;
    }
`;
const CustomCol = styled(Col)`
    display: flex !important;
    padding: 0px;
    align-items: center !important;
    justify-content: space-between !important;
    border-bottom-width: 1px !important;
    padding-bottom: 1rem !important;
    margin-bottom: 1rem !important;
`;
const Body = styled.div`
    display: flex;
    align-items: center;
`;
const IconBody = styled.div`
    width: 2.75rem;
    height: 2.75rem;
    background-color: rgba(237, 238, 241);
    color: rgba(103, 113, 126);
    border-radius: 9999px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem; /* 16px */
`;
const Icon = styled.i`
    font-size: 1.125rem;
    line-height: 1.75rem;
`;
const DateWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;
const Price = styled.div`
    margin-right: 1rem;
    font-weight: 500;
`;
const Dates = styled.div`
    margin-right: 1rem /* 16px */;
    font-size: 0.9rem /* 14.4px */;
    color: rgba(168, 174, 181);
`;

const PaymentStatus = styled.div`
    font-size: 0.875rem /* 14px */;
    line-height: 1.25rem /* 20px */;
    padding-left: 0.75rem /* 12px */;
    padding-right: 0.75rem /* 12px */;
    border-radius: 50px;
    font-weight: 400;
    color: #fff;
    display: flex;
    align-items: center !important;
    text-transform: capitalize;
    color: #00be70;
    background-color: #e2fff3;
`;
const PaymentPages = styled.div`
    display: flex;
    justify-content: center;
`;
const Button = styled.button`
    padding-top: 0.5rem /* 8px */;
    padding-bottom: 0.5rem /* 8px */;
    padding-left: 2rem /* 32px */;
    padding-right: 2rem /* 32px */;
    font-weight: 500;
    font-size: 0.9rem /* 14.4px */;
    border: none;
    cursor: pointer;
`;
export default CustomerPayments;
