/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * CustomiseAbleCalendar Component
 * Description: This component provides a customizable calendar view for managing appointments. It extends the functionality of the standard calendar by providing options for custom business hours, VIP appointments, and a waiting list.
 * It includes features such as creating, modifying, and deleting appointments, navigating between days, weeks, and months, displaying daily, weekly, and monthly appointments, handling Pusher events for real-time updates, and fetching appointments based on the selected date range.
 * Appointments are created based on working hours availability and can be customized with options like custom business hours, VIP appointments, and waiting list management.
 *
 * Props:
 * - next: () => void - Callback function to navigate to the next period (day, week, or month) in the calendar.
 * - prev: () => void - Callback function to navigate to the previous period (day, week, or month) in the calendar.
 * - today: () => void - Callback function to navigate to the current date in the calendar.
 * - changeView: (view: string) => void - Callback function triggered when the calendar view is changed.
 * - showTeamCalenda: boolean - A boolean flag indicating whether the team calendar is displayed.
 * - teamDate: () => void - Callback function triggered when the team calendar date is changed.
 *
 * State:
 * - view: string - The current view of the calendar like timeGridDay or teamGridWeek.
 * - dateRange: string - The date range for the calendar.
 * - showTeamCalendar: boolean - A boolean flag indicating whether the team calendar is displayed.
 * - serviceProviderId: any - The ID of the service provider for the calendar.
 * - teamCalendarDate: any - The date for the team calendar.
 * - pickDate: any - Callback function triggered when a date is picked on the calendar.
 * - calendar_size: any - The size of the calendar.
 * - customBusinessHours: boolean - A boolean flag indicating whether custom business hours are enabled.
 * - waitingList: boolean - A boolean flag indicating whether the waiting list feature is enabled.
 * - vipAppointment: boolean - A boolean flag indicating whether VIP appointments are enabled.
 *
 */

import React, { useState, useEffect, useRef } from 'react';
import { CalendarLayout } from '@components/calendar';
import { useAppSelector } from 'hooks/redux';
import CustomiseCalendarHeader from '@components/calendar/calendarHeader/CustomiseCalendarHeader';
import { CustomisedBookingsCalendar } from '@components/calendar/bookingsCalendar';
import CustomisedTeamCalendar from '@components/calendar/teamCalendar/CustomisedTeamCalendar';

interface IProps {
    next: () => void;
    prev: () => void;
    today: () => void;
    changeView: (view: string) => void;
    showTeamCalenda: boolean;
    teamDate: () => void;
}

const CustomiseAbleCalendar = () => {
    const _useRef = useRef<IProps>(null);
    let _userData = JSON.parse(localStorage.getItem('user') || '{}');
    const { current_view }: any = useAppSelector(state => state.calenderCurrentView);
    const [view, setView] = useState<any>(
        _userData !== undefined && _userData?.user_login === 'serviceProvider'
            ? 'timeGridDay'
            : current_view
            ? current_view
            : _userData.user.current_view
            ? _userData.user.current_view
            : 'timeGridDay'
    );
    const [dateRange, setDateRange] = useState('');
    const [showTeamCalendar, setShowTeamCalendar] = useState(false);
    const [serviceProviderId, setServiceProviderId] = useState();
    const [teamCalendarDate, setTeamCalendarDate] = useState();
    const [pickDate, setPickDate] = useState<any>();
    const [calendar_size, setCalendar_Size] = useState<any>();
    const [customBusinessHours, setCustomBusinessHours] = useState<any>(false);
    const [waitingList, setWaitingList] = useState<any>(false);
    const [vipAppointment, setVipAppointment] = useState<any>(false);

    useEffect(() => {
        changeView(
            _userData !== undefined && _userData?.user_login === 'serviceProvider'
                ? 'timeGridDay'
                : current_view
        );
        if (current_view == 'teamGridWeek') {
            setView('timeGridDay');
            setShowTeamCalendar(true);
        }
    }, []);

    const next = () => {
        if (_useRef?.current) {
            _useRef?.current?.next();
        }
    };

    const prev = () => {
        if (_useRef?.current) {
            _useRef?.current?.prev();
        }
    };

    const today = () => {
        if (_useRef?.current) {
            _useRef?.current?.today();
        }
    };

    const changeView = (view: string) => {
        if (view != 'teamGridWeek') {
            setShowTeamCalendar(false);
            if (_useRef?.current) {
                _useRef?.current?.changeView(view);
                setView(view);
            }
        } else {
            setShowTeamCalendar(true);
        }
    };

    return (
        <>
            <CustomiseCalendarHeader
                setServiceProviderId={setServiceProviderId}
                setCalendar_Size={setCalendar_Size}
                goNext={next}
                goPrev={prev}
                goToday={today}
                currentView={view}
                setPickDate={setPickDate}
                changeView={changeView}
                dateRange={dateRange}
                setDateRange={setDateRange}
                setTeamCalendarDate={setTeamCalendarDate}
                showTeamCalendar={showTeamCalendar}
                teamCalendarDate={teamCalendarDate}
                setCustomBusinessHours={setCustomBusinessHours}
                setVipAppointment={setVipAppointment}
                vipAppointment={vipAppointment}
                customBusinessHours={customBusinessHours}
            />
            {showTeamCalendar !== true ? (
                <CustomisedBookingsCalendar
                    teamCalendarDate={teamCalendarDate}
                    setTeamCalendarDate={setTeamCalendarDate}
                    ref={_useRef}
                    serviceProviderId={
                        _userData !== undefined && _userData?.user_login === 'serviceProvider'
                            ? _userData.user._id
                            : serviceProviderId
                    }
                    calendar_size={calendar_size}
                    setView={setView}
                    setDateRange={setDateRange}
                    initialView={view}
                    pickDate={pickDate}
                    dateRange={dateRange}
                    vipAppointment={vipAppointment}
                    customBusinessHours={customBusinessHours}
                    waitingList={waitingList}
                />
            ) : (
                <CustomisedTeamCalendar
                    ref={_useRef}
                    teamCalendarDate={teamCalendarDate}
                    setTeamCalendarDate={setTeamCalendarDate}
                    serviceProviderId={serviceProviderId}
                    setDateRange={setDateRange}
                    viewMode={view}
                    pickDate={pickDate}
                    calendar_size={calendar_size}
                    vipAppointment={vipAppointment}
                    customBusinessHours={customBusinessHours}
                    waitingList={waitingList}
                />
            )}
            <style>{`
            body {
                background-color: #fff;
            }`}</style>
        </>
    );
};

CustomiseAbleCalendar.Layout = CalendarLayout;
export default CustomiseAbleCalendar;
