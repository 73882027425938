/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Component: EditAppointmentDrawerForCustomer
 * Description: This component represents the drawer for editing existing appointments from the customer's perspective.
 * It includes functionalities such as updating appointment details, handling date and time selection, managing
 * appointment status, redeeming coupons, displaying available slots for a specific service and service provider on the selected date, and interacting with the API. The component integrates with various UI elements
 * and supports features like conflict detection, payment processing.
 * Developed with React and utilizes styled-components and scss for styling.
 *
 * Props:
 * - id: string - Identifier for the appointment being edited.
 * - onChange: (appointment: IAppointment) => void - Callback function triggered when changes are made to the appointment. Receives the updated appointment details.
 *
 */
import {
    Input,
    Select,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    useSnackbar,
    LoaderOverlay,
    Switch,
} from '@components/common';
import { useEffect, useState } from 'react';
import SelectService from '../newAppointmentDrawer/SelectService';
import { ICustomer } from 'interfaces/customer.interface';
import { IAppointment } from 'interfaces/appointment.interface';

import as from 'components/appointments/Appointment.module.scss';
import styled from 'styled-components';
import { Col, Container, Row, media } from 'styled-bootstrap-grid';
import Button from '@components/Button';
import { IServiceProvider } from 'interfaces/serviceProvider.interface';
import ConflictModal from '../modal/ConflictModal';
import { api } from 'helpers/auth-axios';
import CompletePayment from './CompletePayment';
import moment from 'moment-timezone';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import RedeemModel from '../modal/RedeemModel';
import ReportModel from '../modal/ReportModel';
import { CustomDiv } from '@components/settings/businessServices/BusinessServices';
import { palette } from 'styled/common';

const getAppointmentLabel = (status: string | undefined) => {
    if (status === 'confirmed' || status === 'finished') {
        return as.appointment_statusSuccess;
    } else if (status === 'Late') {
        return as.appointment_statusWarning;
    } else if (status === 'cancelled') {
        return as.appointment_statusDanger;
    } else {
        return as.appointment_statusGray;
    }
};

const DurationHours: { label: string; value: number }[] = [];
for (let i = 0; i < 24; i++) {
    DurationHours.push({
        label: `${i}h`,
        value: i,
    });
}

const DurationMinutes: { label: string; value: number }[] = [];
for (let i = 0; i < 4; i++) {
    DurationMinutes.push({
        label: `${i * 15}min`,
        value: i * 15,
    });
}

const EditAppointmentDrawerForCustomer = ({
    id,
    onChange,
}: {
    id: string;
    onChange(appointment: IAppointment): void;
}) => {
    const [openSnackbar] = useSnackbar();
    const { t }: any = useTranslation();
    var _userData = JSON.parse(localStorage.getItem('user') || '{}');
    const navigate = useNavigate();
    const [appointment, setAppointment] = useState<IAppointment>();
    const [loading, setLoading] = useState(false);
    const [appointmentStatus, setAppointmentStatus] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState<ICustomer>();
    const [selectedServiceProvider, setSelectedServiceProvider] = useState<IServiceProvider>();
    const [serviceView, setServiceView] = useState(false);
    const [selectedService, setSelectedService] = useState<any>();
    const [conflictDates, setConflictDates] = useState<string[]>([]);
    const [showConflictModal, setShowConflictModal] = useState(false);
    const [appointmentNote, setAppointmentNote] = useState<string>();
    const [customerNote, setCustomerNote] = useState<string>();
    const [start, setStart] = useState<any>();
    const [end, setEnd] = useState<any>();
    const [status, setStatus] = useState<any>();
    const [overbooking, setOverbooking] = useState(false);
    const [providerId, setProviderId] = useState<any>();
    const [startDate, setStartDate] = useState<any>();
    const [enddate, setEndDate] = useState<any>();
    const [durationField, setDurationFieldOpen] = useState<boolean>(false);
    const [serviceDurationHours, setServiceDurationHours] = useState<any>(0);
    const [durationHours, setDurationHours] = useState<any>();
    const [serviceDurationMinutes, setServiceDurationMinutes] = useState<any>(0);
    const [durationMinutes, setDurationMinutes] = useState<any>();
    const [availableSlot, setAvailableSlot] = useState<any[]>([]);
    const [dropDownFilter, setDropdownFilter] = useState<any>();
    const [payment, setPayment] = useState<any>();
    const [chargeModel, setChargeModel] = useState<boolean>(false);
    const [is_vip, setIs_vip] = useState<any>();
    const [businessId, setbusinessId] = useState<any>();
    const [verifyToken, setVerifyToken] = useState<any>();
    const [appointmentId, setAppointmentId] = useState<any>();
    const [business, setBusiness] = useState<any>();
    const [timezone, setTimezone] = useState<any>();
    const [validToken, setValidToken] = useState<boolean>(false);
    const [inValidToken, setInValidToken] = useState<boolean>(false);
    const [startTime, setStartTime] = useState<any>();
    const [overlayLoading, setOverLayloading] = useState(false);
    const [redeemModel, setRedeemModel] = useState(false);
    const [coupenData, setCoupenData] = useState<any>('');
    const [totalValue, setTotalValue] = useState<any>('');
    const [coupenUsed, setCoupenUsed] = useState(false);
    const [redeemCoupen, setRedeemCoupen] = useState('');
    const [coupenModelData, setCoupenModelData] = useState<any>('');
    const [reportModel, setReportModel] = useState(false);
    const [is_video, setIs_video] = useState(false);
    const [is_waiting, setIs_Waiting] = useState(false);
    const [appointmentSlots, setAppointmentSlots] = useState<any[]>([]);
    const defaultDuration = serviceDurationHours * 60 + serviceDurationMinutes;
    const customDuration = durationHours * 60 + durationMinutes;
    const appointmentTime: { label: string; value: any }[] = [];
    const [cancellationReason, setCancellationReason] = useState<any>('');
    const [reasonError, setReasonError] = useState<any>('');
    const currentTime = moment().format();
    let time = moment().format('HH:mm');
    let currentDate = moment().format('YYYY-MM-DD');
    let todayDate = moment(startDate ? startDate : start).format('YYYY-MM-DD');

    useEffect(() => {
        api.get(`/auth/public/${window.location.pathname.slice(1, 25)}`).then((res: any) => {
            setBusiness(res.data.business);
            getAppointment(res?.data?.business?.timezone);
            setTimezone(res?.data?.business?.timezone);
            moment.tz.setDefault(res?.data?.business?.timezone);
        });
    }, []);

    useEffect(() => {
        if (overbooking) onSubmit();
    }, [overbooking]);

    useEffect(() => {
        getAvailableSlot();
    }, [startDate, defaultDuration, customDuration, start, is_vip]);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        dropDownValue();
    }, [startDate, defaultDuration, customDuration, start, selectedService, dropDownFilter]);

    const event = {
        id,
        start,
        end,
        customer: selectedCustomer,
        service: selectedService,
        serviceProvider: selectedServiceProvider,
    };

    const getAppointmentStatuses = (status: string | undefined) => {
        if (status === 'confirmed' || status === 'cancelled') {
            return [
                { key: t('confirmed'), value: 'confirmed' },
                { key: t('cancelled'), value: 'cancelled' },
            ];
        } else {
            return [];
        }
    };

    if (is_vip === true) {
        let vipTime: any[] = [];
        let duration = durationField === false ? defaultDuration : customDuration;
        for (let i: any = 0; i < 24; i++) {
            for (let j = 0; j < 4; j++) {
                vipTime.push(moment({ hour: i, minute: j * 15 }).format('HH:mm'));
            }
        }
        let tmpTime: any[] = [];
        vipTime.map((res: any, index: any) => {
            let customTime = moment(res, 'HH:mm').add(5, 'minute').format('HH:mm');
            let tmpTime1 = moment(res, 'HH:mm').add(duration, 'minute').format('HH:mm');
            let format = 'HH:mm';
            if (appointmentSlots?.includes(res) || appointmentSlots?.includes(customTime)) {
                for (let i = 0; i < appointmentSlots?.length; i++) {
                    let mTime = moment(appointmentSlots[i], format),
                        beforeTime = moment(res, format),
                        afterTime = moment(tmpTime1, format);
                    if (
                        mTime.isBetween(beforeTime, afterTime) &&
                        mTime.isBetween(beforeTime, afterTime)
                    ) {
                        if (tmpTime[index - 1] != -1) {
                            tmpTime.splice(index - 1, 1);
                        }
                    }
                }
            } else {
                tmpTime.push(res);
            }
        });
        tmpTime.forEach((item: any) => {
            if (todayDate === currentDate) {
                item >= time &&
                    appointmentTime.push({
                        label: moment(moment().format() + ' ' + item, 'YYYY-MM-DD HH:mm:ss').format(
                            _userData?.user?.business_id.time_select === '12' ? 'hh:mm a' : 'HH:mm'
                        ),
                        value: moment(moment().format() + ' ' + item, 'YYYY-MM-DD HH:mm:ss').format(
                            'HH:mm'
                        ),
                    });
            } else {
                appointmentTime.push({
                    label: moment(moment().format() + ' ' + item, 'YYYY-MM-DD HH:mm:ss').format(
                        _userData?.user?.business_id.time_select === '12' ? 'hh:mm a' : 'HH:mm'
                    ),
                    value: moment(moment().format() + ' ' + item, 'YYYY-MM-DD HH:mm:ss').format(
                        'HH:mm'
                    ),
                });
            }
        });
    } else {
        availableSlot?.length &&
            availableSlot.forEach((item: any) => {
                if (todayDate === currentDate) {
                    item >= time &&
                        appointmentTime.push({
                            label: moment(
                                moment().format() + ' ' + item,
                                'YYYY-MM-DD HH:mm:ss'
                            ).format(business?.time_select === '12' ? 'hh:mm a' : 'HH:mm'),
                            value: moment(
                                moment().format() + ' ' + item,
                                'YYYY-MM-DD HH:mm:ss'
                            ).format('HH:mm'),
                        });
                } else {
                    appointmentTime.push({
                        label: moment(moment().format() + ' ' + item, 'YYYY-MM-DD HH:mm:ss').format(
                            business?.time_select === '12' ? 'hh:mm a' : 'HH:mm'
                        ),
                        value: moment(moment().format() + ' ' + item, 'YYYY-MM-DD HH:mm:ss').format(
                            'HH:mm'
                        ),
                    });
                }
            });
    }

    const setUpdateAppointment = (appointment: IAppointment) => {
        const appointmentDuration: any = appointment!.duration;
        setProviderId(appointment?.serviceProvider?._id);
        setAppointment(appointment);
        setAppointmentNote(appointment?.appointment_note);
        setCustomerNote(appointment?.customer_note);
        setSelectedCustomer(appointment?.customer);
        setSelectedService(appointment?.service);
        setSelectedServiceProvider(appointment?.serviceProvider);
        setStart(appointment?.booked_from_iso);
        setEnd(appointment?.booked_till_iso);
        setStartDate(appointment?.booked_from_iso);
        setStartTime(appointment?.booked_from_iso);
        setEndDate(appointment?.booked_till_iso);
        setServiceDurationHours(
            appointmentDuration >= 60 ? Math.floor(appointmentDuration / 60) : ''
        );
        setDurationHours(appointmentDuration >= 60 ? Math.floor(appointmentDuration / 60) : '');
        setDurationMinutes(appointmentDuration % 60);
        setServiceDurationMinutes(appointmentDuration % 60);
        setDropdownFilter(moment(appointment.booked_from_iso).format('HH:mm'));
        setPayment(appointment.payment);
        setIs_vip(appointment?.is_vip);
        setbusinessId(appointment?.business_id);
        setAppointmentId(appointment?._id);
        setStatus(appointment?.status);
        setCoupenUsed(appointment.coupen_used);
        setTotalValue(appointment.total_value);
        setIs_video(appointment?.is_video_appointment);
        setCancellationReason(appointment?.cancelation_reason);
    };

    const getAppointment = (data: any) => {
        setOverLayloading(true);
        api.get(
            `appointments/get_customer_appointment/${window.location.pathname.slice(
                26,
                50
            )}?business_id=${window.location.pathname.slice(1, 25)}&timeZone=${data}`
        )
            .then((res: any) => {
                setOverLayloading(false);
                if (res.error) return;
                const appointment = res.data as IAppointment;
                setUpdateAppointment(appointment);
            })
            .catch((e: any) => {
                setOverLayloading(false);
            });
    };

    const updateEvent = (data: any) => {
        onChange({
            ...event,
            ...data,
        });
    };

    let totalValueDrawer: any =
        appointment?.total?.length > 1
            ? appointment?.total?.substring(1, appointment?.total?.length)
            : 0;
    const calculateInPercentage = (totalValueDrawer / 100) * parseInt(coupenData?.percentage);
    const afterPercentageValue = totalValueDrawer - calculateInPercentage;

    const onSubmit = () => {
        if (status == 'cancelled' && cancellationReason?.trim()?.length == 0) {
            setReasonError(t('Cancellation reason is required'));
            return;
        }
        const startTime =
            moment(startDate ? startDate : start).format('YYYY-MM-DD') +
            'T' +
            moment(start).format('HH:mm') +
            ':00';
        const endTime =
            moment(enddate ? enddate : end).format('YYYY-MM-DD') +
            'T' +
            moment(start)
                .add(durationField === false ? defaultDuration : customDuration, 'minutes')
                .format('HH:mm') +
            ':00';

        const userView = {
            current_view: 'timeGridDay',
        };

        const updatedAppointment = {
            booked_from_iso: appointmentTime?.length ? moment(startTime).format() : null,
            booked_till_iso: appointmentTime?.length ? moment(endTime).format() : null,
            booked_from: startTime ? moment(startTime).format() : moment(start).format(),
            booked_till: endTime ? moment(endTime).format() : moment(end).format(),
            appointment_note: appointmentNote ? appointmentNote : '',
            serviceProvider: selectedServiceProvider!._id,
            customer: selectedCustomer!._id,
            service: selectedService!._id,
            total:
                coupenData?.in_percentage === true
                    ? _userData.user.business_id.currency + afterPercentageValue
                    : coupenData?.price
                    ? coupenData?.price > selectedService?.my_price
                        ? '0'
                        : business?.currency + (selectedService?.my_price - coupenData?.price)
                    : business?.currency + selectedService?.my_price,
            total_value: parseInt(selectedService?.my_price!),
            customer_note: customerNote ? customerNote : '',
            status: status,
            duration: durationField === false ? defaultDuration : customDuration,
            business_id: businessId !== undefined && businessId,
            timeZone: timezone,
            user: userView,
            customerToken: verifyToken,
            login_customer: _userData?.user_login === 'customer' ? true : false,
            coupen_used: coupenData?.price ? true : false,
            discount:
                coupenData?.in_percentage === true ? calculateInPercentage : coupenData?.price,
            is_video_appointment: is_video,
            is_waiting: appointment?.is_waiting,
            approve_waiting: is_waiting,
        };

        setOverLayloading(true);
        appointmentId !== undefined &&
            api
                .put(`appointments/customer_reschedule/${appointmentId}`, updatedAppointment)
                .then((res: any) => {
                    setOverLayloading(false);
                    if (res.error) {
                        return;
                    }
                    _userData?.user_login == 'customer' &&
                        navigate(`/customers/${_userData?.user?._id}`);
                    setValidToken(true);
                    setInValidToken(false);
                    openSnackbar(t('Appointment updated successfully!'));
                    getAvailableSlot();
                })
                .catch(e => {
                    setOverLayloading(false);
                    if (e?.response) {
                        const response = e?.response?.data;
                        if (response) {
                            if (response?.message == 'Invalid token') {
                                setInValidToken(true);
                                setValidToken(false);
                            }

                            openSnackbar(response?.message);
                        }
                        if (response?.dates) {
                            setConflictDates(response?.dates);
                            setShowConflictModal(true);
                        }
                    }
                });
    };

    const appointmentStatusOpen = () => {
        if (appointmentStatus) {
            setAppointmentStatus(false);
        } else {
            setAppointmentStatus(true);
        }
    };

    const doSetStatus = (status: any) => {
        setStatus(status);
        appointmentStatusOpen();
    };

    const hiddenDateCalendar = (e: any) => {
        setStartDate(e.target.value);
        setEndDate(e.target.value);
    };

    const getAvailableSlot = (start_date = '') => {
        if (selectedServiceProvider !== undefined) {
            let date: any = moment(startDate ? startDate : start).format();
            let duration = durationField === false ? defaultDuration : customDuration;
            api.post(
                `appointments/customer_available_slots/${
                    selectedServiceProvider._id
                }?business_id=${window.location.pathname.slice(1, 25)}`,
                {
                    date: date,
                    duration: duration,
                    timeZone: timezone,
                    calendar: business?.customCalendar ? 'customise' : undefined,
                }
            ).then((res: any) => {
                setAvailableSlot(res.data?.slots);
                let unique = [...new Set(res.data?.appointmentSlots)];
                setAppointmentSlots(unique.sort());
            });
        }
    };

    const dropDownValue = () => {
        let time = '';
        appointmentTime.forEach((val: any) => {
            const value = moment(start).format(
                business?.time_select === '12' ? 'hh:mm a' : 'HH:mm'
            );

            if (value.indexOf('am') != -1 || value.indexOf('pm') != -1) {
                time = val.label;
            } else {
                time = val.value;
            }
            if (time === value) {
                let vals = [];
                if (val.value >= value) {
                    vals.push(val.value);
                }

                setDropdownFilter(val.value);
                return;
            } else {
                return appointmentTime[0].label;
            }
        });
    };

    const paymentButtons =
        (business?.booking_without_payment === false &&
            selectedServiceProvider?.stripe_account_id !== null &&
            selectedServiceProvider?.stripe_account_verified === true) ||
        (business?.booking_without_payment === false && business?.stripe_account_verified === true);

    const readOnly =
        currentTime >= end
            ? true
            : status && status === 'confirmed' && appointment?.status === 'confirmed'
            ? false
            : true;

    // get coupen details
    const submitCoupen = () => {
        api.post(`/gift-card/get`, { redeem: redeemCoupen })
            .then((res: any) => {
                openSnackbar(t(`Redeem successfully`));
                setCoupenModelData(res.data);
                setRedeemModel(true);
            })
            .catch((e: any) => {
                if (e?.response) {
                    const response = e?.response?.data?.response;
                    openSnackbar(response?.message);
                }
            });
    };

    return (
        <>
            {overlayLoading && <LoaderOverlay />}
            <MainContainer>
                <Row>
                    <Col style={{ zIndex: '1' }}>
                        <DrawerModel display={redeemModel || reportModel}>
                            {!serviceView && !chargeModel && !redeemModel && !reportModel && (
                                <AppointmentContent>
                                    <HeaderContainer>
                                        {_userData?.user_login == 'customer' && (
                                            <Back
                                                to={
                                                    loading
                                                        ? ''
                                                        : `/customers/${_userData?.user?._id}`
                                                }>
                                                <i className="fal fa-chevron-left"></i>
                                            </Back>
                                        )}
                                        <Header>{t('Appointment')}</Header>
                                        <ToggleButton>
                                            <Dropdown>
                                                <DropdownToggle
                                                    onClick={() => {
                                                        currentTime <= end == true &&
                                                            appointmentStatusOpen();
                                                    }}>
                                                    <StatusButton
                                                        onClick={() => {
                                                            currentTime <= end == true &&
                                                                appointmentStatusOpen();
                                                        }}
                                                        className={`${getAppointmentLabel(
                                                            status ? status : appointment?.status
                                                        )}`}>
                                                        {status
                                                            ? t(status)
                                                            : t(appointment?.status)}
                                                        <DownIcon className="fas fa-chevron-down"></DownIcon>
                                                    </StatusButton>
                                                </DropdownToggle>
                                                {appointmentStatus && (
                                                    <DropdownMenu>
                                                        {getAppointmentStatuses(
                                                            appointment?.status
                                                        ).map(status => {
                                                            return (
                                                                <DropdownItem
                                                                    key={status.key}
                                                                    onClick={() => {
                                                                        doSetStatus(status.value);
                                                                    }}>
                                                                    <Status
                                                                        className={`${
                                                                            as.appointment_statusLabel
                                                                        } ${getAppointmentLabel(
                                                                            status.value
                                                                        )}`}>
                                                                        {t(status.key)}
                                                                    </Status>
                                                                </DropdownItem>
                                                            );
                                                        })}
                                                    </DropdownMenu>
                                                )}
                                            </Dropdown>
                                        </ToggleButton>
                                    </HeaderContainer>
                                    {appointment?.is_waiting && (
                                        <WaitingListText>
                                            {t('Waiting list appointment')}
                                        </WaitingListText>
                                    )}
                                    <DrawerBody>
                                        {selectedCustomer ? (
                                            <div>
                                                <AppointmentCustomer>
                                                    <Circle>
                                                        <NameCircle
                                                            src={`${process.env.REACT_APP_PROFILE_URL}${selectedCustomer?.photo}`}></NameCircle>
                                                    </Circle>
                                                    <CustomerContent>
                                                        <Name>
                                                            {selectedCustomer.firstname +
                                                                ' ' +
                                                                selectedCustomer.lastname}
                                                        </Name>
                                                        <Email>{selectedCustomer.email}</Email>
                                                    </CustomerContent>
                                                    <div>
                                                        <Icons className="fal fa-chevron-right"></Icons>
                                                    </div>
                                                </AppointmentCustomer>
                                            </div>
                                        ) : (
                                            <div>
                                                <AppointmentCustomer>
                                                    <Circle>
                                                        <NameCircle></NameCircle>
                                                    </Circle>
                                                    <Label>{t('Select a client')}</Label>
                                                    <div>
                                                        <Icons className="fal fa-plus"></Icons>
                                                    </div>
                                                </AppointmentCustomer>
                                            </div>
                                        )}

                                        {selectedServiceProvider ? (
                                            <div>
                                                <ServiceProviderContent>
                                                    <Circle>
                                                        <NameCircle
                                                            src={`${process.env.REACT_APP_PROFILE_URL}${selectedServiceProvider?.photo}`}></NameCircle>
                                                    </Circle>
                                                    <CustomerContent>
                                                        <Name>{selectedServiceProvider.name}</Name>
                                                        <Email>
                                                            {selectedServiceProvider.email}
                                                        </Email>
                                                    </CustomerContent>
                                                    <div>
                                                        <Icons className="fal fa-chevron-right"></Icons>
                                                    </div>
                                                </ServiceProviderContent>
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                        {selectedService ? (
                                            <SelectServiceContent>
                                                <ServiceBorder
                                                    className={`service-color-${selectedService.color}--bd`}></ServiceBorder>
                                                <ServiceDetails>
                                                    <NameBody>
                                                        <ServiceName>
                                                            {selectedService.name}
                                                        </ServiceName>
                                                        <ServiceDuration>
                                                            {appointment!.duration}min
                                                        </ServiceDuration>
                                                    </NameBody>
                                                    <PriceBody>
                                                        <ServicePrice>
                                                            {coupenData?.in_percentage === true
                                                                ? _userData.user.business_id
                                                                      .currency +
                                                                  afterPercentageValue
                                                                : coupenData?.price
                                                                ? coupenData?.price >
                                                                  selectedService?.my_price
                                                                    ? '0'
                                                                    : _userData.user.business_id
                                                                          .currency +
                                                                      (selectedService?.my_price -
                                                                          coupenData?.price)
                                                                : appointment?.total}
                                                        </ServicePrice>
                                                        <div>
                                                            <Icons className="fal fa-chevron-right"></Icons>
                                                        </div>
                                                    </PriceBody>
                                                </ServiceDetails>
                                            </SelectServiceContent>
                                        ) : (
                                            <SelectServiceBody>
                                                <ServiceHeader>{t('Select service')}</ServiceHeader>
                                                <div>
                                                    <Icons className="fal fa-chevron-right"></Icons>
                                                </div>
                                            </SelectServiceBody>
                                        )}
                                        {appointment?.service?.is_video && (
                                            <CustomDiv
                                                style={{
                                                    marginTop: '0.3rem',
                                                    marginBottom: '0.3rem',
                                                }}>
                                                <CustomSpan style={{ fontSize: '1rem' }}>
                                                    {t('video appointment')}
                                                </CustomSpan>
                                                <Switch
                                                    value={is_video}
                                                    disabled={currentTime <= end == false}
                                                    onChange={() => {
                                                        setIs_video(!is_video);
                                                    }}
                                                    isSmall={true}
                                                />
                                            </CustomDiv>
                                        )}
                                        <FormContent>
                                            <FormDetails>
                                                <DatesBody>
                                                    <FormDate>
                                                        <CalenderDate>
                                                            {startDate
                                                                ? moment(startDate).format(
                                                                      'ddd, MMM DD'
                                                                  )
                                                                : moment(start).format(
                                                                      'ddd, MMM DD'
                                                                  )}
                                                        </CalenderDate>
                                                        <input
                                                            type="date"
                                                            readOnly={readOnly}
                                                            min={
                                                                new Date()
                                                                    .toISOString()
                                                                    .split('T')[0]
                                                            }
                                                            value={moment(startDate).format(
                                                                'YYYY-MM-DD'
                                                            )}
                                                            onChange={(e: any) =>
                                                                hiddenDateCalendar(e)
                                                            }
                                                        />
                                                    </FormDate>
                                                </DatesBody>
                                                {is_vip === true && (
                                                    <ToggleDurationButton>
                                                        <div>
                                                            <CustomSpan>
                                                                {t('Vip Appointment?')}
                                                            </CustomSpan>
                                                        </div>
                                                        <div>
                                                            <Switch
                                                                value={true}
                                                                disabled={true}
                                                                isSmall={true}
                                                            />{' '}
                                                        </div>
                                                    </ToggleDurationButton>
                                                )}
                                                <InputGroup>
                                                    <InputContent>
                                                        <Col lg={12}>
                                                            <Select
                                                                label={t('Start')}
                                                                disabled={true}
                                                                value={moment(
                                                                    moment().format() +
                                                                        ' ' +
                                                                        dropDownFilter,
                                                                    'YYYY-MM-DD HH:mm:ss'
                                                                ).format(
                                                                    business?.time_select === '12'
                                                                        ? 'hh:mm a'
                                                                        : 'HH:mm'
                                                                )}
                                                                readOnly={readOnly}
                                                                options={appointmentTime}
                                                                onChange={(val: string) => {
                                                                    const newStart =
                                                                        moment(start).format(
                                                                            'YYYY-MM-DD'
                                                                        ) +
                                                                        'T' +
                                                                        val +
                                                                        ':00';

                                                                    setStart(newStart);
                                                                }}
                                                            />
                                                        </Col>
                                                    </InputContent>
                                                </InputGroup>
                                                <InputGroup>
                                                    <Input
                                                        label={t(`Appointment Notes`)}
                                                        value={customerNote}
                                                        disabled={currentTime <= end == false}
                                                        onChange={({
                                                            value,
                                                        }: {
                                                            value: string;
                                                        }) => {
                                                            setCustomerNote(value);
                                                        }}
                                                    />
                                                </InputGroup>
                                                {_userData?.user_login === 'customer' ? (
                                                    ' '
                                                ) : (
                                                    <InputGroup>
                                                        <Input
                                                            label={t(`Confirmation Code`)}
                                                            value={verifyToken}
                                                            onChange={({
                                                                value,
                                                            }: {
                                                                value: string;
                                                            }) => {
                                                                setVerifyToken(value);
                                                            }}
                                                        />
                                                    </InputGroup>
                                                )}
                                                {status == 'cancelled' ? (
                                                    <InputGroup>
                                                        <Input
                                                            label={t('Cancellation Reason')}
                                                            value={cancellationReason}
                                                            onChange={({
                                                                value,
                                                            }: {
                                                                value: string;
                                                            }) => {
                                                                setCancellationReason(value);
                                                            }}
                                                            error={
                                                                reasonError && !cancellationReason
                                                                    ? { message: reasonError }
                                                                    : undefined
                                                            }
                                                        />
                                                    </InputGroup>
                                                ) : (
                                                    ''
                                                )}
                                                <>
                                                    {coupenUsed === false &&
                                                        coupenData?.price === undefined && (
                                                            <>
                                                                <InputGroup>
                                                                    <Input
                                                                        label={t(
                                                                            `Have any discount voucher/coupen ?`
                                                                        )}
                                                                        value={redeemCoupen}
                                                                        disabled={
                                                                            currentTime <= end ==
                                                                            false
                                                                        }
                                                                        onChange={({
                                                                            value,
                                                                        }: {
                                                                            value: string;
                                                                        }) => {
                                                                            setRedeemCoupen(value);
                                                                        }}
                                                                    />
                                                                </InputGroup>
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        justifyContent: 'flex-end',
                                                                    }}>
                                                                    {currentTime <= end && (
                                                                        <Button
                                                                            bgtype={'secondary'}
                                                                            ifClicked={() =>
                                                                                submitCoupen()
                                                                            }
                                                                            label={t(
                                                                                'Redeem'
                                                                            )}></Button>
                                                                    )}
                                                                </div>
                                                            </>
                                                        )}
                                                </>
                                                {validToken &&
                                                    validToken === true &&
                                                    (_userData?.user_login === 'customer' ? (
                                                        ''
                                                    ) : (
                                                        <Verified>Token Verified</Verified>
                                                    ))}
                                                {inValidToken &&
                                                    inValidToken === true &&
                                                    (_userData?.user_login === 'customer' ? (
                                                        ''
                                                    ) : (
                                                        <Invalid>Invalid Token</Invalid>
                                                    ))}
                                                {appointment?.is_waiting && (
                                                    <div style={{ marginTop: '0.5rem' }}>
                                                        <CustomSpan>
                                                            {t(
                                                                'Remove from waiting list to normal appointment'
                                                            )}
                                                        </CustomSpan>
                                                        <Switch
                                                            value={is_waiting}
                                                            onChange={val => {
                                                                if (val === true) {
                                                                    openSnackbar(
                                                                        t(
                                                                            'Please cancel scheduled appointment first to remove this appointment into normal'
                                                                        )
                                                                    );
                                                                }
                                                                setIs_Waiting(!is_waiting);
                                                            }}
                                                            isSmall={true}
                                                        />
                                                    </div>
                                                )}

                                                <PlatformDiv>
                                                    <TotalPriceBody>
                                                        <PlatForm>Payment</PlatForm>
                                                        <PlatFormText>
                                                            {payment == null
                                                                ? t('unpaid')
                                                                : t('paid')}
                                                        </PlatFormText>
                                                    </TotalPriceBody>
                                                    <TotalPriceBody>
                                                        <PriceHeader>{t('Total')}</PriceHeader>
                                                        <TotalPrice>
                                                            {coupenData?.in_percentage === true
                                                                ? _userData.user.business_id
                                                                      .currency +
                                                                  afterPercentageValue?.toFixed(2)
                                                                : coupenData?.price
                                                                ? coupenData?.price > totalValue
                                                                    ? '0'
                                                                    : appointment?.total?.charAt(
                                                                          0
                                                                      ) +
                                                                      (totalValue -
                                                                          coupenData?.price)
                                                                : appointment?.total?.charAt(0) +
                                                                  parseInt(
                                                                      totalValueDrawer
                                                                  ).toFixed(2)}
                                                        </TotalPrice>
                                                    </TotalPriceBody>
                                                </PlatformDiv>

                                                {currentTime <= end && (
                                                    <FormFooter>
                                                        {_userData.user_login === 'customer' ? (
                                                            <>
                                                                {payment == null &&
                                                                paymentButtons === true ? (
                                                                    <FormButtons>
                                                                        <Button
                                                                            bgtype={'secondary'}
                                                                            ifClicked={() =>
                                                                                setReportModel(true)
                                                                            }
                                                                            width="8rem !important"
                                                                            label={t(
                                                                                'Report'
                                                                            )}></Button>
                                                                        <Button
                                                                            bgtype={'secondary'}
                                                                            ifClicked={() => {
                                                                                setChargeModel(
                                                                                    true
                                                                                );
                                                                            }}
                                                                            label={`${t('Pay')}
                                                                        ${
                                                                            business?.currency +
                                                                            appointment?.total_value?.toFixed(
                                                                                2
                                                                            )
                                                                        }`}></Button>
                                                                        <Button
                                                                            bgtype={'secondary'}
                                                                            ifClicked={onSubmit}
                                                                            width="8rem !important"
                                                                            label={t(
                                                                                'Save'
                                                                            )}></Button>
                                                                    </FormButtons>
                                                                ) : (
                                                                    <div
                                                                        style={{
                                                                            display: 'flex',
                                                                            justifyContent:
                                                                                'space-between',
                                                                        }}>
                                                                        <Button
                                                                            bgtype={'secondary'}
                                                                            ifClicked={() =>
                                                                                setReportModel(true)
                                                                            }
                                                                            width="8rem !important"
                                                                            label={t(
                                                                                'Report'
                                                                            )}></Button>
                                                                        <Button
                                                                            bgtype={'secondary'}
                                                                            ifClicked={onSubmit}
                                                                            width="8rem !important"
                                                                            label={t(
                                                                                'Save'
                                                                            )}></Button>
                                                                    </div>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                }}>
                                                                <Button
                                                                    bgtype={'secondary'}
                                                                    ifClicked={onSubmit}
                                                                    width="8rem !important"
                                                                    label={t('Save')}></Button>
                                                            </div>
                                                        )}
                                                    </FormFooter>
                                                )}
                                            </FormDetails>
                                        </FormContent>
                                    </DrawerBody>
                                </AppointmentContent>
                            )}

                            {reportModel && (
                                <ReportModel
                                    onClose={() => setReportModel(false)}
                                    onSave={() => {
                                        getAppointment(timezone);
                                        setReportModel(false);
                                    }}
                                    appointmentData={appointment}
                                    dashboard={false}
                                />
                            )}

                            {redeemModel && (
                                <RedeemModel
                                    onClose={() => setRedeemModel(false)}
                                    data={coupenModelData}
                                    onSave={e => {
                                        setCoupenData(e);
                                        setRedeemModel(false);
                                    }}
                                />
                            )}

                            {chargeModel === true && (
                                <CompletePayment
                                    newAppointmentModel={false}
                                    stripeId={selectedCustomer?.stripe_id}
                                    onClose={() => setChargeModel(false)}
                                    onSubmit={() => {
                                        setChargeModel(false);
                                    }}
                                    selectedService={selectedService}
                                    tipValue={appointment?.total_value}
                                    platForm={appointment?.platform}
                                    selectedProfessional={selectedServiceProvider}
                                    customerDetails={selectedCustomer}
                                    calendarDate={appointment?.booked_from_iso}
                                    appointment={appointment}
                                />
                            )}

                            {serviceView && (
                                <SelectService
                                    id={providerId}
                                    onClose={() => {
                                        setServiceView(false);
                                    }}
                                    selectedService={selectedService}
                                    onSelect={(service: any) => {
                                        const data = {
                                            price: service.price,
                                            _id: service.service_id,
                                            my_price: service.my_price,
                                            my_duration: service.my_duration,
                                            duration: service.duration,
                                            name: service.name,
                                            color: service.color,
                                            available_for_boooking: service.available_for_boooking,
                                            is_video: service?.is_video,
                                        };
                                        setSelectedService(data);
                                        setServiceDurationHours(
                                            service.my_duration >= 60
                                                ? Math.floor(service.my_duration / 60)
                                                : ''
                                        );
                                        setDurationHours(
                                            service.my_duration >= 60
                                                ? Math.floor(service.my_duration / 60)
                                                : ''
                                        );
                                        setDurationMinutes(service.my_duration % 60);
                                        setServiceDurationMinutes(service.my_duration % 60);
                                        updateEvent({ service });
                                        setIs_video(service?.is_video);
                                    }}
                                />
                            )}

                            {showConflictModal && (
                                <ConflictModal
                                    dates={conflictDates}
                                    onContinue={() => {
                                        setShowConflictModal(false);
                                        setOverbooking(true);
                                    }}
                                    onClose={() => setShowConflictModal(false)}
                                />
                            )}
                        </DrawerModel>
                    </Col>
                </Row>
            </MainContainer>
        </>
    );
};

const MainContainer = styled(Container)`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
`;
const AppointmentContent = styled(Col)`
    height: 100%;
    display: flex;
    flex-direction: column;
`;
const HeaderContainer = styled.div`
    display: flex;
    padding: 1rem 1.75rem;
    min-height: 70px;
    align-items: center;
`;
const Header = styled.div`
    font-size: 1.5rem;
    font-weight: 600;
`;
const DrawerModel = styled.div<any>`
    background-color: ${palette.white};
    z-index: 100;
    box-shadow: ${palette.customer_drawer_shadow} 0px 0px 48px 0px;
    min-width: 360px;
    border-radius: 0.75rem;
    width: 400px;
    display: ${({ display }) => (display ? 'block' : 'flex')};
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    ${media.xs`
    width: 100%;
    height: 98%;
	`}
    ${media.sm`
    width: 100%;
    height: 98%;
	`}
    ${media.md`
    width: 100%;
    height: 98%;

	`}
	${media.lg`
    width: 400px;
	`}
`;
const DrawerBody = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 0%;
    padding-top: 0.5rem /* 8px */;
    padding: 0 1.75rem;
    margin-bottom: 1rem;
`;
const AppointmentCustomer = styled.div`
    display: flex;
    align-items: center;
    box-shadow: 0 4px 8px 0 ${palette.customer_drawer_shadow};
    background: ${palette.white};
    padding: 1rem 16px;
    border-radius: 0.5rem;
    width: 100%;
    border: 1px dashed ${palette.select_box};
    cursor: pointer;
`;
const ServiceProviderContent = styled.div`
    display: flex;
    align-items: center;
    box-shadow: 0 4px 8px 0 ${palette.select_box};
    background: #fff;
    padding: 1rem 16px;
    border-radius: 0.5rem;
    width: 100%;
    border: 1px dashed ${palette.select_box};
    cursor: pointer;
    margin-top: 0.5rem;
`;
const FormContent = styled.div`
    flex: 1 1 0%;
`;
const FormDetails = styled.div`
    padding-top: 1rem;
    height: calc(100% - 84px);
`;
const DatesBody = styled.div`
    justify-content: space-between !important;
    display: -webkit-box !important;
    margin-bottom: 0.5rem /* 24px */;
`;
const FormDate = styled.div`
    font-weight: 500;
    font-size: 1.35rem;
    display: block;
    justify-content: center;
    align-items: center;
    input {
        border: none;
        box-sizing: border-box;
        outline: 0;
        position: relative;
        top: -1.8rem;
        width: 8rem;
        opacity: 0;
        cursor: pointer;
    }
    input[type='date']::-webkit-calendar-picker-indicator {
        background: transparent;
        bottom: 0;
        color: transparent;
        cursor: pointer;
        height: auto;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
    }
`;
const SelectServiceContent = styled.div`
    border-radius: 0.5rem /* 8px */;
    padding: 12px 12px 12px 20px;
    margin-top: 0.5rem;
    width: 100%;
    display: flex;
    position: relative;
    box-shadow: 0 4px 8px 0 ${palette.select_box};
    background: ${palette.white};
    border: 1px dashed ${palette.select_box};
    cursor: pointer;
`;
const ServiceHeader = styled.div`
    color: ${palette.grey.grey_20};
`;
const InputGroup = styled.div`
    margin-bottom: 1rem;
`;
const InputContent = styled(Row)`
    margin-bottom: 1rem;
`;
const ServiceBorder = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-left-width: 4px;
    border-radius: 8px 0 0 8px;
`;
const ServiceDetails = styled.div`
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const NameBody = styled.div`
    display: flex;
    flex-direction: column;
`;
const ServiceName = styled.div`
    font-weight: 500;
    font-size: 1.1rem;
`;
const ServiceDuration = styled.div`
    font-size: 0.925rem;
    color: ${palette.grey.grey_20};
`;
const PriceBody = styled.div`
    display: flex;
    align-items: center;
`;
const ServicePrice = styled.div`
    font-weight: 500;
    font-size: 1.1rem;
    margin-right: 18px;
`;
const SelectServiceBody = styled.div`
    border-radius: 0.5rem /* 8px */;
    padding-top: 1.5rem /* 24px */;
    padding-bottom: 1.5rem /* 24px */;
    padding-left: 1rem /* 16px */;
    padding-right: 1rem /* 16px */;
    margin-bottom: 2rem /* 32px */;
    align-items: center;
    display: flex;
    box-shadow: 0 4px 8px 0 ${palette.select_box};
    background: ${palette.white};
    border: 1px dashed ${palette.select_box};
    cursor: pointer;
    justify-content: space-between;
`;
const Circle = styled.div`
    display: inline-block;
    flex-shrink: 0;
    position: relative;
    border-radius: 0.42rem;
`;
const Label = styled.div`
    margin-left: 1rem /* 16px */;
    margin-right: 1rem /* 16px */;
    flex: 1 1 0%;
`;
const Icons = styled.i`
    font-size: 1.125rem /* 18px */;
    line-height: 1.75rem /* 28px */;
`;
const NameCircle = styled.img`
    background: linear-gradient(-135deg, ${palette.grey.light}, ${palette.grey.lightest});
    color: ${palette.grey.dark_50};
    width: 3.14rem;
    height: 3.14rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    line-height: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-radius: 0.42rem;
    font-size: 1.21rem;
    text-transform: capitalize !important;
    border-radius: 50%;
`;
const PlatformDiv = styled.div`
    display: flex;
    justify-content: space-between;
`;
const TotalPriceBody = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 0.5rem /* 16px */;
`;
const PlatForm = styled.div`
    font-size: 0.875rem /* 14px */;
    line-height: 1.25rem /* 20px */;
    text-transform: capitalize;
    color: ${palette.grey.grey_20};
    text-align: left;
`;
const PlatFormText = styled.div`
    font-size: 1.3rem /* 24px */;
    line-height: 2rem /* 32px */;
    font-weight: 600;
    text-align: left;
`;

const PriceHeader = styled.div`
    font-size: 0.875rem /* 14px */;
    line-height: 1.25rem /* 20px */;
    text-transform: capitalize;
    color: ${palette.grey.grey_20};
    text-align: right;
`;
const TotalPrice = styled.div`
    font-size: 1.5rem /* 24px */;
    line-height: 2rem /* 32px */;
    font-weight: 600;
    text-align: right;
`;
const FormFooter = styled.div`
    padding: 0.5rem 0rem;
    background: ${palette.white};
`;

const FormButtons = styled.div`
    display: flex;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1.5rem /* 24px */;
    justify-content: space-between;
`;

const CustomerContent = styled.div`
    margin-left: 1rem /* 16px */;
    margin-right: 1rem /* 16px */;
    flex: 1 1 0%;
    flex-direction: column;
`;
const Name = styled.div`
    font-weight: 500;
`;
const Email = styled.div`
    color: ${palette.grey.grey_20};
    font-size: 0.9rem;
`;

const ToggleButton = styled.div`
    display: flex;
    justify-content: flex-end;
    flex: 1 1 0%;
`;
const ToggleDurationButton = styled.div`
    margin-bottom: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const CustomSpan = styled.span`
    font-size: 0.8rem;
`;
const StatusButton = styled.button`
    padding: 0.92rem 1.85rem;
    font-weight: 600;
    text-transform: capitalize;
    border-radius: 0.57rem;
    font-size: 0.92rem;
    padding: 0.71rem 1.57rem;
    border: none;
    cursor: pointer;
`;

const DownIcon = styled.i`
    margin-left: 0.5rem /* 8px */;
    font-size: 1.125rem;
    line-height: 1.75rem;
`;
const Status = styled.div`
    border-radius: 0.25rem;
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 700;
`;
const CalenderDate = styled.div`
    cursor: pointer;
`;

const Verified = styled.div`
    color: ${palette.green};
`;
const Invalid = styled.div`
    color: ${palette.red_10};
`;
const Back = styled(Link)`
    margin-right: 1.25rem /* 20px */;
    font-size: 1.5rem /* 30px */;
    line-height: 2.25rem /* 36px */;
`;
const WaitingListText = styled.div`
    margin: 0 1.7rem;
    padding: 0.3rem;
`;
export default EditAppointmentDrawerForCustomer;
