/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Component: BrandInfo
 * Description: This component represents the brand information including the logo and brand name.
 *
 * Dependencies:
 * - React
 * - styled-components
 * - styled-bootstrap-grid (media)
 * - react-i18next
 *
 * Usage:
 * - Import this component to display the brand logo and name.
 */

import { media } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { palette } from 'styled/common';
import { CommonEnum } from 'utils';
import useRouter from 'hooks/router';

const BrandInfo = () => {
    const { t } = useTranslation();
    const router = useRouter();

    return (
        <div>
            {' '}
            <BrandContent onClick={() => router.navigate('/')}>
                <Logo src={CommonEnum.logo} alt="Logo" />
            </BrandContent>
            {/* <BrandName>{CommonEnum.AppName}</BrandName> */}
        </div>
    );
};

const BrandContent = styled.div`
    padding: 0rem 0rem 0rem 1.8rem;
    display: flex;
    align-items: center;
    justify-content: start;
    cursor: pointer;
    color: ${palette.white};
    font-size: 2rem;
    width: 10rem;
`;

const BrandName = styled.div`
    padding: 0.8rem 0rem 0.8rem 0rem;
    display: flex;
    align-items: center;
    justify-content: start;
    cursor: pointer;
    color: ${palette.white};
    font-size: 2rem;
    ${media.xs`
    font-size: 1.5rem;
  `}
    ${media.sm`
    font-size: 1.5rem;
  `}
    ${media.md`
    font-size: 1.5rem;
  `}
    ${media.lg`
    font-size: 1.7rem;
  `}
  ${media.xl`
  font-size: 2rem;
  `}
`;
const Logo = styled.img`
    width: -webkit-fill-available;
    height: auto;
    max-height: 7rem;
`;

export default BrandInfo;
