/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * BusinessDetails Component
 * Description: This component allows users to view and update their business details. It includes features such as uploading a profile image, updating business information, managing notifications, and configuring the business location on a map.
 *
 * Props:
 * - callBusiness: Function - A callback function used to trigger a business data refresh.
 *
 */

import React, { useEffect, useRef, useState } from 'react';
import Button from '@components/Button';
import styled from 'styled-components';
import { Container, Row, Col, media } from 'styled-bootstrap-grid';
import { Input, LoaderOverlay, Select, Switch, useSnackbar } from '@components/common';
import { businessTypes } from 'constants/data';
import moment from 'moment-timezone';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { palette } from '../../../styled/common';
import { api } from 'helpers/auth-axios';
import { useTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import states from 'states-us';
import Compressor from 'compressorjs';
import MultiSelect from '@components/common/select/MultiSelect';
import { Languages } from 'utils';

if (process.env.REACT_APP_MAPBOX_TOKEN !== undefined) {
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;
}

const BusinessDetails = ({ callBusiness }: { callBusiness?: any }) => {
    const { t }: any = useTranslation();
    const [profileImage, setProfileImage] = useState<any>(null);
    const [business, setBusiness] = useState<any>();
    const [email, setEmail] = useState<any>();
    const [sms, setSms] = useState<any>();
    const [push_notification, setPush_notification] = useState<any>();
    const [before_two_notification, setBefore_two_notification] = useState<any>();
    const [one_day_notification, setOne_day_notification] = useState<any>();
    const [uploadImage, setUploadImage] = useState<boolean>(false);
    const [loginUrl, setLoginUrl] = useState<any>();
    const [loading, setLoading] = useState(false);
    const [leftMenu, setLeftMenu] = useState(false);
    const [language, setLanguage] = useState('');
    const mapContainer: any = useRef(null);
    const map: any = useRef(null);
    const geocoder: any = useRef(null);
    const [lng, setLng] = useState(0);
    const [lat, setLat] = useState(0);
    const [address, setAddress] = useState('');
    const [address2, setAddress2] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [postcode, setPostcode] = useState('');
    const [openSnackbar] = useSnackbar();

    const validationSchema = Yup.object().shape({
        name: Yup.string().required(t('This field is required')),
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { handleSubmit, setValue, formState, trigger, getValues } = useForm(formOptions);
    const { errors, isSubmitting } = formState;
    let _userData = JSON.parse(localStorage.getItem('user') || '{}');

    useEffect(() => {
        getBusiness();
    }, [callBusiness]);

    useEffect(() => {
        if (!map.current) return;
        geocoder.current.on('result', (e: any) => {
            const result = e.result;
            if (typeof result.geometry.coordinates == 'object') {
                setLat(result.geometry.coordinates[1]);
                setLng(result.geometry.coordinates[0]);
            }
            setAddress(result?.place_name);
            if (result.context) {
                result.context.forEach((v: any, i: any) => {
                    if (v.id.indexOf('place') >= 0) {
                        setCity(v.text);
                    }
                    if (v.id.indexOf('postcode') >= 0) {
                        setPostcode(v.text);
                    }
                    if (v.id.indexOf('region') >= 0) {
                        setState(v.text);
                    }
                });
            }
        });
    });

    // Set initial values
    useEffect(() => {
        setProfileImage(business?.photo);
        setTimeout(() => {
            setValue('business_type' as never, business?.business_type as never);
            setValue('name' as never, business?.name as never);
            setValue('phone' as never, business?.phone as never);
            setValue('business_url' as never, business?.business_url as never);
            setValue('timezone' as never, business?.timezone as never);
            setValue(
                'hide_service_descriptions' as never,
                business?.hide_service_descriptions as never
            );
            setValue('hide_business_prices' as never, business?.hide_business_prices as never);
            setValue('hide_categories' as never, business?.hide_categories as never);
            setValue('language' as never, business?.language as never);
            setValue('loyality_discount' as never, business?.loyality_discount as never);
        });
    }, [business, setValue]);

    let location = {
        zipcode: postcode,
        address: address,
        address2: address2,
        city: city,
        state: state,
        coordinates: {
            latitude: lat,
            longitude: lng,
        },
    };

    const getBusiness = () => {
        setLoading(true);
        api.get(`/businesses/${_userData?.user?.business_id?._id}`)
            .then((res: any) => {
                if (res) {
                    setProfileImage(res?.data?.photo);
                    setLoading(false);
                    setUploadImage(false);
                    setValue('phone' as never, res?.data?.phone as never);
                    setValue('business_type' as never, res?.data?.business_type as never);
                    setValue('loyality_discount' as never, res?.data?.loyality_discount as never);
                    setBusiness(res.data);
                    setEmail(res?.data?.notifications?.email);
                    setSms(res?.data?.notifications?.sms);
                    setPush_notification(res?.data?.notifications?.push_notification);
                    setOne_day_notification(res?.data?.notifications?.before_one_day);
                    setBefore_two_notification(res?.data?.notifications?.before_two_hours);
                    setLoginUrl(window.location.origin + '/' + res.data._id + '/' + 'login');
                    setLeftMenu(res?.data.leftMenu);
                    setLanguage(res?.data?.language);
                    setLat(res?.data?.location?.coordinates?.latitude);
                    setLng(res?.data?.location?.coordinates.longitude);
                    setAddress(res?.data?.location?.address);
                    setAddress2(res?.data?.location?.address2);
                    setCity(res?.data?.location?.city);
                    setState(res?.data?.location?.state);
                    setPostcode(res?.data?.location?.zipcode);

                    if (map.current) return;
                    map.current = new mapboxgl.Map({
                        container: mapContainer.current,
                        style: 'mapbox://styles/mapbox/streets-v11',
                        center: [
                            res?.data?.location ? res?.data?.location?.coordinates.longitude : 0,
                            res?.data?.location ? res?.data?.location?.coordinates?.latitude : 0,
                        ],
                        zoom: 6,
                    });
                    geocoder.current = new MapboxGeocoder({
                        accessToken: mapboxgl.accessToken,
                        // @ts-ignore
                        mapboxgl: mapboxgl,
                        placeholder: t('Search on map'),
                    });
                    map.current.addControl(geocoder.current);
                }
            })
            .catch((e: any) => {
                setLoading(false);
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    };

    const onSubmit = async (data: any) => {
        setLoading(true);
        const notifications = {
            email: email,
            sms: sms,
            push_notification: push_notification,
            before_one_day: one_day_notification,
            before_two_hours: before_two_notification,
        };
        const businessDetails = {
            ...data,
            newPhoto: uploadImage == true ? profileImage : null,
            notifications: notifications,
            leftMenu: leftMenu,
            location: location,
        };
        await api
            .put(
                `businesses/${business._id!}`,
                uploadImage == true
                    ? businessDetails
                    : {
                          ...data,
                          notifications: notifications,
                          leftMenu: leftMenu,
                          location: location,
                      }
            )
            .then((res: any) => {
                var _userData = JSON.parse(localStorage.getItem('user') || '{}');
                setLoading(false);
                if (res.data.leftMenu !== business?.leftMenu) {
                    window.location.reload();
                }
                if (res !== undefined) {
                    _userData.user.business_id = res.data;
                    localStorage.setItem('user', JSON.stringify(_userData));
                }
                i18n.changeLanguage(res.data.language);
                getBusiness();
                openSnackbar(t('Updated successfully!'));
            })
            .catch((e: any) => {
                setLoading(false);
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    };

    async function onChange({ name, value }: { name: string; value: string }) {
        if (name === 'phone') {
            let phone = value;

            setValue(name as never, phone as never);
            await trigger(name as never);
        } else {
            setValue(name as never, value as never);
            await trigger(name as never);
        }
    }

    const handleCapture = ({ target }: any) => {
        new Compressor(target.files[0], {
            quality: 0.6,
            success: res => {
                setUploadImage(true);
                const reader: any = new FileReader();
                reader.readAsDataURL(res);
                reader.onload = () => {
                    if (reader.readyState === 2) {
                        setProfileImage(reader.result);
                    }
                };
            },
        });
    };

    const States: { label: string; value: any }[] = [];
    states?.length &&
        states.map(x => {
            States.push({ label: x.name, value: x.name });
        });

    console.log(
        '==========language',
        Languages.filter((e: any) => e.value == getValues('language'))
    );
    return (
        <>
            {loading && <LoaderOverlay />}
            {business && (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Wrapper>
                        <Row>
                            <Col lg={12}>
                                <BusniessCard
                                    htmlFor="faceImage"
                                    aria-label="upload picture"
                                    style={{
                                        backgroundImage: `url(${
                                            uploadImage === true
                                                ? profileImage
                                                : `${process.env.REACT_APP_PROFILE_URL}${profileImage}`
                                        })`,
                                    }}></BusniessCard>
                                <FileInput
                                    accept="image/*,.jpeg,.png"
                                    id="faceImage"
                                    type="file"
                                    onChange={handleCapture}
                                />
                            </Col>
                            <CustomCol lg={6}>
                                <Card>
                                    <InputGroup>
                                        <Input
                                            label={t('What is the name of your business?')}
                                            name="name"
                                            value={business.name}
                                            onChange={onChange}
                                            error={errors.name as any}
                                        />
                                    </InputGroup>
                                    <InputGroup>
                                        <Input
                                            label={t('What is your business phone number?')}
                                            name="phone"
                                            value={getValues('phone')}
                                            allowPhoneNumberOnly={true}
                                            onChange={onChange}
                                            maxLength={14}
                                            error={errors.phone as any}
                                        />
                                    </InputGroup>
                                    <InputGroup>
                                        <Input
                                            label={t('What is your business website URL?')}
                                            name="business_url"
                                            value={business?.business_url}
                                            onChange={onChange}
                                            error={errors.business_url as any}
                                        />
                                    </InputGroup>
                                    <InputGroup>
                                        <Input
                                            label={t('URL for customer login?')}
                                            name="business_url"
                                            readOnly={true}
                                            value={loginUrl}
                                            onChange={onChange}
                                        />
                                    </InputGroup>
                                    <InputGroup>
                                        <Select
                                            disabled={true}
                                            value={
                                                getValues('business_type')
                                                    ? getValues('business_type')
                                                    : businessTypes[0]?.label
                                            }
                                            name="business_type"
                                            label={t('What type of business are you running?')}
                                            options={businessTypes}
                                            onChange={(e: any) =>
                                                onChange({ name: 'business_type', value: e })
                                            }
                                        />
                                    </InputGroup>
                                    <InputGroup>
                                        <Select
                                            disabled={true}
                                            value={business?.timezone}
                                            label={t('Time Zone')}
                                            onChange={(val: string) =>
                                                onChange({ value: val, name: 'timezone' })
                                            }
                                            options={moment.tz.names().map(timezone => {
                                                return { label: timezone };
                                            })}
                                        />
                                    </InputGroup>
                                    <InputGroup>
                                        <MultiSelect
                                            label={t('Language')}
                                            options={Languages}
                                            value={Languages.filter(
                                                (e: any) => e.value == getValues('language')
                                            )}
                                            onChange={(e: any) => {
                                                onChange({ value: e?.value, name: 'language' });
                                            }}
                                        />
                                    </InputGroup>

                                    <CardList>
                                        <div>{t('Left Menu')}</div>
                                        <Switch
                                            onChange={(val: boolean) => setLeftMenu(val)}
                                            value={leftMenu}
                                        />
                                    </CardList>

                                    <Divider />
                                    <CardList>
                                        <div>{t('Email Notification')}</div>
                                        <Switch
                                            onChange={(val: boolean) => setEmail(val)}
                                            value={email}
                                        />
                                    </CardList>
                                    <Divider />
                                    <CardList>
                                        <div>{t('SMS Notification')}</div>
                                        <Switch
                                            onChange={(val: boolean) => setSms(val)}
                                            value={sms}
                                        />
                                    </CardList>
                                    <Divider />
                                    <CardList>
                                        <div>{t('Push Notification')}</div>
                                        <Switch
                                            onChange={(val: boolean) => setPush_notification(val)}
                                            value={push_notification}
                                        />
                                    </CardList>
                                    <Divider />
                                    <CardList>
                                        <div>{t('Appointment Notifications Before Two Hours')}</div>
                                        <Switch
                                            onChange={(val: boolean) =>
                                                setBefore_two_notification(val)
                                            }
                                            value={before_two_notification}
                                        />
                                    </CardList>
                                    <Divider />
                                    <CardList>
                                        <div>{t('Appointment Notifications Before One Day')}</div>
                                        <Switch
                                            onChange={(val: boolean) =>
                                                setOne_day_notification(val)
                                            }
                                            value={one_day_notification}
                                        />
                                    </CardList>
                                    <Divider />
                                    <CustomButtom>
                                        <Button
                                            type="submit"
                                            bgtype="secondary"
                                            label={t('Update')}></Button>
                                    </CustomButtom>
                                </Card>
                            </CustomCol>
                            <CustomCol lg={6}>
                                <SettingCard style={{ paddingTop: '0.17rem !important' }}>
                                    <Map ref={mapContainer} />
                                    <style>{`
                                            .mapboxgl-ctrl-top-right {
                                                width: 100% !important;
                                                padding: 0.71rem !important;
                                                top: 0;
                                                right: 0;
                                            }
                                            .mapboxgl-ctrl-geocoder {
                                                max-width: 100% !important;
                                                width: 100% !important;
                                                margin: 0 !important;
                                            }
                                        `}</style>
                                    <InputGroup>
                                        <Input
                                            label={t('Street address & number')}
                                            value={address}
                                            onChange={({ value }: { value: any }) => {
                                                setAddress(value);
                                            }}
                                        />
                                    </InputGroup>
                                    <InputGroup>
                                        <Input
                                            label={t('Suite number (optional)')}
                                            value={address2}
                                            onChange={({ value }: { value: any }) => {
                                                setAddress2(value);
                                            }}
                                        />
                                    </InputGroup>
                                    <InputGroup>
                                        <Input
                                            label={t('City')}
                                            value={city}
                                            onChange={({ value }: { value: any }) => {
                                                setCity(value);
                                            }}
                                        />
                                    </InputGroup>
                                    <Wrapper>
                                        <Row>
                                            <CustomCol lg={7} margin={true}>
                                                <InputGroup>
                                                    <Select
                                                        label={t('State')}
                                                        disabled={true}
                                                        value={state}
                                                        options={States}
                                                        onChange={val => setState(val)}
                                                    />
                                                </InputGroup>
                                            </CustomCol>
                                            <Col lg={5}>
                                                <InputGroup>
                                                    <Input
                                                        label={t('Postal Code')}
                                                        value={postcode}
                                                        onChange={({ value }: { value: any }) => {
                                                            setPostcode(value);
                                                        }}
                                                    />
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                    </Wrapper>
                                </SettingCard>
                            </CustomCol>
                        </Row>
                    </Wrapper>
                </form>
            )}
        </>
    );
};

const Wrapper = styled(Container)`
    padding: 0rem;
`;

const CustomCol = styled(Col)<any>`
    margin-top: ${({ margin }) => (margin ? '' : '1rem')};
`;

const Card = styled.div`
    height: 100%;
    background: ${palette.white};
    box-shadow: inset -1.80118px -2.70178px 9.00592px rgba(0, 0, 0, 0.25);
    border-radius: 4.50296px;
    padding: 3rem 2rem 1.75rem 2rem !important;
`;

const BusniessCard = styled.label`
    display: flex;
    flex-direction: column;
    justify-content: end;
    border-radius: 0.5rem;
    color: ${palette.white};
    height: 15.71rem;
    width: 100%;
    background-position: 50%;
    background-size: cover;
    position: relative;
    padding: 1.71rem 1.71rem 1.14rem 1.14rem;
    z-index: 0;
    overflow: hidden;
    cursor: pointer;
`;

const InputGroup = styled.div`
    margin-bottom: 1rem;
`;

const SettingCard = styled.div`
    height: 100%;
    background: ${palette.white};
    box-shadow: inset -1.80118px -2.70178px 9.00592px rgba(0, 0, 0, 0.25);
    border-radius: 4.50296px;
    padding: 1.26rem 2rem !important;
`;

const CardList = styled.div`
    align-items: center !important;
    cursor: pointer !important;
    display: flex !important;
    justify-content: space-between;
`;

export const Divider = styled.hr`
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
    border: 0.1rem none none none solid ${palette.grey.lightest};
    border-top: none;
    border-right: none;
    border-left: none;
`;

const FileInput = styled.input`
    display: none;
`;

export const CustomButtom = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const Map = styled.div`
    margin-top: 0rem;
    height: 24.57rem;
    font: 0.85rem/1.42rem Helvetica Neue, Arial, Helvetica, sans-serif !important;
    overflow: hidden !important;
    position: relative !important;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    margin-bottom: 1rem;
    ${media.xs`
    margin-top: 1.8rem;
  `}
    ${media.sm`
    margin-top: 1.8rem;
  `}
    ${media.md`
    margin-top: 1.8rem;
  `}
    ${media.lg`
    margin-top: 0rem;
  `}
`;

export default BusinessDetails;
