/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * UserNav Component
 * Description: Component for displaying user navigation and related actions, including notifications and user profile options.
 * Purpose:
 * - Displays user information such as profile picture, name, and role.
 * - Allows users to view and manage notifications.
 * - Provides options for editing the user profile and logging out.
 *
 * Props:
 * - openNotification: () => void - Callback function to open user notifications.
 * - openAuth: () => void - Callback function to open authentication popup.
 * - openNotificationPopup: boolean - Boolean to control the visibility of the notifications popup.
 * - openAuthPopup: boolean - Boolean to control the visibility of the authentication popup.
 * - dropPosition: string - Position for displaying dropdown options (e.g., 'down').
 * - userData: any - User data including information like name, role, and profile picture.
 * - setOpenAuthPopup: any - Callback function to set the visibility of the authentication popup.
 * - onClick: any - Callback function for handling click events or user profile.
 * - icon: any - Icon to be displayed in the user navigation dropdown.
 */

import s from './UserNav.module.scss';
import './UserNav.module.scss';
import { Dropdown, DropdownMenu } from '@components/common';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { api } from 'helpers/auth-axios';
import { INotification } from 'interfaces/notification.interface';
import { palette } from 'styled/common';
import DropDownList from '../DropDownList';
import { Col, Container, media, Row } from 'styled-bootstrap-grid';
import EditProfileModel from '../EditProfileModel';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface IProps {
    openNotification?: () => void;
    openAuth?: () => void;
    openNotificationPopup: boolean;
    openAuthPopup?: boolean;
    dropPosition?: string;
    userData?: any;
    setOpenAuthPopup?: any;
    onClick?: any;
    icon?: any;
}

const UserNav = ({
    openAuth,
    openAuthPopup,
    openNotification,
    openNotificationPopup,
    userData,
    setOpenAuthPopup,
    onClick,
    icon,
}: IProps) => {
    let _user: any = JSON.parse(localStorage.getItem('user') || '{}');
    let _url: any = JSON.parse(localStorage.getItem('url') || '{}');
    const [user, setUser] = useState<any>();
    const [notifications, setNotifications] = useState<INotification[]>([]);
    const [editProfileModal, setEditProfileModel] = useState(false);
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        _user?.user?.role !== 'ADMIN' &&
            api
                .get('/appointments/all/notifications', {
                    params: {
                        page: 0,
                        pageSize: 40,
                    },
                })
                .then((res: any) => {
                    setNotifications(res.data);
                });
        setUser(JSON.parse(localStorage.getItem('user') || '{}'));
    }, []);

    const doLogout = () => {
        _user?.user_login == 'customer'
            ? navigate(`/${_user?.user?.business_id?._id}/login`)
            : navigate(`/login`);
        localStorage.removeItem('user');
        localStorage.removeItem('url');
    };

    return (
        <Flex>
            {_user?.user?.role === 'ADMIN' || _user?.user_login === 'customer' ? (
                ''
            ) : (
                <Dropdown>
                    {openNotificationPopup && (
                        <DropdownMenu className={s.notifications_menu} scrollable={false}>
                            <div className={s.notifications_header}>
                                <Heading>Activity</Heading>
                            </div>
                            <div className={s.notifications_body}>
                                {notifications?.length ? (
                                    notifications.map((notification: any) => {
                                        return (
                                            <List>
                                                <Avatar
                                                    className={` ${s.notification_icon}`}></Avatar>
                                                <div className={s.notification_details}>
                                                    {notification}
                                                </div>
                                            </List>
                                        );
                                    })
                                ) : (
                                    <Notification>{t('No Notification')}</Notification>
                                )}
                            </div>
                        </DropdownMenu>
                    )}
                </Dropdown>
            )}
            <Profile onClick={onClick}>
                <ProfileIcon
                    src={`${process.env.REACT_APP_PROFILE_URL}${_user?.user?.photo}`}
                    alt=""
                />

                <FlexCol>
                    <Title color={_user?.user_login === 'customer' && 'black'}>
                        {_user?.user?.role === 'ADMIN' ? 'Admin' : _user?.user_login}
                    </Title>
                    <Name color={_user?.user_login === 'customer' && 'black'}>
                        {_user?.user_login === 'customer'
                            ? _user?.user?.firstname + ' ' + _user?.user?.lastname
                            : _user?.user?.name}
                    </Name>
                </FlexCol>
                <Icon icon={icon}>
                    <DropDownList
                        userData={_user}
                        url={_url}
                        onBack={val => setOpenAuthPopup(val)}
                        openAuth={openAuth}
                        openAuthPopup={openAuthPopup}
                        dropPosition="down"
                    />
                </Icon>
            </Profile>
            {openAuthPopup === true && (
                <CustomContainer style={{ marginTop: '0.5rem' }}>
                    <Row>
                        <CustomCol col={12} onClick={() => setEditProfileModel(true)}>
                            {' '}
                            <i className="fal fa-edit"></i>
                            {t('Edit')}
                        </CustomCol>
                        <CustomCol col={12} onClick={() => doLogout()}>
                            {' '}
                            <i className="far fa-sign-out"></i>
                            {t('Logout')}
                        </CustomCol>
                    </Row>
                </CustomContainer>
            )}
            {editProfileModal && <EditProfileModel onClose={() => setEditProfileModel(false)} />}
        </Flex>
    );
};

const Heading = styled.div`
    color: rgba(255, 255, 255) !important;
    font-weight: 500 !important;
    font-size: 1.25rem !important;
    line-height: 1.75rem !important;
`;

const Flex = styled.div`
    align-items: center !important;
`;

const Profile = styled.div`
    align-items: center !important;
    display: flex !important;
    margin-left: 2rem;
    z-index: 1;
    @media screen and (min-width: 200px) and (max-width: 375px) {
        margin-left: 0.5rem;
    }
    @media screen and (min-width: 376px) and (max-width: 398px) {
        margin-left: 1rem;
    }
    ${media.xs`
    margin-top: 1rem;
  `}
    ${media.sm`
    margin-top: 1rem;
  `}
    ${media.md`
    margin-top: 1rem;
    cursor: pointer
  `}
    ${media.lg`
    margin-top: 0rem;
    `}
`;

const List = styled.div`
    display: flex;
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
    border: 0.1rem solid ${palette.grey.lightest};
    align-items: center;
`;

const Avatar = styled.div`
    margin-right: 0.75rem !important;
`;

const ProfileIcon = styled.img`
    width: 36px;
    height: 36px;
    border-radius: 2rem !important;
    margin-right: 0.5rem !important;
`;

const FlexCol = styled.div`
    flex-direction: column !important;
    display: flex !important;
`;
const Title = styled.div<any>`
    color: ${({ color }) => (color ? 'black' : 'white')} !important;
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
    text-align: left !important;
    text-transform: capitalize;
`;

const Name = styled.div<any>`
    color: ${({ color }) => (color ? 'black' : 'white')} !important;
    font-weight: 500 !important;
    font-size: 0.9rem !important;
`;

const Icon = styled.div<any>`
    color: white !important;
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
    margin-left: 0.5rem !important;
    margin-top: 0.25rem !important;
    cursor: pointer;
    ${media.xs`
   display: none;
  `}
    ${media.sm`
    display: none;
  `}
    ${media.md`
    display: none;
  `}
    ${media.lg`
    display: ${({ icon }: any) => (icon ? 'none' : 'flex')};
    `}
`;

const Notification = styled.div`
    margin: 0.5rem !important;
`;
const CustomContainer = styled(Container)`
    ${media.xs`
   display: flex;
  `}
    ${media.sm`
    display: flex;
  `}
    ${media.md`
    display: flex;
  `}
    ${media.lg`
    display: none;
    `}
`;

export const CustomCol = styled(Col)`
    padding: 1rem;
    cursor: pointer;
    margin-left: 1rem;
    color: ${palette.light};
    display: flex;
    font-size: 1rem;
    align-items: center;
    gap: 1rem;
    :hover {
        background: #2e3648;
    }
    ${media.xs`
   display: flex;
  `}
    ${media.sm`
    display: flex;
  `}
    ${media.md`
    display: flex;
  `}
    ${media.lg`
    display: none;
    `}
`;

export default UserNav;
