/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Purpose:
 * - Display Code Snippets: The `SyntaxHighlighter` component is designed to display code snippets in a visually appealing manner, making it easier for users to read and understand the code.
 * - Syntax Highlighting: It leverages the `Prism` component from the `react-syntax-highlighter` library to provide syntax highlighting for various programming languages, improving code readability.
 * - Copy-to-Clipboard Functionality: The component includes a 'Copy' button powered by `react-copy-to-clipboard` to allow users to easily copy the highlighted code to their clipboard.
 * - User Experience: Enhances the overall user experience by providing a clean and user-friendly way to showcase and share code snippets within the application.
 *
 * Props:
 * - children?: any - The code snippet content to be highlighted.
 * - language: string - The programming language for syntax highlighting.
 */

import { Prism as Highlighter } from 'react-syntax-highlighter';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { vscDarkPlus } from 'react-syntax-highlighter/dist/cjs/styles/prism';
import { useSnackbar } from '../snackbar';
import './SyntaxHighlighter.scss';
import { useTranslation } from 'react-i18next';
import { palette } from 'styled/common';

const SyntaxHighlighter = ({ children, language }: { children?: any; language: string }) => {
    const [openSnackbar] = useSnackbar();
    const { t } = useTranslation();

    return (
        <div className="syntaxHighlighter--container">
            <div className="syntaxHighlighter--lang">{t('javascript')}</div>
            <Highlighter
                language={language}
                style={vscDarkPlus}
                className="syntaxHighlighter"
                wrapLongLines={true}
                customStyle={{
                    paddingTop: '26px',
                }}>
                {children ?? ''}
            </Highlighter>
            <CopyToClipboard
                text={children}
                onCopy={() => {
                    openSnackbar(t('Copied to clipboard'));
                }}>
                <button
                    className="syntaxHighlighter--copy"
                    style={{ background: palette.secondary }}>
                    {t('Copy')}
                </button>
            </CopyToClipboard>
        </div>
    );
};

export default SyntaxHighlighter;
