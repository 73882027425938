/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * CustomerLogin Component
 * Description: This component handles customer login and password reset functionalities.
 * It includes features such as sending a password reset email, user login, and navigation between login and password reset views.
 * It uses form validation, redux for state management, and includes navigation links and UI components for a seamless user experience.
 *
 */

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Container, Col, Row, media } from 'styled-bootstrap-grid';
import { Input, useSnackbar } from '@components/common';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'hooks/redux';
import { userActions } from 'actions/user';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import useRouter from 'hooks/router';
import SideCover from '@components/common/sideCover/SideCover';
import ForgotPassword from './CustomerForgotPassword';
import { useTranslation } from 'react-i18next';
import Button from '@components/Button';

const CustomerLogin = () => {
    useEffect(() => {
        localStorage.removeItem('user');
        localStorage.removeItem('url');
    }, []);

    const { t }: any = useTranslation();
    const router = useRouter();
    const navigate = useNavigate();
    const [openSnackbar] = useSnackbar();
    const dispatch = useAppDispatch();
    const [authMethod, setAuthMethod] = useState('email');
    const [loading, setLoading] = useState(false);
    const [forgotPassword, setForgotPassword] = useState<any>(false);

    useEffect(() => {
        if (router.query.success !== undefined) {
            if (router.query.provider) setAuthMethod(router.query.provider as string);
            if (router.query.accessToken) {
                dispatch(userActions.getCurrentUser(router.query.accessToken as string)).then(
                    (res: any) => {
                        if (res.email === true) {
                            navigate(`/customers/${res?._id}`);
                        }
                    }
                );
            }
        }
    }, [dispatch, router.query]);

    const validationSchema = Yup.object().shape({
        // email: Yup.string().required(t('This field is required')),
        phone: Yup.string()
            .min(14, t('Phone must be a valid phone'))
            .required(t('This field is required')),
        password: Yup.string().required(t('This field is required')),
    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { handleSubmit, setValue, formState, trigger, getValues } = useForm(formOptions);
    const { errors } = formState;

    const onSubmit = ({ phone, password }: any) => {
        setLoading(true);
        let customerLogin = {
            phone,
            password,
            role: 'customer',
            businessId: window.location.pathname.slice(1, 25),
        };
        return dispatch(userActions.login(customerLogin)).then((res: any) => {
            setLoading(false);
            if (res.user) {
                let _userData = res.user;
                if (res?.user_login === 'customer') {
                    navigate(`/customers/${_userData?._id}`);
                }
            } else {
                setLoading(false);
                openSnackbar(t('Login Failed'));
            }
        });
    };

    async function onChange({ name, value }: { name: string; value: string }) {
        setValue(name as never, value as never);
        await trigger(name as never);
    }

    return (
        <>
            <Wrapper>
                <Row>
                    <SideCover />
                    <Col lg={5} style={{ padding: '0' }}>
                        <CardRight>
                            <Inner>
                                <Heading>
                                    {forgotPassword === false
                                        ? t('Customer Log In')
                                        : t('Reset Password')}
                                </Heading>

                                {forgotPassword === true && (
                                    <SignInText>
                                        {t('Already have password?')}
                                        <Link to="">
                                            <SignUpText
                                                className=""
                                                onClick={() => setForgotPassword(false)}>
                                                {t('Log In')}
                                            </SignUpText>
                                        </Link>
                                    </SignInText>
                                )}
                            </Inner>

                            {forgotPassword === false ? (
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <InputGroup>
                                        <Input
                                            allowPhoneNumberOnly={true}
                                            maxLength={14}
                                            label={t('Phone')}
                                            name="phone"
                                            value={getValues('phone')}
                                            onChange={onChange}
                                            error={errors.phone as any}
                                        />
                                    </InputGroup>
                                    {/* <InputGroup>
                                        <Input
                                            label={t('Email')}
                                            name="email"
                                            type="email"
                                            onChange={onChange}
                                            error={errors.email as any}
                                        />
                                    </InputGroup> */}

                                    <InputGroup>
                                        <Input
                                            type={t('Password')}
                                            name="password"
                                            label="Password"
                                            onChange={onChange}
                                            error={errors.password as any}
                                        />
                                    </InputGroup>

                                    <CustomDiv onClick={() => setForgotPassword(true)}>
                                        <Link to="">{t('Forgot Password?')}</Link>
                                    </CustomDiv>

                                    <Button
                                        type={'submit'}
                                        label={t('Log In')}
                                        width={'100% !important'}
                                        bgtype={'secondary'}
                                        disabled={loading}
                                    />
                                </form>
                            ) : (
                                <ForgotPassword
                                    setForgotPassword={setForgotPassword}
                                    isCustomer={true}
                                />
                            )}
                        </CardRight>
                    </Col>
                </Row>
            </Wrapper>
        </>
    );
};
const Wrapper = styled(Container)`
    padding: 0;
    min-width: 100%;
    background-color: #fff;
`;
const CardRight = styled.div`
    padding-left: 4.75rem !important;
    padding-right: 4.75rem !important;
    background-color: #fff;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;
const Inner = styled.div`
    margin-bottom: 1rem;
`;
const Heading = styled.h3`
    color: #000;
    font-size: 2.6rem;
    font-weight: 600 !important;
    ${media.xs`
    margin-top:0;
	`}
    ${media.sm`
    margin-top:0;
	`}
	${media.md`
    margin-top:0;
	`}
`;
const InputGroup = styled.div`
    margin-bottom: 1.1rem;
`;
const SignUpText = styled.span`
    font-weight: 800;
    padding-left: 0.5rem;
    margin: 0;
`;
const SignInText = styled.span`
    display: flex;
    color: #000 !important;
    font-size: 1.125rem !important;
    line-height: 1.75rem !important;
`;
const CustomDiv = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 0rem 0rem;
    margin-bottom: 0.5rem;
`;
export default CustomerLogin;
