/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * OthersCard Component
 * Description: This component displays details of a specific 'Other' member, including information, permissions, and options to edit or delete.
 * It consists of tabs for 'About' and 'Permissions', and utilizes sub-components like CardHeader, EditOther, Permissions, and DeleteModel.
 *
 * Props:
 * - callOther: Function to fetch and update the list of 'Others' (e.g., when an 'Other' is deleted).
 */

import useRouter from 'hooks/router';
import { useEffect, useState } from 'react';
import { IOther } from 'interfaces/others.interface';
import { LoaderOverlay, Tab, TabPanel, Tabs, useSnackbar } from '@components/common';
import styled from 'styled-components';
import { Col, Container, Row, media } from 'styled-bootstrap-grid';
import TabContext from '@components/common/tabContext';
import Permissions from '@components/common/permissions/Permissions';
import DeleteModel from '@components/common/deleteModel/DeleteModel';
import { palette } from 'styled/common';
import Accordion from '@components/Accordion';
import { api } from 'helpers/auth-axios';
import EditOther from '@components/team/edit/editOther';
import CardHeader from '@components/common/cardHeader/CardHeader';
import SendMessage from '@components/common/SendMessage';
import { MobileViewDiv } from '../serviceProviderCard/ServiceProviderCard';
import { useTranslation } from 'react-i18next';

const OthersCard = ({ callOther }: any) => {
    const { t }: any = useTranslation();
    const [other, setOther] = useState<IOther>();
    const [tabValue, setTabValue] = useState(0);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState<any>();
    const [loading, setLoading] = useState(false);
    const [businessId, setBusinessId] = useState<any>();
    const router = useRouter();
    const [openSnackbar] = useSnackbar();
    const [isSendMessage, setIsSendMessage] = useState(false);
    let _userData = JSON.parse(localStorage.getItem('user') || '{}');

    useEffect(() => {
        data();
    }, [router.query.id]);

    const data = () => {
        setLoading(true);
        api.get(`/other/${router.query.id as string}`)
            .then((res: any) => {
                setLoading(false);
                if (res.data !== undefined) {
                    setOther(res.data);
                    setBusinessId(res.data.business_id);
                }
            })
            .catch(e => {
                setLoading(false);
            });
    };

    const deleteModel = (id: any) => {
        setDeleteModal(true);
        setDeleteId(id);
    };

    const deleteOther = (id: any) => {
        setLoading(true);
        api.delete(`/other/${id}`).then((res: any) => {
            if (res?.data) {
                router.navigate('/team/other-list');
                openSnackbar(t('Other deleted successfully!'));
                setDeleteModal(false);
                callOther();
            } else {
                openSnackbar(t('Failed to delete  Other!'));
                setDeleteModal(false);
            }
        });
    };

    const tabsNameList = [
        {
            name: t('About'),
            content: (
                <>
                    <MobileViewDiv>
                        <CardHeader
                            parent={'team'}
                            userData={other}
                            deleteModel={() => deleteModel(router.query.id)}
                            messageModel={() => setIsSendMessage(true)}
                        />
                    </MobileViewDiv>
                    <EditOther
                        clickCallBack={() => {
                            data();
                            callOther();
                        }}
                    />
                </>
            ),
        },
        {
            name: t('Permissions'),
            content: (
                <Permissions businessId={businessId} defaultPermissions={other?.permissions} />
            ),
        },
    ];

    return (
        <>
            {loading && <LoaderOverlay />}
            <MainContainer>
                <Row>
                    {other && (
                        <>
                            <WebView lg={12}>
                                <CardHeader
                                    parent={'team'}
                                    userData={other}
                                    deleteModel={() => deleteModel(router.query.id)}
                                    messageModel={() => setIsSendMessage(true)}
                                />
                            </WebView>
                            <WebView lg={12}>
                                <TabsCard>
                                    <TabContext value={tabValue}>
                                        <Tabs
                                            onChange={(event, newValue) => {
                                                setTabValue(newValue);
                                            }}>
                                            <Tab label={tabsNameList[0].name} />
                                            <Tab label={tabsNameList[1].name} />
                                        </Tabs>
                                        <TabPanel value={0} preload={true}>
                                            <TabContent>{tabsNameList[0].content}</TabContent>
                                        </TabPanel>
                                        <TabPanel value={1} preload={true}>
                                            <TabContent>{tabsNameList[1].content}</TabContent>
                                        </TabPanel>
                                    </TabContext>
                                </TabsCard>
                            </WebView>
                            <MobileView lg={12}>
                                <Accordion tabsNameList={tabsNameList} />
                            </MobileView>

                            {deleteModal && other && (
                                <DeleteModel
                                    id={other._id}
                                    onDelete={() => {
                                        setDeleteModal(false);
                                    }}
                                    name={t('Others')}
                                    content={t(
                                        'If you delete this Other you will lose all information connected to their profile including permissions. This action cannot be undone.'
                                    )}
                                    loading={loading}
                                    onClick={() => deleteOther(deleteId)}
                                    onClose={() => setDeleteModal(false)}
                                />
                            )}
                        </>
                    )}
                    {isSendMessage && (
                        <SendMessage
                            name={other?.name}
                            phone={other?.phone}
                            senderEmail={_userData.user.email}
                            reciverEmail={other?.email}
                            onClose={() => {
                                setIsSendMessage(false);
                            }}
                        />
                    )}
                </Row>
            </MainContainer>
        </>
    );
};

const MainContainer = styled(Container)`
    margin-top: 1rem;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    ${media.xs`
    overflow-x: hidden;
    overflow-y: auto;
	`}
    ${media.sm`
    overflow-x: hidden;
    overflow-y: auto;
	`}
	${media.md`
	`}
	${media.lg`
	`}
`;

const WebView = styled(Col)`
    display: block;
    @media screen and (min-width: 1px) and (max-width: 900px) {
        display: none;
    }
`;

const MobileView = styled(Col)`
    display: none;
    @media screen and (min-width: 1px) and (max-width: 900px) {
        display: block;
    }
`;

const TabsCard = styled.div`
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: ${palette.light};
    background-clip: border-box;
    border-radius: 0.42rem;
    border: 0;
    background: ${palette.white};
    box-shadow: inset -1.80118px -2.70178px 9.00592px rgba(0, 0, 0, 0.25);
    border-radius: 4.50296px;
`;

const TabContent = styled.div`
    padding: 1.3rem;
    flex: 1;
`;
export default OthersCard;
