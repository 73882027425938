import React, { FC } from 'react';
import { Aside, Header } from '@components/common';
import styled from 'styled-components';
import { media } from 'styled-bootstrap-grid';
import { routes } from 'constants/routes';
import useRouter from 'hooks/router';
import { HeaderContent } from '@components/common/layout/Layout';

const Layout: FC<any> = ({ children, ...props }) => {
    const router = useRouter();
    let _userData = JSON.parse(localStorage.getItem('user') || '{}')?.user?.business_id;

    const activeRoute = () => {
        for (let i = 0; i < routes?.length; i++) {
            if (routes[i].patterns) {
                for (let j = 0; j < routes[i].patterns!.length; j++) {
                    if (router.pathname === routes[i].patterns![j].path) {
                        return routes[i].patterns![j];
                    }
                }
            }
            if (router.pathname === routes[i].path) {
                return routes[i];
            }
        }
    };

    return (
        <Wrapper>
            <Aside />
            <PageContent margin={_userData?.leftMenu === true && true}>
                <HeaderContent>
                    <Header activeRoute={activeRoute()!} />
                </HeaderContent>
                <Content>{children}</Content>
            </PageContent>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow: auto;
    flex-direction: column !important;
    display: flex !important;
`;

const PageContent = styled.div<any>`
    flex-direction: column !important;
    flex-grow: 1 !important;
    display: flex !important;
    margin-left: ${({ margin }) => (margin ? ' 18.6rem' : '')};
    ${media.xs`
    margin-left: 0rem;
  `}
    ${media.sm`
    margin-left: 0rem;
  `}
    ${media.md`
    margin-left: 0rem;
  `}
    ${media.lg`
    margin-left: ${({ margin }: any) => (margin ? ' 18.6rem' : '')};
  `}
`;

const Content = styled.div`
    padding: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
`;

export default Layout;
