/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * EditServiceProvider Component
 * Description: This component allows the user to edit details of a service provider, including name, email, phone, password, short description, profile image, assigned services, and other settings such as custom durations, VIP status, and waiting list status. It supports updating the password and provides a preview of the current profile image.
 *
 * Props:
 * - clickCallBack: () => void - Callback function triggered after successfully updating the service provider.
 * - available: boolean - Indicates whether the service provider is available (online).
 * - defaultData: IServiceProvider - Default data for pre-filling the form when editing an existing service provider.
 *
 */

import React, { useEffect, useState } from 'react';
import { Input, LoaderOverlay, Switch, useSnackbar } from '@components/common';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import SelectServices from '../../selectServices/SelectServices';
import { IService } from 'interfaces/service.interface';
import { IServiceProvider } from 'interfaces/serviceProvider.interface';
import useRouter from 'hooks/router';
import { Col, Container, media, Row } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import Button from '@components/Button';
import { api } from 'helpers/auth-axios';
import EditSelectedServicesModel from './EditSelectedServicesModel';
import { FooterButton } from '@components/team/create/createServiceProvider/CreateServiceProvider';
import { useTranslation } from 'react-i18next';
import Compressor from 'compressorjs';
import { palette } from 'styled/common';
import MultiSelect from '@components/common/select/MultiSelect';

const EditServiceProvider = ({ clickCallBack, available, defaultData }: any) => {
    const { t }: any = useTranslation();
    const [openSnackbar] = useSnackbar();
    const router = useRouter();
    const [showSelect, setShowSelect] = useState(false);
    const [serviceProvider, setServiceProvider] = useState<IServiceProvider>();
    const [assignedServices, setAssignedServices] = useState<any[]>([]);
    const [duration, setDuration] = useState<boolean>();
    const [vip, setVip] = useState<boolean>();
    const [waitingList, setWaitingList] = useState<boolean>();
    const [editSelectedServicesModel, setEditSelectedServicesModel] = useState(false);
    const [editData, setEditData] = useState(null);
    const [profileImage, setProfileImage] = useState<any>();
    const [uploadImage, setUploadImage] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const serviceProviderRates = [
        { label: t('Hourly'), value: 'hourly' },
        { label: t('Daily'), value: 'daily' },
        { label: t('Complete Work'), value: 'complete work' },
    ];

    useEffect(() => {
        setUploadImage(false);
        if (defaultData) {
            setServiceProvider(defaultData);
            setValue('name', defaultData?.name);
            setValue('email', defaultData?.email);
            setValue('phone', defaultData?.phone);
            setAssignedServices(defaultData?.services);
            setValue('updatePassword', '');
            setValue('position', defaultData?.position);
            setValue('description', defaultData?.description);
            setProfileImage(defaultData?.photo);
            setVip(defaultData?.vip);
            setWaitingList(defaultData?.is_waiting);
            setValue(
                'rate_type',
                serviceProviderRates?.find((e: any) => e?.value == defaultData?.rate_type)
            );
            setValue('appointment_percenatege', defaultData?.appointment_percenatege);
        }
    }, [defaultData]);

    const validationSchema = Yup.object().shape(
        {
            name: Yup.string().required(t('This field is required')),
            email: Yup.string().email().required(t('This field is required')),
            rate_type: Yup.object().required(t('This field is required')),
            appointment_percenatege: Yup.number().min(1).required(t('This field is required')),
            phone: Yup.string()
                .min(9, t('Phone must be a valid phone'))
                .required(t('This field is required')),
            updatePassword: Yup.string().when('updatePassword', (val: any, schema: any) => {
                if (val?.length > 0) {
                    return Yup.string()
                        .required(t('This field is required'))
                        .min(8, t('Password must be at least 8 characters'));
                } else {
                    return Yup.string().notRequired();
                }
            }),
        },
        [['updatePassword', 'updatePassword']]
    );

    const { handleSubmit, setValue, getValues, formState, trigger } = useForm<IServiceProvider>({
        resolver: yupResolver(validationSchema),
        mode: 'onChange',
    });
    const { errors } = formState;

    async function onChange({ name, value }: { name: string; value: string }) {
        if (name === 'phone') {
            let phone = value;

            setValue(name as never, phone as never);
            await trigger(name as never);
        } else if (name === 'email') {
            setValue(name as never, value?.toLowerCase() as never);
            await trigger(name as never);
        } else {
            setValue(name as never, value as never);
            await trigger(name as never);
        }
    }

    const onSubmit = (data: IServiceProvider) => {
        setLoading(true);
        api.put(`/serviceProvider/${router.query.id as string}`, {
            ...data,
            vip: vip,
            is_waiting: waitingList,
            newPhoto: uploadImage == true ? profileImage : null,
            rate_type: data?.rate_type?.value,
            services: assignedServices.map(item => {
                return {
                    price: item.price,
                    service_id: item.service_id,
                    my_price: item.my_price,
                    vip_price: item.vip_price ? item.vip_price : item.my_price,
                    my_duration: item.my_duration,
                    duration: item.duration,
                    name: item.name,
                    color: item.color,
                    available_for_boooking: item.available_for_boooking,
                    is_video: item?.is_video,
                };
            }),
        })
            .then((res: any) => {
                setUploadImage(false);
                setLoading(false);
                clickCallBack();
                if (res) {
                    openSnackbar(t('Service Provider updated successfully!'));
                    router.navigate(`/team/service-provider-list/${res.data?._id}`);
                }
            })
            .catch((e: any) => {
                setLoading(false);
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    };

    const getServiceProviderById = () => {
        api.get(`/serviceProvider/${router.query.id as string}`)
            .then((serviceProvider: any) => {
                setServiceProvider(serviceProvider.data);
                setValue('name', serviceProvider.data.name);
                setValue('email', serviceProvider.data.email);
                setValue('phone', serviceProvider.data.phone);
                setAssignedServices(serviceProvider.data.services);
                setValue('updatePassword', '');
                setValue('position', serviceProvider.data.position);
                setValue('description', serviceProvider.data.description);
                setProfileImage(serviceProvider.data.photo);
                setVip(serviceProvider?.data?.vip);
                setWaitingList(serviceProvider?.data?.is_waiting);
            })
            .catch((e: any) => {
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    };

    const handleCapture = ({ target }: any) => {
        new Compressor(target.files[0], {
            quality: 0.6,
            success: res => {
                setUploadImage(true);
                const reader: any = new FileReader();
                reader.readAsDataURL(res);
                reader.onload = () => {
                    if (reader.readyState === 2) {
                        setProfileImage(reader.result);
                    }
                };
            },
        });
    };

    return (
        <>
            {loading && <LoaderOverlay />}
            <MainContainer>
                <CustomRow>
                    <Col lg={12}>
                        {serviceProvider && (
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Card>
                                    <FormContent>
                                        <Left>
                                            <Circle>
                                                <CircleLabel
                                                    htmlFor="faceImage"
                                                    aria-label="upload picture">
                                                    {available === true && (
                                                        <OnlineBadge></OnlineBadge>
                                                    )}
                                                    <Data
                                                        src={
                                                            uploadImage == true
                                                                ? profileImage
                                                                : `${process.env.REACT_APP_PROFILE_URL}${profileImage}`
                                                        }></Data>
                                                </CircleLabel>
                                                <FileInput
                                                    accept="image/jpeg/png"
                                                    id="faceImage"
                                                    type="file"
                                                    onChange={handleCapture}
                                                />
                                            </Circle>
                                            <InputField>
                                                <Input
                                                    label={t('Name')}
                                                    name="name"
                                                    value={getValues('name')}
                                                    onChange={onChange}
                                                    error={errors.name as any}
                                                />
                                            </InputField>
                                            <InputField>
                                                <Input
                                                    label={t('Email')}
                                                    name="email"
                                                    readOnly={true}
                                                    value={getValues('email')?.toLowerCase()}
                                                    onChange={onChange}
                                                    error={errors.email as any}
                                                />
                                            </InputField>
                                            <InputField>
                                                <Input
                                                    label={t('Phone')}
                                                    name="phone"
                                                    value={getValues('phone')}
                                                    onChange={onChange}
                                                    allowPhoneNumberOnly={true}
                                                    maxLength={14}
                                                    error={errors.phone as any}
                                                />
                                            </InputField>
                                            <InputField>
                                                <MultiSelect
                                                    options={serviceProviderRates}
                                                    label="Choose work type"
                                                    value={getValues('rate_type')}
                                                    name="rate_type"
                                                    onChange={(e: any) =>
                                                        onChange({ name: 'rate_type', value: e })
                                                    }
                                                    error={errors?.rate_type as any}
                                                />
                                            </InputField>
                                            <InputField>
                                                <Input
                                                    allowOnlyNumbersAndDecimal={true}
                                                    label={
                                                        getValues('rate_type') &&
                                                        getValues('rate_type')?.value == 'daily'
                                                            ? t('Daily Rate')
                                                            : t('Percentage on appointments')
                                                    }
                                                    name="appointment_percenatege"
                                                    value={getValues('appointment_percenatege')}
                                                    onChange={onChange}
                                                    error={errors.appointment_percenatege as any}
                                                />
                                            </InputField>

                                            <InputField>
                                                <Input
                                                    label={t('Password')}
                                                    type={t('Password')}
                                                    name="updatePassword"
                                                    value={getValues('updatePassword')}
                                                    onChange={onChange}
                                                    error={errors.updatePassword as any}
                                                />
                                            </InputField>

                                            <InputField>
                                                <Input
                                                    label={t('Short Description')}
                                                    name="description"
                                                    value={serviceProvider.description}
                                                    onChange={onChange}
                                                />
                                            </InputField>
                                            <CustomToggle>
                                                <ToggleButton>
                                                    <CustomSpan>{t('Custom Durations')}</CustomSpan>
                                                    <Switch
                                                        value={duration}
                                                        onChange={() => {
                                                            setDuration(!duration);
                                                        }}
                                                        isSmall={true}
                                                    />
                                                </ToggleButton>
                                                <ToggleButton>
                                                    <CustomSpan>{t('VIP')}</CustomSpan>
                                                    <Switch
                                                        value={vip}
                                                        onChange={() => {
                                                            setVip(!vip);
                                                        }}
                                                        isSmall={true}
                                                    />
                                                </ToggleButton>
                                                <ToggleButton>
                                                    <CustomSpan>{t('Waiting List')}</CustomSpan>
                                                    <Switch
                                                        value={waitingList}
                                                        onChange={() => {
                                                            setWaitingList(!waitingList);
                                                        }}
                                                        isSmall={true}
                                                    />
                                                </ToggleButton>
                                            </CustomToggle>
                                            <div style={{ marginTop: '1rem' }}>
                                                <FooterButton>
                                                    <Button
                                                        bgtype={'secondary'}
                                                        type={'submit'}
                                                        label={t('Update')}></Button>
                                                </FooterButton>
                                            </div>
                                        </Left>
                                    </FormContent>
                                </Card>
                            </Form>
                        )}
                        {showSelect && (
                            <SelectServices
                                selectedServices={assignedServices.map(service => {
                                    return {
                                        price: service.price,
                                        _id: service.service_id,
                                        my_price: service.my_price,
                                        my_duration: service.my_duration,
                                        duration: service.duration,
                                        name: service.name,
                                        color: service.color,
                                        business_id: service.business_id,
                                        available_for_boooking: service.available_for_boooking,
                                        tax_rate: service.tax_rate,
                                        updated_at: service.updated_at,
                                        created_at: service.created_at,
                                        description: service.description,
                                        is_video: service?.is_video,
                                        __v: service.__v,
                                    };
                                })}
                                modelStatus={'editservice'}
                                onClose={() => {
                                    setShowSelect(false);
                                }}
                                onSelect={(services: IService[]) => {
                                    const data = services.map(item => {
                                        return {
                                            price: item.price,
                                            service_id: item._id,
                                            _id: item._id,
                                            my_price: item.my_price,
                                            my_duration: item.my_duration,
                                            duration: item.duration,
                                            name: item.name,
                                            color: item.color,
                                            available_for_boooking: item.available_for_boooking,
                                            is_video: item?.is_video,
                                        };
                                    });
                                    for (
                                        let index = 0;
                                        index < serviceProvider!.services?.length;
                                        index++
                                    ) {
                                        for (let ind = 0; ind < data?.length; ind++) {
                                            if (
                                                serviceProvider?.services[index].service_id ==
                                                data[ind].service_id
                                            ) {
                                                data[ind] = serviceProvider?.services[index];
                                            }
                                        }
                                    }
                                    setAssignedServices(data);
                                }}
                            />
                        )}

                        {editSelectedServicesModel ? (
                            <EditSelectedServicesModel
                                editData={editData}
                                onClose={() => {
                                    setEditSelectedServicesModel(false);
                                    getServiceProviderById();
                                }}
                            />
                        ) : (
                            ''
                        )}
                    </Col>
                </CustomRow>
            </MainContainer>
        </>
    );
};

const MainContainer = styled(Container)`
    width: 100%;
`;

const CustomRow = styled(Row)`
    display: flex;
    justify-content: center;
`;

const Form = styled.form``;

const Card = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;
    border-radius: 0.42rem;
    border: 0;
`;

const FormContent = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    ${media.xs`
display: block !important;
`}
    ${media.sm`
  display: block;
`}
  ${media.md`
  display: flex;
`}
${media.lg`
  display: flex;
`}
`;

const Left = styled.div`
    padding: 1.71rem;
    flex-basis: 28.57rem;
`;

export const Right = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

const Circle = styled.div`
    justify-content: center !important;
    display: flex !important;
    margin-bottom: 3.5rem /* 24px */;
`;

const CircleLabel = styled.label`
    display: inline-block;
    flex-shrink: 0;
    position: relative;
    border-radius: 0.42rem;
    width: 3.14rem;
    height: 3.14rem;
`;

const Data = styled.img`
    background: linear-gradient(-135deg, ${palette.grey.light}, ${palette.grey.lightest});
    color: ${palette.grey.dark_50};
    width: 5.42rem;
    height: 5.42rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    line-height: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-radius: 0.42rem;
    font-size: 1.21rem;
    text-transform: capitalize !important;
    border-radius: 50%;
    cursor: pointer;
`;

const InputField = styled.div`
    margin-bottom: 1.71rem;
`;

export const TabsContent = styled.div`
    padding: 1.71rem;
    flex: 1;
`;

export const ServicesContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ServicesWrapper = styled.div`
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
`;

export const Services = styled.div`
    border-radius: 0.5rem;
    padding: 1.85rem 1.42rem;
    position: relative;
    cursor: pointer !important;
`;

export const Label = styled.div`
    background-color: none;
    padding: 0.14rem 0.42rem 0.14rem 0.14rem;
    display: inline-block;
    border-radius: 3.57rem;
    font-size: 0.85rem;
    font-weight: 800;
    color: #000;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0.42rem;
    right: 0.42rem;
`;

export const Name = styled.div`
    font-weight: 500;
    font-size: 1.2rem;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
`;

export const ServiceDuration = styled.div`
    font-size: 0.9rem /* 14.4px */;
`;

export const EditButton = styled.div`
    margin-top: 2rem /* 32px */;
    justify-content: flex-end;
    display: flex;
`;

export const EditServiceButton = styled.button`
    padding: 0.92rem 1.85rem;
    font-weight: 600;
    text-transform: capitalize;
    color: #fff;
    background: #141923;
    border-radius: 0.57rem;
    font-size: 0.92rem;
    border-width: 0.07rem !important;
    padding: 0.92rem 1.85rem;
    cursor: pointer;
`;

export const AddService = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
`;

export const Image = styled.img`
    width: 70%;
    height: auto;
`;

export const ToggleButton = styled.div`
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: column;
`;

export const CustomSpan = styled.span`
    font-size: 0.9rem;
    margin: 0.4rem;
`;

export const CustomToggle = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const SetCustom = styled.div`
    // width: 3rem;
    text-align: center;
    background: none;
    border: 1px solid #2e3648;
    padding: 0.14rem 0.42rem 0.14rem 0.14rem;
    display: inline-block;
    border-radius: 3.57rem;
    font-size: 0.85rem;
    font-weight: 500;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0.4rem;
    left: 0.4rem;
    z-index: 2;
`;

export const LabelMyPrice = styled.div`
    padding: 0.14rem 0.42rem 0.14rem 0.14rem;
    display: inline-block;
    font-size: 0.85rem;
    font-weight: 800;
    justify-content: flex-end;
    color: #2e3648;
    display: flex;
    margin-right: -1rem;
`;

export const FileInput = styled.input`
    display: none;
`;

const OnlineBadge = styled.div`
    width: 1rem;
    height: 1rem;
    background-color: green;
    border-radius: 50%;
    position: absolute;
    right: -2rem;
    bottom: -1.7rem;
`;

export default EditServiceProvider;
