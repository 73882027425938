/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * PromotionOptions Component
 * Description: This component serves as a page for managing promotion options.
 * It includes the PromotionsTabs component to display tabs for different promotion settings.
 *
 */

import { Layout } from '@components/common';
import PromotionsTabs from '@components/settings/settingsOptions/PromotionTabs';

const PromotionOptions = () => {
    return (
        <>
            <PromotionsTabs />
        </>
    );
};
PromotionOptions.Layout = Layout;
export default PromotionOptions;
