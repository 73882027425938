/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Component for displaying reported appointments by customers.
 *
 * Component Description:
 * - Fetches and displays reported customer appointments from the API.
 * - Utilizes MDBDataTableV5 for a responsive and interactive table.
 * - Displays loading overlay while fetching data.
 * - Allows navigation to the calendar for a specific appointment.
 *
 */

import React, { useEffect, useState } from 'react';
import {
    CloseButton,
    NotFound,
} from '../components/team/list/serviceProviderList/ServiceProviderList';
import styled from 'styled-components';
import 'bootstrap-css-only/css/bootstrap.min.css';
import { MDBDataTableV5 } from 'mdbreact';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Header, Layout, LoaderOverlay, useSnackbar } from '@components/common';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import { HeaderContent } from '@components/common/layout/Layout';
import { api } from 'helpers/auth-axios';
import { useNavigate } from 'react-router-dom';
const AppointmentReports = () => {
    const { t }: any = useTranslation();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [openSnackbar] = useSnackbar();
    const navigate = useNavigate();
    var _userData = JSON.parse(localStorage.getItem('user') || '{}')?.user?.business_id;
    useEffect(() => {
        setLoading(true);
        api.get(`/report/get-all-reports`)
            .then((res: any) => {
                setData(res.data);
                setLoading(false);
            })
            .catch((e: any) => {
                setLoading(false);
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    }, []);

    const [tableData, setTableData] = useState({
        columns: [
            {
                label: t('Customer Name'),
                field: 'customer',
                width: 470,
                attributes: { 'aria-controls': 'DataTable', 'aria-label': 'Name' },
            },
            {
                label: t('Service Provider'),
                field: 'serviceProvider',
                width: 470,
                sort: String('disabled'),
            },
            {
                label: t('Service'),
                field: 'service',
                width: 470,
                sort: String('disabled'),
            },
            {
                label: t('Appointment Time'),
                field: 'booked_from_iso',
                width: 470,
                sort: String('disabled'),
            },
            { label: t('Reason'), field: 'description', width: 470, sort: String('disabled') },
        ],
        rows: [],
    });
    const createTable = (row: any) => {
        let _obj = {
            customer: <Text>{row?.customerId?.fullname}</Text>,
            serviceProvider: <Text>{row?.serviceProvider?.name}</Text>,
            service: <Text>{row?.service?.name}</Text>,
            booked_from_iso: (
                <Text>
                    {moment(row?.appointmentId?.booked_from_iso).format('YYYY-MM-DD')} &nbsp;{' '}
                    {moment(row?.appointmentId?.booked_from_iso).format('HH:mm')}
                </Text>
            ),
            description: <Text>{row?.description}</Text>,
            clickEvent: () => navigate(`/calendar?appointment=${row?.appointmentId?._id}`),
        };
        setTableData((preValue): any => {
            return {
                columns: [...preValue.columns],
                rows: [...preValue.rows, _obj],
            };
        });
    };
    useEffect(() => {
        if (data) {
            data.map((val: any) => createTable(val));
        }
    }, [data]);
    return (
        <>
            {loading && <LoaderOverlay />}
            <Container>
                <Row>
                    <Col>
                        <HeaderContent display={true}>
                            <HeaderCustom>
                                {_userData?.leftMenu === true && (
                                    <CloseButton to="/customer" style={{ marginBottom: '0.5rem' }}>
                                        <i className="fal fa-chevron-left"></i>
                                    </CloseButton>
                                )}
                                <Header
                                    activeRoute={{
                                        title: t('Customer Reported Appointments'),
                                        icon: 'fad fa-exclamation-triangle',
                                        path: '/',
                                        smallScreen: true,
                                    }}
                                />
                            </HeaderCustom>
                        </HeaderContent>
                        {loading && <LoaderOverlay />}
                        {data?.length ? (
                            <MDBDataTableV5
                                data={tableData}
                                entries={20}
                                pagesAmount={40}
                                noBottomColumns={true}
                                searchTop={false}
                                searchBottom={false}
                                responsive={true}
                                striped={true}
                            />
                        ) : (
                            <CustomDiv>
                                <NotFound>{t('No Record Found!')}</NotFound>
                            </CustomDiv>
                        )}
                    </Col>
                </Row>
            </Container>
        </>
    );
};
const CustomDiv = styled.div`
    margin-top: 2rem;
`;
const Text = styled.div``;
AppointmentReports.Layout = Layout;
const HeaderCustom = styled.div`
    display: flex;
    align-items: center;
`;
export default AppointmentReports;
