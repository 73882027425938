/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Busienss Service service
 * Description: This service handles API requests related to  Busienss Service and to create/update/get Busienss Service.
 */

import { IService } from 'interfaces/service.interface';
import { api } from '../helpers/auth-axios';

export const serviceService = {
    create,
    getServices,
    updateService,
};

function create(serviceData: IService): Promise<IService> {
    return api.post('/services', serviceData).then(res => {
        return res.data;
    });
}

function getServices(): Promise<IService[]> {
    return api.get('/services').then(res => {
        return res.data;
    });
}

function updateService(id: string, service: IService): Promise<IService> {
    return api.put(`/services/${id}`, service).then(res => {
        return res.data;
    });
}
