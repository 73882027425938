/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Checkout Component
 * Description: React component for handling the checkout process. Includes steps for selecting services/asset, payment methods, and completing the transaction.
 *
 * Components:
 * - BasketSidebar: Component for displaying the basket sidebar during the checkout process.
 * - SelectServices: Component for selecting assets during the checkout process.
 * - PaymentMethods: Component for selecting payment methods during the checkout process.
 * - LoaderOverlay: Component for displaying a loading overlay.
 * - NewCreditCard: Component for adding a new credit card for Stripe payments.
 * - CreateAssetService: Component for creating and managing assets related to services.
 *
 * Purpose: This component serves the purpose of managing the checkout process, including selecting assets, payment methods, and completing the transaction.
 */

import { useCallback, useState } from 'react';
import { ICustomer } from 'interfaces/customer.interface';
import { IService } from 'interfaces/service.interface';
import styled from 'styled-components';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import BasketSidebar from './BasketSidebar';
import SelectAssets from './SelectAssets';
import PaymentMethods from './PaymentMethods';
import { useEffect } from 'react';
import { api } from 'helpers/auth-axios';
import finishImg from 'images/finish-success.svg';
import { LoaderOverlay, useSnackbar } from '@components/common';
import NewCreditCard from '@components/settings/businesBilling/creditCard';
import useRouter from 'hooks/router';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Button from '@components/Button';
import CreateAssetService from './assets/CreateAssetService';
import { palette } from 'styled/common';
import { BusinessServices } from '@components/settings';
import EditService from '@components/settings/businessServices/EditService';
import CreateService from '@components/settings/businessServices/CreateService';
import PackagesService from '@components/settings/PromotionsAndGiftCard/PackagesService';
import NewAppointmentDrawerForCustomer from '@components/appointments/editAppointmentDrawer/NewAppointmentDrawerForCustomer';

const Checkout = () => {
    const { t }: any = useTranslation();
    const [finished, setFinished] = useState(false);
    const [loading, setLoading] = useState(false);
    const [step, setStep] = useState(1);
    const [subtotal, setSubtotal] = useState<number>(0);
    const [total, setTotal] = useState<number>(0);
    const [paymentMethod, setPaymentMethod] = useState({
        code: 'cash',
        label: 'Cash',
    });
    const [gratuity, setGratuity] = useState<any>({ label: 'No Tip', value: 0 });
    const [selectedCustomer, setSelectedCustomer] = useState<any>();
    const [selectedServices, setSelectedServices] = useState<any[]>([]);
    const [openCard, setOpenCard] = useState<boolean>(false);
    const [paymentIntentId, setPaymentIntentId] = useState<any>(null);
    const [paymentMethodId, setPaymentMethodId] = useState<any>(null);
    const [business, setBusiness] = useState<any>();
    const [openSnackbar] = useSnackbar();
    const [customTip, setCustomTip] = useState<any>();
    const router = useRouter();
    let _userData = JSON.parse(localStorage.getItem('user') || '{}');
    const [createAsset, setCreateAssets] = useState<any>(false);
    const [createService, setCreateService] = useState<any>(false);
    const [createPackage, setCreatePackage] = useState<any>(false);
    const [loadAssets, setLoadAssets] = useState<any>(false);
    const [editAssets, setEditAsset] = useState<any>();
    const [tabValue, setTabValue] = useState(0);
    const [showEdit, setShowEdit] = useState(false);
    const [selectedService, setSelectedService] = useState<any>();
    const [selectedPackage, setSelectedPackage] = useState<any>();

    useEffect(() => {
        setCreateAssets(false);
        setCreatePackage(false);
        setCreateService(false);
        setSelectedServices([]);
        setSelectedCustomer('');
    }, [tabValue]);

    useEffect(() => {
        setTotal(gratuity.value ? parseFloat(gratuity.value) + subtotal : 0 + subtotal);
        let serviceTotal = selectedServices.reduce(
            (a, b) => a + parseInt(b.price) * b?.newQuantity,
            0
        );

        setSubtotal(
            paymentMethod !== undefined &&
                (paymentMethod.code === 'paypal' || paymentMethod.code === 'american_express')
                ? serviceTotal + 1
                : serviceTotal
        );
    }, [subtotal, gratuity, customTip, paymentMethod, selectedServices]);

    useEffect(() => {
        getBusiness();
    }, []);

    // When both the payment intent and the payment method are gathered
    // make the actual transaction
    useEffect(() => {
        if (paymentIntentId !== null && paymentMethodId !== null) {
            const customerData = {
                firstname: selectedCustomer?.firstname,
                lastname: selectedCustomer?.lastname,
                email: selectedCustomer?.email,
                phone: selectedCustomer?.phone,
            };
            setLoading(true);
            api.post('/appointments/payment/pay', {
                stripe_account_id: business && business.stripe_account_id,
                payment_intent_id: paymentIntentId,
                payment_method_id: paymentMethodId,
                appointment_details: {
                    sale: 'sale',
                    booking_without_payment: false,
                    stripe_account_verified: business && business.stripe_account_verified,
                },
                customer_details: customerData,
                transaction_details: transaction_details,
            })
                .then(res => {
                    if (res) {
                        setLoading(false);
                        setSelectedServices([]);
                        setSelectedCustomer('');
                        setSubtotal(0);
                        setGratuity({ label: 'No Tip', value: 0 });
                        setPaymentMethod({
                            code: 'cash',
                            label: 'Cash',
                        });
                        setFinished(false);
                        setOpenCard(false);
                        setPaymentIntentId(null);
                        setPaymentMethodId(null);
                        setStep(3);
                        openSnackbar(t('Payed successfully!'));
                    }
                })
                .catch((e: any) => {
                    setLoading(false);
                    if (e?.response) {
                        openSnackbar(e?.response?.data?.message);
                    }
                });
        }
    }, [paymentMethodId, paymentIntentId]);

    const onBack = () => {
        setStep(prevValue => prevValue - 1);
    };

    const onNext = () => {
        setStep(prevValue => prevValue + 1);
    };

    const handlePaymentGratuity = (
        method: { code: string; label: string },
        gratuity: { label: string; value: number }
    ) => {
        setGratuity(gratuity);
        setPaymentMethod(method);
    };

    const transaction_details = {
        customer: selectedCustomer?._id,
        total: _userData.user.business_id.currency + total.toFixed(2),
        total_value: total.toFixed(2),
        items: selectedServices.map(service => {
            return {
                service: tabValue == 1 ? service : undefined,
                asset: tabValue == 0 ? service : undefined,
                package: tabValue == 2 ? service : undefined,
                price: _userData.user.business_id.currency + service.price,
                price_value: service.price,
                quantity: service?.quantity,
                service_name: service.name,
                service_id: tabValue == 1 ? service._id : undefined,
                tax_rate: service.tax_rate,
                asset_id: tabValue == 0 ? service._id : undefined,
                package_id: tabValue == 2 ? service._id : undefined,
                total_sold: service.total_sold,
                newQuantity: service?.newQuantity,
            };
        }),
        payments: [
            {
                amount: _userData.user.business_id.currency + total.toFixed(2),
                amount_value: total.toFixed(2),
                payment_method: paymentMethod,
                tip: gratuity.value ? gratuity.value : 0,
            },
        ],
        payment_method: paymentMethod,
        tip: {
            amount:
                _userData.user.business_id.currency +
                (gratuity.value ? parseFloat(gratuity.value).toFixed(2) : 0),
            amount_value: gratuity.value ? parseFloat(gratuity.value).toFixed(2) : 0,
            label: gratuity.label,
        },
    };

    const handleCharge = () => {
        setLoading(true);
        api.post('/transactions', transaction_details)
            .then(res => {
                if (res) {
                    setLoading(false);
                    setFinished(true);
                    openSnackbar(t('Transaction successfully!'));
                    router.navigate(`/sales`);
                    setSelectedServices([]);
                    setSelectedCustomer('');
                    setSubtotal(0);
                    setGratuity({ label: 'No Tip', value: 0 });
                    setPaymentMethod({
                        code: 'cash',
                        label: 'Cash',
                    });
                    setFinished(false);
                    setStep(3);
                }
            })
            .catch((e: any) => {
                setLoading(false);
                openSnackbar(t('Failed!'));
            });
    };

    const createPaymentIntent = useCallback(
        (customerData: any, total: any) => {
            if (!_userData || !selectedServices) return;
            let subTotal: any = total * 100;
            api.post('/billing/payment-intent', {
                stripe_account_id: business && business.stripe_account_id,
                amount: parseFloat(subTotal),
                customer_details: {
                    firstname: customerData.firstname,
                    lastname: customerData.lastname,
                    email: customerData.email,
                    phone: customerData.phone,
                },
                description: {
                    service_name: selectedServices.map(service => service.name),
                    staff_name: customerData.firstname + ' ' + customerData.lastname,
                    date: moment().format('YYYY-MM-DD'),
                    service_tip: gratuity,
                },
            })
                .then(res => {
                    const data = res.data as any;
                    setPaymentIntentId(data.payment_intent_id);
                    setStep(prevVal => prevVal + 1); // move to next step
                })
                .catch((e: any) => {
                    if (e?.response) {
                        openSnackbar(e?.response?.data?.message);
                    }
                });
        },
        [selectedServices]
    );

    const getBusiness = () => {
        api.get(`/businesses/${_userData?.user?.business_id?._id}`).then((res: any) => {
            if (res) {
                setBusiness(res.data);
            }
        });
    };

    const onSelectService = (service: IService) => {
        setSelectedService(service);
        setShowEdit(true);
    };

    return (
        <>
            <TabMainContainer fluid>
                <TabWrapper>
                    <Tab
                        col={4}
                        active={tabValue === 0 && true}
                        onClick={() => {
                            setTabValue(0);
                            setSelectedPackage('');
                            setSelectedService('');
                        }}>{`Assets`}</Tab>
                    <Tab
                        col={4}
                        active={tabValue === 1 && true}
                        onClick={() => {
                            setTabValue(1);
                            setSelectedPackage('');
                            setSelectedService('');
                        }}>{`${t('Services')}`}</Tab>
                    <Tab
                        col={4}
                        active={tabValue === 2 && true}
                        onClick={() => {
                            setTabValue(2);
                            setSelectedPackage('');
                            setSelectedService('');
                        }}>{`${t('Packages')}`}</Tab>
                </TabWrapper>
            </TabMainContainer>

            <MainContainer>
                {loading && <LoaderOverlay />}
                {createPackage && tabValue == 2 ? (
                    <PackagesService onClose={() => setCreatePackage(false)} />
                ) : createAsset && tabValue == 0 ? (
                    <CreateAssetService
                        onClose={() => {
                            setCreateAssets(false);
                            setEditAsset('');
                        }}
                        handleAdd={() => {
                            setLoadAssets(true);
                            setCreateAssets(false);
                            setEditAsset('');
                        }}
                        editAssets={editAssets}
                    />
                ) : (
                    !finished && (
                        <Wrapper>
                            {openCard ? (
                                business && business.stripe_account_verified ? (
                                    <NewCreditCard
                                        buttonTitle={`${
                                            loading == true
                                                ? t('Processing please wait')
                                                : t('Charge')
                                        }`}
                                        stripeAccountId={business && business.stripe_account_id}
                                        onSave={(paymentMethodId: string) => {
                                            setPaymentMethodId(paymentMethodId);
                                        }}
                                    />
                                ) : (
                                    <div>Please Connect your account with stripe first!</div>
                                )
                            ) : (
                                <Row>
                                    {step == 1 && (
                                        <>
                                            {tabValue == 1 ? (
                                                showEdit ? (
                                                    <EditService
                                                        service={selectedService}
                                                        onClose={() => setShowEdit(false)}
                                                    />
                                                ) : createService ? (
                                                    <CreateService
                                                        onCreate={() => setCreateService(false)}
                                                    />
                                                ) : (
                                                    <>
                                                        <Col lg={8}>
                                                            <BusinessServices
                                                                onSelectService={(e: any) =>
                                                                    setSelectedService(e)
                                                                }
                                                                createService={() =>
                                                                    setCreateService(true)
                                                                }
                                                                isSale={true}
                                                                isEdit={onSelectService}
                                                                selectedService={selectedService}
                                                            />
                                                        </Col>
                                                        <Col lg={4}>
                                                            <NewAppointmentDrawerForCustomer
                                                                isSale={true}
                                                                isSelectedService={selectedService}
                                                                onCreate={() =>
                                                                    setSelectedService('')
                                                                }
                                                            />
                                                        </Col>
                                                    </>
                                                )
                                            ) : tabValue == 2 ? (
                                                <>
                                                    <>
                                                        <Col lg={8}>
                                                            <PackagesService
                                                                onClose={() =>
                                                                    setCreateService(false)
                                                                }
                                                                onSelectPackage={(e: any) =>
                                                                    setSelectedPackage(e)
                                                                }
                                                            />
                                                        </Col>
                                                        <Col lg={4}>
                                                            <NewAppointmentDrawerForCustomer
                                                                isSale={true}
                                                                isPackage={true}
                                                                isSelectedService={selectedService}
                                                                selectedPackage={selectedPackage}
                                                                onCreate={() =>
                                                                    setSelectedPackage('')
                                                                }
                                                            />
                                                        </Col>
                                                    </>
                                                </>
                                            ) : (
                                                <Col md={7} lg={tabValue == 0 ? 8 : 12}>
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            alignItems: 'center',
                                                        }}>
                                                        <TitleBody>
                                                            &nbsp; &nbsp;
                                                            <Heading>Add New Sale</Heading>
                                                        </TitleBody>

                                                        {tabValue == 0 ? (
                                                            <Button
                                                                bgtype={'secondary'}
                                                                ifClicked={() =>
                                                                    setCreateAssets(true)
                                                                }
                                                                label={t('Create Assets')}></Button>
                                                        ) : tabValue == 1 ? (
                                                            <Button
                                                                bgtype={'secondary'}
                                                                ifClicked={() =>
                                                                    setCreateService(true)
                                                                }
                                                                label={t(
                                                                    'Create Service'
                                                                )}></Button>
                                                        ) : tabValue == 2 ? (
                                                            <Button
                                                                bgtype={'secondary'}
                                                                ifClicked={() =>
                                                                    setCreatePackage(true)
                                                                }
                                                                label={t(
                                                                    'Create Package'
                                                                )}></Button>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </div>

                                                    <SelectAssets
                                                        selectedAsset={selectedServices}
                                                        onSelect={(services: IService[]) => {
                                                            setSelectedServices(services);

                                                            setGratuity({
                                                                label: 'No Tip',
                                                                value: 0,
                                                            });
                                                        }}
                                                        onClose={() => {}}
                                                        onEdit={e => {
                                                            setEditAsset(e);
                                                            setCreateAssets(true);
                                                        }}
                                                        setLoadAssets={setLoadAssets}
                                                        loadAssets={loadAssets}
                                                        tabValue={tabValue}
                                                    />
                                                </Col>
                                            )}

                                            {tabValue == 0 ? (
                                                <BasketSidebar
                                                    step={step}
                                                    onNext={onNext}
                                                    customer={selectedCustomer}
                                                    items={selectedServices}
                                                    subtotal={subtotal}
                                                    gratuity={gratuity.value}
                                                    onSelectCustomer={(customer: ICustomer) => {
                                                        setSelectedCustomer(customer);
                                                    }}
                                                    setSelectedServices={setSelectedServices}
                                                    onCharge={() => ''}
                                                />
                                            ) : (
                                                ''
                                            )}
                                        </>
                                    )}{' '}
                                    {step == 2 && tabValue == 0 && (
                                        <>
                                            <PaymentMethods
                                                subtotal={subtotal}
                                                gratuity={gratuity}
                                                paymentMethod={paymentMethod}
                                                customTip={(val: any) => setCustomTip(val)}
                                                onBack={onBack}
                                                onChange={handlePaymentGratuity}
                                            />
                                            <BasketSidebar
                                                step={step}
                                                onNext={onNext}
                                                customer={selectedCustomer}
                                                items={selectedServices}
                                                subtotal={subtotal}
                                                gratuity={gratuity.value}
                                                paymentMethod={paymentMethod?.code}
                                                onSelectCustomer={(customer: ICustomer) => {
                                                    setSelectedCustomer(customer);
                                                }}
                                                setSelectedServices={setSelectedServices}
                                                onCharge={() => {
                                                    if (
                                                        business &&
                                                        business.stripe_account_verified == true &&
                                                        (paymentMethod.code == 'paypal' ||
                                                            paymentMethod.code ==
                                                                'american_express')
                                                    ) {
                                                        setOpenCard(true);
                                                        createPaymentIntent(
                                                            selectedCustomer,
                                                            total
                                                        );
                                                    } else if (
                                                        paymentMethod.code == 'cash' ||
                                                        'cheque' ||
                                                        'gift_card'
                                                    ) {
                                                        handleCharge();
                                                    }
                                                }}
                                            />
                                        </>
                                    )}
                                    {step == 3 && tabValue == 0 && (
                                        <CheckoutSuccess>
                                            <Image src={finishImg} alt="Finished" />
                                            <SuccessTitle>{t('Checkout complete!')}</SuccessTitle>

                                            <Button
                                                bgtype={'secondary'}
                                                ifClicked={() => setStep(1)}
                                                label={t('Go Back')}></Button>
                                        </CheckoutSuccess>
                                    )}
                                </Row>
                            )}
                        </Wrapper>
                    )
                )}
            </MainContainer>
        </>
    );
};

const TabMainContainer = styled(Container)`
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 1px) and (max-width: 900px) {
        padding: 1rem;
    }
`;

const TabWrapper = styled.div`
    color: #8c8b88;
    background-color: #fafafa;
    min-height: 36px;
    border-radius: 8px;
    padding: 4px;
    display: flex;
    align-items: stretch;
    cursor: pointer;
    position: relative;
    font-size: 13px;
    justify-content: space-evenly;
    margin-bottom: 1rem;
`;
const Wrapper = styled(Container)`
    max-width: 100%;
`;

const MainContainer = styled.div`
    width: 100%;
    position: relative;
    height: 100%;
`;

const TitleBody = styled.div`
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom: 1.75rem;
`;

const Heading = styled.div`
    font-size: 25px;
    font-weight: 600;
`;

const CheckoutSuccess = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 300px;
    margin: 0 auto;
`;

const Image = styled.img`
    max-width: 220px;
`;

const SuccessTitle = styled.div`
    font-weight: 800;
    font-size: 27px;
`;

const Tab = styled(Col)<any>`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.9rem;
    border-radius: 0.2rem;
    font-weight: 500;
    background-color: ${({ active }) => (active ? palette.secondary : '')};
    color: ${({ active }) => (active ? palette.light : '')};
`;

export default Checkout;
