/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Billing Component for managing subscription plans and credit card information.
 *
 * Component Description:
 * - Displays a list of subscription plans using the Pricing component.
 * - Allows users to subscribe to a plan and manage credit card information.
 * - Provides a link to navigate back to the settings page.
 *
 */

import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import { Link } from 'react-router-dom';
import { Layout } from '@components/common';
import Pricing from '@components/settings/businesBilling/pricing/Pricing';
import { useState } from 'react';
import NewCreditCard from '@components/settings/businesBilling/creditCard';
import { useTranslation } from 'react-i18next';

export default function Billing() {
    const [showCard, setShowCard] = useState(false);
    let _userData = JSON.parse(localStorage.getItem('user') || '{}')?.user?.business_id;
    const { t } = useTranslation();

    return (
        <Wrapper>
            <Row>
                <Column lg={12}>
                    {_userData?.leftMenu === true && (
                        <Link to="/settings">
                            <Icon className="fal fa-chevron-left"></Icon>
                        </Link>
                    )}
                    <Header>{t('A Plan for Everyone')}</Header>
                </Column>
            </Row>
            {showCard ? (
                <NewCreditCard
                    buttonTitle={t('Subscribe')}
                    stripeAccountId={''}
                    onSave={() => ''}
                />
            ) : (
                <Pricing onSubscribe={() => setShowCard(true)} />
            )}
        </Wrapper>
    );
}

const Wrapper = styled(Container)``;

const Column = styled(Col)`
    display: flex;
    align-items: center;
    margin-bottom: 2rem !important;
`;

const Header = styled.h1`
    font-size: 1.875rem;
    font-weight: 600;
    margin-top: 1rem;
`;

const Icon = styled.i`
    font-size: 1.75rem !important;
    line-height: 2.25rem !important;
    margin-right: 1.25rem !important;
`;

Billing.Layout = Layout;
