/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * LoyaltyDiscount Component
 * Description: This component allows users to manage loyalty discounts, providing fields to set the loyalty discount percentage and the number of loyalty discount appointments.
 * It includes functionalities such as updating loyalty discount settings for a business.
 *
 */

import { useEffect, useState } from 'react';
import Button from '@components/Button';
import styled from 'styled-components';
import { Container, Row, Col, media } from 'styled-bootstrap-grid';
import { Input, Layout, LoaderOverlay, Select, useSnackbar } from '@components/common';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { palette } from '../../../styled/common';
import { api } from 'helpers/auth-axios';
import { CustomButtom, Divider } from '../businessDetails/BusinessDetails';
import { CustomRow } from '@components/team/create/createTeamMember/CreateTeamMember';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const LoyalityDiscount = () => {
    const { t }: any = useTranslation();
    const [openSnackbar] = useSnackbar();

    const validationSchema = Yup.object().shape({
        loyality_discount: Yup.string()
            .required(t('This field is required'))
            .test(t('is-less-than-100'), t('Value must not be greater than 100%'), (value: any) => {
                const numericValue = parseFloat(value);
                return isNaN(numericValue) || numericValue <= 100;
            }),

        loyality_appointments: Yup.string().required(t('This field is required')),
    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { handleSubmit, setValue, getValues, formState, trigger, clearErrors } =
        useForm(formOptions);
    const { errors } = formState;
    const [loading, setLoading] = useState(false);
    let _userData = JSON.parse(localStorage.getItem('user') || '{}');
    const [business, setBusiness] = useState<any>();

    useEffect(() => {
        getBusiness();
    }, []);

    useEffect(() => {
        if (business) {
            setValue('loyality_discount' as never, business?.loyality_discount as never);
            setValue('loyality_appointments' as never, business?.loyality_appointments as never);
        }
    }, [business]);

    async function onChange({ name, value }: { name: any; value: any }) {
        setValue(name as never, value as never);
        await trigger(name as never);
    }

    const onSubmit = (data: any) => {
        setLoading(true);
        if (_userData?.user?.business_id?._id) {
            api.put(`businesses/${_userData?.user?.business_id?._id!}`, data)
                .then((res: any) => {
                    setLoading(false);

                    getBusiness();
                    openSnackbar(t('Updated successfully!'));
                })
                .catch((e: any) => {
                    setLoading(false);
                    if (e?.response) {
                        openSnackbar(e?.response?.data?.message);
                    }
                });
        }
    };

    const getBusiness = async () => {
        setLoading(true);
        await api
            .get(`/businesses/${_userData?.user?.business_id?._id}`)
            .then((res: any) => {
                if (res) {
                    setBusiness(res?.data);
                    setLoading(false);
                    setValue('loyality_discount' as never, res?.data?.loyality_discount as never);
                    setValue(
                        'loyality_appointments' as never,
                        res?.data?.loyality_appointments as never
                    );
                }
            })
            .catch((e: any) => {
                setLoading(false);
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    };

    return (
        <>
            <Wrapper>
                <CustomRow>
                    {loading && <LoaderOverlay />}
                    <Col lg={12}>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                margin: '1rem 0',
                                justifyContent: 'space-between',
                            }}>
                            <Link to="/settings/promotions">
                                <Icon className="fal fa-chevron-left"></Icon>
                            </Link>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                <Header>{t('Manage Loyality Discounts')}</Header>
                            </div>
                            <div></div>
                        </div>
                    </Col>
                </CustomRow>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Wrapper>
                        <Row justifyContent="center">
                            <CustomCol lg={6}>
                                <Card>
                                    <InputGroup>
                                        <Input
                                            name={'loyality_discount'}
                                            label={`${t('Loyality Discount (%)')}`}
                                            allowOnlyNumbersAndDecimal={true}
                                            onChange={onChange}
                                            value={getValues('loyality_discount')}
                                            error={errors.loyality_discount as any}
                                        />
                                    </InputGroup>

                                    <InputGroup>
                                        <Input
                                            name={'loyality_appointments'}
                                            label={`${t('Loyality Discount Appointments')}`}
                                            allowOnlyNumbersAndDecimal={true}
                                            onChange={onChange}
                                            value={getValues('loyality_appointments')}
                                            error={errors.loyality_appointments as any}
                                        />
                                    </InputGroup>

                                    <CustomButtom>
                                        <Button
                                            type="submit"
                                            bgtype="secondary"
                                            label={t('Update')}></Button>
                                    </CustomButtom>
                                </Card>
                            </CustomCol>
                        </Row>
                    </Wrapper>
                </form>
            </Wrapper>
        </>
    );
};

const Wrapper = styled(Container)`
    padding: 0rem;
`;

const CustomCol = styled(Col)`
    ${media.xs`
    border-right: none;
  `}
    ${media.sm`
    border-right: none;
  `}
    ${media.md`
    border-right: none;
  `}
    ${media.lg`
  `}
`;

const Card = styled.div`
    background: ${palette.white};
    box-shadow: inset -1.80118px -2.70178px 9.00592px rgba(0, 0, 0, 0.25);
    border-radius: 4.50296px;
    padding: 3rem 2rem 1.75rem 2rem !important;
`;

const InputGroup = styled.div`
    margin-bottom: 1.71rem;
`;

const Header = styled.h1`
    font-size: 1.875rem;
    font-weight: 600;
`;

const Icon = styled.i`
    font-size: 1.75rem !important;
    line-height: 2.25rem !important;
    margin-right: 1.25rem !important;
`;

export const HeadingAddCard = styled.h1`
    font-weight: 600 !important;
    font-size: 1.875rem !important;
    line-height: 2.25rem !important;
    margin: 0;
`;

LoyalityDiscount.Layout = Layout;
export default LoyalityDiscount;
