/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * TabContext Component
 * Description: A context provider component for managing the context of tabs within a tabbed interface.
 * Purpose:
 * - Context Creation: The `TabContext` component creates a context using React's `createContext` and provides it to its children.
 * - Unique Prefix: Generates a unique ID prefix using `useUniquePrefix` for creating unique IDs for tab elements.
 * - Context Value: The context value includes the generated ID prefix and the provided 'value'.
 * - Hook: Provides a custom hook 'useTabContext' for consuming the tab context within components.
 *
 * Props:
 * - children: React.ReactNode - The child components to be wrapped within the tab context.
 * - value: any - The value to be included in the tab context.
 */

import React from 'react';

const Context = React.createContext<any>(null);

const TabContext = ({ children, value }: { children: React.ReactNode; value: any }) => {
    const idPrefix = useUniquePrefix();

    const context = React.useMemo(() => {
        return { idPrefix, value };
    }, [idPrefix, value]);

    return <Context.Provider value={context}>{children}</Context.Provider>;
};

const useUniquePrefix = () => {
    const [id, setId] = React.useState<string>();

    React.useEffect(() => {
        setId(`tab-${Math.round(Math.random() * 1e5)}`);
    }, []);

    return id;
};

export function useTabContext() {
    return React.useContext(Context);
}

export default TabContext;
