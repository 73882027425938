/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * i18n Configuration
 * Description: Configuration file for i18n library to handle internationalization/localization in the application.
 */

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import english from './en.json';
import ukrainian from './uk.json';
import russian from './ru.json';
import german from './de.json';
let _userData = JSON.parse(localStorage.getItem('user') || '{}')?.user?.business_id;

const resources = {
    en: {
        translation: english,
    },
    uk: {
        translation: ukrainian,
    },
    ru: {
        translation: russian,
    },
    de: {
        translation: german,
    },
};
i18n.use(initReactI18next).init({
    resources,
    lng: _userData?.language ? _userData?.language : 'en',
    fallbackLng: 'en',
    debug: true,
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;
