/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * CustomerCard Component
 * Description: This component displays detailed information about a customer, including tabs for managing appointments, notes, and notifications. It also provides options to edit, delete, and send messages to the customer.
 *
 * Props:
 * - callCustomer: () => void - Callback function triggered when customer data is updated to reload customer.
 */
import useRouter from 'hooks/router';
import { useEffect, useState } from 'react';
import CustomerAppointments from './CustomerAppointments';
import { LoaderOverlay, Tab, TabPanel, Tabs, useSnackbar } from '@components/common';
import CustomerNotifications from './CustomerNotifications';
import CustomerNotes from './CustomerNotes';
import styled from 'styled-components';
import { Container, Row, Col, media } from 'styled-bootstrap-grid';
import TabContext from '@components/common/tabContext';
import DeleteModel from '@components/common/deleteModel/DeleteModel';
import { palette } from 'styled/common';
import Accordion from '@components/Accordion';
import { api } from 'helpers/auth-axios';
import EditCustomer from '../editCustomer';
import SendMessage from '@components/common/SendMessage';
import CardHeader from '@components/common/cardHeader/CardHeader';
import { MobileViewDiv } from '@components/team/cards/serviceProviderCard/ServiceProviderCard';
import { useTranslation } from 'react-i18next';
import CustomerPayments from './CustomerPayments';
import CustomerPackages from './CustomerPackages';

const CustomerCard = ({ callCustomer }: any) => {
    const { t }: any = useTranslation();
    const [customer, setCustomer] = useState<any>();
    const [tabValue, setTabValue] = useState(0);
    const [openDeleteModel, setOpenDeleteModel] = useState(false);
    const [loading, setLoading] = useState(false);
    const [deleteId, setDeleteId] = useState<any>();
    const [totalAppointment, setTotalAppointment] = useState<any>();
    const router = useRouter();
    const [openSnackbar] = useSnackbar();
    const [isSendMessage, setIsSendMessage] = useState(false);
    let _userData = JSON.parse(localStorage.getItem('user') || '{}');

    useEffect(() => {
        getCustomer();
    }, [router.query]);

    //get customer
    const getCustomer = () => {
        setLoading(true);
        api.get(`/customers/${router.query.id as string}`)
            .then((customer: any) => {
                setCustomer(customer.data.customer);
                setLoading(false);
                setTotalAppointment(customer.data.appointments);
            })
            .catch((e: any) => {
                setLoading(false);
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    };

    const deleteModel = (id: any) => {
        setOpenDeleteModel(true);
        setDeleteId(id);
    };

    const deleteCustomer = (id: any) => {
        setLoading(true);
        api.delete(`/customers/${id}`)
            .then(() => {
                router.navigate('/customers');
                openSnackbar(t('Customer deleted successfully'));
                setOpenDeleteModel(false);
                setLoading(false);
                callCustomer();
            })
            .catch((e: any) => {
                setLoading(false);
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });

        setOpenDeleteModel(false);
    };

    const tabsNameList = [
        {
            name: t('About'),
            content: (
                <>
                    <MobileViewDiv>
                        <CardHeader
                            userData={customer}
                            deleteModel={() => deleteModel(router.query.id)}
                            messageModel={() => setIsSendMessage(true)}
                        />
                    </MobileViewDiv>
                    <EditCustomer
                        callBack={() => {
                            getCustomer();
                            callCustomer();
                        }}
                    />
                </>
            ),
        },
        {
            name: `${t('Appointments')} (${totalAppointment})`,
            content: <CustomerAppointments customerId={customer?._id} />,
        },
        {
            name: t('Notes'),
            content: <CustomerNotes customer={customer} />,
        },
        {
            name: t('Notifications'),
            content: (
                <CustomerNotifications
                    customer={customer}
                    callBack={() => _userData?.user_login == 'customer' && getCustomer()}
                />
            ),
        },
        {
            name: t('Assets'),
            content: <CustomerPayments customerId={customer?._id} />,
        },
        {
            name: t('Packages'),
            content: <CustomerPackages customerId={customer} />,
        },
    ];

    const tabsList = [
        {
            name: t('Profile'),
            content: (
                <>
                    <MobileViewDiv>
                        <CardHeader
                            userData={customer}
                            deleteModel={() => deleteModel(router.query.id)}
                            messageModel={() => setIsSendMessage(true)}
                        />
                    </MobileViewDiv>
                    <EditCustomer
                        callBack={() => {
                            getCustomer();
                            callCustomer();
                        }}
                    />
                </>
            ),
        },
        {
            name: t('Appointments'),
            content: <CustomerAppointments customerId={customer?._id} />,
        },
        {
            name: t('Notifications'),
            content: (
                <CustomerNotifications
                    customer={customer}
                    callBack={() => _userData?.user_login == 'customer' && getCustomer()}
                />
            ),
        },
        {
            name: t('Assets'),
            content: <CustomerPayments customerId={customer?._id} />,
        },
        {
            name: t('Packages'),
            content: <CustomerPackages customerId={customer} />,
        },
    ];

    return (
        <>
            {loading && <LoaderOverlay />}
            <MainContainer>
                <Row>
                    {customer && (
                        <>
                            <WebView lg={12}>
                                <CardHeader
                                    userData={customer}
                                    deleteModel={() => deleteModel(router.query.id)}
                                    messageModel={() => setIsSendMessage(true)}
                                />
                            </WebView>
                            <WebView lg={12}>
                                <TabsCard>
                                    {_userData?.user_login !== 'customer' ? (
                                        <TabContext value={tabValue}>
                                            <Tabs
                                                onChange={(event, newValue) => {
                                                    setTabValue(newValue);
                                                }}>
                                                <Tab label={tabsNameList[0].name} />
                                                <Tab label={tabsNameList[1].name} />
                                                <Tab label={tabsNameList[2].name} />
                                                <Tab label={tabsNameList[3].name} />
                                                <Tab label={tabsNameList[4].name} />
                                                <Tab label={tabsNameList[5].name} />
                                            </Tabs>
                                            <TabPanel value={0} preload={true}>
                                                <TabContent padding={'0'}>
                                                    {' '}
                                                    {tabsNameList[0].content}
                                                </TabContent>
                                            </TabPanel>
                                            <TabPanel value={1} preload={true}>
                                                <TabContent>{tabsNameList[1].content}</TabContent>
                                            </TabPanel>
                                            <TabPanel value={2}>
                                                <TabContent>{tabsNameList[2].content}</TabContent>
                                            </TabPanel>
                                            <TabPanel value={3} preload={true}>
                                                <TabContent>{tabsNameList[3].content}</TabContent>
                                            </TabPanel>
                                            <TabPanel value={4}>
                                                <TabContent>{tabsNameList[4].content}</TabContent>
                                            </TabPanel>
                                            <TabPanel value={5} preload={true}>
                                                <TabContent>{tabsNameList[5].content}</TabContent>
                                            </TabPanel>
                                        </TabContext>
                                    ) : (
                                        <TabContext value={tabValue}>
                                            <Tabs
                                                onChange={(event, newValue) => {
                                                    setTabValue(newValue);
                                                }}>
                                                <Tab label={tabsList[1].name} />
                                                <Tab label={tabsList[2].name} />
                                                <Tab label={tabsList[3].name} />
                                                <Tab label={tabsList[4].name} />
                                            </Tabs>
                                            <TabPanel value={0} preload={true}>
                                                <TabContent>{tabsList[1].content}</TabContent>
                                            </TabPanel>
                                            <TabPanel value={1}>
                                                <TabContent>{tabsList[2].content}</TabContent>
                                            </TabPanel>
                                            <TabPanel value={2}>
                                                <TabContent>{tabsList[3].content}</TabContent>
                                            </TabPanel>
                                            <TabPanel value={3}>
                                                <TabContent>{tabsList[4].content}</TabContent>
                                            </TabPanel>
                                        </TabContext>
                                    )}
                                </TabsCard>
                            </WebView>
                            <MobileView lg={12}>
                                <Accordion
                                    tabsNameList={
                                        _userData?.user_login !== 'customer'
                                            ? tabsNameList
                                            : tabsList
                                    }
                                />
                            </MobileView>
                        </>
                    )}
                    {openDeleteModel && customer && (
                        <DeleteModel
                            id={customer._id}
                            onDelete={() => {
                                setOpenDeleteModel(false);
                            }}
                            onClose={() => {
                                setOpenDeleteModel(false);
                            }}
                            name={t('Customer')}
                            content={t(
                                'If you delete this client you will lose all information connected to their profile including past appointments, payment history, and more. This action cannot be undone.'
                            )}
                            loading={loading}
                            onClick={() => deleteCustomer(deleteId)}
                        />
                    )}
                    {isSendMessage && (
                        <SendMessage
                            name={customer.name}
                            phone={customer.phone}
                            senderEmail={_userData.user.email}
                            reciverEmail={customer.email}
                            onClose={() => {
                                setIsSendMessage(false);
                            }}
                        />
                    )}
                </Row>
            </MainContainer>
        </>
    );
};

const MainContainer = styled(Container)`
    padding-bottom: 0rem !important;
    display: flex;
    flex-direction: column;
    ${media.xs`
    overflow-x: hidden;
    overflow-y: auto;
	`}
    ${media.sm`
    overflow-x: hidden;
    overflow-y: auto;
	`}
	${media.md`
	`}
	${media.lg`
	`}
`;

const WebView = styled(Col)`
    display: block;
    @media screen and (min-width: 1px) and (max-width: 900px) {
        display: none;
    }
`;

const MobileView = styled(Col)`
    display: none;
    @media screen and (min-width: 1px) and (max-width: 900px) {
        display: block;
    }
`;

export const TabsCard = styled.div`
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;
    border-radius: 0.42rem;
    box-shadow: 0rem 0rem 2.2rem 0rem ${palette.grey.shadow};
    border: 0;
    background: ${palette.white};
    box-shadow: inset -1.80118px -2.70178px 9.00592px rgba(0, 0, 0, 0.25);
    border-radius: 4.50296px;
`;

export const TabContent = styled.div<any>`
    padding: ${({ padding }) => (padding ? '' : '1.3rem')};
    flex: 1;
`;

export default CustomerCard;
