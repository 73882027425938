/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * LabelService Component
 * Description: This component handles the creation of a new category, including name.
 *
 */

import React, { useEffect, useState } from 'react';
import Button from '@components/Button';
import styled from 'styled-components';
import { Container, Row, Col, media } from 'styled-bootstrap-grid';
import { Layout, LoaderOverlay, useSnackbar } from '@components/common';
import { useNavigate } from 'react-router-dom';
import { Input, Select, Switch } from '@components/common';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FooterButton } from '@components/team/create/createServiceProvider/CreateServiceProvider';
import { useTranslation } from 'react-i18next';
import { palette } from 'styled/common';
import { api } from 'helpers/auth-axios';

const LabelService = ({
    onCreate,
    onClose,
    onEdit,
}: {
    onCreate?: any;
    onClose?: any;
    onEdit?: any;
}) => {
    const { t }: any = useTranslation();
    const navigate = useNavigate();
    const [ifClicked, setIfClicked] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [openSnackbar] = useSnackbar();

    useEffect(() => {
        if (onEdit) {
            setValue('name', onEdit?.name);
        }
    }, [onEdit]);

    const validationSchema = Yup.object().shape({
        name: Yup.string().required(t('This field is required')),
    });

    const { handleSubmit, setValue, getValues, formState, trigger } = useForm<any>({
        resolver: yupResolver(validationSchema),
    });
    const { errors } = formState;

    async function onChange({ name, value }: { name: string; value: any }) {
        setValue(name as never, value as never);
        await trigger(name as never);
    }

    const onSubmit = (data: any) => {
        const serviceData = {
            ...data,
        };
        setLoading(true);
        api.post('/labeled-services', serviceData)
            .then(() => {
                setLoading(false);
                setIfClicked(true);
                if (onCreate) {
                    onCreate();
                } else {
                    navigate('/services');
                }

                openSnackbar(t('Category added successfully!'));
            })
            .catch(e => {
                setLoading(false);
                if (e?.response) {
                    const response = e?.response?.data;
                    if (response) {
                        openSnackbar(response?.message);
                    }
                }
            });
    };

    const onUpdateSubmit = (data: any) => {
        const serviceData = {
            ...data,
        };
        setLoading(true);
        api.put(`/labeled-services/${onEdit?._id}`, serviceData)
            .then(() => {
                setLoading(false);
                setIfClicked(true);
                if (onCreate) {
                    onCreate();
                } else {
                    navigate('/services');
                }

                openSnackbar(t('Category updated successfully!'));
            })
            .catch(e => {
                setLoading(false);
                if (e?.response) {
                    const response = e?.response?.data;
                    if (response) {
                        openSnackbar(response?.message);
                    }
                }
            });
    };

    return (
        <MainContent>
            {loading && <LoaderOverlay />}
            <Inner>
                <form onSubmit={handleSubmit(onEdit ? onUpdateSubmit : onSubmit)}>
                    <Wrapper>
                        <Row>
                            <Header lg={12}>
                                <Heading>
                                    {t(onEdit ? 'Update Service Category' : 'Add Service Category')}
                                </Heading>
                                <Cross
                                    className="fal fa-times"
                                    onClick={() => {
                                        onClose && onClose();
                                    }}></Cross>
                            </Header>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <InputGroup>
                                            <Input
                                                label={t('Category name')}
                                                name="name"
                                                value={getValues('name')}
                                                onChange={onChange}
                                                error={errors.name as any}
                                            />
                                        </InputGroup>

                                        <FooterButton>
                                            <Button
                                                type="submit"
                                                label={t(
                                                    onEdit ? 'Update Category' : 'Add Category'
                                                )}
                                                bgtype="secondary"></Button>
                                        </FooterButton>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Wrapper>
                </form>
            </Inner>
        </MainContent>
    );
};

export const MainContent = styled.div`
    position: relative;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    padding: 2.7rem;
    flex: 1 1;
    display: flex;
    flex-direction: column;
    ${media.xs`
	height: auto;
  `}
    ${media.sm`
	height: 100%;
  `}
	${media.md`
	height: 100%;
  `}
	${media.lg`
	height: 100%;
  `}
`;

const Inner = styled.div`
    margin-left: auto !important;
    margin-right: auto !important;
`;

const Wrapper = styled(Container)`
    padding: 0rem;
`;

const Header = styled(Col)`
    display: flex;
    justify-content: space-between;
`;

const InputGroup = styled.div`
    margin-bottom: 1.71rem;
`;

const Card = styled.div`
    background: ${palette.white};
    box-shadow: inset -1.80118px -2.70178px 9.00592px ${palette.box_hadow_color};
    border-radius: 4.50296px;
`;

const CardBody = styled.div`
    padding: 3rem 2rem 1.75rem 2rem !important;
    ${media.xs`
	padding: 3rem 2rem 1.75rem 2rem !important;
  `}
    ${media.sm`
	padding: 3rem 2rem 1.75rem 2rem !important;
  `}
	${media.md`
	padding: 3rem 2rem 1.75rem 2rem !important;
  `}
	${media.lg`
	padding: 3rem 2rem 1.75rem 2rem !important;
  `}
`;

const Heading = styled.h1`
    font-weight: 600 !important;
    font-size: 1.875rem !important;
    line-height: 2.25rem !important;
    margin: 0;
`;

const Cross = styled.i`
    font-size: 1.75rem !important;
    line-height: 2.5rem !important;
    cursor: pointer !important;
`;

export const AddAssets = styled.div`
    text-transform: capitalize;
    font-weight: 600;
    display: inline-block;
    border: none;
    border-radius: 0.57rem;
    padding: 0.7rem 1.85rem;
    color: ${palette.white};
    background: ${palette.secondary};
    box-shadow: none;
    margin: undefined;
    cursor: pointer;
    width: -webkit-min-content;
    width: -moz-min-content;
    width: min-content;
    white-space: nowrap;
    -webkit-transition: all ease 0.25s;
    transition: all ease 0.25s;
`;

LabelService.Layout = Layout;
export default LabelService;
