/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * PastBookings Component
 * Description: This component displays past bookings for a customer.
 *
 * Props:
 * - customerId: string - The ID of the customer for whom past bookings are displayed.
 * - onInitialLoad: (total: number) => void - Callback function triggered after the initial loading of past bookings, providing the total number of past bookings.
 */

import { useState, useEffect, useRef } from 'react';
import { IAppointment } from 'interfaces/appointment.interface';
import moment from 'moment';
import as from 'components/appointments/Appointment.module.scss';
import { LoaderOverlay, Spinner } from '@components/common';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { api } from 'helpers/auth-axios';
import { useTranslation } from 'react-i18next';
import { palette } from 'styled/common';

const getAppointmentLabel = (status: string) => {
    if (status === 'confirmed' || status === 'finished') {
        return as.appointment_statusSuccess;
    } else if (status === 'late') {
        return as.appointment_statusWarning;
    } else if (status === 'cancelled') {
        return as.appointment_statusDanger;
    } else {
        return as.appointment_statusGray;
    }
};

const PastBookings = ({
    customerId,
    onInitialLoad,
}: {
    customerId: string;
    onInitialLoad(total: any): void;
}) => {
    const { t }: any = useTranslation();
    const pageSize = 20;
    const [total, setTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [bookings, setBookings] = useState<IAppointment[]>([]);
    const [firstLoading, setFirstLoading] = useState(false);
    let _userData = JSON.parse(localStorage.getItem('user') || '{}');

    useEffect(() => {
        setPage(0);
        setBookings([]);
        setFirstLoading(true);
        api.get(`/appointments/customers/${customerId}?page=${0}&pageSize=${pageSize}&status=done`)
            .then((res: any) => {
                setFirstLoading(false);
                setBookings(res.data.bookings);
                onInitialLoad(res.data?.totalPast);
                setTotal(res.data.total ?? 0);
            })
            .catch(e => {
                setFirstLoading(false);
            });
    }, [customerId]);

    useEffect(() => {
        if (page === 0) return;

        setLoading(true);
        api.get(
            `/appointments/customers/${customerId}?page=${page}&pageSize=${pageSize}&status=done`
        )
            .then((res: any) => {
                setLoading(false);
                setBookings(prevBookings => [...prevBookings, ...res.data.bookings]);
                setTotal(res.data.total ?? 0);
                onInitialLoad(res.data?.totalPast);
            })
            .catch(e => {
                setLoading(false);
            });
    }, [page]);

    const loadMore = () => {
        setPage(prevPage => prevPage + 1);
    };

    return (
        <>
            {firstLoading && <LoaderOverlay />}
            <Container>
                <Row>
                    {' '}
                    {bookings &&
                        bookings.map((booking: any, i: number) => (
                            <Wrapper lg={12} key={i}>
                                <AppointmentDate>
                                    <AppointmentMonth>
                                        {moment(booking.booked_from_iso).format('MMM')}
                                    </AppointmentMonth>
                                    <Date>{moment(booking.booked_from_iso).format('DD')}</Date>
                                    <AppointmentMonth>
                                        {moment(booking.booked_from_iso).format(
                                            _userData.user.business_id.time_select === '12'
                                                ? 'hh:mm a'
                                                : 'HH:mm'
                                        )}
                                    </AppointmentMonth>
                                </AppointmentDate>
                                <Hr
                                    className={`service-color-${booking.service?.color}--bg-2`}></Hr>
                                <AppointmentContent>
                                    <AppointmentLabel>
                                        <div
                                            className={`${
                                                as.appointment_statusLabel
                                            } ${getAppointmentLabel(booking.status)}`}>
                                            {t(booking.status)}
                                        </div>
                                    </AppointmentLabel>

                                    <AppointmentLabel>
                                        <div style={{ fontSize: '0.7rem' }}>
                                            {booking.is_package
                                                ? 'P -'
                                                : booking.is_vip
                                                ? 'Vip -'
                                                : booking.is_waiting
                                                ? 'w -'
                                                : ''}
                                        </div>
                                    </AppointmentLabel>
                                    {booking?.service?.name ? (
                                        <AppointmentName>{booking?.service?.name}</AppointmentName>
                                    ) : (
                                        <AppointmentName>{t('service removed')} </AppointmentName>
                                    )}

                                    <AppointmentProvider>
                                        {t('Service by')}{' '}
                                        <AppointmentProviderName>
                                            {booking?.serviceProvider?.name}
                                        </AppointmentProviderName>
                                    </AppointmentProvider>
                                    <AppointmentProvider>
                                        {' '}
                                        {booking?.payment == null ? t('unpaid') : t('paid')}
                                    </AppointmentProvider>
                                </AppointmentContent>
                                <IconBody>
                                    {booking?.is_video_appointment && (
                                        <i className="fal fa-video"></i>
                                    )}
                                    {booking?.reportId && (
                                        <i
                                            className="fad fa-exclamation-triangle"
                                            style={{ color: 'red' }}></i>
                                    )}
                                    <CustomLink
                                        to={
                                            _userData?.user_login === 'customer'
                                                ? `/${_userData?.user?.business_id?._id}/${booking._id}/appointment`
                                                : `/calendar?appointment=${booking._id}`
                                        }>
                                        <i className="fad fa-arrow-right"></i>
                                    </CustomLink>
                                </IconBody>
                            </Wrapper>
                        ))}
                    {(total > (page + 1) * pageSize || loading) && (
                        <AppointmentPages>
                            <Button disabled={loading} onClick={() => loadMore()}>
                                {loading ? (
                                    <Spinner color="secondary" />
                                ) : (
                                    `${t('Load more appointments')} (${
                                        total - (page + 1) * pageSize
                                    })`
                                )}
                            </Button>
                        </AppointmentPages>
                    )}
                </Row>
            </Container>
        </>
    );
};

const Wrapper = styled(Col)`
    display: flex;
    padding: 0rem;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom-width: 1px;
`;

const AppointmentDate = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 0.875rem;
    line-height: 1.25rem;
    width: 5rem;
`;
const AppointmentMonth = styled.div`
    color: rgba(103, 113, 126);
`;

const Date = styled.div`
    font-size: 1.5rem /* 24px */;
    line-height: 2rem /* 32px */;
    padding-top: 0.25rem /* 4px */;
    padding-bottom: 0.25rem /* 4px */;
`;

const Hr = styled.div`
    border-radius: 0.25rem /* 4px */;
    width: 0.28rem;
`;

const AppointmentContent = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-left: 1rem;
`;

const AppointmentLabel = styled.div`
    display: flex;
`;

const AppointmentName = styled.div`
    font-weight: 500;
    font-size: 0.9rem;
`;

const AppointmentProvider = styled.div`
    font-size: 0.75rem /* 12px */;
    line-height: 1rem /* 16px */;
    color: rgba(181, 181, 195);
`;

const AppointmentProviderName = styled.span`
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`;

const IconBody = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1 1 0%;
    gap: 0.5rem;
`;

const CustomLink = styled(Link)`
    border-radius: 0.25rem /* 4px */;
    width: 2.5rem /* 40px */;
    height: 2.5rem /* 40px */;
    color: rgba(73, 196, 150);
    color: rgba(73, 196, 150);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(244, 245, 246);
    &:hover {
        background-color: rgba(237, 238, 241);
        transition-property: background-color, border-color, color, fill, stroke, opacity,
            box-shadow, transform, filter, backdrop-filter;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 150ms;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

const AppointmentPages = styled(Col)`
    display: flex;
    justify-content: center;
`;

const Button = styled.button`
    padding-top: 0.5rem /* 8px */;
    padding-bottom: 0.5rem /* 8px */;
    padding-left: 2rem /* 32px */;
    padding-right: 2rem /* 32px */;
    font-weight: 500;
    font-size: 0.9rem /* 14.4px */;
    border-radius: 0.57rem;
    color: ${palette.white};
    background: ${palette.secondary};
`;
export default PastBookings;
