/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Component: ModifyAppointmentModal
 * Description: This component represents a modal for modifying existing appointments from the calendar. Users can update the appointment by dragging on the calendar, adjusting both start and end dates. The modal checks for conflicts and displays a conflict modal if any conflicts are found.
 * Developed with React and styled-components for styling.
 *
 * Props:
 * - id: string - The id of the appointment.
 * - startDate: any - The start date of the appointment.
 * - endDate: any - The end date of the appointment.
 * - onClose: () => void - Callback function triggered when the modify appointment modal is closed.
 * - onSave: () => void - Callback function triggered when changes to the appointment are saved.
 * - serviceProvierData: any - Service provider data related to the appointment.
 * - serviceProvider: boolean - Indicates whether the appointment is associated with a service provider.
 * - onDragDate: any - Callback function triggered when the appointment is dragged.
 * - is_blocked?: boolean - Indicates whether it is blocked or not.
 * - appointment?: any - Indicates Additional appointment information.
 * - is_vip?: any - Indicates whether the appointment is marked as VIP or not.
 *
 */

import { LoaderOverlay, useSnackbar } from '@components/common';
import styled from 'styled-components';
import { appointmentActions } from 'actions/appointment';
import { useAppDispatch } from 'hooks/redux';
import { useState } from 'react';
import Button from '@components/Button';
import ConflictModal from '@components/appointments/modal/ConflictModal';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { palette } from 'styled/common';
const ModifyAppointmentModal = ({
    id,
    startDate,
    endDate,
    onClose,
    onSave,
    serviceProvierData,
    serviceProvider,
    onDragDate,
    is_blocked,
    appointment,
    is_vip,
}: {
    id: string;
    startDate: any;
    endDate: any;
    onClose(): void;
    onSave(): void;
    serviceProvierData: any;
    serviceProvider: boolean;
    onDragDate: any;
    is_blocked?: any;
    appointment?: any;
    is_vip?: any;
}) => {
    const { t }: any = useTranslation();
    const [loading, setLoading] = useState(false);
    const [conflictDates, setConflictDates] = useState<string[]>([]);
    const [showConflictModal, setShowConflictModal] = useState(false);
    const dispatch = useAppDispatch();
    const [openSnackbar] = useSnackbar();
    const closeModal = (event: any) => {
        event.preventDefault();
        if (event.target === event.currentTarget) {
            onClose();
        }
    };
    const start: any = moment(startDate).format();
    const end: any = moment(endDate).format();
    const calculateDuration = moment.duration(moment(end).diff(start));
    const duration = calculateDuration.asMinutes();

    let _appointment = {
        appointment_note: appointment?.appointment_note,
        serviceProvider: appointment?.serviceProvider?._id,
        customer: appointment?.customer?._id,
        service: appointment?.service?._id,
        total: appointment?.total,
        total_value: appointment?.total_value,
        customer_note: appointment?.customer_note,
        coupen_used: false,
        is_video_appointment: appointment?.is_video_appointment,
        is_waiting: appointment?.is_waiting,
        is_vip: is_vip,
    };

    const save = () => {
        setLoading(true);
        const updatedAppointment = {
            ..._appointment,
            booked_from_iso: moment(startDate).format(),
            booked_till_iso: moment(endDate).format(),
            booked_from: moment(startDate).format(),
            booked_till: moment(endDate).format(),
            duration: duration,
        };

        let from_hour: any = moment(startDate).format('HH');
        let from_mint: any = moment(startDate).format('mm');
        let till_hours: any = moment(endDate).format('HH');
        let till_mint: any = moment(endDate).format('mm');
        let today: any =
            onDragDate !== '' ? moment(onDragDate).format('YYYY-MM-DD') : moment().format();
        let day: any = today !== '' ? moment(today).format('DD') : '';
        let year: any = today !== '' ? moment(today).format('YYYY') : '';
        let month: any = today !== '' ? moment(today).format('MM') : '';
        let booked_from_iso = moment(
            year + month + day + from_hour + from_mint,
            'YYYY MM DD HH mm'
        );
        let booked_till_iso = moment(
            year + month + day + till_hours + till_mint,
            'YYYY MM DD HH mm'
        );

        const updatedAppointmentWithServiceProvider = {
            ..._appointment,
            booked_from_iso: moment(booked_from_iso).format(),
            booked_till_iso: moment(booked_till_iso).format(),
            booked_from: moment(booked_from_iso).format(),
            booked_till: moment(booked_till_iso).format(),
            serviceProvider: serviceProvierData,
            duration: duration,
        };
        dispatch(
            appointmentActions.updateAppointmentById(
                id,
                serviceProvider === true
                    ? updatedAppointmentWithServiceProvider
                    : updatedAppointment
            )
        )
            .then((res: any) => {
                setLoading(false);
                if (res.message) {
                    openSnackbar(t('Conflict'));
                }
                setLoading(false);
                onSave();
                openSnackbar(
                    t(
                        is_blocked
                            ? 'Modify block successfully!'
                            : 'Modify Appointment successfully!'
                    )
                );
                onClose();
            })
            .catch(e => {
                setLoading(false);
                onClose();
                if (is_blocked) {
                    openSnackbar(t('Conflict between blocks!'));
                } else if (e.response) {
                    const response = e.response.data.response;

                    if (e?.response?.data?.message === undefined) {
                        openSnackbar(t('Something went wrong!'));
                    } else {
                        openSnackbar(response?.message);
                    }

                    if (response?.dates) {
                        setConflictDates(response.dates);
                        setShowConflictModal(true);
                    } else {
                        onClose();
                    }
                }
            });
    };
    return (
        <>
            <Model tabIndex={-1} onClick={e => loading === false && closeModal(e)}>
                {loading && <LoaderOverlay />}
                <ModelBody>
                    <ModelHeading>
                        <Heading>
                            {t(
                                is_blocked
                                    ? 'Modify existing block?'
                                    : 'Modify existing appointment?'
                            )}
                        </Heading>
                        <Cover onClick={() => loading === false && onClose()}>
                            <i className="fal fa-times"></i>
                        </Cover>
                    </ModelHeading>
                    <ModelHead></ModelHead>
                    <Space></Space>
                    <Footer>
                        <Button bgtype={'secondary'} ifClicked={save} label={t('Update')}></Button>
                        <Button
                            bgtype={'discard'}
                            ifClicked={() => !loading && onClose()}
                            label={t('Cancel')}></Button>
                    </Footer>
                </ModelBody>
            </Model>
            {showConflictModal && (
                <ConflictModal
                    dates={conflictDates}
                    onContinue={() => {
                        setShowConflictModal(false);
                    }}
                    onClose={() => {
                        onClose();
                        setShowConflictModal(false);
                    }}
                />
            )}
        </>
    );
};
const Model = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background-color: ${palette.modal_background};
    padding: 24px;
    overflow: auto;
    overscroll-behavior-y: contain;
    z-index: 102;
`;
const ModelBody = styled.div`
    display: flex;
    flex-direction: column;
    background: ${palette.white};
    box-shadow: inset -1.80118px -2.70178px 9.00592px ${palette.box_hadow_color};
    border-radius: 4.50296px;
    padding: 24px;
    padding-top: 0;
`;
const ModelHead = styled.div`
    display: flex;
    justify-content: end;
    margin-bottom: 0.75rem;
`;
const Cover = styled.div`
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${palette.grey.grey_20};
    border-radius: 8px;
    font-size: 1.7rem;
    cursor: pointer;
`;
const ModelHeading = styled.div`
    align-items: center !important;
    display: flex !important;
    justify-content: space-between;
    margin: 1rem 0;
`;
const Heading = styled.h1`
    font-weight: 600 !important;
    font-size: 1.875rem !important;
    line-height: 2.25rem !important;
`;
const Space = styled.div`
    padding-top: 4.5rem !important;
`;
const Footer = styled.div`
    display: flex;
    justify-content: space-between;
`;

export default ModifyAppointmentModal;
