/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * Tab Component
 * Description: A customizable tab component for creating tabbed navigation.
 * Purpose:
 * - Display Tabs: The `Tab` component is designed to display tabs within a tabbed interface, allowing users to navigate between different sections or views.
 * - Customization: It accepts various props for customization, including 'value', 'label', 'selected', 'theme', 'onClick', and 'onChange'.
 * - Callbacks: Provides callback functions 'onClick' and 'onChange' to handle tab click events and value changes.
 *
 * Props:
 * - value?: any - The value associated with the tab.
 * - label?: string - The label or text content of the tab.
 * - selected?: boolean - If true, indicates that the tab is currently selected.
 * - theme?: string - A string representing the theme or style of the tab.
 * - onClick?(): void - Callback function triggered when the tab is clicked/selected.
 * - onChange?(event: React.SyntheticEvent, value: any): void - Callback function triggered when the tab value changes/selected.
 */

export interface TabProps {
    value?: any;
    label?: string;
    selected?: boolean;
    theme?: string;
    onClick?(): void;
    onChange?(event: React.SyntheticEvent, value: any): void;
}

const Tab = ({ value, label, selected, theme, onClick, onChange }: TabProps) => {
    const themeClass = theme ? `tab--${theme}` : 'tab--default';

    const handleClick = (event: React.MouseEvent) => {
        if (!selected && onChange) {
            onChange(event, value);
        }

        if (onClick) {
            onClick();
        }
    };

    return (
        <div
            role="tab"
            onClick={handleClick}
            tabIndex={selected ? 0 : -1}
            aria-selected={selected}
            className={`tab ${themeClass} ${selected ? 'tab--active' : ''}`}>
            {label}
        </div>
    );
};

export default Tab;
