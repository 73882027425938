/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * OnlineBooking Component
 * Description: This component manages online bookings, including integration with Stripe for payment processing and customization of the booking widget. It provides a fully customizable booking widget that users can embed on their websites or applications. Users can connect to Stripe for payment processing and customize the appearance of the booking widget.
 *
 */

import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'styled-bootstrap-grid';
import { api } from 'helpers/auth-axios';
import useRouter from 'hooks/router';
import { IAccount, IAccountLink } from 'interfaces/stripe.interface';
import SyntaxHighlighter from '@components/common/syntaxHighlighter/SyntaxHighlighter';
import CustomiseWidget from './CustomiseWidget';
import { getEmbededCode } from './embedCode';
import { useAppDispatch } from 'hooks/redux';
import { LoaderOverlay, useSnackbar } from '@components/common';
import { businessActions } from 'actions/business';
import { useTranslation } from 'react-i18next';
import { palette } from 'styled/common';

const OnlineBooking = () => {
    const { t }: any = useTranslation();
    const router = useRouter();
    const dispatch = useAppDispatch();
    const [openSnackbar] = useSnackbar();
    const [business, setBusiness] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);
    const [btnBgColor, setBtnBgColor] = useState<any>('');
    const [btnTextColor, setBtnTextColor] = useState<any>('');
    const [closeModel, setCloseModel] = useState(false);
    let _userData = JSON.parse(localStorage.getItem('user') || '{}');

    useEffect(() => {
        getBusiness();
    }, []);

    useEffect(() => {
        if (router.query.status === 'stripe_return' && router.query.account_id) {
            router.navigate('/settings/online-booking');
            api.get('/billing/account').then(res => {
                const account: IAccount = res.data;

                // Not fully completed -> show error message
                if (!account.details_submitted) {
                    openSnackbar(t('Failed to integrate with Stripe. Please try again'));
                } else {
                    dispatch(
                        businessActions.updateBusines(_userData?.user?.business_id?._id!, {
                            stripe_account_verified: true,
                        })
                    ).then((res: any) => {
                        getBusiness();
                        openSnackbar(t('Stripe integrated successfully!'));
                    });
                }
            });
        }
    }, [router.query]);

    const connectToStripe = () => {
        const stripeData = {
            id: null,
        };
        api.post('/billing/connect-to-stripe', stripeData).then((res: any) => {
            console.log(res);
            const data: IAccountLink = res.data;
            window.location.href = data.url;
        });
    };

    const getBusiness = () => {
        setLoading(true);
        api.get(`/businesses/${_userData?.user?.business_id?._id}`)
            .then((res: any) => {
                setLoading(false);
                _userData.user.business_id = res.data;
                localStorage.setItem('user', JSON.stringify(_userData));
                if (res.data) {
                    setBusiness(res.data);
                }
            })
            .catch((e: any) => {
                setLoading(false);
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    };

    const accountRemove = () => {
        dispatch(
            businessActions.updateBusines(_userData?.user?.business_id._id!, {
                stripe_account_verified: false,
            })
        ).then((res: any) => {
            getBusiness();
            openSnackbar(t('Stripe Account Removed Successfully!'));
        });
    };
    setTimeout(() => {
        setCloseModel(false);
    }, 1000);
    return (
        <Wrapper>
            {loading && <LoaderOverlay />}
            {business && (
                <Row>
                    <Col md={6} lg={7}>
                        <Wrapper
                            onClick={() => {
                                setCloseModel(true);
                            }}>
                            <Row>
                                <Flex>
                                    <Card>
                                        <div>
                                            <Heading>{t('Online Bookings')}</Heading>
                                            <Text>{t('A fully customizable booking widget.')}</Text>
                                            <PointsContent>
                                                <AlignFlex>
                                                    <Points>1</Points>
                                                    <div>
                                                        {t(
                                                            'Paste code into an HTML file where the booking button should appear.'
                                                        )}
                                                    </div>
                                                </AlignFlex>
                                                <AlignFlex>
                                                    <Points>2</Points>
                                                    <div style={{ width: 'fit-content' }}>
                                                        {t(
                                                            'If you want to integrate it into ReactJs/NextJs/GatsbyJs, then please paste it within an HTML `iframe` tag using the srcDoc prop.'
                                                        )}
                                                    </div>
                                                </AlignFlex>
                                            </PointsContent>
                                        </div>
                                        <SyntaxHighlighter language="javascript">
                                            {getEmbededCode(
                                                _userData?.user?.business_id?._id!,
                                                btnBgColor,
                                                btnTextColor,
                                                t('Book Now')
                                            )}
                                        </SyntaxHighlighter>
                                    </Card>
                                </Flex>
                            </Row>
                        </Wrapper>
                    </Col>

                    <Col md={6} lg={5}>
                        {business?.stripe_account_verified === false && (
                            <Flex style={{ padding: '0' }}>
                                <Card>
                                    <CardBody>
                                        <AlertInfo>
                                            <AlertInfoHeading>{t('Stripe Fees')}</AlertInfoHeading>
                                            <AlertInfoText>
                                                {t('Stripe charges')} <strong>2.9%</strong>{' '}
                                                {t('plus')} <strong>30</strong>{' '}
                                                {t('cents per transaction.')}
                                            </AlertInfoText>
                                        </AlertInfo>
                                    </CardBody>
                                    <Button onClick={connectToStripe}>
                                        <i className="fab fa-stripe fa-3x"></i>
                                    </Button>
                                </Card>
                            </Flex>
                        )}
                        {business?.stripe_account_verified === true && (
                            <Flex style={{ padding: '0' }}>
                                <RemoveAccount onClick={accountRemove}>
                                    {t('Remove Connected Account')}
                                </RemoveAccount>
                            </Flex>
                        )}

                        <Card>
                            <Heading>{t('Settings')}</Heading>
                            <Text>
                                {t(
                                    'You can customize the booking widget to match your brand colors.'
                                )}
                            </Text>

                            <CustomiseWidget
                                btnBgColor={btnBgColor}
                                btnTextColor={btnTextColor}
                                setBtnBgColor={setBtnBgColor}
                                setBtnTextColor={setBtnTextColor}
                                callBack={closeModel}
                            />
                        </Card>
                    </Col>
                </Row>
            )}
        </Wrapper>
    );
};

const Wrapper = styled(Container)`
    padding: 0rem;
`;

const Card = styled.div`
    background: ${palette.white};
    box-shadow: inset -1.80118px -2.70178px 9.00592px rgba(0, 0, 0, 0.25);
    border-radius: 4.50296px;
    padding: 1.75rem 2rem;
`;

const CardBody = styled.div`
    margin-bottom: 1.5rem !important;
`;

const AlertInfo = styled.div`
    border-left: 0.3rem solid ${palette.blue.defaultBlue};
    background: rgba(8, 148, 226, 0.038);
    padding: 14px;
    border-radius: 4px;
`;

const AlertInfoHeading = styled.div`
    color: ${palette.blue.defaultBlue};
    font-weight: bold;
    text-transform: capitalize;
`;

const AlertInfoText = styled.div`
    font-size: 14px;
    padding-top: 4px;
`;

const Button = styled.button`
    cursor: pointer;
    padding: 0px 26px;
    font-weight: 600;
    color: #fff;
    border-radius: 8px;
    font-size: 13px;
    background-color: ${palette.blue.stripe_button};
    outline: none;
    border: none;
`;

const RemoveAccount = styled.button`
    cursor: pointer;
    padding: 18px 26px;
    font-weight: 600;
    text-transform: capitalize;
    color: #fff;
    background: #27292e;
    border-radius: 8px;
    font-size: 1rem;
    background-color: ${palette.primary} !important;
    outline: none;
    border: none;
`;

const Heading = styled.div`
    font-size: 1.25rem !important;
    line-height: 1.75rem !important;
    font-weight: 600 !important;
`;

const Text = styled.p`
    font-size: 1rem !important;
    line-height: 1.5rem !important;
    color: rgba(157, 160, 164) !important;
    margin: 0rem;
`;

const Flex = styled(Col)`
    margin-bottom: 1.2rem;
`;

const PointsContent = styled.div`
    padding: 1.25rem 0rem !important;
`;

const Points = styled.div`
    color: rgba(255, 255, 255) !important;
    background-color: ${palette.secondary};
    border-radius: 9999px !important;
    justify-content: center !important;
    align-items: center !important;
    width: 2rem !important;
    height: 2rem !important;
    display: flex !important;
    margin-right: 0.5rem;
`;

const AlignFlex = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem !important;
`;

export default OnlineBooking;
