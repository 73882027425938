/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * CustomersOption Component
 * Description: This component represents the main entry point for customer-related options.
 * It includes the layout, navigation, and rendering of customer-specific options like customers or customer reports through the 'CustomerOptions' component.
 * The layout is provided by the 'Layout' component from '@components/common'.
 *
 */

import { Layout } from '@components/common';
import CustomerOptions from '@components/customers/CustomerOptions';

const CustomersOption = () => {
    return (
        <>
            <CustomerOptions />
        </>
    );
};

CustomersOption.Layout = Layout;
export default CustomersOption;
