/**
 * eBookingSystem - Web App
 * Developed by Smart Soft Studios
 * Copyright © 2024 Smart Soft Studios. All rights reserved.
 *
 * TeamMemberList Component
 * Description: This component displays a list of team members. It includes a search field, a list of team members, and an option to add a new team member. The list supports pagination and lazy loading for improved performance.
 *
 * Props:
 * - hasId: any - Indicates whether a specific team member ID is present in the router query to get specific team member data.
 *
 */

import React, {
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useRouter from 'hooks/router';
import _ from 'lodash';
import { IGetTeamResponse, ITeam } from 'interfaces/teamMember.interface';
import { Container, Row, Col, media } from 'styled-bootstrap-grid';
import styled from 'styled-components';
import SearchField from '@components/common/search/SearchField';
import ContentList from '@components/common/contentList/ContentList';
import { api } from 'helpers/auth-axios';
import { LoaderOverlay, useSnackbar } from '@components/common';
import { AddButton } from '@components/customers/customersList/CustomersList';
import { CustomDiv, NotFound } from '../serviceProviderList/ServiceProviderList';
import { useTranslation } from 'react-i18next';
import CreateTeamMember from '@components/team/create/createTeamMember';
import { palette } from 'styled/common';

interface IProps {
    hasId: any;
}

const TeamMemberList = forwardRef(({}, ref) => {
    useImperativeHandle(ref, () => ({
        getTeamMember() {
            getAllTeamMember();
        },
    }));
    const navigate = useNavigate();
    const router = useRouter();
    const { t }: any = useTranslation();
    const [pageNumber, setPageNumber] = useState(0);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(false);
    const [query, setQuery] = useState<any>(null);
    const [value, setValue] = useState<any>('');
    const [initial, setInitial] = useState(false); // used to check whether data is initially on store
    const observer = useRef<IntersectionObserver>();
    const [team, setTeam] = useState<IGetTeamResponse>();
    const [createModel, setCreateModel] = useState(false);
    const [openSnackbar] = useSnackbar();
    let _userData = JSON.parse(localStorage.getItem('user') || '{}')?.user?.business_id;

    useEffect(() => {
        if (team !== undefined && team.total !== 0) {
            setPageNumber(team && team.page);
            setValue((team !== undefined && team.query) ?? null);
            setQuery((team !== undefined && team.query) ?? null);
            setHasMore(true);
            setInitial(true);
            return;
        }

        getAllTeamMember();
    }, []);

    useEffect(() => {
        if ((initial && pageNumber === 0) || !hasMore || (pageNumber === 0 && hasMore)) {
            return;
        }

        if (initial) {
            setInitial(false);
            return;
        }

        setLoading(true);
    }, [pageNumber]);

    const loaderRef = useCallback(
        (node: any) => {
            if (loading) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver(entries => {});
            if (node) observer.current.observe(node);
        },
        [loading, hasMore]
    );

    const getAllTeamMember = (id = null) => {
        setLoading(true);
        api.get(`/team?page=${pageNumber}&pageSize=${20}`)
            .then((team: any) => {
                setTeam(team.data);
                if (
                    team.data.team?.length &&
                    router.query[Object.keys(router.query)[0]] === '' &&
                    window.innerWidth > 767
                ) {
                    navigate(`/team/team-list/${id ? id : team.data.team[0]._id}`);
                }
                setHasMore(team.data.team?.length > 0);
                setLoading(false);
            })
            .catch((e: any) => {
                if (e?.response) {
                    openSnackbar(e?.response?.data?.message);
                }
            });
    };

    const searchStaff = (query: string) => {
        let value = query;
        if (Number.isInteger(parseInt(value)) === true) {
            if (value?.length <= 3) {
                value = value.replace(/[- .]/g, '');
            } else if (value?.length <= 7) {
                value = value.replace(/[- .]/g, '');
                value = value.slice(0, 3) + '-' + value.slice(3, 6);
            } else if (value?.length >= 7) {
                value = value.replace(/[- .]/g, '');
                value = value.slice(0, 3) + '-' + value.slice(3, 6) + '-' + value.slice(6);
            }
            setLoading(true);
            api.get(`/team?page=${0}&pageSize=${20}&query=${value}`)
                .then((team: any) => {
                    setLoading(false);
                    setTeam(team.data);
                    setHasMore(team.data.team?.length > 0);
                })
                .catch((e: any) => {
                    setLoading(false);
                    if (e?.response) {
                        openSnackbar(e?.response?.data?.message);
                    }
                });
        } else {
            setLoading(true);
            api.get(`/team?page=${0}&pageSize=${20}&query=${value}`)
                .then((team: any) => {
                    setLoading(false);
                    setTeam(team.data);
                    setHasMore(team.data.team?.length > 0);
                })
                .catch((e: any) => {
                    setLoading(false);
                    if (e?.response) {
                        openSnackbar(e?.response?.data?.message);
                    }
                });
        }
    };

    const searchStaffDebounce = useMemo(() => {
        return _.debounce((searchVal: string) => {
            setHasMore(true);
            setQuery(searchVal);
            setPageNumber(0);

            searchStaff(searchVal);
        }, 300);
    }, []);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
        searchStaffDebounce(event.target.value);
    };

    return (
        <>
            {' '}
            {createModel ? (
                <CreateTeamMember
                    onClose={() => {
                        setCreateModel(false);
                        team?.team[0]?._id && navigate(`/team/team-list/${team?.team[0]?._id}`);
                    }}
                    onSave={(id: any) => {
                        setCreateModel(false);
                        getAllTeamMember(id);
                    }}
                />
            ) : (
                <Wrapper>
                    {loading && <LoaderOverlay />}
                    <CustomRow>
                        <CustomCol md={12} lg={12}>
                            <HeadingWrapper>
                                <CustomDiv>
                                    {_userData?.leftMenu === true && (
                                        <Button to="/team">
                                            <i className="fal fa-chevron-left"></i>
                                        </Button>
                                    )}
                                    <Header>{t('Team Members')}</Header>
                                </CustomDiv>
                                <AddButton
                                    onClick={() => {
                                        router.navigate(`/team/team-list/`);
                                        setCreateModel(true);
                                    }}>
                                    <i className="fal fa-plus"></i>
                                </AddButton>
                            </HeadingWrapper>
                            <OnSamllViewPort hasId={router.query['*']}>
                                <SearchField
                                    value={value}
                                    handleChange={handleChange}
                                    name={t(`Team member`)}
                                    setValue={() => {
                                        setValue('');
                                        if (value) {
                                            searchStaff('');
                                        }
                                    }}
                                />
                                <TeamList>
                                    {team?.team?.length
                                        ? team.team.map((team: ITeam, i: any) => {
                                              const queryId =
                                                  router.query[Object.keys(router.query)[0]];
                                              return (
                                                  <ContentList
                                                      key={i}
                                                      queryId={`${
                                                          (queryId as string) === team._id
                                                              ? 'isActivelist'
                                                              : ''
                                                      }`}
                                                      link={`/team/team-list/${team._id}`}
                                                      icon={team?.photo}
                                                      name={team.name}
                                                      email={team?.email}
                                                      phone={team.phone}
                                                  />
                                              );
                                          })
                                        : loading === false && (
                                              <NotFound>{t('No Team member found')}</NotFound>
                                          )}
                                    <div ref={loaderRef}></div>
                                </TeamList>
                            </OnSamllViewPort>
                        </CustomCol>
                    </CustomRow>
                </Wrapper>
            )}
        </>
    );
});
const Wrapper = styled(Container)`
    flex: 0 0 35rem;
    margin-top: 1rem;
    margin-left: 0rem;
    background: ${palette.white};
    box-shadow: inset -1.80118px -2.70178px 9.00592px rgba(0, 0, 0, 0.25);
    border-radius: 4.50296px;
    ${media.xs`
    flex: 0 0 35rem;
    `}
    ${media.sm`
    flex: 0 0 35rem;
    `}
	${media.md`
    padding: 0;
     flex: 0 0 27rem;
    `}
	@media screen and (min-width: 1076px) and (max-width: 1140px) {
        flex: 0 0 27rem;
    }
    @media screen and (min-width: 1032px) and (max-width: 1075px) {
        flex: 0 0 18rem;
    }
    @media screen and (min-width: 992px) and (max-width: 1031px) {
        flex: 0 0 16rem;
    }

    ${media.lg`
    padding: 0;
    background-color: white;
    `}
    ${media.xl`
    padding: 0;
    background-color: white;
`}
`;
const CustomRow = styled(Row)`
    position: relative;
    margin: 0;
`;
const CustomCol = styled(Col)`
    padding: 0;
`;
const Header = styled.h1`
    font-size: 1.78rem;
    font-weight: 600;
    margin: 0;
`;

const Button = styled(Link)`
    margin-right: 1.25rem /* 20px */;
    font-size: 1.75rem /* 30px */;
    line-height: 2.25rem /* 36px */;
`;
const TeamList = styled.div`
    margin-top: 1rem;
    height: 40rem;
    overflow: auto;
`;
const OnSamllViewPort = styled.span<IProps>`
    display: block;
    ${media.xs`
	display: ${({ hasId }: any) => (hasId ? 'none' : 'block')};
	`}
    ${media.sm`
	display: ${({ hasId }: any) => (hasId ? 'none' : 'block')};
	`}
	${media.md`
    display: block;
	`}
	${media.lg`
    display: block;
	`}
`;

const HeadingWrapper = styled.div`
    padding: 1rem 1rem 0.5rem 1.71rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
export default TeamMemberList;
